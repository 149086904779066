import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Menu from '../../components/menu/Menu'
import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import RouteHeader from '../../components/layout/RouteHeader'

const ClubMenuScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)

  return (
    <RouteContainer>
      <RouteHeader>{t('menu.manage')}</RouteHeader>
      {clubId && <Menu clubId={clubId} />}
    </RouteContainer>
  )
}

export default ClubMenuScreen
