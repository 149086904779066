import { useLocation } from 'react-router-dom'

import Routes from './Routes'
import AppLayout from './components/layout/AppLayout'
import PageLayout from './components/layout/PageLayout'
import AppBar from './components/AppBar'
import useIsResettingPassword from './hooks/useIsResettingPassword'

const Content = () => {
  const location = useLocation()
  const isLoginScreen = location.pathname === '/login'
  const isResettingPassword = useIsResettingPassword()

  return (
    <AppLayout>
      <PageLayout
        header={
          !isLoginScreen && !isResettingPassword && (
            <AppBar />
          )
        }
      >
        <Routes />
      </PageLayout>
    </AppLayout>
  )
}

export default Content
