import { useContext } from 'react'
import { useClubOrderDeliveryPlaces } from '@itsilesia/udrink-common-components'

import { ClubContext } from '../../components/ClubContext'
import useDataList from '../../hooks/useDataList'
import OrderDeliveryPlacesList from '../../components/clubs/OrderDeliveryPlacesList'
import RouteContainer from '../../components/layout/RouteContainer'

const ClubOrderDeliveryPlacesScreen = () => {
  const { clubId } = useContext(ClubContext)

  const useResource = ({ page, size }) =>
    useClubOrderDeliveryPlaces(clubId, {
      page,
      size,
      sort: 'name',
    })

  const { handleChangePage, handleChangeRowsPerPage, ...props } = useDataList(
    `${clubId}-order-delivery-places`,
    useResource,
  )

  return (
    <RouteContainer>
      {clubId ? <OrderDeliveryPlacesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> : <div />}
    </RouteContainer>
  )
}

export default ClubOrderDeliveryPlacesScreen
