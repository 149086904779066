import { useParams } from 'react-router-dom'
import { useClubOrderPreparationPlaces } from '@itsilesia/udrink-common-components'

import useDataList from '../hooks/useDataList'
import OrderPreparationPlacesList from '../components/clubs/OrderPreparationPlacesList'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'

const OrderPreparationPlacesScreen = () => {
  const { clubId } = useParams()

  const useResource = ({ page, size }) =>
    useClubOrderPreparationPlaces(clubId, {
      page,
      size,
      sort: 'name',
    })

  const { handleChangePage, handleChangeRowsPerPage, ...props } = useDataList(
    `${clubId}-order-preparation-places`,
    useResource,
  )

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <OrderPreparationPlacesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default OrderPreparationPlacesScreen
