const isToDateValid = (to, customStart) => {
  const currentTime = new Date().getTime()
  const fromTime = customStart.getTime()
  const toTime = new Date(to).getTime()

  if (toTime >= currentTime) {
    throw new Error('statistics.timePeriod.errors.futureDate')
  }

  if (toTime <= fromTime) {
    throw new Error('statistics.timePeriod.errors.tooEarlyDate')
  }
}

export default isToDateValid
