import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import styled from 'styled-components/macro'

import stopPropagateEventFactory from '../../../utils/stopPropagateEventFactory'

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.55;
  transition: opacity 200ms ease;
  cursor: default;
  ${({ visible }) => !visible && 'visibility: hidden;'}

  &:hover {
    opacity: 1;
  }
`

const StyledButton = styled(Button)`
  min-width: 35px;
`

const StyledTopButton = styled(StyledButton)`
  padding: 6px 0 0;
`

const StyledBottomButton = styled(StyledButton)`
  padding: 0 0 6px;
`

const SubcategoryReorderPanel = ({
  onOrderUp: handleOrderUp,
  onOrderDown: handleOrderDown,
  disabledUp,
  disabledDown,
}) => {
  const { t } = useTranslation()

  return (
    <ButtonsContainer
      visible={!disabledUp || !disabledDown}
      onClick={stopPropagateEventFactory()}
    >
      <Tooltip
        title={!disabledUp ? t('menu.reorder.up') : ''}
        aria-label={t('menu.reorder.up')}
        TransitionProps={{
          timeout: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        }}
      >
        <StyledTopButton
          disabled={disabledUp}
          onClick={handleOrderUp}
        >
          <ArrowDropUp viewBox="0 -5 24 24" />
        </StyledTopButton>
      </Tooltip>
      <Tooltip
        title={!disabledDown ? t('menu.reorder.down') : ''}
        aria-label={t('menu.reorder.up')}
        TransitionProps={{
          timeout: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        }}
      >
        <StyledBottomButton
          disabled={disabledDown}
          onClick={handleOrderDown}
        >
          <ArrowDropDown viewBox="0 5 24 24" />
        </StyledBottomButton>
      </Tooltip>
    </ButtonsContainer>
  )
}

SubcategoryReorderPanel.propTypes = {
  onOrderUp: PropTypes.func,
  onOrderDown: PropTypes.func,
  disabledUp: PropTypes.bool,
  disabledDown: PropTypes.bool,
}

SubcategoryReorderPanel.defaultProps = {
  onOrderUp: () => {},
  onOrderDown: () => {},
  disabledUp: false,
  disabledDown: false,
}

export default SubcategoryReorderPanel
