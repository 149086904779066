import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'

const ProductsListActions = ({ row }) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={t('tableTooltips.edit')}
      aria-label={t('tableTooltips.edit')}
    >
      <IconButton component={Link} to={`/clubs/${row.clubId}/products/${row.id}/edit`}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}

ProductsListActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clubId: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductsListActions
