import * as yup from 'yup'

const sentryFeedbackSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('feedback.errors.required.name'),
  email: yup
    .string()
    .email('feedback.errors.valid.email')
    .required('feedback.errors.required.email'),
  comments: yup
    .string()
    .required('feedback.errors.required.comments'),
})

export default sentryFeedbackSchema
