import BILLING_TYPES from '../constants/billingTypes'

const mapTaxRates = data => data.map(vatRate => ({ ...vatRate, rate: vatRate.rate || 0 }))

const mapToAPIUpdateTaxRate = data => ({
  ...data,
  removeRate: data.billingType !== BILLING_TYPES.TAXED.type,
})

export { mapToAPIUpdateTaxRate }
export default mapTaxRates
