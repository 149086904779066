import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const orderDeliveryPlaceSchema = yup.object().shape({
  name: yup
    .string()
    .required('orderDelivery.errors.name.required'),
  description: yup
    .string()
    .max(1000, messageObject('orderDelivery.errors.description.max')),
})

export default orderDeliveryPlaceSchema
