const CATEGORIES = Object.freeze({
  FOOD: {
    value: 'FOOD',
    code: 'menu.categories.food',
    info: '',
  },
  ALCOHOLIC: {
    value: 'ALCOHOLIC',
    code: 'menu.categories.alcohol',
    info: '',
  },
  NON_ALCOHOLIC: {
    value: 'NON_ALCOHOLIC',
    code: 'menu.categories.beverages',
    info: '',
  },
  CIGARETTES: {
    value: 'CIGARETTES',
    code: 'menu.categories.cigarettes',
    info: 'menu.info.categories.cigarettes',
  },
})

export default CATEGORIES
