import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useClub,
  useUser,
  useOrderDeliveryPlace,
  useOrderPreparationPlace,
} from '@itsilesia/udrink-common-components'
import get from 'lodash/get'

const ClubName = ({ params, children }) => {
  const { data } = useClub(params.id)
  const name = useMemo(() => get(data, 'club.name', ''), [data])
  return children(name)
}

const UserName = ({ params, children }) => {
  const { data } = useUser(params.id)
  const username = useMemo(() => get(data, 'username', ''), [data])
  return children(username)
}

const OrderPreparationPlaceName = ({ params, children }) => {
  const { data } = useOrderPreparationPlace(params.id)
  const name = useMemo(() => get(data, 'name', ''), [data])
  return children(name)
}

const OrderDeliveryPlaceName = ({ params, children }) => {
  const { data } = useOrderDeliveryPlace(params.id)
  const name = useMemo(() => get(data, 'name', ''), [data])
  return children(name)
}

const NamePropTypes = {
  params: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
}

ClubName.propTypes = { ...NamePropTypes }
UserName.propTypes = { ...NamePropTypes }
OrderPreparationPlaceName.propTypes = { ...NamePropTypes }
OrderDeliveryPlaceName.propTypes = { ...NamePropTypes }

export {
  ClubName,
  UserName,
  OrderPreparationPlaceName,
  OrderDeliveryPlaceName,
}
