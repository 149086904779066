import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import TIME_PERIOD from '../../../../../constants/timePeriod'
import TimePeriodPropTypes from '../../../../../propTypes/TimePeriodPropTypes'
import parseISODate from '../../../../../utils/parseISODate'
import { GRAY } from '../../../../../constants/colors'

const Container = styled.div`
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
`

const DateText = styled.span`
  font-weight: 800;
`

const Header = styled.header`
  text-align: right;
  font-size: 12px;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 30px 0;
`

const Card = styled.div`
  min-width: 140px;
  padding: 8px 13px;
  margin-left: 6px;
  border-radius: 5px;
  background-color: ${GRAY};
`

const CardValue = styled.header`
  margin: 0;
  ${({ isSolo }) => isSolo && 'padding: 6px;'}
  font-size: 16px;
  font-weight: 800;
`

const CardTextHelper = styled.span`
  display: block;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
`

const PeriodSummary = ({ timePeriod, config, from, to }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Header>
        <span>{t(timePeriod.sumCode)}</span>
        {TIME_PERIOD.CUSTOM.value === timePeriod.value && (
          <>
            <span>{` ${t('statistics.from').toLowerCase()} `}</span>
            <DateText>{`${parseISODate(from)} `}</DateText>
            <span>{`${t('statistics.to').toLowerCase()} `}</span>
            <DateText>{parseISODate(to)}</DateText>
          </>
        )}
        <span>:</span>
      </Header>
      <CardContainer>
        {config.map(({ key, value, textHelper }) => (
          <Card key={key}>
            <CardValue isSolo={!textHelper}>{value}</CardValue>
            <CardTextHelper>{textHelper}</CardTextHelper>
          </Card>
        ))}
      </CardContainer>
    </Container>
  )
}

PeriodSummary.propTypes = {
  timePeriod: TimePeriodPropTypes,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      textHelper: PropTypes.string,
    }),
  ),
  from: PropTypes.string,
  to: PropTypes.string,
}

PeriodSummary.defaultProps = {
  timePeriod: {},
  config: [],
  from: '',
  to: '',
}

export default PeriodSummary
