const ADMIN_ROLE = 'ADMIN'
const MANAGER_ROLE = 'MANAGER'
const BARTENDER_ROLE = 'BARTENDER'

const ROLE_GROUPS = Object.freeze({
  [ADMIN_ROLE]: {
    value: ADMIN_ROLE,
    code: 'user.role.admin',
    accessRole: [ADMIN_ROLE],
  },
  [MANAGER_ROLE]: {
    value: MANAGER_ROLE,
    code: 'user.role.manager',
    accessRole: [ADMIN_ROLE, MANAGER_ROLE],
  },
  [BARTENDER_ROLE]: {
    value: BARTENDER_ROLE,
    code: 'user.role.bartender',
    accessRole: [ADMIN_ROLE, MANAGER_ROLE, BARTENDER_ROLE],
  },
})

export default ROLE_GROUPS

export {
  ADMIN_ROLE,
  MANAGER_ROLE,
  BARTENDER_ROLE,
}
