import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { API, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'
import get from 'lodash/get'
import styled from 'styled-components/macro'
import useSWR from 'swr'

import {
  createClubMenu,
  updateMenuItems,
  updateMenuStructure,
  deleteProductImage,
  createProductImage,
  createSubcategoryImage,
  deleteSubcategoryImage,
} from '../../../services/api/menus'
import { UPLOAD_IMAGE } from '../../../constants/menuEditEvents'
import CATEGORIES from '../../../constants/categories'
import MenuForm from '../../forms/MenuForm'
import useMenuForm from '../../../hooks/useMenuForm'
import reduceItemsEvents from '../../../utils/reduceItemsEvents'
import useSnackbar from '../../../hooks/useSnackbar'
import getResponseErrorCodes from '../../../utils/getResponseErrorCodes'
import IdPropType from '../../../propTypes/IdPropType'
import parseCodeToI18nMessage from '../../../utils/parseCodeToI18nMessage'
import { FormButton } from '../../inputs'

const FullHeightContainer = styled.div`
  height: 100%;
`

const AlertContainer = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  grid-gap: 10px;
`

const Menu = ({ clubId }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const [isSubmitting, setSubmitting] = useState(false)
  const [activeCategory, setActiveCategory] = useState(CATEGORIES.ALCOHOLIC.value)
  const [subcategoriesToDeleteImage, setSubcategoriesToDeleteImage] = useState([])

  const {
    revalidate,
    activeMenu,
    pushEvent,
    isValidating,
    pipeState,
    clearEvents,
    error,
  } = useMenuForm({
    clubId,
  })

  const {
    data: isClubOpen,
    isLoading,
  } = useSWR(() => API.clubIsOpen.get(clubId).key)

  useTrackLoadingStatus(`${clubId}-menu`, isValidating)

  const handleNewMenu = async () => {
    await createClubMenu(clubId)
    await revalidate()
  }
  const formDisabled = isValidating || isSubmitting || isClubOpen

  const handleSubmit = async () => {
    const itemsEvents = reduceItemsEvents(pipeState.pipe)
    const categoryOrder = Object.values(activeMenu?.categories).map(category => category.id)
    const images = pipeState.pipe.filter(event => event.type === UPLOAD_IMAGE)

    try {
      setSubmitting(true)
      await updateMenuItems(`${activeMenu.id}`, itemsEvents)
      await updateMenuStructure(`${activeMenu.id}`, categoryOrder)

      await Promise.all(
        images.map(async (image) => {
          if (Object.prototype.hasOwnProperty.call(image.payload, 'productUuid')) {
            const { productUuid, files } = image.payload
            const product = activeMenu.menuProducts.find(entry => entry.uuid === productUuid)
            if (!product) {
              return
            }

            const imageUuid = get(product, 'images.[0].imageUuid', null)
            if (imageUuid) {
              await deleteProductImage(imageUuid)
            }

            if (files.length !== 0) {
              await createProductImage(productUuid, files[0])
            }
          }
          if (Object.prototype.hasOwnProperty.call(image.payload, 'subcategoryUuid')) {
            const { subcategoryUuid, files } = image.payload

            const subcategory = activeMenu.categories[activeCategory].subcategories.find(
              entry => entry.uuid === subcategoryUuid,
            )

            if (!subcategory) {
              return
            }

            const subcategoryImageUuid = get(subcategory, 'images.[0].imageUuid', null)

            if (subcategoryImageUuid) {
              await deleteSubcategoryImage(subcategoryImageUuid)
            }

            if (files.length !== 0) {
              await createSubcategoryImage(subcategoryUuid, files[0])
            }
          }
        }),
      )
      await revalidate()
      clearEvents()
      addSnackbar(t('menu.success.update'))
    } catch (err) {
      const codes = getResponseErrorCodes(err)

      if (codes.length) {
        codes.forEach((code) => {
          addSnackbar(t(...parseCodeToI18nMessage(code)))
        })
      } else {
        addSnackbar(t('common.errors.generic'))
      }
    }
    setSubmitting(false)
  }

  const getSubcategoriesImagesDeleted = () => {
    const pipelineImages = pipeState.pipe.filter(event => event.type === UPLOAD_IMAGE)

    pipelineImages.forEach((image) => {
      if (Object.prototype.hasOwnProperty.call(image.payload, 'subcategoryUuid')) {
        const { subcategoryUuid, files } = image.payload
        if (files.length === 0) {
          setSubcategoriesToDeleteImage(prevSubcategoriesToDeleteImage => [
            ...prevSubcategoriesToDeleteImage, {
              subcategoryUuid,
            }])
        }
      }
    })
  }

  useEffect(() => {
    if (pipeState) {
      setSubcategoriesToDeleteImage([])
      getSubcategoriesImagesDeleted()
    }
  }, [pipeState]) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <FullHeightContainer>
        <Typography>{t('common.errors.generic')}</Typography>
      </FullHeightContainer>
    )
  }

  if (!isValidating && !activeMenu) {
    return (
      <FullHeightContainer>
        <Typography>{t('menu.noActiveMenu')}</Typography>
        <FormButton onClick={handleNewMenu}>{t('common.create')}</FormButton>
      </FullHeightContainer>
    )
  }

  if (isValidating && !activeMenu) {
    return <FullHeightContainer />
  }

  return (
    <FullHeightContainer>
      {!isLoading && (
        <>
          {isClubOpen
          && (
            <AlertContainer color="red">
              <ErrorOutline color="red" />
              <Typography>
                {t('menu.cannotEditAlert') }
              </Typography>
            </AlertContainer>
          )}
          <MenuForm
            clubId={clubId}
            activeMenu={activeMenu.categories}
            pushEvent={pushEvent}
            disabled={formDisabled}
            onSubmit={handleSubmit}
            activeCategory={activeCategory}
            onActiveCategoryChange={setActiveCategory}
            isLoading={isValidating}
            subcategoriesToDeleteImage={subcategoriesToDeleteImage}
          />
        </>
      )}
    </FullHeightContainer>
  )
}

Menu.propTypes = {
  clubId: IdPropType.isRequired,
}

export default Menu
