import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import RouteHeader from '../../components/layout/RouteHeader'
import Modifiers from '../../components/modifiers/Modifiers'

const ClubModifiersScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)

  return (
    <RouteContainer>
      <RouteHeader>{t('modifiers.manage')}</RouteHeader>
      {clubId && <Modifiers clubId={clubId} />}
    </RouteContainer>
  )
}

export default ClubModifiersScreen
