export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const CREATE_PRODUCT_EVENT = 'CREATE'
export const UPDATE_PRODUCT_EVENT = 'UPDATE'
export const DELETE_PRODUCT_EVENT = 'REMOVE'
export const ADD_MODIFIER = 'ADD_MODIFIER'
export const REMOVE_MODIFIER = 'REMOVE_MODIFIER'
export const CHANGE_SUBCATEGORY_ORDER = 'CHANGE_SUBCATEGORY_ORDER'
export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY'
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY'
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY'
