import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import FormModePropType from '../../../propTypes/FormModePropType'
import { UPDATE, CREATE } from '../../../constants/formModes'
import {
  GeneralSection,
} from './formSections'
import FormikForm from '../StyledFormikForm'
import GenerateCodeSection from './formSections/GenerateCodeSection'
import SectionBody from './formSections/section/SectionBody'
import FormContent from './formSections/section/FormContent'
import LimitationsSection from './formSections/LimitationsSection/LimitationsSection'
import FormFooter from '../FormFooter'

const StyledFormikForm = styled(FormikForm)`
  padding-bottom: 25px;
`

const DiscountCodeForm = ({
  mode,
  values,
  isSubmitting,
  onCancel,
  setFieldValue,
}) => {
  const confirmMessageKey = useMemo(
    () => (mode === UPDATE ? 'common.update' : 'common.save'),
    [mode],
  )

  return (
    <StyledFormikForm>
      <FormContent>
        <SectionBody>
          <GeneralSection values={values} setFieldValue={setFieldValue} />
        </SectionBody>
        <SectionBody>
          <GenerateCodeSection discountCode={values.discountCode} />
        </SectionBody>
        <SectionBody>
          <LimitationsSection values={values} setFieldValue={setFieldValue} />
        </SectionBody>
      </FormContent>
      <FormFooter
        onCancel={onCancel}
        confirmMessageKey={confirmMessageKey}
        disabled={isSubmitting}
      />
    </StyledFormikForm>
  )
}

DiscountCodeForm.propTypes = {
  mode: FormModePropType,
  onCancel: PropTypes.func.isRequired,
  values: {
    name: PropTypes.string,
    description: PropTypes.string,
    discountCode: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
    totalUserAmount: PropTypes.number,
    minimumOrderPrice: PropTypes.number,
    totalAmount: PropTypes.number,
    totalDiscountPrice: PropTypes.number,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    status: PropTypes.string,
  },
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

DiscountCodeForm.defaultProps = {
  mode: CREATE,
  values: {
    name: '',
    description: '',
    discountCode: '',
    type: '',
    value: 0,
    totalUserAmount: 0,
    minimumOrderPrice: 0,
    totalAmount: 0,
    totalDiscountPrice: 0,
    dateFrom: '',
    dateTo: '',
    status: '',
  },
}

export default DiscountCodeForm
