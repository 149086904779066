import * as yup from 'yup'

const fiservAccessDataEditSchema = yup.object().shape({
  storeId: yup.string().required('clubs.errors.required.fiservAccessData.storeId'),
  test: yup.boolean(),
})

const fiservAccessDataCreateSchema = fiservAccessDataEditSchema.shape({
  sharedSecret: yup.string().required('clubs.errors.required.fiservAccessData.sharedSecret'),
  apiKey: yup.string().required('clubs.errors.required.fiservAccessData.apiKey'),
  secretKey: yup.string().required('clubs.errors.required.fiservAccessData.secretKey'),
})

export { fiservAccessDataEditSchema, fiservAccessDataCreateSchema }
