import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tooltip, IconButton, Button } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import styled from 'styled-components/macro'
import stopPropagateEventFactory from '../../../utils/stopPropagateEventFactory'

const Container = styled.div`
  display: inline-block;
  margin: 10px;
`

const ModifierActions = ({
  onEditDialogOpen: handleEditDialogOpen,
  onCreateOptionDialogOpen: handleCreateOptionDialogOpen,
  onDelete: handleDelete,
}) => {
  const { t } = useTranslation()

  return (
    <Container onClick={stopPropagateEventFactory()}>
      <Button onClick={handleCreateOptionDialogOpen}>
        {t('modifiers.addOption')}
      </Button>
      <Tooltip title={t('tableTooltips.delete')} aria-label={t('tableTooltips.delete')}>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tableTooltips.edit')} aria-label={t('tableTooltips.edit')}>
        <IconButton onClick={handleEditDialogOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Container>
  )
}

ModifierActions.propTypes = {
  onCreateOptionDialogOpen: PropTypes.func,
  onEditDialogOpen: PropTypes.func,
  onDelete: PropTypes.func,
}

ModifierActions.defaultProps = {
  onCreateOptionDialogOpen: () => {},
  onEditDialogOpen: () => {},
  onDelete: () => {},
}

export default memo(ModifierActions)
