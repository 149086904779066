import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import noop from 'lodash/noop'
import {
  LogInTextField as TextField,
  LogInButton as Button,
} from '../../inputs'

const Form = styled.form`
  transform: translateY(-33%);
  padding: 10px;
  margin: 0 auto;
  width: 300px;
`
const Header = styled.h2`
  text-align: center;
  font-size: 34px;
  font-weight: 900;
  margin-bottom: 0;
  color: #f86675;
`
const SubTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #f86675;
`
const ErrorMessage = styled.div`
  padding-top: 4px;
  font-size: 11px;
  color: #ff5465;
`

const SignInForm = ({
  values,
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  isInvalidData,
  isInvalidRole,
  handleSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <Form onSubmit={handleSubmit}>
      <Header>SoEasy</Header>
      <SubTitle>{t('common.titles.adminPanel')}</SubTitle>
      <TextField
        type="email"
        placeholder={t('common.email')}
        errorMessage={t(errors.username)}
        name="username"
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        type="password"
        placeholder={t('common.password')}
        errorMessage={t(errors.password)}
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {isInvalidData ? <ErrorMessage>{t('signIn.errors.wrongCredentials')}</ErrorMessage> : null}
      {isInvalidRole ? <ErrorMessage>{t('signIn.errors.wrongRole')}</ErrorMessage> : null}
      <Button disabled={isSubmitting} isLoading={isSubmitting}>
        {t('common.signIn').toUpperCase()}
      </Button>
    </Form>
  )
}

SignInForm.defaultProps = {
  handleChange: noop,
  handleBlur: noop,
  handleSubmit: noop,
  isSubmitting: false,
  isInvalidData: false,
  isInvalidRole: false,
  errors: {
    username: null,
    password: null,
  },
}

SignInForm.propTypes = {
  values: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isInvalidData: PropTypes.bool,
  isInvalidRole: PropTypes.bool,
}

export default SignInForm
