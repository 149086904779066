import ActionsCell from './ProductsListActions'

const columns = [
  {
    key: 'name',
    prop: 'name',
    label: { translationKey: 'product.title' },
  },
  {
    key: 'subcategory',
    prop: 'subcategory.name',
    label: { translationKey: 'common.subcategory' },
  },
  {
    key: 'price',
    prop: 'price',
    label: { translationKey: 'common.price' },
  },
  {
    key: 'actions',
    label: { translationKey: 'drawer.actions.title' },
    component: ActionsCell,
    alignText: 'right',
  },
]

export default columns
