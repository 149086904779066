import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from '@material-ui/core'

import TIME_SCALE from '../../../../../constants/timeScale'

const TimeScalePicker = ({ activeTimeScale, onChange: handleChange }) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup variant="contained">
      {Object.values(TIME_SCALE).map(({ value, code }) => (
        <Button
          key={value}
          color={activeTimeScale === value ? 'primary' : 'default'}
          onClick={() => handleChange(value)}
        >
          {t(code)}
        </Button>
      ))}
    </ButtonGroup>
  )
}

TimeScalePicker.propTypes = {
  activeTimeScale: PropTypes.string,
  onChange: PropTypes.func,
}

TimeScalePicker.defaultProps = {
  activeTimeScale: '',
  onChange: () => {},
}

export default memo(TimeScalePicker)
