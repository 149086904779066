import { createSlice } from '@reduxjs/toolkit'

const initialState = null

const currentClubModule = createSlice({
  name: 'selectedClub',
  initialState,
  reducers: {
    change(_, action) {
      const { payload: newClubId } = action
      return newClubId
    },
  },
})

export default currentClubModule
