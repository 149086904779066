const isFromDateValid = (from, customEnd) => {
  const currentTime = new Date().getTime()
  const fromTime = new Date(from).getTime()
  const toTime = customEnd.getTime()

  if (fromTime >= currentTime) {
    throw new Error('statistics.timePeriod.errors.futureDate')
  }

  if (fromTime >= toTime) {
    throw new Error('statistics.timePeriod.errors.tooLateDate')
  }
}

export default isFromDateValid
