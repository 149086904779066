const BILLING_TYPES = {
  TAXED: {
    type: 'TAXED',
    i18n: 'taxRates.billingTypes.taxed',
  },
  TAX_FREE: {
    type: 'TAX_FREE',
    i18n: 'taxRates.billingTypes.taxFree',
  },
  RESERVE: {
    type: 'RESERVE',
    i18n: 'taxRates.billingTypes.reserve',
  },
}

export default BILLING_TYPES
