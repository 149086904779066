import { createContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const OrderDeliveryPlaceContext = createContext(null)

const OrderDeliveryPlaceContextProvider = ({ children }) => {
  const [orderDeliveryPlaceId, setOrderDeliveryPlaceId] = useState(null)
  const [
    isDeleteOrderDeliveryPlaceDialogOpen,
    setIsDeleteOrderDeliveryPlaceDialogOpen,
  ] = useState(false)

  const values = useMemo(() => ({
    orderDeliveryPlaceId,
    isDeleteOrderDeliveryPlaceDialogOpen,
    setOrderDeliveryPlaceId,
    setIsDeleteOrderDeliveryPlaceDialogOpen,
  }), [
    orderDeliveryPlaceId,
    isDeleteOrderDeliveryPlaceDialogOpen,
    setOrderDeliveryPlaceId,
    setIsDeleteOrderDeliveryPlaceDialogOpen,
  ])

  return (
    <OrderDeliveryPlaceContext.Provider
      value={values}
    >
      {children}
    </OrderDeliveryPlaceContext.Provider>
  )
}

OrderDeliveryPlaceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OrderDeliveryPlaceContextProvider
