import { useMemo, useCallback } from 'react'
import { NumberParam, useQueryParams } from 'use-query-params'

const useQueryPagination = (defaultSize = 25) => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    size: NumberParam,
  })

  const setPage = useCallback(
    (page, updateType = 'pushIn') => setQuery({ page }, updateType),
    [setQuery],
  )

  const setPageSize = useCallback(
    (size, updateType = 'pushIn') => setQuery({ size, page: 0 }, updateType),
    [setQuery],
  )

  const size = query.size || defaultSize
  const page = query.page || 0

  return useMemo(
    () => ({
      setPage,
      setPageSize,
      size,
      page,
    }),
    [size, page, setPage, setPageSize],
  )
}

export default useQueryPagination
