import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import useModifiersForm from '../../../hooks/useModifiersForm'
import useSnackbar from '../../../hooks/useSnackbar'
import IdPropType from '../../../propTypes/IdPropType'
import getResponseErrorCodes from '../../../utils/getResponseErrorCodes'
import parseCodeToI18nMessage from '../../../utils/parseCodeToI18nMessage'
import reduceModifierEvents from '../../../utils/reduceModifierEvents'
import ModifiersForm from '../../forms/ModifiersForm'
import { updateModifiers } from '../../../services/api/modifiers'

const FullHeightContainer = styled.div`
  height: 100%;
`

const Modifiers = ({ clubId }) => {
  const { t } = useTranslation()
  const [isSubmitting, setSubmitting] = useState(false)
  const { addSnackbar } = useSnackbar()
  const {
    revalidate,
    modifiers,
    pushEvent,
    isValidating,
    pipeState,
    clearEvents,
    error,
  } = useModifiersForm({ clubId })

  useEffect(() => {
    if (error) {
      addSnackbar('common.errors.generic')
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const isNoOptions = !modifiers || modifiers.some(mod => mod.options.length === 0)

  const formDisabled = pipeState.pipe.length === 0 || isValidating || isSubmitting

  const handleSubmit = async () => {
    if (isNoOptions) {
      addSnackbar(t('modifiers.noOptions'))
    } else {
      const modifierEvents = reduceModifierEvents(pipeState.pipe)
      setSubmitting(true)
      try {
        await updateModifiers(clubId, modifierEvents)
        await revalidate()
        clearEvents()
        addSnackbar(t('modifiers.success'))
      } catch (err) {
        const codes = getResponseErrorCodes(err)

        if (codes.length) {
          codes.forEach((code) => {
            addSnackbar(t(...parseCodeToI18nMessage(code)))
          })
        } else {
          addSnackbar(t('common.errors.generic'))
        }
      }

      setSubmitting(false)
    }
  }

  return (
    <FullHeightContainer>
      <ModifiersForm
        pushEvent={pushEvent}
        modifiers={modifiers}
        disabled={formDisabled}
        onSubmit={handleSubmit}
      />
    </FullHeightContainer>
  )
}

Modifiers.propTypes = {
  clubId: IdPropType.isRequired,
}

export default Modifiers
