import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts'

import {
  DEEPER_BLUE,
  DEEPER_GREEN,
} from '../../../../../constants/colors'
import { ORDERS, TIPS } from '../../../../../constants/orders'
import TIME_SCALE from '../../../../../constants/timeScale'
import TIME_PERIOD from '../../../../../constants/timePeriod'
import OrdersTooltip from './OrdersTooltip'
import ChartSettings from '../../chart/ChartSettings'
import { StatisticHeader, StatisticContainer } from '../../statistic'
import TimeScalePropTypes from '../../../../../propTypes/TimeScalePropTypes'
import TimePeriodPropTypes from '../../../../../propTypes/TimePeriodPropTypes'
import OrdersLegend from './OrdersLegend'
import { yAxisConfig } from '../../chart/config'
import OrderSVGSrc from '../../../../../assets/images/orders.svg'

const OrdersStatisticView = ({
  data,
  ordersCount,
  tipsCount,
  timeScale,
  timePeriod,
  from,
  to,
  onTimeScaleChange: handleTimeScaleChange,
  onTimePeriodChange: handleTimePeriodChange,
}) => {
  const { t } = useTranslation()
  const [isTipsChecked, setIsTipsChecked] = useState(true)
  const [isOrdersChecked, setIsOrdersChecked] = useState(true)

  const bars = useMemo(() => {
    const toDisplay = []
    if (isOrdersChecked) {
      const ordersConfig = {
        key: '1',
        dataKey: ORDERS,
        fill: DEEPER_GREEN,
      }
      toDisplay.push(ordersConfig)
    }
    if (isTipsChecked) {
      const tipsConfig = {
        key: '2',
        dataKey: TIPS,
        fill: DEEPER_BLUE,
      }
      toDisplay.push(tipsConfig)
    }

    return toDisplay
  }, [isOrdersChecked, isTipsChecked])

  return (
    <StatisticContainer>
      <StatisticHeader
        iconSrc={OrderSVGSrc}
        timePeriod={timePeriod}
        from={from}
        to={to}
        summaryConfig={[{
          key: '1',
          value: ordersCount,
          textHelper: t('statistics.ordersGenitive'),
        }, {
          key: '2',
          value: tipsCount,
          textHelper: t('statistics.tipsGenitive'),
        }]}
      >
        {t('statistics.orders')}
      </StatisticHeader>
      <ChartSettings
        activeTimeScale={timeScale.value}
        activeTimePeriod={timePeriod.value}
        onTimeScaleChange={handleTimeScaleChange}
        onTimePeriodChange={handleTimePeriodChange}
        from={from}
        to={to}
      />
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={data}>
          <YAxis {...yAxisConfig} allowDecimals={false} />
          <XAxis dataKey="timeScale" interval="preserveEnd" />
          <Tooltip
            header={t('statistics.orders')}
            content={<OrdersTooltip timeScaleName={t(timeScale.code)} />}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {bars.map(({
            key,
            dataKey,
            fill,
          }) => (
            <Bar
              key={key}
              type="monotone"
              dataKey={dataKey}
              fill={fill}
            />
          ))}
          <Legend
            layout="horizontal"
            align="center"
            content={
              <OrdersLegend
                isOrdersChecked={isOrdersChecked}
                isTipsChecked={isTipsChecked}
                onOrdersClick={() => setIsOrdersChecked(checked => !checked)}
                onTipsClick={() => setIsTipsChecked(checked => !checked)}
              />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </StatisticContainer>
  )
}

OrdersStatisticView.propTypes = {
  data: PropTypes.array,
  timeScale: TimeScalePropTypes,
  timePeriod: TimePeriodPropTypes,
  onTimeScaleChange: PropTypes.func,
  onTimePeriodChange: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  tipsCount: PropTypes.number,
  ordersCount: PropTypes.number,
}

OrdersStatisticView.defaultProps = {
  data: [],
  timeScale: TIME_SCALE.HOUR,
  timePeriod: TIME_PERIOD.LAST_DAY,
  onTimeScaleChange: () => {},
  onTimePeriodChange: () => {},
  from: null,
  to: null,
  tipsCount: 0,
  ordersCount: 0,
}

export default OrdersStatisticView
