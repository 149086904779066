import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CropFree as CropFreeIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'

import { OrderPreparationPlaceContext } from '../../../providers/OrderPreparationPlaceContextProvider'
import IdPropType from '../../../propTypes/IdPropType'

const OrderPreparationPlacesListActions = ({ row }) => {
  const { t } = useTranslation()
  const {
    setOrderPreparationPlaceUuid,
    setOrderPreparationPlaceId,
    setIsDeleteOrderPreparationPlaceDialogOpen,
  } = useContext(OrderPreparationPlaceContext)

  return (
    <>
      <Tooltip
        title={t('tableTooltips.edit')}
        aria-label={t('tableTooltips.edit')}
      >
        <IconButton component={Link} to={`/clubs/${row.clubId}/order-preparation-places/${row.id}/edit`}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.qrCode')}
        aria-label={t('tableTooltips.qrCode')}
      >
        <IconButton onClick={() => setOrderPreparationPlaceUuid(row.uuid)}>
          <CropFreeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.delete')}
        aria-label={t('tableTooltips.delete')}
      >
        <IconButton onClick={() => {
          setOrderPreparationPlaceId(row.id)
          setIsDeleteOrderPreparationPlaceDialogOpen(true)
        }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

OrderPreparationPlacesListActions.propTypes = {
  row: PropTypes.shape({
    id: IdPropType.isRequired,
    clubId: IdPropType.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
}

export default OrderPreparationPlacesListActions
