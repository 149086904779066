import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'

import StyledSelect from './StyledSelect'
import WORK_ZONES from '../../../../constants/workZones'

const WorkZoneSelect = ({ flex, ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledSelect style={{ flex }} {...props}>
      {WORK_ZONES.map(({ value, code }) => (
        <MenuItem key={value} value={value}>
          {t(code)}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

WorkZoneSelect.propTypes = {
  flex: PropTypes.string,
}

WorkZoneSelect.defaultProps = {
  flex: '1',
}

export default WorkZoneSelect
