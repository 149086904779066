import PropTypes from 'prop-types'
import * as StyledPageLayout from './PageLayout.styles'

const PageLayout = ({ header, footer, children }) => (
  <StyledPageLayout.Container fillHeight>
    {header && <StyledPageLayout.HeaderContainer>{header}</StyledPageLayout.HeaderContainer>}
    {children && (
      <StyledPageLayout.ContentContainer>
        <StyledPageLayout.ScrollContainer>{children}</StyledPageLayout.ScrollContainer>
      </StyledPageLayout.ContentContainer>
    )}
    {footer && <StyledPageLayout.FooterContainer>{footer}</StyledPageLayout.FooterContainer>}
  </StyledPageLayout.Container>
)

PageLayout.defaultProps = {
  header: null,
  children: null,
  footer: null,
}

PageLayout.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
}

export default PageLayout
