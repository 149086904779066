import {
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { createSlice } from '@reduxjs/toolkit'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  TableHead,
  Checkbox,
} from '@material-ui/core'
import noop from 'lodash/noop'
import isObject from 'lodash/isObjectLike'
import difference from 'lodash/difference'

import DataTableRow from './DataTableRow'

const StyledTableContainer = styled(TableContainer)`
  position: relative;
  flex: 1;
  margin: ${({ theme }) => theme.spacing(3)}px 0;
  height: 60vh;
  overflow: auto;
  text-align: right;

  &:hover {
    ${({ theme }) => theme.scrollbar}
  }

  .MuiTableCell-head {
    font-weight: bold;
  }

  .MuiTableCell-stickyHeader {
    background: #fff;
  }
`

const EmptyArrayMessage = styled.span`
  position: absolute;
  top: 72px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`

const createInitialState = (rest = {}) => ({
  list: [],
  multipleSelect: false,
  ableWithoutSelection: false,
  ...rest,
})

const SelectedList = createSlice({
  name: 'SelectedList',
  initialState: createInitialState(),
  reducers: {
    addCheckbox(state, action) {
      if (!state.multipleSelect) {
        state.list = [action.payload]
      } else if (!state.list.includes(action.payload)) {
        state.list.push(action.payload)
      }
    },
    removeCheckbox(state, action) {
      if (state.list.length !== 1 || state.ableWithoutSelection) {
        state.list = state.list.filter(id => id !== action.payload)
      }
    },
    toggleCheckboxes(state, { payload: currentDisplayedIds }) {
      const currentCheckedIds = state.list.filter(id => currentDisplayedIds.includes(id))
      const notCheckedIds = difference(currentDisplayedIds, currentCheckedIds)

      if (!notCheckedIds.length) {
        state.list = state.list.filter(id => !currentDisplayedIds.includes(id))
      } else {
        state.list = [...state.list, ...notCheckedIds]
      }
    },
  },
})

const DataTable = ({
  columns,
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total: totalElements,
  rowsPerPageOptions,
  pageSize,
  page,
  enableSelect,
  multipleSelect,
  ableWithoutSelection,
  setSelectedIds,
  initialSelectedIds,
  emptyArrayMessage,
  isSingleItemSelect,
}) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(
    SelectedList.reducer,
    { multipleSelect, ableWithoutSelection, list: initialSelectedIds },
    createInitialState,
  )
  const isTableEmpty = !data.length

  const rowsIds = useMemo(() => data.map(row => row.id), [data])
  const isMultipleCheckboxChecked = useMemo(
    () => !difference(rowsIds, state.list.filter(id => rowsIds.includes(id))).length,
    [rowsIds, state.list],
  )

  useEffect(() => {
    if (enableSelect && setSelectedIds) setSelectedIds(state.list)
  }, [enableSelect, setSelectedIds, state])

  const handleCheckboxToggle = (e, rowId) =>
    (e.target.checked
      ? dispatch(SelectedList.actions.addCheckbox(rowId))
      : dispatch(SelectedList.actions.removeCheckbox(rowId)))

  const handleMultipleCheckbokToggle = useCallback(
    () => dispatch(SelectedList.actions.toggleCheckboxes(rowsIds)),
    [rowsIds],
  )

  const renderDataRow = useCallback(
    (row, index) => (
      <DataTableRow
        key={row.id}
        index={index}
        row={row}
        columns={columns}
        enableSelect={enableSelect}
        checked={state.list.includes(row.id)}
        onToggle={handleCheckboxToggle}
        isSingleItemSelect={isSingleItemSelect}
      />
    ),
    [columns, state, enableSelect, isSingleItemSelect],
  )

  return (
    <>
      <StyledTableContainer component={Paper}>
        <Table aria-label="caption table" stickyHeader>
          <TableHead>
            <TableRow>
              {enableSelect && (!multipleSelect ? (
                <TableCell padding="checkbox" />
              ) : (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isMultipleCheckboxChecked}
                    onClick={handleMultipleCheckbokToggle}
                  />
                </TableCell>
              ))}
              {columns
                .filter(column => !(enableSelect && column.key === 'actions'))
                .map((column) => {
                  const LabelComponent = !isObject(column.label) && column.label
                  return (
                    <TableCell
                      key={column.key}
                      component="th"
                      scope="row"
                      width={column.width}
                      align={column.alignText}
                      {...column.headProps}
                    >
                      {LabelComponent ? <LabelComponent /> : t(column.label.translationKey)}
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(renderDataRow)}
          </TableBody>
          {isTableEmpty && <EmptyArrayMessage>{emptyArrayMessage}</EmptyArrayMessage>}
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalElements}
        rowsPerPage={pageSize}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

DataTable.defaultProps = {
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
  multipleSelect: false,
  ableWithoutSelection: false,
  setSelectedIds: undefined,
  initialSelectedIds: [],
  enableSelect: false,
  emptyArrayMessage: '',
  isSingleItemSelect: false,
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  multipleSelect: PropTypes.bool,
  ableWithoutSelection: PropTypes.bool,
  setSelectedIds: PropTypes.func,
  initialSelectedIds: PropTypes.array,
  enableSelect: PropTypes.bool,
  emptyArrayMessage: PropTypes.string,
  isSingleItemSelect: PropTypes.bool,
}

export default DataTable
