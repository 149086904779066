import { useTranslation } from 'react-i18next'
import { useLocation, matchPath } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import PAGE_TITLES from '../../constants/pageTitles'

const PageHelmet = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const pageTitleCode = PAGE_TITLES.find(({ path }) => {
    const match = matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })
    return match?.isExact
  })?.code || 'common.titles.adminPanel'

  return (
    <Helmet>
      <title>{`SoEasy | ${t(pageTitleCode)}`}</title>
    </Helmet>
  )
}

export default PageHelmet
