import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import {
  DragIndicator,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Draggable } from 'react-beautiful-dnd'

import { TextField } from '../../../inputs'
import omitProps from '../../../../utils/omitProps'
import { peanutsToPLN } from '../../../../utils/peanutsToPLN'
import { WHITE, BLUE, LIGHT_BLUE } from '../../../../constants/colors'
import IdPropType from '../../../../propTypes/IdPropType'
import ModifierOptionInput from '../../MenuForm/MenuProduct/MenuProductInput'

const StyledDragIndicator = styled(DragIndicator)`
  cursor: grab;
`
const PaddedIconButton = styled(IconButton)`
  margin: 0px;
  padding: 6px;
`

const StyledPaper = styled.div(({ theme, isDragging }) => `
  display: grid;
  grid-template-columns: auto 1.7fr 0.3fr auto auto auto;
  grid-gap: 5px;
  padding: 2px;
  margin: 0 10px 10px 10px;
  border: 1px solid ${isDragging ? theme.palette.action.disabled : 'transparent'};
  border-radius: 5px;
  background: ${WHITE};
  box-shadow: ${isDragging
    ? '0px 0px 32px -7px rgba(0,0,0,0.3)'
    : '0px 0px 0px 0px rgba(0,0,0,0)'};
  transition:
    border 200ms ease-in-out,
    box-shadow 100ms ease;

  & > * {
    align-self: center;
  }
`)

const StyledNameTextField = styled(omitProps('hasChange')(TextField))`
  border-color: ${BLUE};
  background-color: ${({ hasChange }) => (hasChange ? LIGHT_BLUE : '')};
`

const StyledPlaceholder = styled.div(({ theme }) => `
  position: relative;
  padding: 15.5px 13px;
  border: 1px solid ${theme.palette.text.disabled};
  border-radius: 4px;
  line-height: 23px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid ${theme.palette.text.primary};
  }

  &:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 100%;
    top: 0;
    right: 0;
    background: ${WHITE};
  }
`)

const PriceTextField = styled(omitProps('hasChange')(TextField))`
  min-width: 120px;
  background-color: ${({ hasChange }) => (hasChange ? LIGHT_BLUE : '')};
`

const StyledPriceTextField = ({ hasChange, ...props }) => (
  <PriceTextField
    fullWidth
    hasChange={hasChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <mat-icon matSuffix>zł</mat-icon>
        </InputAdornment>
      ),
    }}
    {...props}
  />
)

const StyledPriceTextFieldPlaceholder = styled(StyledPlaceholder)`
  position: relative;
  min-width: 120px;

  &:before {
    content: 'zł';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-52%);
    padding-right: 21px;
    background: white;
  }
`

const ModifierOption = ({
  modifierUuid,
  index,
  option: {
    uuid,
    name,
    price,
  },
  onDelete: handleDelete,
  onEdit: handleEdit,
}) => {
  const { t } = useTranslation()
  const priceInPLN = peanutsToPLN(price)

  const handleNameUpdate = useCallback((value) => {
    handleEdit({
      modifierUuid,
      uuid,
      name: value,
    })
  }, [handleEdit, modifierUuid, uuid])

  const handlePriceUpdate = useCallback((value) => {
    handleEdit({
      modifierUuid,
      uuid,
      price: value * 100,
    })
  }, [handleEdit, modifierUuid, uuid])

  const handleOptionDelete = useCallback(
    () => handleDelete({ modifierUuid, uuid }),
    [handleDelete, modifierUuid, uuid],
  )

  return (
    <Draggable draggableId={`${uuid}`} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging, isDropAnimating }) => (
        <StyledPaper
          ref={innerRef}
          isDragging={isDragging && !isDropAnimating}
          {...draggableProps}
        >
          <div {...dragHandleProps}>
            <StyledDragIndicator />
          </div>
          <ModifierOptionInput
            value={name}
            onEdit={handleNameUpdate}
            component={StyledNameTextField}
            componentPlaceholder={StyledPlaceholder}
          />
          <ModifierOptionInput
            value={priceInPLN}
            onEdit={handlePriceUpdate}
            type="number"
            component={StyledPriceTextField}
            componentPlaceholder={StyledPriceTextFieldPlaceholder}
          />
          <Tooltip title={t('tableTooltips.delete')} aria-label={t('tableTooltips.delete')}>
            <PaddedIconButton onClick={handleOptionDelete}>
              <DeleteIcon />
            </PaddedIconButton>
          </Tooltip>
        </StyledPaper>
      )}
    </Draggable>
  )
}

ModifierOption.propTypes = {
  modifierUuid: IdPropType.isRequired,
  index: PropTypes.number.isRequired,
  option: PropTypes.shape({
    uuid: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

ModifierOption.defaultProps = {
  onDelete: () => {},
  onEdit: () => {},
}

StyledPriceTextField.propTypes = {
  hasChange: PropTypes.bool,
}

StyledPriceTextField.defaultProps = {
  hasChange: false,
}

export default memo(ModifierOption)
