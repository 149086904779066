import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'

const createOrderCollectPlace = async (values) => {
  const { data: orderCollectPlaces } = await fetch(API.orderCollectPlaces.create(values))
  return orderCollectPlaces
}

const updateOrderCollectPlace = async (id, values) => {
  const { data: orderCollectPlaces } = await fetch(API.orderCollectPlaces.update(id, values))

  if (!values.isDeviceConnected && values.deviceConnectionId) {
    await fetch(API.deviceWorkplaces.delete(values.deviceConnectionId))
  }

  return orderCollectPlaces
}

const deleteOrderCollectPlace = async (id) => {
  const { data: orderCollectPlaces } = await fetch(API.orderCollectPlaces.delete(id))
  return orderCollectPlaces
}

export {
  updateOrderCollectPlace,
  createOrderCollectPlace,
  deleteOrderCollectPlace,
}
