import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Field } from 'formik'
import { Switch, Tooltip } from '@material-ui/core'

import IdPropType from '../../../propTypes/IdPropType'
import FormModePropType from '../../../propTypes/FormModePropType'
import { UPDATE, CREATE } from '../../../constants/formModes'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import FormFooter from '../FormFooter'
import StyledFormikForm from '../StyledFormikForm'
import PropEntity from '../PropEntity'
import AlertDialog from '../../AlertDialog'
import { TextField, TextArea } from '../../inputs'

const StyledTextField = styled(TextField)`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`

const OrderCollectPlaceForm = ({
  values,
  setFieldValue,
  mode,
  isSubmitting,
  onCancel,
}) => {
  const { t } = useTranslation()
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertCallback, setAlertCallback] = useState(() => () => {})

  const { isDeviceConnected } = values

  const confirmMessageKey = useMemo(
    () => (mode === UPDATE ? 'common.update' : 'common.save'),
    [mode],
  )

  const handleDeviceConnectionChange = ({ target: { checked } }) => {
    if (checked) {
      setFieldValue('isDeviceConnected', checked)
    } else {
      setIsAlertOpen(true)
      setAlertCallback(() => () => setFieldValue('isDeviceConnected', checked))
    }
  }

  const handleAlertConfirm = () => {
    alertCallback()
    setIsAlertOpen(false)
  }

  return (
    <StyledFormikForm>
      <FormStyleWrapper>
        <Field
          formatError={t}
          label={t('orderCollect.name')}
          name="name"
          component={StyledTextField}
        />
        {mode === UPDATE && (
          <Row>
            <PropEntity
              value={isDeviceConnected ? t('orderPlace.deviceIsConnected') : t('orderPlace.deviceIsNotConnected')}
              alert={!isDeviceConnected && values.deviceConnectionId ? t('orderPlace.alert.info') : ''}
            />
            <Tooltip
              title={isDeviceConnected ? t('orderPlace.disconnectDevice') : t('orderPlace.connectDevice')}
              aria-label={isDeviceConnected ? t('orderPlace.disconnectDevice') : t('orderPlace.connectDevice')}
            >
              <Switch
                onChange={handleDeviceConnectionChange}
                checked={isDeviceConnected}
                disabled={!values.deviceConnectionId}
                color="primary"
              />
            </Tooltip>
            <AlertDialog
              onConfirm={handleAlertConfirm}
              onCancel={() => setIsAlertOpen(false)}
              message={t('orderCollect.alert.message')}
              title={t('orderPlace.alert.title')}
              confirmText={t('orderPlace.alert.removeConnection')}
              confirmColor="secondary"
              isOpen={isAlertOpen}
            />
          </Row>
        )}
        <Field
          formatError={t}
          label={t('common.description')}
          name="description"
          component={TextArea}
        />
      </FormStyleWrapper>
      <FormFooter
        onCancel={onCancel}
        disabled={isSubmitting}
        confirmMessageKey={confirmMessageKey}
      />
    </StyledFormikForm>
  )
}

OrderCollectPlaceForm.defaultProps = {
  mode: CREATE,
}

OrderCollectPlaceForm.propTypes = {
  values: PropTypes.shape({
    deviceConnectionId: IdPropType,
    isDeviceConnected: PropTypes.bool.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  mode: FormModePropType,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default OrderCollectPlaceForm
