import { useMemo } from 'react'
import UsersListActions from './UsersListActions'
import UsersListUsernameLink from './UsersListUsernameLink'

const useUsersColumns = (
  handleMoreInfoDialogOpen,
  handleDeleteUserDialogOpen,
) => useMemo(() => [
  {
    key: 'username',
    component: UsersListUsernameLink,
    label: { translationKey: 'user.screen.list.username' },
  },
  {
    key: 'clubs',
    label: { translationKey: 'user.screen.list.clubs' },
    render: ({ row }) => row.clubs?.map(({ name }) => name).join(', '),
  },
  {
    key: 'actions',
    label: { translationKey: 'common.actions' },
    component: UsersListActions,
    componentProps: {
      onMoreInfoDialogOpen: handleMoreInfoDialogOpen,
      onDeleteUserDialogOpen: handleDeleteUserDialogOpen,
    },
    alignText: 'right',
  },
], [handleMoreInfoDialogOpen, handleDeleteUserDialogOpen])

export default useUsersColumns
