import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Divider } from '@material-ui/core'

import { DataField, StatusField } from '../fields'
import UserClubsDetails from './UserClubsDetails'
import UserPropType from '../../../propTypes/UserPropType'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "firstName surname"
    "firstDivider firstDivider"
    "status email"
    "roleGroup roleGroup"
    "secondDivider secondDivider"
    "clubs clubs";
  padding: 30px;
  min-width: 630px;
`

const StyledFirstDivider = styled(Divider)`
  grid-area: firstDivider;
  margin: 20px 8px;
`

const StyledSecondDivider = styled(StyledFirstDivider)`
  grid-area: secondDivider;
`

const UserDetails = ({ user }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <DataField
        label={t('common.firstName')}
        value={user.profile.name}
        style={{ gridArea: 'firstName' }}
      />
      <DataField
        label={t('common.surname')}
        value={user.profile.surname}
        style={{ gridArea: 'surname' }}
      />
      <StyledFirstDivider />
      <StatusField
        label={t('common.status')}
        status={user.enabled}
        style={{ gridArea: 'status' }}
      />
      <DataField
        label={t('common.email')}
        value={user.username}
        style={{ gridArea: 'email' }}
      />
      <DataField
        label={t('common.roleGroup')}
        value={user.roleGroup.name}
        style={{ gridArea: 'roleGroup' }}
      />
      <StyledSecondDivider />
      <UserClubsDetails
        user={user}
        style={{ gridArea: 'clubs' }}
      />
    </Container>
  )
}

UserDetails.propTypes = {
  user: UserPropType.isRequired,
}

export default UserDetails
