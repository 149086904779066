import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Formik, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FormModePropType from '../../../propTypes/FormModePropType'
import { TextField } from '../../inputs'
import FormFooter from '../FormFooter'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import StyledFormikForm from '../StyledFormikForm'
import { CREATE } from '../../../constants/formModes'
import { UserCreateValidationSchema } from '../../../validations/userCreateSchema'
import { UserEditValidationSchema } from '../../../validations/userEditSchema'

const FormFields = styled(FormStyleWrapper)`
  display: flex;
  flex-direction: column;

  > * {
    margin: ${({ theme }) => theme.spacing(1)}px 0;
  }
`

const UserFormSimple = ({
  initialValues,
  onSubmit,
  mode,
}) => {
  const formData = useMemo(
    () => ({
      ...initialValues,
      confirmPassword: '',
    }),
    [initialValues],
  )

  const userSchema = mode === CREATE ? UserCreateValidationSchema : UserEditValidationSchema
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={userSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <StyledFormikForm>
          <FormFields>
            <Field
              name="email"
              label={t('user.form.email')}
              type="email"
              formatError={t}
              component={TextField}
            />
            <Field
              name="password"
              label={t('user.form.password')}
              type="password"
              formatError={t}
              component={TextField}
            />
            <Field
              name="confirmPassword"
              label={t('user.form.confirmPassword')}
              type="password"
              formatError={t}
              component={TextField}
            />
          </FormFields>
          <FormFooter
            onCancel={history.goBack}
            disabled={isSubmitting}
            confirmMessageKey={t('common.save')}
          />
        </StyledFormikForm>
      )}
    </Formik>
  )
}

UserFormSimple.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func,
  mode: FormModePropType.isRequired,
}

UserFormSimple.defaultProps = {
  initialValues: {
    email: '',
    password: '',
  },
  onSubmit: () => {},
}

export default UserFormSimple
