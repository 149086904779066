import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useClubWeeklySchedule } from '@itsilesia/udrink-common-components'
import { Button } from '@material-ui/core'
import styled from 'styled-components/macro'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import ClubOpenHours from '../../components/clubs/ClubOpenHours'
import RouteHeader from '../../components/layout/RouteHeader'
import ButtonsSection from '../../components/layout/ButtonsSection'

const StyledButtonsSection = styled(ButtonsSection)`
  margin-top: 20px;
`

const ClubHoursScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)
  const { data: weeklySchedule } = useClubWeeklySchedule(clubId)

  return (
    <RouteContainer fillHeight={false}>
      <RouteHeader>{t('schedule.openHours')}</RouteHeader>
      {weeklySchedule && (
        <>
          <ClubOpenHours weeklySchedule={weeklySchedule} />
          <StyledButtonsSection>
            <Button variant="contained" color="primary" to="open-hours/edit" component={Link}>
              {t('common.goToEdit')}
            </Button>
          </StyledButtonsSection>
        </>
      )}
    </RouteContainer>
  )
}

export default ClubHoursScreen
