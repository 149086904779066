import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

const StyledButton = styled(Button)(({ theme, color }) => `
  & + & {
    margin-left: ${theme.spacing(3)}px;
  }
  &.MuiButton-contained {
    color: ${color};
    border: 1px solid ${color};
  }
`)

const FormButton = props => <StyledButton {...props} />

FormButton.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
}

FormButton.defaultProps = {
  variant: 'contained',
  type: 'button',
}

export default FormButton
