import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { MenuItem } from '@material-ui/core'

import { Select, TextField } from '../../../../inputs'
import TIME_PERIOD from '../../../../../constants/timePeriod'
import omitProps from '../../../../../utils/omitProps'
import parseISODate from '../../../../../utils/parseISODate'

const PickerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  max-width: 318px;
`

const CustomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledSelect = styled(omitProps('isCustomActive')(Select))`
  .MuiSelect-root {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }

  ${({ isCustomActive }) => isCustomActive
  && `
    position: absolute;
    top: calc(-100% - 10px);
    width: 100%;
  `}
`

const StyledTextField = styled(TextField)`
  &:last-child {
    margin-left: 10px;
  }

  .MuiInputBase-input {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }

  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }
`

const TimePeriodSelect = ({
  activeTimePeriod,
  from,
  to,
  onChange: handlePeriodChange,
}) => {
  const { t } = useTranslation()

  const handleChange = ({ target: { value } }) => {
    handlePeriodChange(value)
  }

  const handleDateChange = ({ target: { value } }, date) => {
    handlePeriodChange(activeTimePeriod, {
      [date]: `${value}T00:00`,
    })
  }

  const isCustomActive = activeTimePeriod === TIME_PERIOD.CUSTOM.value

  return (
    <PickerContainer>
      <StyledSelect
        isCustomActive={isCustomActive}
        value={activeTimePeriod}
        onChange={handleChange}
      >
        {Object.values(TIME_PERIOD).map(({ value, code }) => (
          <MenuItem key={value} value={value}>
            {t(code)}
          </MenuItem>
        ))}
      </StyledSelect>
      {isCustomActive && (
        <CustomContainer>
          <StyledTextField
            label={t('statistics.from')}
            type="date"
            value={parseISODate(from)}
            onChange={e => handleDateChange(e, 'from')}
            required
          />
          <StyledTextField
            label={t('statistics.to')}
            type="date"
            value={parseISODate(to)}
            onChange={e => handleDateChange(e, 'to')}
            required
          />
        </CustomContainer>)}
    </PickerContainer>
  )
}

TimePeriodSelect.propTypes = {
  activeTimePeriod: PropTypes.string,
  onChange: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
}

TimePeriodSelect.defaultProps = {
  activeTimePeriod: '',
  onChange: () => {},
  from: new Date().toISOString().slice(0, 10),
  to: new Date().toISOString().slice(0, 10),
}

export default TimePeriodSelect
