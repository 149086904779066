import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import SubcategoryReorderPanel from './SubcategoryReorderPanel'
import ProductImage from './MenuProduct/ProductImage'

const HeaderContainer = styled.div`
  display: flex;
  cursor: pointer;

  & > * {
    align-self: center;
  }
`

const StyledSpan = styled.span`
  margin-left: 10px;
`

const SubcategoryHeader = ({
  children,
  onOrderUp: handleOrderUp,
  onOrderDown: handleOrderDown,
  disabledUp,
  disabledDown,
  imageUrl,
}) => (
  <HeaderContainer>
    <SubcategoryReorderPanel
      disabledUp={disabledUp}
      disabledDown={disabledDown}
      onOrderUp={handleOrderUp}
      onOrderDown={handleOrderDown}
    />
    <ProductImage src={imageUrl} />
    <StyledSpan>{children}</StyledSpan>
  </HeaderContainer>
)

SubcategoryHeader.propTypes = {
  children: PropTypes.string,
  onOrderUp: PropTypes.func,
  onOrderDown: PropTypes.func,
  disabledUp: PropTypes.bool,
  disabledDown: PropTypes.bool,
  imageUrl: PropTypes.string,
}

SubcategoryHeader.defaultProps = {
  children: '',
  onOrderUp: () => {},
  onOrderDown: () => {},
  disabledUp: false,
  disabledDown: false,
  imageUrl: '',
}

export default SubcategoryHeader
