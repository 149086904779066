import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'

import { modifierSchema } from '../../../validations/modifierSchema'
import { TextField, FormButton, TextArea } from '../../inputs'
import IdPropType from '../../../propTypes/IdPropType'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1.6;
`

const StyledTextField = styled(TextField)(
  ({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`,
)

const ModifierDialog = ({
  open,
  onClose: handleClose,
  onSubmit: handleSubmit,
  dialogValues,
  isEdit,
}) => {
  const { t } = useTranslation()

  const handleFormSubmit = ({ max, ...values }, { setSubmitting }) => {
    handleSubmit({
      ...values,
      max: max === '' ? null : max,
    })
    setSubmitting(false)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        initialValues={dialogValues}
        validationSchema={modifierSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle>
              {isEdit ? t('modifiers.editDialogTitle') : t('modifiers.addDialogTitle')}
            </DialogTitle>
            <StyledDialogContent dividers>
              <Field
                formatError={t}
                label={t('modifiers.name')}
                name="name"
                component={StyledTextField}
              />
              <Field
                formatError={t}
                label={t('modifiers.min')}
                name="min"
                type="number"
                component={StyledTextField}
              />
              <Field
                formatError={t}
                label={`${t('modifiers.max')} ${t('common.optional')}`}
                name="max"
                type="number"
                component={StyledTextField}
              />
              <Field
                formatError={t}
                label={`${t('common.description')} ${t('common.optional')}`}
                name="description"
                component={TextArea}
              />
            </StyledDialogContent>
            <DialogActions>
              <FormButton onClick={handleClose}>{t('common.back')}</FormButton>
              <FormButton type="submit" color="primary" disabled={isSubmitting}>
                {t('common.save')}
              </FormButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

ModifierDialog.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
}

ModifierDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  dialogValues: PropTypes.exact({
    uuid: IdPropType,
    name: PropTypes.string,
    description: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  isEdit: PropTypes.bool,
}

ModifierDialog.defaultProps = {
  dialogValues: {
    uuid: '',
    name: '',
    description: '',
    min: 0,
    max: undefined,
  },
  isEdit: false,
}

export default ModifierDialog
