import { useContext } from 'react'
import { useClubOrderPreparationPlaces } from '@itsilesia/udrink-common-components'

import { ClubContext } from '../../components/ClubContext'
import useDataList from '../../hooks/useDataList'
import OrderPreparationPlacesList from '../../components/clubs/OrderPreparationPlacesList'
import RouteContainer from '../../components/layout/RouteContainer'

const ClubOrderPreparationPlacesScreen = () => {
  const { clubId } = useContext(ClubContext)

  const useResource = ({ page, size }) =>
    useClubOrderPreparationPlaces(clubId, {
      page,
      size,
      sort: 'name',
    })

  const { handleChangePage, handleChangeRowsPerPage, ...props } = useDataList(
    `${clubId}-order-preparation-places`,
    useResource,
  )

  return (
    <RouteContainer>
      {clubId ? <OrderPreparationPlacesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> : <div />}
    </RouteContainer>
  )
}

export default ClubOrderPreparationPlacesScreen
