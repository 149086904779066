import DiscountCodesListActions from './DiscountCodesListActions'

const DISCOUNT_CODES_COLUMNS = [
  {
    key: 'name',
    prop: 'name',
    label: { translationKey: 'discountCodes.name' },
  },
  {
    key: 'status',
    prop: 'status',
    label: { translationKey: 'discountCodes.status' },
  },
  {
    key: 'type',
    prop: 'type',
    label: { translationKey: 'discountCodes.discountType' },
  },
  {
    key: 'totalAmount',
    prop: 'totalAmount',
    label: { translationKey: 'discountCodes.pool' },
  },
  {
    key: 'dateTo',
    prop: 'dateTo',
    label: { translationKey: 'discountCodes.endDate' },
  },
  {
    key: 'actions',
    label: '',
    component: DiscountCodesListActions,
    alignText: 'right',
  },
]

export default DISCOUNT_CODES_COLUMNS
