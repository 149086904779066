import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import InfoHazeIcon from '../../InfoHazeIcon'

const Container = styled.div`
  display: flex;
  transform: translateX(6px);
`

const AmountLabel = () => {
  const { t } = useTranslation()
  const hours = new Date().toTimeString().split(' ')[0].slice(0, 5)

  return (
    <Container>
      <span>{t('product.amount')}</span>
      <InfoHazeIcon
        horizontalAlign="left"
        hazeWidth="250px"
        message={`${t('inventory.amountMessage')}: ${hours}`}
      />
    </Container>
  )
}

export default AmountLabel
