import { useSelector } from 'react-redux'

const useCurrentUser = value =>
  useSelector((state) => {
    try {
      const { user } = state.auth
      return value ? user[value] : user
    } catch (err) {
      return undefined
    }
  })

export default useCurrentUser
