import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tooltip, IconButton, Switch, CircularProgress } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import styled from 'styled-components/macro'

const StyledIconButton = styled(IconButton)`
  margin: 0px;
  padding: 6px;
`

const ProductActions = ({
  isMenuDisabled,
  onlyPreview,
  isLoading,
  onChangeOnlyPreview: handleChangeOnlyPreview,
  onEditDialogOpen: handleEditDialogOpen,
  onDelete: handleDelete,
}) => {
  const { t } = useTranslation()

  const handleEditDialog = (event) => {
    event.stopPropagation()
    handleEditDialogOpen()
  }

  return (
    <>
      <Tooltip
        title={t(onlyPreview ? 'tableTooltips.onlyPreview' : 'tableTooltips.availableForSale')}
        aria-label={onlyPreview ? 'tableTooltips.onlyPreview' : 'tableTooltips.availableForSale'}
      >
        <Switch
          checked={!onlyPreview}
          onChange={event => handleChangeOnlyPreview(!event.target.checked)}
          disabled={isMenuDisabled}
        />
      </Tooltip>
      {(isLoading && !isMenuDisabled)
        ? <CircularProgress size="1.7rem" />
        : (
          <Tooltip title={t('tableTooltips.edit')} aria-label={t('tableTooltips.edit')}>
            <span>
              <StyledIconButton onClick={handleEditDialog} disabled={isMenuDisabled}>
                <EditIcon />
              </StyledIconButton>
            </span>
          </Tooltip>
        )}
      <Tooltip title={t('tableTooltips.delete')} aria-label={t('tableTooltips.delete')}>
        <span>
          <StyledIconButton onClick={handleDelete} disabled={isMenuDisabled}>
            <DeleteIcon />
          </StyledIconButton>
        </span>
      </Tooltip>
    </>
  )
}

ProductActions.propTypes = {
  isMenuDisabled: PropTypes.bool,
  onlyPreview: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onChangeOnlyPreview: PropTypes.func,
  onEditDialogOpen: PropTypes.func,
  onDelete: PropTypes.func,
}

ProductActions.defaultProps = {
  isLoading: false,
  isMenuDisabled: false,
  onChangeOnlyPreview: () => {},
  onEditDialogOpen: () => {},
  onDelete: () => {},
}

export default memo(ProductActions)
