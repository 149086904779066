import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'
import {
  mapToAPICreateClub,
  mapToAPIUpdateClub,
  mapToAPIAccessDataFiserv,
} from '../../../utils/mapClub'
import { updateWeeklySchedules, createWeeklySchedules } from '../weeklySchedules'
import { PAYMENT_ACQUIRER_FISERV } from '../../../constants/paymentAcquirers'

const updateClubAsManager = async (id, values) => {
  const { data: club } = await fetch(API.clubs.update(id, mapToAPIUpdateClub(values)))

  return club
}

const updateClub = async (id, values) => {
  const { data: club } = await fetch(API.clubs.update(id, mapToAPIUpdateClub(values)))

  const weeklySchedule = await updateWeeklySchedules(values)

  let data = { club, weeklySchedule }

  await fetch(API.clubUsersRelations.updateOwner({
    clubId: club.id,
    userId: values.ownerId,
  }))

  const { sharedSecret, apiKey, secretKey } = mapToAPIAccessDataFiserv(values)

  if (sharedSecret && apiKey && secretKey && !club.active) {
    const { data: paymentData } = await fetch(
      API.accessDataFiserv.update(club.uuid, mapToAPIAccessDataFiserv(values)),
    )
    data = { ...data, paymentData }
  }

  return data
}

const createClub = async (values) => {
  const { data } = await fetch(API.clubs.create(mapToAPICreateClub(values)))

  await createWeeklySchedules(data.id, values)

  await fetch(API.payeeAcquirers.create({
    acquirer: PAYMENT_ACQUIRER_FISERV,
    payeeId: data.uuid,
  }))

  await fetch(API.accessDataFiserv.create(mapToAPIAccessDataFiserv(values, data.uuid)))

  await fetch(API.clubUsersRelations.updateOwner({
    clubId: data.id,
    userId: values.ownerId,
  }))
}

const deleteClub = async (id) => {
  const { data } = await fetch(API.clubs.delete(id))

  return data
}

export { createClub, updateClub, updateClubAsManager, deleteClub }
