import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { GREEN, RED } from '../../constants/colors'

const Text = styled.span`
  font-size: 12px;
  margin-right: 2px;
`

const ConnectedText = styled(Text)`
  color: ${GREEN};
`

const DisconnectedText = styled(Text)`
color: ${RED};
`

const ConnectedComponent = ({ row }) => {
  const { t } = useTranslation()

  return (
    <span>
      {row?.deviceWorkplaceConnection ? (
        <ConnectedText>{t('orderPlace.connected')}</ConnectedText>
      ) : (
        <DisconnectedText>{t('orderPlace.notConnected')}</DisconnectedText>
      )}
    </span>
  )
}

ConnectedComponent.propTypes = {
  row: PropTypes.shape({
    deviceWorkplaceConnection: PropTypes.object,
  }).isRequired,
}

export default ConnectedComponent
