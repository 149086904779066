const TIME_PERIOD = Object.freeze({
  LAST_DAY: {
    value: 'LAST_DAY',
    inHours: 24,
    code: 'statistics.timePeriod.lastDay',
    sumCode: 'statistics.timePeriod.inLastDay',
  },
  LAST_WEEK: {
    value: 'LAST_WEEK',
    inHours: 168, // 7 days
    code: 'statistics.timePeriod.lastWeek',
    sumCode: 'statistics.timePeriod.inLastWeek',
  },
  LAST_TWENTY_EIGHT_DAYS: {
    value: 'LAST_TWENTY_EIGHT_DAYS',
    inHours: 672,
    code: 'statistics.timePeriod.lastTwentyEightDays',
    sumCode: 'statistics.timePeriod.inLastTwentyEightDays',
  },
  LAST_FOUR_MONTH: {
    value: 'LAST_FOUR_MONTH',
    inHours: 2928, // 31 days * 2 + 30 days * 2
    code: 'statistics.timePeriod.lastFourMonth',
    sumCode: 'statistics.timePeriod.inLastFourMonth',
  },
  LAST_SIX_MONTH: {
    value: 'LAST_SIX_MONTH',
    inHours: 4383,
    code: 'statistics.timePeriod.lastSixMonth',
    sumCode: 'statistics.timePeriod.inLastSixMonth',
  },
  LAST_YEAR: {
    value: 'LAST_YEAR',
    inHours: 8766, // 365,25 days
    code: 'statistics.timePeriod.lastYear',
    sumCode: 'statistics.timePeriod.inLastYear',
  },
  CUSTOM: {
    value: 'CUSTOM',
    inHours: null,
    code: 'statistics.timePeriod.custom',
    sumCode: 'statistics.timePeriod.inPeriod',
  },
})

export default TIME_PERIOD
