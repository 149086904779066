import { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import TIME_SCALE from '../../constants/timeScale'
import TIME_PERIOD from '../../constants/timePeriod'
import formatDateToString from './formatDateToString'
import subtractHours from './subtractHours'
import isFromDateValid from './isFromDateValid'
import isToDateValid from './isToDateValid'
import trimDownPeriod from './trimDownPeriod'

const useStatisticChart = ({ handleError }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  useEffect(() => {
    const updateDateInterval = setInterval(() => {
      setCurrentDate(new Date())
    }, 3600000) // This is one hour.

    return () => {
      clearInterval(updateDateInterval)
    }
  }, [])

  const [timeScale, setTimeScale] = useState(TIME_SCALE.DAY)
  const [timePeriod, setTimePeriod] = useState(TIME_PERIOD.LAST_WEEK)
  const [customStart, setCustomStart] = useState(
    () => subtractHours(currentDate, TIME_PERIOD.LAST_WEEK.inHours),
  )
  const [customEnd, setCustomEnd] = useState(currentDate)

  const handleTimeScaleChange = useCallback((key) => {
    if (TIME_SCALE.DAY.value === key
      && timePeriod.value === TIME_PERIOD.LAST_DAY.value) {
      setTimePeriod(TIME_PERIOD.LAST_WEEK)
    }

    if (TIME_SCALE.WEEK.value === key
      && (
        timePeriod.value === TIME_PERIOD.LAST_DAY.value
        || timePeriod.value === TIME_PERIOD.LAST_WEEK.value
      )) {
      setTimePeriod(TIME_PERIOD.LAST_TWENTY_EIGHT_DAYS)
    }

    setTimeScale(TIME_SCALE[key])
  }, [timePeriod])

  const handleTimePeriodChange = useCallback((key, { from, to } = {}) => {
    if (TIME_PERIOD.LAST_DAY.value === key
      && timeScale.value !== TIME_SCALE.HOUR.value) {
      setTimeScale(TIME_SCALE.HOUR)
    }

    if (TIME_PERIOD.LAST_WEEK.value === key
      && timeScale.value === TIME_SCALE.WEEK.value) {
      setTimeScale(TIME_SCALE.DAY)
    }

    if (TIME_PERIOD.CUSTOM.value === key) {
      try {
        if (from) {
          isFromDateValid(from, customEnd)
          setCustomStart(new Date(from))
        }

        if (to) {
          isToDateValid(to, customStart)
          setCustomEnd(new Date(to))
        }
      } catch (error) {
        handleError(error)
      }
    }

    setTimePeriod(TIME_PERIOD[key])
  }, [timeScale, customStart, customEnd]) // eslint-disable-line react-hooks/exhaustive-deps

  const from = useMemo(() => {
    const period = timePeriod.value === TIME_PERIOD.CUSTOM.value
      ? customStart
      : subtractHours(currentDate, timePeriod.inHours)
    return formatDateToString(trimDownPeriod(period, timeScale))
  }, [timePeriod, customStart, currentDate, timeScale])

  const to = useMemo(
    () => {
      const period = timePeriod.value === TIME_PERIOD.CUSTOM.value
        ? customEnd
        : currentDate
      return formatDateToString(trimDownPeriod(period, timeScale))
    },
    [timePeriod, customEnd, currentDate, timeScale],
  )

  useEffect(() => {
    if (timePeriod.value !== TIME_PERIOD.CUSTOM.value) {
      setCustomStart(subtractHours(currentDate, timePeriod.inHours))
      setCustomEnd(currentDate)
    }
  }, [timePeriod, currentDate])

  return {
    from,
    to,
    timeScale,
    timePeriod,
    handleTimeScaleChange,
    handleTimePeriodChange,
  }
}

useStatisticChart.propTypes = {
  handleError: PropTypes.func,
}

useStatisticChart.defaultProps = {
  handleError: () => {},
}

export default useStatisticChart
