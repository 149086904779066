import PropTypes from 'prop-types'
import { List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import ListItemRow from './ListItemRow'
import useNavigationTabs from '../../../hooks/useNavigationTabs'

const NavigationList = ({ tabsList }) => {
  const { t } = useTranslation()
  const tabs = useNavigationTabs(tabsList)

  return (
    <List>
      {tabs.map(({ component: Component, key, ...tab }) => (Component
        ? (
          <Component key={key} />
        ) : (
          <ListItemRow
            key={tab.to}
            to={tab.to}
            selected={tab.selected}
            primary={t(tab.translationKey)}
            icon={tab.icon}
          />
        )))}
    </List>
  )
}

NavigationList.propTypes = {
  tabsList: PropTypes.array,
}

NavigationList.defaultProps = {
  tabsList: [],
}

export default NavigationList
