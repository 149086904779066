import { useCallback } from 'react'

const FormikFieldMapper = ({
  form: { setFieldValue },
  field: { name, value },
  children,
  ...rest
}) => {
  const handleChange = useCallback(
    newValue => setFieldValue(name, newValue),
    [name, setFieldValue],
  )

  return children({
    onChange: handleChange,
    value,
    ...rest,
  })
}

export default FormikFieldMapper
