import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, InputAdornment } from '@material-ui/core'

import styled from 'styled-components/macro'

import StyledTextField from '../../ClubForm/StyledTextField'
import SectionHeader from './section/SectionHeader'
import { PERCENT } from '../../../../constants/discountTypes'
import OutlinedTextArea from '../../../inputs/OutlinedTextArea'
import { ACTIVE, INACTIVE } from '../../../../constants/discountStatuses'

const DiscountValueRow = styled.div(({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)}px;
`)

const GeneralSectionContainer = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)}px;
`)

const GeneralSection = ({ type, status, setFieldValue }) => {
  const { t } = useTranslation()

  const handleDiscountStatusChange = ({ target: { value } }) => {
    setFieldValue('status', value)
  }

  // TODO: enable type when we figure out how to print receipts for AMOUNT discounts
  // const handleDiscountTypeChange = ({ target: { value } }) => {
  //   setFieldValue('type', value)
  // }

  return (
    <>
      <SectionHeader
        title={t('clubs.form.general')}
      />
      <GeneralSectionContainer>
        <Field
          formatError={t}
          label={t('common.name')}
          name="name"
          required
          component={StyledTextField}
        />
        <FormControl fullWidth variant="outlined" required>
          <InputLabel id="status-select-label">{t('common.status')}</InputLabel>
          <Select
            native
            variant="outlined"
            labelId="test-select-label"
            label={t('common.status')}
            name="status"
            inputProps={{ id: 'discount-type-select' }}
            value={status}
            onChange={handleDiscountStatusChange}
          >
            <option key={ACTIVE} value={ACTIVE}>
              {t(`discountCodes.${ACTIVE}`)}
            </option>
            <option key={INACTIVE} value={INACTIVE}>
              {t(`discountCodes.${INACTIVE}`)}
            </option>
          </Select>
        </FormControl>
        <DiscountValueRow>
          {/* // TODO: enable type when we figure out how to print receipts for AMOUNT discounts */}
          {/* <FormControl fullWidth>
            <StyledSelect
              native
              variant="outlined"
              name="type"
              inputProps={{ id: 'discount-type-select' }}
              value={type}
              onChange={handleDiscountTypeChange}
            >
              {DISCOUNT_TYPES.map(discountType =>
                <option key={discountType} value={discountType}>
                  {t(`discountCodes.${discountType}`)}
                </option>)}
            </StyledSelect>
          </FormControl> */}
          <Field
            formatError={t}
            label={t(`discountCodes.${type === PERCENT ? 'discountPercent' : 'discountValue'}`)}
            name="value"
            type="number"
            required
            InputProps={{
              endAdornment: type === PERCENT ? (
                <InputAdornment position="end">%</InputAdornment>
              ) : (
                null
              ),
            }}
            component={StyledTextField}
          />
        </DiscountValueRow>
        <Field
          formatError={t}
          label={`${t('common.description')} ${t('common.optional')}`}
          name="description"
          component={OutlinedTextArea}
        />
      </GeneralSectionContainer>
    </>
  )
}

GeneralSection.propTypes = {
  setFieldValue: PropTypes.func,
  status: PropTypes.string,
  type: PropTypes.string,
}

GeneralSection.defaultProps = {
  setFieldValue: () => {},
  status: '',
  type: PERCENT,
}

export default GeneralSection
