import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import styled from 'styled-components/macro'
import { Collapse, InputLabel, FormControl } from '@material-ui/core'

import { Select } from '../../../../inputs'
import StyledTextField from '../../StyledTextField'
import useCollapse from '../../../../../hooks/useCollapse'
import SectionHeader from './section/SectionHeader'
import SectionBody from './section/SectionBody'
import SectionContent from './section/SectionContent'
import TIME_ZONES_IDS from '../../../../../constants/timeZonesIds'
import COUNTRIES from '../../../../../constants/countries'

const Row = styled.div`
  display: flex;
`

const ZipCodeColumn = styled.div`
  flex: 1;
  padding-right: 18px;
`

const CityColumn = styled.div`
  flex: 2;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`

const CountryColumn = styled.div`
  flex: 5;
  padding-right: 18px;
`

const TimeZoneColumn = styled.div`
  flex: 4;
`

const LocalizationSection = ({
  countryValue,
  timeZoneValue,
  onChange: handleChange,
}) => {
  const { t } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse(false)

  return (
    <SectionBody>
      <SectionHeader
        title={t('clubs.form.localization')}
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      />
      <Collapse in={!collapse} timeout="auto">
        <SectionContent>
          <Field
            formatError={t}
            label={t('clubs.form.coordinates')}
            name="coordinates"
            component={StyledTextField}
          />
          <Field
            formatError={t}
            label={t('clubs.form.street')}
            name="address"
            component={StyledTextField}
          />
          <Row>
            <ZipCodeColumn>
              <Field
                formatError={t}
                label={t('clubs.form.zipCode')}
                name="zipCode"
                component={StyledTextField}
              />
            </ZipCodeColumn>
            <CityColumn>
              <Field
                formatError={t}
                label={t('clubs.form.city')}
                name="city"
                component={StyledTextField}
              />
            </CityColumn>
          </Row>
          <Row>
            <CountryColumn>
              <StyledFormControl>
                <InputLabel variant="outlined" htmlFor="club-country-select">
                  {t('clubs.form.country')}
                </InputLabel>
                <Select
                  native
                  label={t('clubs.form.country')}
                  variant="outlined"
                  name="country"
                  inputProps={{ id: 'club-country-select' }}
                  value={countryValue}
                  onChange={handleChange}
                >
                  {COUNTRIES.map(country => (
                    <option key={country.value} value={country.value}>
                      {t(country.name)}
                    </option>
                  ))}
                </Select>
              </StyledFormControl>
            </CountryColumn>
            <TimeZoneColumn>
              <StyledFormControl>
                <InputLabel variant="outlined" htmlFor="club-time-zone-select">
                  {t('clubs.form.timeZone')}
                </InputLabel>
                <Select
                  native
                  label={t('clubs.form.timeZone')}
                  variant="outlined"
                  name="timeZoneId"
                  inputProps={{ id: 'club-time-zone-select' }}
                  value={timeZoneValue}
                  onChange={handleChange}
                >
                  {TIME_ZONES_IDS.map(timeZoneId => (
                    <option key={timeZoneId} value={timeZoneId}>
                      {timeZoneId}
                    </option>
                  ))}
                </Select>
              </StyledFormControl>
            </TimeZoneColumn>
          </Row>
        </SectionContent>
      </Collapse>
    </SectionBody>
  )
}

LocalizationSection.propTypes = {
  countryValue: PropTypes.string,
  timeZoneValue: PropTypes.string,
  onChange: PropTypes.func,
}

LocalizationSection.defaultProps = {
  countryValue: '',
  timeZoneValue: '',
  onChange: () => {},
}

export default memo(LocalizationSection)
