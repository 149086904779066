import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import HoursRow from './HoursRow'
import { DAYS } from '../../../constants/days'
import IdPropType from '../../../propTypes/IdPropType'

const TabWraper = styled.div`
  flex-grow: 1;
  width: 100%;
  min-width: 260px;
`

const OpeningHoursForm = ({ setFieldValue, values, clubId }) => (
  <TabWraper>
    {DAYS.map(({ value }, index) => (
      <HoursRow
        key={value}
        dayName={value}
        showHeader={index === 0}
        setFieldValue={setFieldValue}
        values={values}
        clubId={clubId}
      />
    ))}
  </TabWraper>
)

OpeningHoursForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    openingHours: PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    }),
  }).isRequired,
  clubId: IdPropType,
}

OpeningHoursForm.defaultProps = {
  setFieldValue: () => {},
  clubId: null,
}

export default OpeningHoursForm
