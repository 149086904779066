import { useMemo, useCallback } from 'react'
import { useClub, useTrackLoadingStatus, useFiserv } from '@itsilesia/udrink-common-components'

import { mapClub } from '../utils/mapClub'
import { createClub, updateClub } from '../services/api'

const useClubForm = ({ id, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useClub(id)
  const { data: dataFiserv } = useFiserv(data?.club.uuid)
  useTrackLoadingStatus('club', isValidating)

  const initialValues = useMemo(() => mapClub(data, dataFiserv), [data, dataFiserv])

  const handleRequest = useMemo(
    () => (id ? values => mutate((status) => {
      const newClub = updateClub(id, values)

      return { ...status, ...newClub }
    }, false) : createClub),
    [id, mutate],
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleRequest(values)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [handleRequest, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useClubForm
