import getNextModifiersState from './getNextModifiersState'
import {
  CREATE_MODIFIER_EVENT,
  UPDATE_MODIFIER_EVENT,
  DELETE_MODIFIER_EVENT,
  CREATE_MODIFIER_OPTION_EVENT,
  UPDATE_MODIFIER_OPTION_EVENT,
  DELETE_MODIFIER_OPTION_EVENT,
  CHANGE_MODIFIER_OPTION_ORDER_EVENT,
} from '../constants/modifierEvents'
import mapToModifierDto from './mapToModifierDto'

const reduceModifierEvents = (eventsPipe) => {
  const events = []

  eventsPipe.forEach((event) => {
    const { payload } = event
    if (getNextModifiersState.actions.createModifier.match(event)) {
      const { ...data } = payload

      events.push({
        eventType: CREATE_MODIFIER_EVENT,
        modifierCreateDto: mapToModifierDto(data),
      })
    }

    if (getNextModifiersState.actions.updateModifier.match(event)) {
      const { uuid, ...rest } = payload

      events.push({
        eventType: UPDATE_MODIFIER_EVENT,
        modifierUuid: uuid,
        modifierUpdateDto: mapToModifierDto(rest),
      })
    }

    if (getNextModifiersState.actions.deleteModifier.match(event)) {
      const uuid = payload
      events.push({
        eventType: DELETE_MODIFIER_EVENT,
        modifierUuid: uuid,
      })
    }

    if (getNextModifiersState.actions.createOption.match(event)) {
      const { modifierUuid, ...data } = payload

      events.push({
        eventType: CREATE_MODIFIER_OPTION_EVENT,
        modifierUuid,
        modifierOptionCreateDto: data,
      })
    }

    if (getNextModifiersState.actions.updateOption.match(event)) {
      const { modifierUuid, uuid, ...rest } = payload

      events.push({
        eventType: UPDATE_MODIFIER_OPTION_EVENT,
        modifierOptionUuid: uuid,
        modifierOptionUpdateDto: rest,
      })
    }

    if (getNextModifiersState.actions.deleteOption.match(event)) {
      const { uuid } = payload
      events.push({
        eventType: DELETE_MODIFIER_OPTION_EVENT,
        modifierOptionUuid: uuid,
      })
    }

    if (getNextModifiersState.actions.reorderOption.match(event)) {
      const { uuid, newOrderNumber } = payload

      events.push({
        eventType: CHANGE_MODIFIER_OPTION_ORDER_EVENT,
        modifierOptionUuid: uuid,
        newOrderNumber,
      })
    }
  })

  return events.map((event) => {
    const { modifierCreateDto, modifierUpdateDto } = event

    if (modifierUpdateDto?.description === '') {
      modifierUpdateDto.description = null
    }
    if (modifierCreateDto?.description === '') {
      modifierCreateDto.description = null
    }

    return event
  })
}

export default reduceModifierEvents
