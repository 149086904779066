import {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Snackbar as MuiSnackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'
import { usePrevious } from '@itsilesia/udrink-common-components'

import snackbarModule from '../store/snackbar'

const AdminSnackbar = () => {
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(undefined)

  const dispatch = useDispatch()
  const queueLength = useSelector(state => snackbarModule.selectors.getLength(state.snackbar))
  const firstItem = useSelector(state => snackbarModule.selectors.getFirst(state.snackbar))
  const previousLength = usePrevious(queueLength)

  const processQueue = useCallback(() => {
    if (queueLength > 0) {
      setMessageInfo(firstItem)
      dispatch(snackbarModule.actions.shiftMessage())
      setOpen(true)
    }
  }, [firstItem, queueLength, dispatch])

  const handleClose = (_, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false)
    }
  }

  const handleExited = () => {
    processQueue()
  }

  useEffect(() => {
    if (queueLength > previousLength) {
      processQueue()
    }
  }, [queueLength, previousLength, processQueue])

  return (
    <MuiSnackbar
      key={messageInfo?.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      onExited={handleExited}
      message={messageInfo?.message}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}

export default AdminSnackbar
