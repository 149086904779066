import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'
import { mapToAPIWeeklySchedules } from '../../../utils/mapClub'

const updateWeeklySchedules = async (values) => {
  const { data: weeklySchedule } = await fetch(API.weeklySchedules.update(
    values.weeklyScheduleId,
    mapToAPIWeeklySchedules({
      openingHours: values.openingHours,
    }),
  ))

  return weeklySchedule
}

const createWeeklySchedules = async (clubId, values) => {
  const { data: weeklySchedule } = await fetch(API.weeklySchedules.create(
    mapToAPIWeeklySchedules({
      openingHours: values.openingHours,
      clubId,
    }),
  ))

  return weeklySchedule
}

export { updateWeeklySchedules, createWeeklySchedules }
