import ActionsCell from './OrderCollectPlacesListActions'
import ConnectedComponent from '../ConnectedComponent'

export default [
  {
    key: 'name',
    prop: 'name',
    label: { translationKey: 'orderCollect.title' },
  },
  {
    key: 'connected',
    prop: 'connected',
    component: ConnectedComponent,
    label: { translationKey: 'orderPlace.device' },
    alignText: 'right',
  },
  {
    key: 'actions',
    label: { translationKey: 'drawer.actions.title' },
    component: ActionsCell,
    alignText: 'right',
  },
]
