import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Checkbox } from '@material-ui/core'

import getPayloadColor from '../../chart/utils/getPayloadColor'
import { ORDERS, TIPS, ALL } from '../../../../../constants/income'

const List = styled.ul`
  display: block;
  white-space: nowrap;
  margin: 0;
  margin-top: 15px;
  padding-left: 31px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: 20px;
  opacity: 0.7;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 200ms ease;

  ${({ active }) => active
    && 'opacity: 1;'}
`

const IncomeLegend = ({
  payload,
  onTipsIncomeClick,
  onOrdersIncomeClick,
  onIncomeSumClick,
  isTipsIncomeChecked,
  isOrdersIncomeChecked,
  isIncomeSumChecked,
}) => {
  const { t } = useTranslation()
  // Styles are destructed because array payload items order depends on chart lines render order.
  // So this approach prevents dynamicly reordering legend list items.
  const tipsStyle = { color: getPayloadColor(payload, TIPS) }
  const incomeStyle = { color: getPayloadColor(payload, ORDERS) }
  const incomeSumStyle = { color: getPayloadColor(payload, ALL) }

  return (
    <List>
      <ListItem
        active={isOrdersIncomeChecked}
        style={incomeStyle}
        onClick={onOrdersIncomeClick}
      >
        <Checkbox checked={isOrdersIncomeChecked} style={incomeStyle} />
        <span>{t('statistics.ordersIncomes')}</span>
      </ListItem>
      <ListItem
        active={isTipsIncomeChecked}
        style={tipsStyle}
        onClick={onTipsIncomeClick}
      >
        <Checkbox checked={isTipsIncomeChecked} style={tipsStyle} />
        <span>{t('statistics.tipsIncomes')}</span>
      </ListItem>
      <ListItem
        active={isIncomeSumChecked}
        style={incomeSumStyle}
        onClick={onIncomeSumClick}
      >
        <Checkbox checked={isIncomeSumChecked} style={incomeSumStyle} />
        <span>{t('common.inTotal')}</span>
      </ListItem>
    </List>
  )
}

IncomeLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  onTipsIncomeClick: PropTypes.func,
  onOrdersIncomeClick: PropTypes.func,
  onIncomeSumClick: PropTypes.func,
  isTipsIncomeChecked: PropTypes.bool,
  isOrdersIncomeChecked: PropTypes.bool,
  isIncomeSumChecked: PropTypes.bool,
}

IncomeLegend.defaultProps = {
  payload: [],
  onTipsIncomeClick: () => {},
  onOrdersIncomeClick: () => {},
  onIncomeSumClick: () => {},
  isTipsIncomeChecked: false,
  isOrdersIncomeChecked: false,
  isIncomeSumChecked: false,
}
export default IncomeLegend
