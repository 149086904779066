import styled from 'styled-components/macro'

import { TextField } from '../../inputs'

const StyledTextField = styled(TextField)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`

export default StyledTextField
