import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import TimeScalePicker from './TimeScalePicker'
import TimePeriodSelect from './TimePeriodSelect'

const SettingsConstainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`

const ChartSettings = ({
  activeTimeScale,
  activeTimePeriod,
  onTimeScaleChange: handleTimeScaleChange,
  onTimePeriodChange: handleTimePeriodChange,
  from,
  to,
}) => (
  <SettingsConstainer>
    <TimeScalePicker
      activeTimeScale={activeTimeScale}
      onChange={handleTimeScaleChange}
    />
    <TimePeriodSelect
      activeTimePeriod={activeTimePeriod}
      onChange={handleTimePeriodChange}
      from={from}
      to={to}
    />
  </SettingsConstainer>
)

ChartSettings.propTypes = {
  activeTimeScale: PropTypes.string,
  activeTimePeriod: PropTypes.string,
  onTimeScaleChange: PropTypes.func,
  onTimePeriodChange: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
}

ChartSettings.defaultProps = {
  activeTimeScale: '',
  activeTimePeriod: '',
  onTimeScaleChange: () => {},
  onTimePeriodChange: () => {},
  from: '',
  to: '',
}

export default ChartSettings
