import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import pathnames from '../../../constants/breadcrumbs'
import LinkRouter from '../LinkRouter'

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;
`)

const getBreadcrumbs = (pathname, paths) => {
  const breadcrumbs = []

  paths.forEach(({ path, noMatchPattern, ...rest }) => {
    const splitPathname = pathname.split('/')
    const noMatch = noMatchPattern.map(pattern =>
      pattern
        .split('/')
        .map((el, index) => (el[0] === ':' ? splitPathname[index] : el))
        .join('/'))

    if (noMatch.includes(pathname)) {
      return
    }

    const match = matchPath(pathname, {
      path,
    })

    if (match) {
      breadcrumbs.push({ match, path, noMatch, ...rest })
    }
  })

  return breadcrumbs
}

const NavigationBreadcrumbs = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const breadcrumbs = useMemo(
    () => getBreadcrumbs(location.pathname, pathnames),
    [location.pathname],
  )

  const content = useMemo(
    () =>
      breadcrumbs.map(({ match, render }) => {
        const renderContent = value =>
          (match.isExact ? (
            <Typography key={match.url}>{value}</Typography>
          ) : (
            <LinkRouter to={match.url} key={match.url}>
              {value}
            </LinkRouter>
          ))

        if (typeof render === 'function') {
          const Component = render
          return (
            <Component params={match.params} key={match.url}>
              {renderContent}
            </Component>
          )
        }
        return renderContent(t(render))
      }),
    [breadcrumbs, t],
  )

  return <StyledBreadcrumbs aria-label="breadcrumb">{content}</StyledBreadcrumbs>
}

export default NavigationBreadcrumbs
