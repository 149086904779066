import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const discountCodeValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('discountCodes.errors.name.required'),
  description: yup
    .string()
    .nullable()
    .max(1000, messageObject('discountCodes.errors.description.max')),
  value: yup
    .number('discountCodes.errors.number')
    .min(1, 'discountCodes.errors.tooSmall')
    .required('discountCodes.errors.isRequired'),
  totalUserAmount: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  minimumOrderPrice: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  totalAmount: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  totalDiscountPrice: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  dateFrom: yup
    .date()
    .min(new Date().toISOString().slice(0, 10), 'discountCodes.errors.dateFrom.minCurrent')
    .max(yup.ref('dateTo'), 'discountCodes.errors.dateFrom.maxDateTo')
    .typeError('validation.fullDate'),
  dateTo: yup
    .date()
    .min(yup.ref('dateFrom'), 'discountCodes.errors.dateTo.minDateFrom')
    .min(new Date().toISOString().slice(0, 10), 'discountCodes.errors.dateTo.minCurrent')
    .typeError('validation.fullDate'),
})

const discountCodeEditionValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('discountCodes.errors.name.required'),
  description: yup
    .string()
    .nullable()
    .max(1000, messageObject('discountCodes.errors.description.max')),
  value: yup
    .number('discountCodes.errors.number')
    .min(1, 'discountCodes.errors.value.tooSmall')
    .required('discountCodes.errors.isRequired'),
  totalUserAmount: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  minimumOrderPrice: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  totalAmount: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  totalDiscountPrice: yup
    .number('discountCodes.errors.number')
    .nullable()
    .min(1, 'discountCodes.errors.tooSmall'),
  dateFrom: yup
    .date()
    .max(yup.ref('dateTo'), 'discountCodes.errors.dateFrom.maxDateTo')
    .typeError('validation.fullDate'),
  dateTo: yup
    .date()
    .min(yup.ref('dateFrom'), 'discountCodes.errors.dateTo.minDateFrom')
    .typeError('validation.fullDate'),
})

export {
  discountCodeValidationSchema,
  discountCodeEditionValidationSchema,
}
