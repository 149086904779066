import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { Button, Typography } from '@material-ui/core'

import { FormButton } from '../../inputs'
import ModifierDialog from './ModifierDialog'
import useSnackbar from '../../../hooks/useSnackbar'
import getNextModifiersState from '../../../utils/getNextModifiersState'
import { FormStyleWrapper } from '../../wrappers'
import ModifierContainer from './ModifierContainer'
import ModifierOptionDialog from './ModifierOptionDialog'

const FormHeaderContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${theme.spacing(3)}px;
`,
)

const FormFooterContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(3)}px;
`,
)

const FlexFormStyleWrapper = styled(FormStyleWrapper)`
  flex: 1;
`

const ModifiersForm = ({ pushEvent, modifiers, disabled, onSubmit: handleSubmit }) => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [isModifierDialogOpen, setModifierDialogOpen] = useState(false)
  const [handleModifierDialogSubmit, setHandleModifierDialogSubmit] = useState(() => () => {})
  const [modifierDialogValues, setModifierDialogValues] = useState()
  const [isEditDialog, setIsEditDialog] = useState(false)

  const [isModifierOptionDialogOpen, setModifierOptionDialogOpen] = useState(false)
  const [modifierOptionDialogValues, setModifierOptionDialogValues] = useState()

  const handleModifierEdit = useCallback(
    ({ uuid: modifierUuid, ...modifierUpdates }) => {
      pushEvent(
        getNextModifiersState.actions.updateModifier({
          uuid: modifierUuid,
          ...modifierUpdates,
        }),
      )
    },
    [pushEvent],
  )

  const handleModifierCreate = useCallback(
    (data) => {
      const modifierUuid = uuid()
      pushEvent(
        getNextModifiersState.actions.createModifier({
          uuid: modifierUuid,
          ...data,
        }),
      )
    },
    [pushEvent],
  )

  const handleModifierDelete = useCallback(
    (modifierUuid) => {
      pushEvent(getNextModifiersState.actions.deleteModifier(modifierUuid))
    },
    [pushEvent],
  )

  const handleCreateModifierDialogOpen = () => {
    if (isEditDialog) {
      setIsEditDialog(false)
    }
    setModifierDialogValues({
      name: '',
      min: 0,
      description: '',
    })
    setHandleModifierDialogSubmit(() => handleModifierCreate)
    setModifierDialogOpen(true)
  }

  const handleEditModifierDialogOpen = useCallback(
    (modifier) => {
      if (modifier) {
        setIsEditDialog(true)
        setModifierDialogValues(modifier)
        setHandleModifierDialogSubmit(() => handleModifierEdit)
        setModifierDialogOpen(true)
      } else {
        addSnackbar(t('modifiers.errors.delete.notExist'))
      }
    },
    [addSnackbar, handleModifierEdit, t],
  )

  const handleModifierOptionEdit = useCallback(
    (option) => {
      pushEvent(getNextModifiersState.actions.updateOption(option))
    },
    [pushEvent],
  )

  const handleModifierOptionDelete = useCallback(
    (data) => {
      pushEvent(getNextModifiersState.actions.deleteOption(data))
    },
    [pushEvent],
  )

  const handleModifierOptionCreate = useCallback(
    (data) => {
      const optionUuid = uuid()
      pushEvent(
        getNextModifiersState.actions.createOption({
          uuid: optionUuid,
          ...data,
        }),
      )
    },
    [pushEvent],
  )

  const handleModifierOptionDialogOpen = (modifierUuid) => {
    setModifierOptionDialogValues({
      modifierUuid,
      name: '',
      price: '',
    })
    setModifierOptionDialogOpen(true)
  }

  return (
    <>
      <FormHeaderContainer>
        <Button onClick={handleCreateModifierDialogOpen} color="primary" variant="contained">
          {t('modifiers.add')}
        </Button>
      </FormHeaderContainer>
      <FlexFormStyleWrapper>
        {modifiers?.length ? (
          modifiers?.map(({ uuid: modifierUuid, name, description, min, max, options }) => (
            <ModifierContainer
              modifierUuid={modifierUuid}
              key={modifierUuid}
              name={name}
              options={options}
              min={min}
              max={max}
              description={description}
              pushEvent={pushEvent}
              onEditDialogOpen={handleEditModifierDialogOpen}
              onDelete={handleModifierDelete}
              onOptionEdit={handleModifierOptionEdit}
              onOptionDelete={handleModifierOptionDelete}
              onCreateOptionDialogOpen={handleModifierOptionDialogOpen}
            />
          ))
        ) : (
          <Typography>{t('modifiers.empty')}</Typography>
        )}
      </FlexFormStyleWrapper>
      <FormFooterContainer>
        <FormButton onClick={handleSubmit} color="primary" type="submit" disabled={disabled}>
          {t('common.save')}
        </FormButton>
      </FormFooterContainer>
      <ModifierDialog
        dialogValues={modifierDialogValues}
        open={isModifierDialogOpen}
        onClose={() => setModifierDialogOpen(false)}
        onSubmit={handleModifierDialogSubmit}
        isEdit={isEditDialog}
      />
      <ModifierOptionDialog
        title={t('modifiers.options.dialogTitle')}
        dialogValues={modifierOptionDialogValues}
        open={isModifierOptionDialogOpen}
        onClose={() => setModifierOptionDialogOpen(false)}
        onSubmit={handleModifierOptionCreate}
      />
    </>
  )
}

ModifiersForm.propTypes = {
  pushEvent: PropTypes.func.isRequired,
  modifiers: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ModifiersForm.defaultProps = {
  modifiers: [],
  disabled: false,
}

export default ModifiersForm
