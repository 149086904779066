import { useSelector } from 'react-redux'

const useIsAuthenticated = () =>
  useSelector((state) => {
    try {
      const { user, accessToken, refreshToken } = state.auth
      return accessToken && refreshToken && user
    } catch (err) {
      return false
    }
  })

export default useIsAuthenticated
