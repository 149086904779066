import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Form, Field, Formik } from 'formik'
import { Button, Card } from '@material-ui/core'

import { TextField, TextArea } from '../../inputs'
import { LIGHT_BLUE, WHITE } from '../../../constants/colors'
import BugFixingSrc from '../../../assets/images/bug-fixing.svg'
import SentryLogoSrc from '../../../assets/images/sentry-logo-black.svg'
import SoEasyLogoSrc from '../../../assets/images/soeasy.png'
import sentryFeedbackSchema from '../../../validations/sentryFeedbackSchema'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 150px);
  margin-left: 55px;

  @media (max-width: 450px) {
    margin: 0;
    padding: 5px 25px;
    width: 100%;
  }
`

const Container = styled(Card)`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 90%;
  max-width: 1100px;
  border-radius: 6px;
  background-color: white;

  @media (max-width: 815px) {
    grid-template-columns: 100%;
  }
`

const ContainerLeftPart = styled.article`
  position: relative;
  flex: 1;
  background-color: ${LIGHT_BLUE};

  @media (max-width: 1104px) {
    overflow: hidden;
  }

  @media (max-width: 815px) {
    display: none;
  }
`

const BugFixingImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 682px;
  padding: 0 20px;
  object-fit: cover;
  transition: transform 1s ease-in-out;
  transform: translateX(0);

  @media (max-width: 1104px) {
    transform: translateX(-17px);
  }
`

const ContainerRightPart = styled.article`
  flex: 1;
`

const ContainerHeader = styled.header`
  width: 100%;
  padding: 30px;
  font-size: 18px;
  text-align: right;
  font-weight: 800;
  letter-spacing: 0;

  @media (max-width: 450px) {
    padding-bottom: 0;
  }
`

const FormHeader = styled.h1`
  margin-bottom: 0;
`

const ResetTextHelper = styled.span`
  padding: 5px 5px 5px 0;
  margin-bottom: 24px;
  font-size: 14px;
`

const StyledButton = styled(Button)`
  white-space: nowrap;

  &.MuiButton-root {
    margin-top: 4px;
  }
`

const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 10px;
  }

  .MuiInputBase-input {
    background-color: ${WHITE}9c;
  }

  .MuiFormLabel-root,
  .MuiFormHelperText-root,
  .MuiInputBase-input {
    font-family: 'Montserrat', sans-serif;
  }
`

const ContainerFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 0;
  margin-top: 40px;

  @media (max-width: 450px) {
    margin-top: 20px;
  }
`

const FooterText = styled.span`
  transform: translateY(-2px);
  font-size: 13px;
`

const SentryImgage = styled.img`
  width: 102px;
`

const SendSentryReportForm = ({ onConfirm: handleConfirm }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <ContainerLeftPart>
        <BugFixingImage src={BugFixingSrc} alt="" />
      </ContainerLeftPart>
      <ContainerRightPart>
        <ContainerHeader>
          <span>SoEasy</span>
          <img src={SoEasyLogoSrc} alt="logo SoEasy" />
        </ContainerHeader>
        <Formik
          initialValues={{ name: '', email: '', comments: '' }}
          validationSchema={sentryFeedbackSchema}
          onSubmit={handleConfirm}
        >
          <Form>
            <FormWrapper>
              <FormHeader>{t('feedback.title')}</FormHeader>
              <ResetTextHelper>
                {t('feedback.titleHelper')}
              </ResetTextHelper>
              <Field
                name="name"
                label={t('feedback.name')}
                component={StyledTextField}
                formatError={t}
              />
              <Field
                name="email"
                type="email"
                label={t('feedback.email')}
                component={StyledTextField}
                formatError={t}
              />
              <Field
                name="comments"
                label={t('feedback.whatHappened')}
                component={TextArea}
                formatError={t}
              />
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('feedback.submit')}
              </StyledButton>
              <ContainerFooter>
                <FooterText>{t('feedback.footer')}</FooterText>
                <a href="https://sentry.io/welcome/">
                  <SentryImgage src={SentryLogoSrc} alt="Sentry" />
                </a>
              </ContainerFooter>
            </FormWrapper>
          </Form>
        </Formik>
      </ContainerRightPart>
    </Container>
  )
}

SendSentryReportForm.propTypes = {
  onConfirm: PropTypes.func,
}

SendSentryReportForm.defaultProps = {
  onConfirm: () => {},
}

export default SendSentryReportForm
