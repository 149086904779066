import { useMemo, useCallback } from 'react'
import { useOrderCollectPlace, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { createOrderCollectPlace, updateOrderCollectPlace } from '../services/api'
import { mapOrderCollectPlace } from '../utils/mapOrderCollectPlace'

const useOrderCollectPlaceForm = ({ id, clubId, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useOrderCollectPlace(id)
  useTrackLoadingStatus(`order-collect-place-${id}`, isValidating)

  const initialValues = useMemo(() => mapOrderCollectPlace(clubId, data || {}), [data, clubId])

  const handleRequest = useMemo(
    () =>
      (id
        ? values => mutate(() => updateOrderCollectPlace(id, values), false)
        : createOrderCollectPlace),
    [id, mutate],
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleRequest(values)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [handleRequest, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useOrderCollectPlaceForm
