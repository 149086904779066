const CLUB_SELECT_PATHNAMES = Object.freeze([
  '/statistics',
  '/workers',
  '/menu',
  '/modifiers',
  '/inventory',
  '/data',
  '/data/edit',
  '/open-hours',
  '/open-hours/edit',
  '/order-collect-places',
  '/order-preparation-places',
  '/order-delivery-places',
  '/discount-codes',
])

export default CLUB_SELECT_PATHNAMES
