import { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import SendReportView from './SendReportView'
import { sendUserFeedback } from '../services/sentry'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null, isFeedbackSent: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState(prevState => ({ ...prevState, eventId }))
    })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      const { eventId, isFeedbackSent } = this.state

      const handleConfirm = async (values, { setSubmitting }) => {
        const isSent = await sendUserFeedback({ ...values, eventId })

        if (isSent) {
          this.setState(prevState => ({ ...prevState, isFeedbackSent: true }))
        }
        setSubmitting(false)
      }

      return (
        <SendReportView
          isFeedbackSent={isFeedbackSent}
          onConfirm={handleConfirm}
        />
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
