import { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Info } from '@material-ui/icons'
import styled from 'styled-components/macro'

import { WHITE, BLACK } from '../constants/colors'

const Message = styled.div`
  position: fixed;
  z-index: 1200;
  transform: ${({ left }) => (left
    ? 'translate(-80%, calc(-100% - 10px))'
    : 'translate(-20%, calc(-100% - 10px))')};
  width: ${({ width }) => width || '340px'};
  padding: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms ease;
  border: 1px solid ${BLACK};
  border-radius: 5px;
  background-color: ${WHITE};
  box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.15);
  font-size: 12px;
  line-height: 16px;
  text-transform: none;

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    ${({ left }) => (left ? 'right: calc(20% - 20px);' : 'left: calc(20% + 3px);')}
    width: 16px;
    height: 16px;
    border: 1px solid ${BLACK};
    border-left: 0px;
    border-top: 0px;
    background-color: ${WHITE};
    transform: rotate(45deg);
  }
`

const HazeContainer = styled.div`
  width: 22px;
  height: 22px;
`

const StyledInfo = styled(Info)`
  width: 22px;
  height: 22px;
  padding-left: 2px;
`

const InfoHazeIcon = ({
  message,
  hazeWidth,
  horizontalAlign,
}) => {
  const containerRef = useRef(null)
  const [haze, setHaze] = useState(null)

  const updateMessagePosition = () => {
    try {
      const hazeNode = document.getElementById('haze').childNodes[0]
      setHaze(hazeNode)
      const { left, top } = containerRef.current.getBoundingClientRect()

      hazeNode.style.left = `${left}px`
      hazeNode.style.top = `${top}px`
    } catch {
      console.error('Message haze do not set.') // eslint-disable-line no-console
    }
  }

  useEffect(() => {
    const element = (
      <Message
        left={horizontalAlign === 'left'}
        width={hazeWidth}
      >
        {message}
      </Message>
    )
    ReactDOM.render(element, document.getElementById('haze'))
  }, [hazeWidth, horizontalAlign, message])

  const handleMouseLeave = () => {
    try {
      haze.style.visibility = 'hidden'
      haze.style.opacity = 0
    } catch {
      console.error('Hiding message haze failed.') // eslint-disable-line no-console
    }
  }

  const handleMouseEnter = () => {
    updateMessagePosition()
    const hazeNode = document.getElementById('haze').childNodes[0]
    hazeNode.style.visibility = 'visible'
    hazeNode.style.opacity = 1
  }

  return (
    <HazeContainer
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledInfo />
    </HazeContainer>
  )
}

InfoHazeIcon.propTypes = {
  message: PropTypes.string,
  hazeWidth: PropTypes.string,
  horizontalAlign: PropTypes.oneOf(['left', 'right']),
}

InfoHazeIcon.defaultProps = {
  message: '',
  hazeWidth: null,
  horizontalAlign: 'right',
}

export default InfoHazeIcon
