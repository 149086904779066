import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'

const createUser = async ({
  name,
  surname,
  email,
  password,
  role,
  club = [],
  enabled = true,
  adult = true,
}) => {
  const { data: { id } } = await fetch(API.users.create({
    name,
    surname,
    username: email,
    password,
    roleGroup: role,
    enabled,
    adult,
  }))

  let userClubs = Array.isArray(club) ? club : [club]
  userClubs = userClubs.filter(userClub => typeof userClub === 'number')

  await Promise.all(userClubs.map(async (clubId) => {
    fetch(API.clubUsersRelations.create({ userId: id, clubId }))
  }))

  return id
}

export default createUser
