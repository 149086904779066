import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useClubs } from '@itsilesia/udrink-common-components'

import AlertDialog from '../../AlertDialog'
import { deleteClub } from '../../../services/api/clubs'
import useSnackbar from '../../../hooks/useSnackbar'
import useDataList from '../../../hooks/useDataList'

const ClubsListActionsMenu = ({ clubId, clubName }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { addSnackbar } = useSnackbar()
  const useResource = ({ page, size }) =>
    useClubs({
      page,
      size,
      sort: 'name',
    })
  const { revalidate } = useDataList('clubs', useResource)

  const [anchorEl, setAnchorEl] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCancelDelete = () => {
    setIsDialogOpen(false)
    setAnchorEl(null)
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteClub(clubId)
      setIsDialogOpen(false)
      revalidate()
    } catch (error) {
      addSnackbar(t('common.errors.generic'))
      throw new Error()
    }
  }

  return (
    <>
      <Tooltip
        title={t('tableTooltips.menu')}
        aria-label={t('tableTooltips.menu')}
      >
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/order-preparation-places`}
        >
          {t('drawer.navigation.orderPreparation')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/order-collect-places`}
        >
          {t('drawer.navigation.orderCollect')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/order-delivery-places`}
        >
          {t('drawer.navigation.orderDelivery')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/menu`}
        >
          {t('drawer.navigation.menu')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/modifiers`}
        >
          {t('drawer.navigation.modifiers')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/inventory`}
        >
          {t('drawer.navigation.inventory')}
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleClose}
          to={`/clubs/${clubId}/discount-codes`}
        >
          {t('drawer.navigation.discountCodes')}
        </MenuItem>
        <MenuItem onClick={() => setIsDialogOpen(true)}>
          {t('drawer.navigation.delete')}
        </MenuItem>
      </Menu>
      <AlertDialog
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`${t('clubs.deleteMessage')}: ${clubName}?`}
        title={t('clubs.deleteTitle')}
        confirmText={t('common.delete')}
        confirmColor={theme.palette.error.main}
        isOpen={isDialogOpen}
      />
    </>
  )
}

ClubsListActionsMenu.propTypes = {
  clubId: PropTypes.number.isRequired,
  clubName: PropTypes.string.isRequired,
}

export default ClubsListActionsMenu
