import PropTypes from 'prop-types'
import { NewPasswordController } from '@itsilesia/udrink-common-components'
import styled from 'styled-components/macro'
import { useState } from 'react'

import { updatePassword } from '../services/api'
import NewPasswordForm from '../components/forms/NewPasswordForm'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.dark};

  & > * {
    transform: translateY(-3%);
  }
`

const NewPasswordScreen = ({ match }) => {
  const [wasResetSuccessfull, setWasResetSuccessful] = useState(null)

  return (
    <Container>
      <NewPasswordController
        token={match.params.token}
        updatePassword={updatePassword}
        onSuccess={() => setWasResetSuccessful(true)}
        onError={(err) => {
          // TODO: add more detailed error based on what happened
          setWasResetSuccessful(false)
          if (process.env.NODE_ENV !== 'production') {
          /* eslint-disable-next-line no-console */
            console.error(err)
          }
        }}
      >
        {props => <NewPasswordForm wasSuccess={wasResetSuccessfull} {...props} />}
      </NewPasswordController>
    </Container>
  )
}

NewPasswordScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default NewPasswordScreen
