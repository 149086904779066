import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'

const updateOrderDeliveryPlace = async (id, values) => {
  const { data: orderDeliveryPlaces } = await fetch(API.orderDeliveryPlaces.update(id, values))
  return orderDeliveryPlaces
}

const createOrderDeliveryPlace = async (values) => {
  const { data: orderDeliveryPlaces } = await fetch(API.orderDeliveryPlaces.create(values))
  return orderDeliveryPlaces
}

const deleteOrderDeliveryPlace = async (id) => {
  const { data: orderDeliveryPlaces } = await fetch(API.orderDeliveryPlaces.delete(id))
  return orderDeliveryPlaces
}

export {
  createOrderDeliveryPlace,
  updateOrderDeliveryPlace,
  deleteOrderDeliveryPlace,
}
