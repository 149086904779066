import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const InputWraper = styled.div`
  position: relative;
  padding: 10px 0px;
`
const ErrorMessage = styled.div`
  position: absolute;
  bottom: -3px;
  font-size: 11px;
  color: #ff5465;
`
const Input = styled.input`
  width: 100%;
  padding: 14px;
  border-radius: 4px;
  border: 0;
  font-size: 13px;
  letter-spacing: 0.3px;
  font-weight: 300;
  color: #0f1117;
  background: ${props => (props.errorMessage ? '#ffb0b0' : 'white')};
  outline: none;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.1);

  &::-webkit-input-placeholder {
    color: ${props => (props.errorMessage ? '#20283b' : '#98a2bb')};
  }
`

const LogInTextField = ({ children, errorMessage, ...props }) => (
  <InputWraper>
    <Input {...props}>{children}</Input>
    <ErrorMessage>{errorMessage}</ErrorMessage>
  </InputWraper>
)

LogInTextField.defaultProps = {
  children: null,
  errorMessage: null,
}

LogInTextField.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
}

export default LogInTextField
