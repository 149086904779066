import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components/macro'

import { CREATE, UPDATE } from '../constants/formModes'
import { discountCodeValidationSchema, discountCodeEditionValidationSchema } from '../validations/discountCodeSchema'
import { PERCENT } from '../constants/discountTypes'
import { ACTIVE } from '../constants/discountStatuses'
import { ADMIN_ROLE, MANAGER_ROLE } from '../constants/roleGroups'
import { mapDiscountCodeValues } from '../utils/mapDiscountCode'
import RouteContainer from '../components/layout/RouteContainer'
import RestrictedContent from '../components/auth/RestrictedContent'
import RouteHeader from '../components/layout/RouteHeader'
import DiscountCodeForm from '../components/forms/DiscountCodeForm/DiscountCodeForm'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import useDiscountCodeForm from '../hooks/useDiscountCodeForm'
import useSnackbar from '../hooks/useSnackbar'
import useHasRole from '../hooks/useHasRole'
import getResponseErrorCodes from '../utils/getResponseErrorCodes'
import parseCodeToI18nMessage from '../utils/parseCodeToI18nMessage'

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const currentDate = new Date()
const initialValues = {
  type: PERCENT,
  dateFrom: new Date().toISOString().slice(0, 10),
  dateTo: new Date(currentDate.setMonth(currentDate.getMonth() + 1)).toISOString().slice(0, 10),
  status: ACTIVE,
}

const DiscountCodeScreen = () => {
  const { id, clubId } = useParams()
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const history = useHistory()
  const isAdmin = useHasRole(ADMIN_ROLE)

  const mode = id ? UPDATE : CREATE

  const {
    handleSubmit,
    initialValues: oldValues,
    isLoading,
  } = useDiscountCodeForm({
    id,
    clubId,
    onSuccess: () => {
      history.push(isAdmin ? `/clubs/${clubId}/discount-codes` : '/discount-codes')
      addSnackbar(t(`discountCodes.success.${mode === UPDATE ? 'update' : 'create'}`))
    },
    onError: (err) => {
      const codes = getResponseErrorCodes(err)

      if (codes.length) {
        codes.forEach((code) => {
          addSnackbar(t(...parseCodeToI18nMessage(code)))
        })
      } else {
        addSnackbar(t('common.errors.generic'))
      }
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    },
  })

  if (isLoading) {
    return <CenteredContainer><CircularProgress /></CenteredContainer>
  }

  return (
    <Formik
      initialValues={oldValues ? mapDiscountCodeValues(oldValues) : initialValues}
      enableReinitialize
      validationSchema={mode === UPDATE
        ? discountCodeEditionValidationSchema
        : discountCodeValidationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <RouteContainer justifyContent="flex-start">
          <RestrictedContent accessRole={ADMIN_ROLE}>
            <NavigationBreadcrumbs />
          </RestrictedContent>
          <RestrictedContent accessRole={MANAGER_ROLE}>
            <RouteHeader>
              {t(`discountCodes.${mode === CREATE ? 'createTitle' : 'editTitle'}`)}
            </RouteHeader>
          </RestrictedContent>
          <DiscountCodeForm
            {...formikProps}
            mode={mode}
            onCancel={() => history.push(isAdmin ? `/clubs/${clubId}/discount-codes` : '/discount-codes')}
          />
        </RouteContainer>
      )}
    </Formik>
  )
}

export default DiscountCodeScreen
