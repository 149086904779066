import { useContext } from 'react'
import { useClub } from '@itsilesia/udrink-common-components'
import { useTranslation } from 'react-i18next'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import ClubStatistics from '../../components/clubs/ClubStatistics'
import RouteHeader from '../../components/layout/RouteHeader'

const ClubStatisticsScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)
  const { data: club } = useClub(clubId)

  return (
    <RouteContainer fillHeight={false}>
      <RouteHeader>{t('drawer.navigation.statistics')}</RouteHeader>
      {club && <ClubStatistics club={club} />}
    </RouteContainer>
  )
}

export default ClubStatisticsScreen
