import { InputAdornment } from '@material-ui/core'
import TextField from './TextField'

export const PriceTextField = props => (
  <TextField
    fullWidth
    type="number"
    inputProps={{
      step: 'any',
      min: 0.01,
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <mat-icon matSuffix>zł</mat-icon>
        </InputAdornment>
      ),
    }}
    {...props}
  />
)
