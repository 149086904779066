import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  CardHeader,
  CardContent,
  IconButton,
  Collapse,
  Tooltip,
  Divider,
  Typography,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components/macro'

import useCollapse from '../../../hooks/useCollapse'
import IdPropType from '../../../propTypes/IdPropType'
import ModifierOption from './ModifierOption/ModifierOption'
import getNextModifiersState from '../../../utils/getNextModifiersState'
import ModifierActions from './ModifierActions'

const StyledCardContent = styled(CardContent)`
  padding: 0;

  &:last-child {
    padding-bottom: 0;
  }
`

const StyledCard = styled.div`
  margin-bottom: 20px;
`
const PaddedDivider = styled(Divider)`
  margin: 15px 0px;
`

const ModifierDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
`

const ModifierContainer = ({
  modifierUuid,
  name,
  options,
  min,
  max,
  description,
  pushEvent,
  onEditDialogOpen: handleToEditDialogOpen,
  onCreateOptionDialogOpen: handleToCreateOptionDialogOpen,
  onDelete: handleDelete,
  onOptionDelete: handleOptionDelete,
  onOptionEdit: handleOptionEdit,
}) => {
  const { t } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse()
  const handleDragEnd = useCallback((result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return
    }

    pushEvent(getNextModifiersState.actions.reorderOption({
      modifierUuid,
      uuid: options[result.source.index].uuid,
      newOrderNumber: options[result.destination.index].position,
    }))
  }, [modifierUuid, options, pushEvent])

  const handleEditDialogOpen = () => {
    handleToEditDialogOpen({
      uuid: modifierUuid,
      name,
      description: description ?? '',
      min,
      max: max ?? null,
    })
  }

  const handleCreateOptionDialogOpen = () => {
    handleToCreateOptionDialogOpen(modifierUuid)
  }

  const handleModifierDelete = () => {
    handleDelete(modifierUuid)
  }

  return (
    <StyledCard>
      <CardHeader
        title={name}
        onClick={toggleCollapse}
        action={(
          <>
            <ModifierActions
              onEditDialogOpen={handleEditDialogOpen}
              onDelete={handleModifierDelete}
              onCreateOptionDialogOpen={handleCreateOptionDialogOpen}
            />
            <Tooltip title={collapse ? t('common.expand') : t('common.fold')}>
              <IconButton>{collapse ? <ExpandMore /> : <ExpandLess />}</IconButton>
            </Tooltip>
          </>
        )}
      />
      <Collapse in={!collapse} timeout="auto" unmountOnExit>
        <StyledCardContent>
          <ModifierDetailsContainer>
            <Typography>{`${t('modifiers.min')}: ${min}`}</Typography>
            <Typography>{`${t('modifiers.max')}: ${max ?? t('common.missing')}`}</Typography>
          </ModifierDetailsContainer>
          <PaddedDivider />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={`${name}-list`}>
              {({ innerRef, droppableProps, placeholder }) => (
                <div ref={innerRef} {...droppableProps}>
                  {options.map((option, index) => (
                    <ModifierOption
                      key={option.uuid}
                      index={index}
                      modifierUuid={modifierUuid}
                      option={option}
                      onDelete={handleOptionDelete}
                      onEdit={handleOptionEdit}
                    />
                  ))}
                  <span>{placeholder}</span>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </StyledCardContent>
      </Collapse>
    </StyledCard>
  )
}

ModifierContainer.propTypes = {
  modifierUuid: IdPropType.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  description: PropTypes.string,
  pushEvent: PropTypes.func.isRequired,
  onEditDialogOpen: PropTypes.func,
  onCreateOptionDialogOpen: PropTypes.func,
  onDelete: PropTypes.func,
  onOptionDelete: PropTypes.func,
  onOptionEdit: PropTypes.func,
  onOptionCreate: PropTypes.func,
  onSubcategoryReorder: PropTypes.func,
}

ModifierContainer.defaultProps = {
  options: [],
  min: 0,
  max: null,
  description: '',
  onEditDialogOpen: () => {},
  onCreateOptionDialogOpen: () => {},
  onDelete: () => {},
  onOptionDelete: () => {},
  onOptionEdit: () => {},
  onOptionCreate: () => {},
  onSubcategoryReorder: () => {},
}

export default memo(ModifierContainer)
