import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import ClubsListNameLink from '../../clubs/ClubsList/ClubsListNameLink'
import UserPropType from '../../../propTypes/UserPropType'
import { FieldHeader, FieldContainer, FieldValue } from '../fields'

const UserClubsDetails = ({ user: { clubs }, ...props }) => {
  const { t } = useTranslation()
  return (
    <FieldContainer {...props}>
      <FieldHeader>{t('common.clubs')}</FieldHeader>
      <FieldValue>
        {clubs.length > 0 ? (
          clubs.map(item => (
            <div key={item.id}>
              <ClubsListNameLink row={item}>
                {`${item.name} (${item.location.city}, ${item.location.address})`}
              </ClubsListNameLink>
            </div>
          ))
        ) : (
          <Typography variant="body1">{t('common.missing')}</Typography>
        )}
      </FieldValue>
    </FieldContainer>
  )
}

UserClubsDetails.propTypes = {
  user: UserPropType.isRequired,
}

export default UserClubsDetails
