import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'

const updateProductAmount = async (productAmountId, amount) => {
  const { data } = await fetch(API.productsAmount.add(productAmountId, { amount }))
  return data
}

const createProductAmount = async (productId, amount) => {
  const { data } = await fetch(API.productsAmount.create({
    productId,
    amount,
  }))
  return data
}

const deleteProductAmount = async (productAmountId) => {
  const { data } = await fetch(API.productsAmount.delete(productAmountId))
  return data
}

export {
  updateProductAmount,
  createProductAmount,
  deleteProductAmount,
}
