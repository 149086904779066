import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { UPLOAD_IMAGE } from '../../../constants/menuEditEvents'
import { productWithPriceInPeanutsSchema } from '../../../validations/productSchema'
import { FormButton } from '../../inputs'
import useSnackbar from '../../../hooks/useSnackbar'
import getNextMenuState from '../../../utils/getNextMenuState'
import getNextMenuStructureState from '../../../utils/getNextMenuStructureState'
import IdPropType from '../../../propTypes/IdPropType'

import CategoriesContainer from './CategoriesContainer'
import ProductDialog from './ProductDialog'
import SubcategoriesContainer from './SubcategoriesContainer'
import SubcategoryDialog from './SubcategoryDialog'

const FormFooterContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(3)}px;
`,
)

const MenuForm = ({
  clubId,
  activeMenu,
  isLoading,
  pushEvent,
  onSubmit,
  disabled,
  activeCategory,
  onActiveCategoryChange,
  subcategoriesToDeleteImage,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false)
  const [isSubcategoryDialogOpen, setIsSubcategoryDialogOpen] = useState(false)
  const [dialogSubcategoryInitialValues, setDialogSubcategoryInitialValues] = useState(undefined)
  const [dialogProductInitialValues, setDialogProductInitialValues] = useState()

  const handleProductEditDialogOpen = useCallback((product) => {
    if (product) {
      setDialogProductInitialValues(product)
      setIsProductDialogOpen(true)
    } else {
      addSnackbar(t('product.errors.delete.productNotExist'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubcategoryEditDialogOpen = useCallback((subcategory) => {
    if (subcategory) {
      setDialogSubcategoryInitialValues(subcategory)
      setIsSubcategoryDialogOpen(true)
    } else {
      addSnackbar(t('subcategory.errors.delete.subcategoryNotExist'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductDelete = useCallback((productId) => {
    pushEvent(getNextMenuState.actions.deleteProduct(productId))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubcategoryDelete = useCallback((subcategoryUuid) => {
    pushEvent(getNextMenuStructureState.actions.deleteSubcategory(subcategoryUuid))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductEdit = useCallback(({ id, ...productUpdates }) => {
    try {
      Object.entries(productUpdates).forEach(([key]) => {
        if (
          Object.entries(productWithPriceInPeanutsSchema.fields).some(
            ([schemaKey]) => schemaKey === key,
          )
        ) {
          productWithPriceInPeanutsSchema.validateSyncAt(key, productUpdates)
        }
      })

      pushEvent(
        getNextMenuState.actions.updateProduct({
          id,
          ...productUpdates,
        }),
      )

      pushEvent({
        type: UPLOAD_IMAGE,
        payload: {
          productUuid: id,
          files: productUpdates.imageFiles,
        },
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
      addSnackbar(t('product.errors.value.invalid'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubcategoryEdit = useCallback(({ subcategoryUuid, ...subcategoryUpdates }) => {
    pushEvent(
      getNextMenuStructureState.actions.updateSubcategory({
        subcategoryUuid,
        ...subcategoryUpdates,
      }),
    )

    pushEvent({
      type: UPLOAD_IMAGE,
      payload: {
        subcategoryUuid,
        files: subcategoryUpdates.imageFiles,
      },
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductCreate = useCallback((data) => {
    pushEvent(
      getNextMenuState.actions.createProduct({
        ...data,
        id: data.productUuid,
        uuid: data.productUuid,
        images: [],
        modifiers: [],
      }),
    )

    if (data.imageFiles?.length > 0) {
      pushEvent({
        type: UPLOAD_IMAGE,
        payload: {
          productUuid: data.productUuid,
          files: data.imageFiles,
        },
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubcategoryCreate = useCallback(
    (data) => {
      const subcategoryUuid = uuid()
      const activeCategoryObject = activeMenu[activeCategory]
      const categoryId = activeCategoryObject.id
      pushEvent(
        getNextMenuStructureState.actions.createSubcategory({
          ...data,
          categoryId,
          activeCategory,
          uuid: subcategoryUuid,
          images: [],
        }),
      )
      if (data.imageFiles?.length > 0) {
        pushEvent({
          type: UPLOAD_IMAGE,
          payload: {
            subcategoryUuid,
            files: data.imageFiles,
          },
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeCategory],
  )

  const handleSubcategoryReorder = useCallback((subcategoryUuid, newOrderNumber) => {
    pushEvent(
      getNextMenuStructureState.actions.reorderSubcategory({
        subcategoryUuid,
        newOrderNumber,
      }),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCategoryReorder = useCallback((categoryId, newIndex) => {
    pushEvent(
      getNextMenuStructureState.actions.reorderCategory({
        categoryId,
        newIndex,
      }),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductModifierAdd = useCallback((productUuid, modifierUuid) => {
    pushEvent(
      getNextMenuState.actions.addModifier({
        productUuid,
        modifierUuid,
      }),
    )
  }, [pushEvent])

  const handleProductModifierRemove = useCallback((productUuid, modifierUuid) => {
    pushEvent(
      getNextMenuState.actions.removeModifier({
        productUuid,
        modifierUuid,
      }),
    )
  }, [pushEvent])

  return (
    <>
      <CategoriesContainer
        activeCategory={activeCategory}
        activeMenu={activeMenu}
        disabled={disabled}
        onNewActiveCategoryClick={value => onActiveCategoryChange(value)}
        onCategoryReorder={handleCategoryReorder}
        onSubcategoryCreate={handleSubcategoryCreate}
      />
      <SubcategoriesContainer
        groupedByCategories={activeMenu}
        activeCategory={activeCategory}
        isMenuDisabled={disabled}
        pushEvent={pushEvent}
        isLoading={isLoading}
        subcategoriesToDeleteImage={subcategoriesToDeleteImage}
        onProductDelete={handleProductDelete}
        onProductEdit={handleProductEdit}
        onProductCreate={handleProductCreate}
        onProductEditDialogOpen={handleProductEditDialogOpen}
        onSubcategoryDelete={handleSubcategoryDelete}
        onSubcategoryEditDialogOpen={handleSubcategoryEditDialogOpen}
        onSubcategoryReorder={handleSubcategoryReorder}
        onProductModifierAdd={handleProductModifierAdd}
        onProductModifierRemove={handleProductModifierRemove}
      />
      <FormFooterContainer>
        <FormButton onClick={onSubmit} color="primary" type="submit" disabled={disabled}>
          {t('common.save')}
        </FormButton>
      </FormFooterContainer>
      <ProductDialog
        title={t('product.edition')}
        initialValues={dialogProductInitialValues}
        open={isProductDialogOpen}
        onClose={() => setIsProductDialogOpen(false)}
        onSubmit={handleProductEdit}
        pushEvent={pushEvent}
        clubId={clubId}
        onAdd={handleProductModifierAdd}
        onRemove={handleProductModifierRemove}
      />
      <SubcategoryDialog
        title={t('subcategory.edition')}
        initialValues={dialogSubcategoryInitialValues}
        open={isSubcategoryDialogOpen}
        onClose={() => setIsSubcategoryDialogOpen(false)}
        onSubmit={handleSubcategoryEdit}
      />
    </>
  )
}

MenuForm.defaultProps = {
  disabled: false,
  isLoading: false,
}

MenuForm.propTypes = {
  clubId: IdPropType.isRequired,
  activeMenu: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  pushEvent: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  activeCategory: PropTypes.string.isRequired,
  onActiveCategoryChange: PropTypes.func.isRequired,
  subcategoriesToDeleteImage: PropTypes.array.isRequired,
}

export default MenuForm
