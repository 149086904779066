import { useMemo, useCallback, useReducer, useEffect } from 'react'
import { useMenu, API, usePrevious } from '@itsilesia/udrink-common-components'
import { createSlice } from '@reduxjs/toolkit'
import useSWR from 'swr'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'

import getNextMenuState from '../utils/getNextMenuState'
import getNextMenuStructureState from '../utils/getNextMenuStructureState'
import getImageUrl from '../utils/getImageUrl'

const createInitialPipeState = (rest = {}) => ({
  pipe: [],
  ...rest,
})

const EventsPipe = createSlice({
  name: 'EventsPipe',
  initialState: createInitialPipeState(),
  reducers: {
    push(state, action) {
      state.pipe.push(action.payload)
    },
    clear(state) {
      state.pipe = []
    },
  },
})

const isMissingActiveMenuError = error => (
  error?.response
  && error.response?.status === 404
  && error.response?.data?.some(({ code }) => code?.match('menu.active_not_found'))
)

const flatProduct = ({ uuid, subcategoryUuid, vatRate, ...product }) => ({
  ...product,
  id: uuid,
  uuid,
  vatType: vatRate.vatType,
  subcategoryUuid,
  imageUrl: product.images[0]?.imageUuid ? getImageUrl(product.images[0].imageUuid) : null,
  availabilityHoursBegin: product.availabilityHours.beginTime,
  availabilityHoursEnd: product.availabilityHours.endTime,
})

const useMenuForm = ({ clubId }) => {
  const [pipeState, pipeDispatch] = useReducer(EventsPipe.reducer, {}, createInitialPipeState)
  const prevPipe = usePrevious(pipeState.pipe)

  const pushEvent = useCallback(event => pipeDispatch(EventsPipe.actions.push(event)), [])
  const clearEvents = useCallback(() => pipeDispatch(EventsPipe.actions.clear()), [])

  const {
    data: currentActiveMenu,
    isValidating: activeMenuIsValidating,
    error: activeMenuError,
    mutate: mutateCurrentActiveMenu,
  } = useSWR(() => API.clubMenus.getActive(clubId).key)

  const { id: currentActiveMenuId } = currentActiveMenu || {}

  const {
    data: activeMenuData,
    isValidating,
    mutate: mutateMenu,
    error: menuError,
  } = useMenu(() => currentActiveMenuId)

  const prevActiveMenuData = usePrevious(activeMenuData)
  const activeMenu = useMemo(() => {
    if (!activeMenuData) {
      return null
    }

    const data = pipeState.pipe === prevPipe && pipeState.pipe.length !== 0
      ? prevActiveMenuData
      : activeMenuData

    const products = pipeState.pipe.reduce(
      getNextMenuState.reducer,
      data.menuProducts.map(flatProduct),
    )

    const structure = pipeState.pipe.reduce(
      getNextMenuStructureState.reducer,
      data.structure,
    )

    const groupedBySubcategories = groupBy(products, 'subcategoryUuid')

    return activeMenuError
      ? null
      : {
        ...data,
        menuProducts: products,
        categories: keyBy(
          structure
            ? structure.map(
              ({ subcategories, ...category }) => ({
                ...category,
                subcategories: subcategories.map(
                  subcategory => ({
                    ...subcategory,
                    imageUrl: subcategory.imageUrl || subcategory.images[0]?.links[0]?.href,
                    products: groupedBySubcategories[subcategory.uuid],
                  }),
                ),
              }),
            )
            : [],
          'name',
        ),
      }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    activeMenuData,
    pipeState.pipe,
    activeMenuError,
  ])

  useEffect(() => {
    if (typeof currentActiveMenuId === 'number') {
      clearEvents()
    }
  }, [clearEvents, currentActiveMenuId])

  const loading = activeMenuIsValidating || (!activeMenuError && isValidating)

  const revalidate = async () => {
    await mutateCurrentActiveMenu()
    await mutateMenu()
  }

  return {
    pipeState,
    activeMenu,
    pushEvent,
    isValidating: loading,
    revalidate,
    clearEvents,
    error: isMissingActiveMenuError(activeMenuError) ? null : activeMenuError || menuError,
  }
}

export default useMenuForm
