import { useMemo, useCallback } from 'react'
import { useOrderDeliveryPlace, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { createOrderDeliveryPlace, updateOrderDeliveryPlace } from '../services/api'
import { mapOrderDeliveryPlace } from '../utils/mapOrderDeliveryPlace'

const useOrderDeliveryPlaceForm = ({ id, clubId, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useOrderDeliveryPlace(id)
  useTrackLoadingStatus(`order-delivery-place-${id}`, isValidating)

  const initialValues = useMemo(() => mapOrderDeliveryPlace(clubId, data || {}), [data, clubId])

  const handleRequest = useMemo(
    () =>
      (id
        ? values => mutate(() => updateOrderDeliveryPlace(id, values), false)
        : createOrderDeliveryPlace),
    [id, mutate],
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleRequest(values)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [handleRequest, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useOrderDeliveryPlaceForm
