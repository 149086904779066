import ActionsCell from './OrderPreparationPlacesListActions'
import ConnectedComponent from '../ConnectedComponent'

const columns = [
  {
    key: 'name',
    prop: 'name',
    label: { translationKey: 'orderPreparation.title' },
  },
  {
    key: 'zone',
    prop: 'workZone',
    label: { translationKey: 'orderPreparation.zone' },
  },
  {
    key: 'connected',
    prop: 'connected',
    component: ConnectedComponent,
    label: { translationKey: 'orderPlace.device' },
    alignText: 'right',
  },
  {
    key: 'actions',
    label: { translationKey: 'drawer.actions.title' },
    component: ActionsCell,
    alignText: 'right',
  },
]

export default columns
