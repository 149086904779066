import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Roles from '../../../constants/roleGroups'
import RestrictedContent from '../../../components/auth/RestrictedContent'

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
`

const RoleButton = styled(Button)(({ theme }) => `
  & + & {
    margin-left: ${theme.spacing(2)}px;
  }
`)

const UserRoleSelect = ({ onRoleClick: handleRoleClick }) => {
  const { t } = useTranslation()

  return (
    <Container>
      {Object.values(Roles).map(({ value, code, accessRole }) => (
        <RestrictedContent
          key={value}
          accessRole={accessRole}
        >
          <RoleButton variant="contained" onClick={() => handleRoleClick(value)}>
            {t(code)}
          </RoleButton>
        </RestrictedContent>
      ))}
    </Container>
  )
}

UserRoleSelect.propTypes = {
  onRoleClick: PropTypes.func,
}

UserRoleSelect.defaultProps = {
  onRoleClick: () => {},
}

export default UserRoleSelect
