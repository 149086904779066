import { useTranslation } from 'react-i18next'
import { Card, CardContent, Divider } from '@material-ui/core'
import styled from 'styled-components/macro'

import { DataField, StatusField } from '../../data/fields'
import ClubPropType from '../../../propTypes/ClubPropType'
import ClubOwnerDetails from './ClubOwnerDetails'

const StyledCardContent = styled(CardContent)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "status  owner"
    "divider divider"
    "city zipCode"
    "street street";
  padding: 30px;
`

const StyledDivider = styled(Divider)`
  grid-area: divider;
  margin: 20px 8px;
`

const ClubDetails = ({ club: { club } }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <StyledCardContent>
        <StatusField
          label={t('common.status')}
          status={club.active}
          style={{ gridArea: 'status' }}
        />
        <ClubOwnerDetails
          club={{ club }}
          style={{ gridArea: 'owner' }}
        />
        <StyledDivider />
        <DataField
          label={t('clubs.form.city')}
          value={club.location.city}
          style={{ gridArea: 'city' }}
        />
        <DataField
          label={t('clubs.form.zipCode')}
          value={club.location.postalCode}
          style={{ gridArea: 'zipCode' }}
        />
        <DataField
          label={t('clubs.form.street')}
          value={club.location.address}
          style={{ gridArea: 'street' }}
        />
      </StyledCardContent>
    </Card>
  )
}

ClubDetails.propTypes = {
  club: ClubPropType.isRequired,
}

export default ClubDetails
