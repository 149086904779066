import { API } from '@itsilesia/udrink-common-components'

import fetch from '../../fetch'

const deleteDiscountCode = async (id) => {
  const { data } = await fetch(API.discounts.delete(id))
  return data
}

const createDiscountCode = async (values) => {
  const { data } = await fetch(API.discounts.create(values))
  return data
}

const updateDiscountCode = async (id, values) => {
  const { data: club } = await fetch(API.discounts.update(id, values))
  return club
}

export { createDiscountCode, updateDiscountCode, deleteDiscountCode }
