import PropTypes from 'prop-types'

import { fieldToTextFieldProps, hasFieldProps } from './TextField/TextField.utils'
import TextField from './TextField/TextField'

const OutlinedTextArea = ({ children, errorMessage, ...props }) =>
  (hasFieldProps(props) ? (
    <TextField
      multiline
      fullWidth
      minRows={3}
      InputLabelProps={{ shrink: true }}
      {...fieldToTextFieldProps(props)}
    >
      {children}
    </TextField>
  ) : (
    <TextField {...props}>{children}</TextField>
  ))

OutlinedTextArea.defaultProps = {
  children: null,
  errorMessage: null,
  variant: 'outlined',
}

OutlinedTextArea.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
  variant: PropTypes.string,
}

export default OutlinedTextArea
