import { createSlice } from '@reduxjs/toolkit'
import maxBy from 'lodash/maxBy'

const getNextMenuStructureState = createSlice({
  name: 'GetNextMenuStructureState',
  initialState: null,
  reducers: {
    createSubcategory(structure, { payload: subcategory }) {
      const activeCategoryObject = structure.find(
        category => subcategory.activeCategory === category.name,
      )
      const categoryId = activeCategoryObject.id

      const categoryIndex = structure.findIndex(
        category => subcategory.activeCategory === category.name,
      )

      // eslint-disable-next-line function-paren-newline
      const maxMenuSubcategoryOrder = maxBy(
        structure[categoryIndex].subcategories, 'position',
      // eslint-disable-next-line function-paren-newline
      )?.position || 0

      structure[categoryIndex].subcategories.push({
        ...subcategory,
        categoryId,
        position: maxMenuSubcategoryOrder + 1,
      })
    },
    updateSubcategory(structure, {
      payload: {
        subcategoryUuid,
        ...subcategoryUpdates
      },
    }) {
      const categoryIndex = structure.findIndex(
        category => category.subcategories.some(
          subcategory => subcategory.uuid === subcategoryUuid,
        ),
      )
      const subcategoryIndex = structure[categoryIndex].subcategories.findIndex(
        subcategory => subcategory.uuid === subcategoryUuid,
      )

      structure[categoryIndex].subcategories[subcategoryIndex] = {
        ...structure[categoryIndex].subcategories[subcategoryIndex],
        ...subcategoryUpdates,
      }
    },
    deleteSubcategory(structure, { payload: subcategoryUuid }) {
      const categoryIndex = structure.findIndex(
        category => category.subcategories.some(
          subcategory => subcategory.uuid === subcategoryUuid,
        ),
      )
      const indexToDelete = structure[categoryIndex].subcategories.findIndex(
        subcategory => subcategory.uuid === subcategoryUuid,
      )
      structure[categoryIndex].subcategories.splice(indexToDelete, 1)
      structure[categoryIndex].subcategories.forEach((subcategory, index) => {
        subcategory.position = index + 1
      })
    },
    reorderSubcategory(structure, { payload: { subcategoryUuid, newOrderNumber } }) {
      const categoryIndex = structure.findIndex(
        category => category.subcategories.some(
          subcategory => subcategory.uuid === subcategoryUuid,
        ),
      )

      const subcategoryIndex = structure[categoryIndex].subcategories.findIndex(
        subcategory => subcategory.uuid === subcategoryUuid,
      )

      if (categoryIndex > -1 && subcategoryIndex > -1) {
        const [removed] = structure[categoryIndex].subcategories.splice(subcategoryIndex, 1)
        structure[categoryIndex].subcategories.splice(newOrderNumber - 1, 0, removed)
      }
      structure[categoryIndex].subcategories.forEach((subcategory, index) => {
        subcategory.position = index + 1
      })
    },
    reorderCategory(structure, { payload: { categoryId, newIndex } }) {
      const categoryIndex = structure.findIndex(category => category.id === categoryId)

      if (categoryIndex > -1) {
        const [removed] = structure.splice(categoryIndex, 1)
        structure.splice(newIndex, 0, removed)
      }
    },
  },
})

export default getNextMenuStructureState
