import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

export const modifierSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('modifiers.errors.name.required'),
  min: yup
    .number()
    .integer()
    .test('isPositiveNumberTest', 'modifiers.errors.min.isPositiveNumber', value => parseFloat(value) >= 0)
    .required('modifiers.errors.min.required'),
  max: yup
    .number()
    .integer()
    .test(
      'isPositiveNullableNumberTest',
      'modifiers.errors.max.isPositiveNumber',
      value => value === null || value === undefined || parseFloat(value) >= 0,
    )
    .min(yup.ref('min'), 'modifiers.errors.max.isMoreThanMin')
    .nullable(true),
  description: yup
    .string()
    .max(1000, messageObject('modifiers.errors.description.max')),
})
