import PropTypes from 'prop-types'

import LinkRouter from '../../navigation/LinkRouter'
import IdPropType from '../../../propTypes/IdPropType'
import RestrictedContent from '../../auth/RestrictedContent'
import { ADMIN_ROLE, MANAGER_ROLE } from '../../../constants/roleGroups'

const UsersListUsernameLink = ({ row }) => (
  <>
    <RestrictedContent accessRole={ADMIN_ROLE}>
      <LinkRouter to={`/users/${row.id}`}>{row.username}</LinkRouter>
    </RestrictedContent>
    <RestrictedContent accessRole={MANAGER_ROLE}>
      <span>{row.username}</span>
    </RestrictedContent>
  </>
)

UsersListUsernameLink.propTypes = {
  row: PropTypes.shape({
    id: IdPropType.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
}

export default UsersListUsernameLink
