import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts'

import {
  DEEPER_BLUE,
  DEEPER_GREEN,
  DEEPER_MARITIME,
} from '../../../../../constants/colors'
import CLIENTS from '../../../../../constants/clients'
import ChartSettings from '../../chart/ChartSettings'
import TIME_SCALE from '../../../../../constants/timeScale'
import TIME_PERIOD from '../../../../../constants/timePeriod'
import TimePeriodPropTypes from '../../../../../propTypes/TimePeriodPropTypes'
import TimeScalePropTypes from '../../../../../propTypes/TimeScalePropTypes'
import { StatisticHeader, StatisticContainer } from '../../statistic'
import ClientsTooltip from './ClientsTooltip'
import ClientsLegend from './ClientsLegend'
import { yAxisConfig } from '../../chart/config'
import ClientsSVGSrc from '../../../../../assets/images/clients.svg'

const ClientsStatisticView = ({
  data,
  oldClientsCount,
  newClientsCount,
  timeScale,
  timePeriod,
  from,
  to,
  onTimeScaleChange: handleTimeScaleChange,
  onTimePeriodChange: handleTimePeriodChange,
}) => {
  const { t } = useTranslation()
  const [isOldClientsChecked, setIsOldClientsChecked] = useState(true)
  const [isNewClientsChecked, setIsNewClientsChecked] = useState(true)
  const [isAllClientsChecked, setIsAllClientsChecked] = useState(false)

  const lines = useMemo(() => {
    const toDisplay = []
    const activeDot = { r: 6 }
    const strokeWidth = 2

    if (isOldClientsChecked) {
      const oldClientsConfig = {
        key: '2',
        dataKey: CLIENTS.OLD.value,
        stroke: DEEPER_BLUE,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(oldClientsConfig)
    }

    if (isNewClientsChecked) {
      const newClientsConfig = {
        key: '1',
        dataKey: CLIENTS.NEW.value,
        stroke: DEEPER_GREEN,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(newClientsConfig)
    }

    if (isAllClientsChecked) {
      const allClientsConfig = {
        key: '3',
        dataKey: CLIENTS.ALL.value,
        stroke: DEEPER_MARITIME,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(allClientsConfig)
    }

    return toDisplay
  }, [isOldClientsChecked, isNewClientsChecked, isAllClientsChecked])

  return (
    <StatisticContainer>
      <StatisticHeader
        iconSrc={ClientsSVGSrc}
        timePeriod={timePeriod}
        from={from}
        to={to}
        summaryConfig={[{
          key: '1',
          value: oldClientsCount,
          textHelper: t('statistics.regularClientsGenitive'),
        }, {
          key: '2',
          value: newClientsCount,
          textHelper: t('statistics.newClientsGenitive'),
        }, {
          key: '3',
          value: oldClientsCount + newClientsCount,
          textHelper: t('statistics.summaryClientsGenitive'),
        }]}
      >
        {t('statistics.clients')}
      </StatisticHeader>
      <ChartSettings
        activeTimeScale={timeScale.value}
        activeTimePeriod={timePeriod.value}
        onTimeScaleChange={handleTimeScaleChange}
        onTimePeriodChange={handleTimePeriodChange}
        from={from}
        to={to}
      />
      <ResponsiveContainer width="100%" height={307}>
        <LineChart data={data}>
          <YAxis {...yAxisConfig} allowDecimals={false} />
          <XAxis dataKey="timeScale" />
          <Tooltip
            header={t('statistics.clients')}
            content={<ClientsTooltip timeScaleName={t(timeScale.code)} />}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {lines.map(({
            key,
            dataKey,
            stroke,
            strokeWidth,
            dot,
            activeDot,
          }) => (
            <Line
              key={key}
              dataKey={dataKey}
              stroke={stroke}
              strokeWidth={strokeWidth}
              activeDot={activeDot}
              dot={dot}
            />
          ))}
          <Legend
            layout="horizontal"
            align="center"
            content={
              <ClientsLegend
                isOldClientsChecked={isOldClientsChecked}
                isNewClientsChecked={isNewClientsChecked}
                isAllClientsChecked={isAllClientsChecked}
                onOldClientsClick={() => setIsOldClientsChecked(checked => !checked)}
                onNewClientsClick={() => setIsNewClientsChecked(checked => !checked)}
                onAllClientsClick={() => setIsAllClientsChecked(checked => !checked)}
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </StatisticContainer>
  )
}

ClientsStatisticView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timeScale: PropTypes.string,
      [CLIENTS.OLD.value]: PropTypes.number,
      [CLIENTS.NEW.value]: PropTypes.number,
      [CLIENTS.ALL.value]: PropTypes.number,
      date: PropTypes.string,
      time: PropTypes.string,
    }),
  ),
  timeScale: TimeScalePropTypes,
  timePeriod: TimePeriodPropTypes,
  onTimeScaleChange: PropTypes.func,
  onTimePeriodChange: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  oldClientsCount: PropTypes.number,
  newClientsCount: PropTypes.number,
}

ClientsStatisticView.defaultProps = {
  data: [],
  timeScale: TIME_SCALE.HOUR,
  timePeriod: TIME_PERIOD.LAST_DAY,
  onTimeScaleChange: () => {},
  onTimePeriodChange: () => {},
  from: null,
  to: null,
  oldClientsCount: 0,
  newClientsCount: 0,
}

export default ClientsStatisticView
