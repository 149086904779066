import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import OpeningHoursForm from '../../components/forms/OpeningHoursForm'
import useHoursForm from '../../hooks/useHoursForm'
import FormStyleWrapper from '../../components/wrappers/FormStyleWrapper'
import FormFooter from '../../components/forms/FormFooter'
import useSnackbar from '../../hooks/useSnackbar'
import StyledFormikForm from '../../components/forms/StyledFormikForm'
import RouteHeader from '../../components/layout/RouteHeader'

const ClubHoursEditScreen = () => {
  const { clubId } = useContext(ClubContext)
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const history = useHistory()

  const { initialValues, handleSubmit } = useHoursForm({
    clubId,
    onSuccess: () => {
      // TODO go to details screen
      history.replace('/open-hours')
      addSnackbar(t('schedule.success'))
    },
    onError: (err) => {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    },
  })

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <RouteContainer justifyContent="flex-start">
          <RouteHeader>{t('schedule.edit')}</RouteHeader>
          <StyledFormikForm>
            <FormStyleWrapper>
              {initialValues && (
                <OpeningHoursForm
                  clubId={clubId}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
            </FormStyleWrapper>
            <FormFooter
              onCancel={() => history.replace('/open-hours')}
              disabled={isSubmitting}
              confirmMessageKey={t('common.edit')}
            />
          </StyledFormikForm>
        </RouteContainer>
      )}
    </Formik>
  )
}

export default ClubHoursEditScreen
