import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Typography } from '@material-ui/core'

const HeaderTitle = styled(Typography)`
  font-size: 15px;
  margin-top: 10px;
  font-weight: 500;
`

const SubsectionHeader = ({ title }) => (
  <HeaderTitle>{title}</HeaderTitle>
)

SubsectionHeader.propTypes = {
  title: PropTypes.string,
}

SubsectionHeader.defaultProps = {
  title: '',
}

export default SubsectionHeader
