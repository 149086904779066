import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Field } from 'formik'
import { Tooltip, Switch } from '@material-ui/core'

import FormikForm from '../../StyledFormikForm'
import StyledTextField from '../StyledTextField'
import AlertDialog from '../../../AlertDialog'
import PropEntity from '../../PropEntity'
import FormStyleWrapper from '../../../wrappers/FormStyleWrapper'
import FormFooter from '../../FormFooter'

const StyledFormikForm = styled(FormikForm)`
  padding-bottom: 25px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`

const ClubForm = ({
  values,
  setFieldValue,
  isSubmitting,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [isStatusInfoVisible, setIsStatusInfoVisible] = useState(false)
  const [alertCallback, setAlertCallback] = useState(() => () => {})

  const handleClubStatusChange = ({ target: { checked } }) => {
    setIsAlertOpen(true)
    setAlertCallback(() => () => setFieldValue('isActive', checked))
  }

  const handleAlertConfirm = async () => {
    alertCallback()
    setIsStatusInfoVisible(status => !status)
    setIsAlertOpen(false)
  }

  const statusValue = t(`common.${values.isActive ? 'active' : 'inactive'}`)
  const statusTooltipText = t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}`)
  const statusButtonColor = values.isActive ? 'secondary' : 'primary'

  return (
    <StyledFormikForm>
      <FormStyleWrapper>
        <Field
          formatError={t}
          label={t('clubs.form.name')}
          name="name"
          component={StyledTextField}
        />
        <Row>
          <PropEntity
            label={t('common.status')}
            value={statusValue}
            alert={isStatusInfoVisible ? t('local.alert.statusInfo') : ''}
          />
          <Tooltip
            title={statusTooltipText}
            aria-label={statusTooltipText}
          >
            <Switch
              onChange={handleClubStatusChange}
              checked={values.isActive}
              color="primary"
            />
          </Tooltip>
          <AlertDialog
            onConfirm={handleAlertConfirm}
            onCancel={() => setIsAlertOpen(false)}
            message={t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}AlertMessage`)}
            title={t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}AlertTitle`)}
            confirmText={statusTooltipText}
            confirmColor={statusButtonColor}
            isOpen={isAlertOpen}
          />
        </Row>
      </FormStyleWrapper>
      <FormFooter
        onCancel={() => history.replace('/data')}
        disabled={isSubmitting}
        confirmMessageKey={t('common.edit')}
      />
    </StyledFormikForm>
  )
}

ClubForm.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default ClubForm
