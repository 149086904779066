import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CropOriginal } from '@material-ui/icons'
import { useDropzone } from 'react-dropzone'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import {
  BLUE,
  DEEPER_BLUE,
  WHITE,
  BLACK,
} from '../../../constants/colors'
import useClockInterval from '../../../hooks/useClockInterval'
import useSnackbar from '../../../hooks/useSnackbar'
import {
  FormButton,
} from '../../inputs'

const FileArea = styled.div(({ theme }) => `
  color: ${theme.palette.action.disabled};
  cursor: pointer;
  outline: none;

  &:hover {
    border-color: ${theme.palette.text.primary};
    color: ${theme.palette.text.primary};
  }
`)

const DropInfo = styled.div`
  position: relative;

  ${({ isDragActive, info, dashOffSet }) => (isDragActive
    ? `&:before {
      content: '${info}';
      position: absolute;
      top: 0px;
      left: 0;
      width: 97%;
      height: 97%;
      margin: 1.5%;
      padding: 10px;
      border-radius: 12px;
      opacity: 0.7;
      background-image: url("
        data:image/svg+xml,%3csvg
        width='100%25'
        height='100%25'
        xmlns='http://www.w3.org/2000/svg'%3e%3crect
        width='100%25'
        height='100%25'
        fill='none'
        rx='12'
        ry='12'
        stroke='rgb(0, 98, 255)'
        stroke-width='6'
        stroke-dasharray='20'
        stroke-dashoffset='${dashOffSet}'
        stroke-linecap='round'/%3e%3c/svg%3e
      ");
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      text-shadow: 0px 0px 1px ${BLACK};
      color: ${BLUE};
    }`
    : '')}
`

const ImagePlaceholder = styled.div(({ theme }) => `
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin: 7px 0 5px 0;
  border: 1px solid ${theme.palette.action.disabled};
  border-radius: 4px;
`)

const ImagePlaceholderHelper = styled.p`
  padding: 0 30px;
  text-align: center;
  margin: 0;
`

const StyledCropOriginalIcon = styled(CropOriginal)`
  width: 100px;
  height: 100px;
`

const Image = styled.img`
  display: flex;
  width: 100%;
  height: 300px;
  margin: 7px 0 5px 0;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
`

const ImageFormButton = styled(FormButton)`
  width: 100%;        
  background-color: ${BLUE};
  color: ${WHITE};

  &:hover {
    background-color: ${DEEPER_BLUE};
  }
`

const ImageFormButtonCancel = styled(Button)(({ theme }) => `
  width: 100%;
  margin-top: ${theme.spacing(1)}px;
`)

const UploadImage = ({
  image,
  onImageChange,
  onInitialImageChange,
  initialValues,
  className,
  onImageLoadSuccess,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file.type === 'image/jpg' || file.type === 'image/jpeg') {
      onImageChange(state => ({ ...state, files: acceptedFiles }))
      const reader = new FileReader()
      reader.onabort = () => addSnackbar(t('image.errors.loadingAbort'))
      reader.onerror = () => addSnackbar(t('image.errors.loadingFail'))
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: 'image/jpeg' })
        const urlCreator = window.URL || window.webkitURL
        const source = urlCreator.createObjectURL(blob)
        onImageChange(state => ({
          ...state,
          blob,
          source,
        }))
        onImageLoadSuccess()
      }
      reader.readAsArrayBuffer(acceptedFiles[0])
    } else {
      addSnackbar(t('image.errors.wrongExtension'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const [dashOffSet] = useClockInterval({
    from: 0,
    to: 39,
    interval: 30,
    condition: isDragActive,
  })

  useEffect(() => {
    const { imageUrl } = initialValues
    if (imageUrl) {
      onImageChange(state => ({
        ...state,
        source: imageUrl,
      }))
    } else {
      onImageChange(state => ({
        ...state,
        source: '',
      }))
    }
    onInitialImageChange()
  }, [initialValues]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDeletePhoto = () => {
    onImageChange(state => ({
      ...state,
      files: [],
      blob: null,
      source: '',
    }))
  }

  return (
    <div className={className}>
      <FileArea {...getRootProps()}>
        <input {...getInputProps()} />
        <DropInfo
          isDragActive={isDragActive}
          dashOffSet={dashOffSet}
          info={t('image.dropNow')}
        >
          {image.source
            ? <Image
                src={image.source}
                Palt={t('image.title')}
            />
            : (
              <ImagePlaceholder isDragActive={isDragActive}>
                <StyledCropOriginalIcon />
                <ImagePlaceholderHelper>{t('image.draggableAreaHelper')}</ImagePlaceholderHelper>
              </ImagePlaceholder>
            )}
        </DropInfo>
        <ImageFormButton>{t(`image.${image.source ? 'change' : 'select'}`)}</ImageFormButton>
      </FileArea>
      {image.source && (
        <ImageFormButtonCancel variant="contained" onClick={onDeletePhoto}>
          {t('image.delete')}
        </ImageFormButtonCancel>
      )}
    </div>
  )
}

UploadImage.propTypes = {
  onImageChange: PropTypes.func,
  onInitialImageChange: PropTypes.func,
  onImageLoadSuccess: PropTypes.func,
  className: PropTypes.string,
  image: PropTypes.exact({
    files: PropTypes.array,
    source: PropTypes.string,
    blob: PropTypes.string,
  }),
  initialValues: PropTypes.exact({
    imageUrl: PropTypes.string,
    availabilityHoursBegin: PropTypes.string,
  }),
}

UploadImage.defaultProps = {
  onImageChange: () => { },
  onInitialImageChange: () => { },
  onImageLoadSuccess: () => { },
  className: '',
  image: PropTypes.exact({
    files: [],
    source: '',
    blob: '',
  }),
  initialValues: {
    imageUrl: '',
    availabilityHoursBegin: null,
  },
}

export default UploadImage
