import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const orderPreparationPlacesValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('orderPreparation.errors.name.required'),
  description: yup
    .string()
    .max(1000, messageObject('orderPreparation.errors.description.max')),
})

export default orderPreparationPlacesValidationSchema
