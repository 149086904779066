import { IconButton, Tooltip } from '@material-ui/core'
import {
  Edit as EditIcon,
  LibraryBooks as LibraryBooksIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const UsersListActions = ({
  row,
  onMoreInfoDialogOpen: handleMoreInfoDialogOpen,
  onDeleteUserDialogOpen: handleDeleteUserDialogOpen,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Tooltip
        title={t('tableTooltips.moreInfo')}
        aria-label={t('tableTooltips.moreInfo')}
      >
        <IconButton onClick={() => handleMoreInfoDialogOpen(row.id)}>
          <LibraryBooksIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.edit')}
        aria-label={t('tableTooltips.edit')}
      >
        <IconButton component={Link} to={`users/${row.id}/edit`}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.delete')}
        aria-label={t('tableTooltips.delete')}
      >
        <IconButton onClick={() => handleDeleteUserDialogOpen(row.id)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

UsersListActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onMoreInfoDialogOpen: PropTypes.func,
  onDeleteUserDialogOpen: PropTypes.func,
}

UsersListActions.defaultProps = {
  onMoreInfoDialogOpen: () => {},
  onDeleteUserDialogOpen: () => {},
}

export default UsersListActions
