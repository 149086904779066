import { useMemo, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import styled from 'styled-components/macro'

import { OrderDeliveryPlaceContext } from '../../../providers/OrderDeliveryPlaceContextProvider'
import { deleteOrderDeliveryPlace } from '../../../services/api/orderDeliveryPlaces'
import IdPropType from '../../../propTypes/IdPropType'
import useSnackbar from '../../../hooks/useSnackbar'
import DataTable from '../../data/DataTable'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog'

import columns from './OrderDeliveryPlacesList.columns'

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const OrderDeliveryPlacesList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
  clubId,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const {
    orderDeliveryPlaceId,
    isDeleteOrderDeliveryPlaceDialogOpen,
    setIsDeleteOrderDeliveryPlaceDialogOpen,
  } = useContext(OrderDeliveryPlaceContext)
  const history = useHistory()

  const clubDeliveryPlaces = useMemo(
    () => data.map(deliveryPlace => ({ ...deliveryPlace, clubId })),
    [data, clubId],
  )

  const clubDeliveryPlaceData = useMemo(
    () => clubDeliveryPlaces.find(collectPlace => collectPlace.id === orderDeliveryPlaceId),
    [clubDeliveryPlaces, orderDeliveryPlaceId],
  )

  const handleDeleteOrderCollectPlace = async () => {
    try {
      await deleteOrderDeliveryPlace(orderDeliveryPlaceId)
      addSnackbar(t('orderDelivery.success.delete'))
      setIsDeleteOrderDeliveryPlaceDialogOpen(false)
      history.replace('/order-delivery-places')
    } catch (err) {
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    }
  }

  return (
    <>
      <TableOptions>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/clubs/${clubId}/order-delivery-places/create`}
          startIcon={<AddIcon />}
        >
          {t('common.create')}
        </Button>
      </TableOptions>
      <DataTable
        data={clubDeliveryPlaces}
        columns={columns}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
      {clubDeliveryPlaceData && (
        <DeleteConfirmationDialog
          isOpen={isDeleteOrderDeliveryPlaceDialogOpen}
          titleText={t('orderDelivery.dialog.deleteOrderDeliveryPlaceAlertTitle', { name: clubDeliveryPlaceData.name })}
          onCancel={() => setIsDeleteOrderDeliveryPlaceDialogOpen(false)}
          onConfirm={() => handleDeleteOrderCollectPlace()}
        />
      )}
    </>
  )
}

OrderDeliveryPlacesList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
}

OrderDeliveryPlacesList.propTypes = {
  clubId: IdPropType.isRequired,
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default OrderDeliveryPlacesList
