import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { FormButton } from '../inputs'

const FormFooterContainer = styled.div(({ theme }) => `
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(3)}px;
`)

const FormFooter = ({ onCancel, disabled, confirmMessageKey }) => {
  const { t } = useTranslation()

  return (
    <FormFooterContainer>
      {onCancel && (
        <FormButton onClick={onCancel}>
          {t('common.cancel')}
        </FormButton>
      )}
      <FormButton
        color="primary"
        type="submit"
        disabled={disabled}
      >
        {t(confirmMessageKey)}
      </FormButton>
    </FormFooterContainer>
  )
}

FormFooter.defaultProps = {
  onCancel: undefined,
  disabled: false,
  confirmMessageKey: '',
}

FormFooter.propTypes = {
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  confirmMessageKey: PropTypes.string,
}

export default FormFooter
export {
  FormFooterContainer,
}
