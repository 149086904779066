import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClubSalesStatistic, usePrevious } from '@itsilesia/udrink-common-components'

import IdPropType from '../../../../propTypes/IdPropType'
import IncomeStatisticView from './view/IncomeStatisticView'
import useStatisticChart from '../../../../hooks/useStatisticChart'
import { ORDERS, TIPS, ALL } from '../../../../constants/income'
import parseTimeScale from '../../../../utils/parseTimeScale'
import parseISODate from '../../../../utils/parseISODate'
import useSnackbar from '../../../../hooks/useSnackbar'
import { peanutsToPLN } from '../../../../utils/peanutsToPLN'

const IncomeStatistic = ({ clubId }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const handleError = ({ message }) => addSnackbar(t(message))

  const {
    from,
    to,
    timeScale,
    timePeriod,
    handleTimeScaleChange,
    handleTimePeriodChange,
  } = useStatisticChart({ handleError })

  const { data } = useClubSalesStatistic(clubId, {
    interval: timeScale.value,
    from,
    to,
  })

  const prevData = usePrevious(data || {})
  const { incomeByIntervals } = data || { incomeByIntervals: {} }
  const chartData = useMemo(
    () =>
      Object.entries(incomeByIntervals || prevData).map(
        ([dateTime, { ordersIncome, tipsIncome }]) => {
          const [date] = parseISODate(dateTime).split('T')

          return {
            timeScale: parseTimeScale(timeScale.value, dateTime),
            ordersIncome: peanutsToPLN(ordersIncome),
            tipsIncome: peanutsToPLN(tipsIncome),
            sumIncome: peanutsToPLN((ordersIncome + tipsIncome)),
            [ORDERS]: peanutsToPLN(ordersIncome),
            [TIPS]: peanutsToPLN(tipsIncome),
            [ALL]: peanutsToPLN((ordersIncome + tipsIncome)),
            date,
          }
        },
      ),
    [incomeByIntervals, prevData, timeScale],
  )

  return (
    <IncomeStatisticView
      data={chartData}
      tipsIncome={data?.totalTipsIncome}
      orderIncome={data?.totalOrdersIncome}
      incomeSum={(data?.totalTipsIncome || 0) + (data?.totalOrdersIncome || 0)}
      timeScale={timeScale}
      timePeriod={timePeriod}
      from={from}
      to={to}
      onTimeScaleChange={handleTimeScaleChange}
      onTimePeriodChange={handleTimePeriodChange}
    />
  )
}

IncomeStatistic.propTypes = {
  clubId: IdPropType.isRequired,
}
export default IncomeStatistic
