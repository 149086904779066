import * as yup from 'yup'

export const modifierOptionSchema = yup.object().shape({
  name: yup.string().trim().required('modifiers.errors.name.required'),
  price: yup
    .string()
    .required('product.errors.price.required')
    .test(
      'isNumberTest',
      'product.errors.price.isNumber',
      value => !Number.isNaN(parseFloat(value)),
    )
    .test(
      'isPositiveNumberTest',
      'product.errors.price.isPositiveNumber',
      value => parseFloat(value) >= 0,
    ),
})
