import { useParams } from 'react-router-dom'
import { useClubOrderDeliveryPlaces } from '@itsilesia/udrink-common-components'

import useDataList from '../hooks/useDataList'
import OrderDeliveryPlacesList from '../components/clubs/OrderDeliveryPlacesList'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'

const OrderDeliveryPlacesScreen = () => {
  const { clubId } = useParams()

  const useResource = ({ page, size }) =>
    useClubOrderDeliveryPlaces(clubId, {
      page,
      size,
      sort: 'name',
    })

  const { handleChangePage, handleChangeRowsPerPage, ...props } = useDataList(
    `${clubId}-order-delivery-places`,
    useResource,
  )

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <OrderDeliveryPlacesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default OrderDeliveryPlacesScreen
