import { combineReducers } from '@reduxjs/toolkit'
import { authModule } from '@itsilesia/udrink-common-components'

import currentClubModule from './currentClub'
import snackbarModule from './snackbar'

const rootReducer = combineReducers({
  auth: authModule.reducer,
  snackbar: snackbarModule.reducer,
  currentClub: currentClubModule.reducer,
})

export default rootReducer
