import { ACTIVE, INACTIVE } from '../constants/discountStatuses'
import { CURRENCY, PERCENT } from '../constants/discountTypes'

export default {
  words: {
    character_0: 'znak',
    character_1: 'znaki',
    character_2: 'znaków',
  },
  translation: {
    drawer: {
      navigation: {
        mainSite: 'Strona główna',
        clubs: 'Lokale',
        title: 'Nawigacja',
        users: 'Użytkownicy',
        workers: 'Pracownicy',
        taxRates: 'Stawki podatkowe',
        orderCollect: 'Stanowiska wydawania',
        orderPreparation: 'Stanowiska przygotowania',
        orderDelivery: 'Miejsca dostarczenia',
        products: 'Produkty',
        menu: 'Menu',
        modifiers: 'Dodatki',
        inventory: 'Inwentarz',
        data: 'Dane',
        statistics: 'Statystyki',
        discountCodes: 'Kody rabatowe',
        hours: 'Godziny',
        delete: 'Usuń lokal',
      },
      actions: {
        title: 'Akcje',
        change_location: 'Zmień lokalizację',
        logout: 'Wyloguj',
      },
    },
    errorCode: {
      menu: {
        notInUse: 'Menu nie powinno być w użyciu.',
      },
      discountCode: {
        notUnique: 'Kod nie jest unikalny',
      },
    },
    common: {
      actions: 'Akcje',
      email: 'Email',
      password: 'Hasło',
      signIn: 'Zaloguj',
      signOut: 'Wyloguj',
      connect: 'Połącz',
      ok: 'Rozumiem',
      add: 'Dodaj',
      cancel: 'Anuluj',
      edit: 'Edytuj',
      goToEdit: 'Przejdź do edycji',
      next: 'Dalej',
      loading: 'Ładowanie',
      save: 'Zapisz',
      create: 'Utwórz',
      update: 'Aktualizuj',
      back: 'Wróć',
      close: 'Zamknij',
      select: 'Wybierz',
      description: 'Opis',
      price: 'Cena',
      subcategory: 'Podkategoria',
      expand: 'Rozwiń',
      fold: 'Zwiń',
      and: 'i',
      inTotal: 'Łącznie',
      help: 'Pomoc',
      homeSite: 'Strona główna',
      delete: 'Usuń',
      errors: {
        generic: 'Wystąpił błąd. Spróbuj ponownie później.',
        email: {
          required: 'Email jest wymagany',
          invalid: 'Wprowadź poprawny adres email',
        },
        password: {
          required: 'Hasło jest wymagane',
        },
      },
      titles: {
        adminPanel: 'Panel administracyjny',
        passwordRecovery: 'Odzyskiwanie hasła',
      },
      languages: {
        pl: 'Polski',
        en: 'Angielski',
      },
      countries: {
        pl: 'Polska',
      },
      or: 'lub',
      clubs: 'Lokale',
      missing: 'Brak',
      name: 'Nazwa',
      firstName: 'Imię',
      surname: 'Nazwisko',
      roleGroup: 'Rola',
      status: 'Status',
      active: 'Aktywny',
      inactive: 'Nieaktywny',
      optional: '(opcjonalne)',
    },
    signIn: {
      errors: {
        wrongCredentials: 'Podana nazwa użytkownika lub hasło są nieprawidłowe',
        wrongRole: 'Nie posiadasz uprawnień do korzystania z panelu administracyjnego.',
      },
    },
    clubs: {
      form: {
        owner: 'Właściciel',
        name: 'Nazwa lokalu',
        nip: 'NIP',
        coordinates: 'Współrzędne (format: szerokość, długość, np: 52.19174, 21.046)',
        street: 'Ulica',
        localization: 'Lokalizacja',
        general: 'Ogólne',
        zipCode: 'Kod pocztowy',
        city: 'Miasto',
        country: 'Kraj',
        timeZone: 'Strefa czasowa',
        beginHour: 'Godzina otwarcia',
        closeHour: 'Godzina zamknięcia',
        activate: 'Aktywuj',
        deactivate: 'Dezaktywuj',
        paymentKeys: 'Klucze płatności',
        paymentAlert: 'Bez wypełnienia tej sekcji, klubu nie można aktywować',
        lockEdit: 'Zablokuj edycję',
        unlockEdit: 'Odblokuj edycję',
        closed: 'Zamknięty',
        fiservAccessData: {
          sharedSecret: 'Shared secret',
          storeId: 'Store Id',
          header: 'Fiserv klucz dostępu.',
          login: 'FDP Login',
          merchantId: 'Merchant Id',
          password: 'FDP Password',
          posId: 'Pos Id',
          sharedKey: 'Shared Key',
          tokenRequestor: 'Token Requestor',
          alert: 'Dane dostępu nie zostaną zaktualizowane, jeżeli klub jest aktywny.',
          secretKey: 'Secret Key',
          apiKey: 'Api Key',
        },
        workingMode: {
          title: 'Tryb pracy testowy',
          on: 'Włączony',
          off: 'Wyłączony',
          setOn: 'Ustaw tryb włączony',
          setOff: 'Ustaw tryb wyłączony',
          onAlertMessage:
            'Zmiana na tryb testowy włączony umożliwi testowanie systemu płatności. Wszystkie transakcje wykonane za pomocą aplikacji będą miały charakter testowy.',
          onAlertTitle: 'Czy jesteś pewien zmiany na tryb testowy włączony?',
          offAlertTitle: 'Czy jesteś pewien zmiany na tryb testowy wyłączony?',
          offAlertMessage:
            'Zmiana na tryb testowy wyłączony spowoduje, że wszystkie płatności dokonywane za pośrednictwem aplikacji będą wiązały się z realnym obciążeniem konta.',
          alertInfo: 'Tryb pracy zostanie zamieniony po zatwierdzeniu formularza.',
        },
        printingMode: {
          title: 'Integracja z drukarką',
          active: 'Aktywna',
          setActive: 'Aktywuj integrację z drukarką',
          activeAlertTitle: 'Czy jesteś pewien, że chcesz dokonać integracji z drukarką?',
          activeAlertMessage:
            'Integracja z drukarką spowoduje udostępnienie barmanom możliwości drukowania paragonów za pośrednictwem systemu.',
          inactive: 'Nieaktywna',
          setInactive: 'Wyłącz integrację z drukarką',
          inactiveAlertTitle: 'Czy jesteś pewien, że chcesz rozłączyć integrację z drukarką?',
          inactiveAlertMessage:
            'Wyłączenie integracji z drukarką spowoduje, że barmani stracą możliwość drukowania paragonów za pośrednictwem systemu.',
          alertInfo: 'Tryb integracji z drukarką zostanie zmieniony po zatwierdzeniu formularza.',
        },
        statusTextHelper: 'Zmiana statusu lokalu jest możliwa tylko w trakcie jego edycji.',
        activateAlertTitle: 'Czy jesteś pewien, że chcesz aktywować lokal?',
        activateAlertMessage:
          'Aktywacja lokalu wiąże się z jego upublicznieniem. Od teraz wszyscy użytkownicy będą mogli go zobaczyć i zamawiać dodane produkty. Upewnij się, że uzupełniłeś menu o wszystkie swoje produkty.',
        deactivateAlertTitle: 'Czy jesteś pewien, że chcesz dezaktywować lokal?',
        deactivateAlertMessage:
          'Dezaktywacja lokalu spowoduje jego zniknięcie z listy lokalów i uniemożliwi użytkownikom dokonywania zakupów.',
        setOpeningHours: 'Ustaw godziny otwarcia',
        closeOnThisDay: 'Zamknij w tym dniu',
        isClosedThisDay: 'Lokal jest zamknięty w tym dniu',
        daysOfWeek: {
          mon: 'Pn',
          tue: 'Wt',
          wed: 'Śr',
          thu: 'Czw',
          fri: 'Pt',
          sat: 'Sb',
          sun: 'Nd',
        },
      },
      success: 'Lokal został zapisany.',
      errors: {
        required: {
          name: 'Nazwa lokalu jest wymagana',
          address: 'Adres jest wymagany',
          zipCode: 'Kod pocztowy jest wymagany',
          city: 'Miasto jest wymagane',
          country: 'Kraj jest wymagany',
          nip: 'NIP jest wymagany',
          polcardAccessData: {
            login: 'Login FDP jest wymagany',
            merchantId: 'Merchant Id jest wymagany',
            password: 'FDP Password jest wymagany',
            posId: 'Pos Id jest wymagany',
            sharedKey: 'Shared Key jest wymagany',
            tokenRequestor: 'Token Requestor jest wymagany',
            sharedSecret: 'Shared secret jest wymagany',
            storeId: 'Store Id jest wymagany',
          },
          fiservAccessData: {
            sharedSecret: 'Shared secret jest wymagany',
            storeId: 'Store Id jest wymagany',
            apiKey: 'Api Key jest wymagany',
            secretKey: 'Secret Key jest wymagany',
          },
        },
        noMatch: {
          zipCode: 'Nieprawidłowy format kodu pocztowego',
          coordinates: 'Nieprawidłowy format współrzędnych geograficznych',
          nip: 'Nieprawidłowy numer NIP',
          nipNumbers: 'Numer NIP powinien zawierać tylko cyfry',
        },
      },
      deleteMessage: 'Czy na pewno chcesz usunąć ten lokal',
      deleteTitle: 'Usuwanie lokalu',
    },
    user: {
      screen: {
        list: {
          username: 'Nazwa użytkownika',
          clubs: 'Lokale',
        },
        create: {
          success: 'Użytkownik został utworzony.',
        },
        edit: {
          success: 'Dane użytkownia zostały zaktualizowane.',
        },
        delete: {
          deleteUserAlertTitle: 'Użytkownik {{username}} zostanie usunięty.',
          deleteAlertMessage: 'Czy kontynuować?',
          success: 'Pomyślnie usunięto użytkownika',
        },
      },
      role: {
        admin: 'Admin',
        manager: 'Manager',
        bartender: 'Barman',
      },
      form: {
        email: 'Email',
        firstName: 'Imię',
        surname: 'Nazwisko',
        password: 'Hasło',
        confirmPassword: 'Powtórz hasło',
        role: 'Rola',
        clubs: 'Lokale',
      },
      errors: {
        email: {
          valid: 'Podaj prawidłowy adres email',
          required: 'Email jest wymagany',
        },
        password: {
          min: "Hasło musi mieć minimum {{ min }} $t(words:character, { 'count': {{ min }} })",
          required: 'Hasło jest wymagane',
        },
        confirmPassword: {
          notMatch: 'Te hasła nie pasują do siebie',
          required: 'Potwierdź hasło',
        },
        clubs: {
          required: 'Użytkownik musi mieć przypisany co najmniej 1 lokal',
        },
      },
    },
    newPassword: {
      title: 'Odzyskiwanie hasła',
      textHelper: 'Wprowadź i powtórz nowe hasło w formularzu poniżej.',
      changePassword: 'Zmień hasło',
      password: {
        label: 'Nowe hasło',
      },
      confirmPassword: {
        label: 'Powtórz hasło',
      },
      success: 'Hasło zostało zaktualizowane.',
    },
    signUp: {
      errors: {
        password: {
          min: "Hasło musi mieć minimum {{ min }} $t(words:character, { 'count': {{ min }} })",
          hasUppercase: 'Hasło musi zawierać wielką literę.',
          hasLowercase: 'Hasło musi zawierać małą literę.',
        },
        confirmPassword: {
          notMatch: 'Te hasła nie pasują do siebie',
          required: 'Potwierdź hasło',
        },
      },
    },
    taxRates: {
      title: 'Stawka podatkowa',
      rate: 'Stawka',
      type: 'Typ',
      billingTypes: {
        taxed: 'Opodatkowany',
        taxFree: 'Wolny od podatku',
        reserve: 'Rezerwa',
      },
      success: 'Stawki podatkowe zostały zaktualizowane.',
      errors: {
        required: 'Stawka jest wymagana',
        noMatch: 'Nieprawidłowy format stawki',
        min: 'Minimalna stawka to 0%',
        max: 'Maksymalna stawka to 100%',
      },
    },
    orderCollect: {
      title: 'Stanowiska wydawania',
      createTitle: 'Tworzenie stanowiska wydawania',
      editTitle: 'Edycja stanowiska wydawania',
      name: 'Nazwa stanowiska',
      description: 'Opis stanowiska',
      alert: {
        message:
          'Usunięcie powiązania pomiędzy urządzeniem a stanowiskiem wydawania będzie skutkowało koniecznością ponownego parowania.',
      },
      success: {
        update: 'Stanowisko wydawania zostało zaktualizowane',
        create: 'Stanowisko wydawania zostało dodane',
        delete: 'Pomyślnie usunięto stanowisko wydawania',
      },
      errors: {
        name: {
          required: 'Nazwa stanowiska jest wymagana',
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
      },
      dialog: {
        deleteOrderCollectPlaceAlertTitle: 'Stanowisko wydawania {{name}} zostanie usunięte.',
      },
    },
    orderPlace: {
      device: 'Urządzenie',
      connected: 'Połączone',
      notConnected: 'Niesparowane',
      deviceIsConnected: 'To stanowisko jest powiązane z urządzeniem',
      deviceIsNotConnected: 'Brak powiązania z urządzeniem',
      connectDevice: 'Przywróć powiązanie',
      disconnectDevice: 'Usuń powiązanie',
      alert: {
        title: 'Usuwanie powiązania',
        removeConnection: 'Usuń powiązanie',
        info: 'Powiązanie zostanie usunięte po zatwierdzeniu formularza.',
      },
    },
    orderPreparation: {
      title: 'Stanowisko przygotowania',
      createTitle: 'Tworzenie stanowiska przygotowania',
      editTitle: 'Edycja stanowiska przygotowania',
      name: 'Nazwa stanowiska',
      zone: 'Strefa',
      oneOrderCollect: 'Stanowisko wydawania',
      alert: {
        message:
          'Usunięcie powiązania pomiędzy urządzeniem a miejscem przygotowania będzie skutkowało koniecznością ponownego parowania.',
      },
      zones: {
        bar: 'Bar',
        kitchen: 'Kuchnia',
      },
      success: {
        update: 'Stanowisko przygotowania zostało zaktualizowane',
        create: 'Stanowisko przygotowania zostało dodane',
        delete: 'Pomyślnie usunięto stanowisko przygotowania',
      },
      errors: {
        name: {
          required: 'Nazwa stanowiska jest wymagana',
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        orderCollectPlace: {
          required: 'Stanowisko wydawania jest wymagane.',
        },
      },
      dialog: {
        deleteOrderPreparationPlaceAlertTitle: 'Stanowisko przygotowania {{name}} zostanie usunięte.',
      },
    },
    orderDelivery: {
      title: 'Miejsce dostarczania',
      name: 'Nazwa miejsca dostarczania',
      createTitle: 'Tworzenie miejsca dostarczania',
      editTitle: 'Edycja miejsca dostarczania',
      success: {
        update: 'Miejsce dostarczania zostało zaktualizowane',
        create: 'Miejsce dostarczania zostało dodane',
        delete: 'Pomyślnie usunięto miejsce dostarczania',
      },
      errors: {
        name: {
          required: 'Nazwa jest wymagana.',
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
      },
      dialog: {
        deleteOrderDeliveryPlaceAlertTitle: 'Miejsce dostarczania {{name}} zostanie usunięte.',
      },
    },
    tableTooltips: {
      availableForSale: 'Produkt w sprzedaży',
      onlyPreview: 'Produkt tylko do podglądu',
      edit: 'Edytuj',
      delete: 'Usuń',
      modifiers: 'Dodatki',
      menu: 'Menu',
      moreInfo: 'Więcej informacji',
      qrCode: 'Wyświetl kod QR',
      preview: 'Podgląd',
    },
    product: {
      title: 'Nazwa produktu',
      edition: 'Edycja produktu',
      add: 'Dodaj produkt',
      new: 'Nowy produkt',
      price: 'Cena produktu',
      portion: 'Porcja',
      amount: 'Ilość',
      maxCount: 'Maksymalna ilość',
      availabilityLabel: 'Okres sprzedaży',
      wholeDay: 'Cały dzień',
      customPeriod: 'Własny zakres',
      setWholeDay: 'Ustaw cały dzień',
      setCustomPeriod: 'Ustaw własny zakres',
      description: 'Opis',
      availabilityHours: {
        beginTime: 'Rozpoczęcie sprzedaży',
        endTime: 'Zakończenie sprzedaży',
      },
      productDialog: {
        basicInfo: 'Podstawowe informacje',
        photo: 'Zdjęcie',
        modifiers: 'Dodatki',
        errors: 'Sekcja zawiera błędnie wypełnione pola',
      },
      errors: {
        name: {
          required: 'Nazwa produktu jest wymagana',
        },
        price: {
          isNumber: 'Cena musi być liczbą',
          isPositiveNumber: 'Cena musi być liczbą dodatnią',
          minimum: 'Cena nie może być mniejsza niż 0.01',
          required: 'Cena produktu jest wymagana',
        },
        workZone: {
          required: 'Stanowisko przygotowywania jest wymagane',
        },
        vatType: {
          required: 'Stawka podatkowa jest wymagana',
        },
        portion: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        value: {
          invalid: 'Wprowadzono nieprawidłowe dane.',
        },
        delete: {
          productNotExist: 'Produkt, który próbujesz usunąć, nie istnieje',
        },
      },
    },
    image: {
      select: 'Wybierz zdjęcie',
      change: 'Zmień zdjęcie',
      delete: 'Usuń zdjęcie',
      title: 'Zdjęcie produktu',
      draggableAreaHelper: 'Przeciągnij i upuść zdjęcie tutaj lub kliknij, aby dodać nowe',
      dropNow: 'Upuść teraz',
      errors: {
        loadingAbort: 'Ładowanie zdjęcia zostało przerwane',
        loadingFail: 'Ładowanie zdjęcia nie powiodło się',
        wrongExtension: 'Niepoprawne rozszerzenie pliku',
      },
      success: {
        load: 'Zdjęcie wczytane prawidłowo',
      },
    },
    menu: {
      manage: 'Zarządzaj menu',
      categories: {
        food: 'Jedzenie',
        alcohol: 'Alkohole',
        beverages: 'Napoje',
        cigarettes: 'Papierosy',
      },
      info: {
        categories: {
          cigarettes:
            'Kategoria "Papierosy" jest wyświetlana wyłącznie na telefonach z systemem Android. Wynika to z polityki sklepu AppStore, na urządzeniach z systemem iOS zabronione są treści promujące papierosy.',
        },
      },
      subcategories: {
        breakfast: 'Śniadania',
        coldSnacks: 'Zimne przekąski',
        salads: 'Sałatki',
        meat: 'Mięsne',
        fish: 'Ryby',
        seaFood: 'Owoce morza',
        soups: 'Zupy',
        burgers: 'Burgery',
        pizza: 'Pizza',
        sides: 'Dodatki',
        kidsMenu: 'Dla dzieci',
        desserts: 'Desery',
        tequila: 'Tequila',
        whisky: 'Whisky',
        cognakAndBrandy: 'Koniaki i brandy',
        rum: 'Rum',
        aperitif: 'Aperitif',
        beer: 'Piwa',
        cocktails: 'Koktajle',
        showPack: 'Zestawy specjalne',
        champagne: 'Szampany',
        sparklingWine: 'Wina musujące',
        bottles: 'Butelki',
        vodka: 'Wódka',
        liqueur: 'Likier',
        whiskey: 'Whiskey',
        customCocktails: 'Niestandardowe koktajle',
        drinks: 'Drinki',
        gin: 'Gin',
        nonAlcoholicBeer: 'Piwa bezalkoholowe',
        carbonatedDrinks: 'Napoje gazowane',
        nonCarbonatedDrinks: 'Napoje niegazowane',
        teaAndCoffee: 'Herbata i kawa',
        cigarettes: 'Papierosy',
        sushi: 'Sushi',
        redWine: 'Czerwone wino',
        whiteWine: 'Białe wino',
        roseWine: 'Wino różowe',
        unknown: 'Nieznana',
      },
      noActiveMenu: 'Lokal nie posiada aktywnego menu',
      cannotEditAlert: 'Menu można edytować jedynie poza godzinami pracy lokalu.',
      success: {
        update: 'Menu zostało pomyślnie zaktualizowane',
      },
      reorder: {
        up: 'Przenieś w górę',
        down: 'Przenieś w dół',
      },
    },
    modifiers: {
      title: 'Dodatki',
      helper: 'Wybierz dodatki które chcesz dodać do produktu',
      manage: 'Zarządzaj dodatkami',
      add: 'Dodaj nową kategorię',
      addOption: 'Dodaj opcję',
      addDialogTitle: 'Dodaj dodatek',
      editDialogTitle: 'Edytuj dodatek',
      name: 'Nazwa',
      min: 'Minimalna ilość wybranych opcji',
      max: 'Maksymalna ilość wybranych opcji',
      emptyMax: 'Nieskończona',
      empty: 'Brak dodatków.',
      emptyHelper: 'Brak dodatków do wybrania. Możesz utworzyć je w zakładce "Dodatki".',
      customMax: 'Własna ilość',
      setEmptyMax: 'Ustaw nieskończoną wartość',
      setCustomMax: 'Ustaw własną wartość',
      success: 'Dodatki zostały pomyślnie zaktualizowane.',
      noOptions: 'Każdy dodatek musi mieć jakieś opcje.',
      options: {
        name: 'Nazwa opcji',
        dialogTitle: 'Dodaj opcję',
        price: 'Cena',
        errors: {
          name: {
            required: 'Nazwa opcji jest wymagana',
          },
          price: {
            required: 'Cena opcji jest wymagana',
            isNumber: 'Cena musi być liczbą',
            isPositiveNumber: 'Cena musi być liczbą dodatnią',
          },
        },
      },
      errors: {
        name: {
          required: 'Nazwa dodatku jest wymagana',
        },
        min: {
          isNumber: 'Wartość minimalna musi być liczbą',
          isPositiveNumber: 'Wartość minimalna musi być większa lub równa 0',
          required: 'Wartość minimalna jest wymagana',
        },
        max: {
          isNumber: 'Wartośc maksymalna musi być liczbą',
          isPositiveNumber: 'Wartość maksymalna musi być większa lub równa 0',
          isMoreThanMin: 'Wartość maksymalna musi być większa niż minimalna',
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        delete: {
          notExist: 'Ten dodatek nie istnieje',
        },
      },
    },
    statistics: {
      income: 'Przychód',
      ordersIncomes: 'Przychody z zamówień',
      tipsIncomes: 'Przychody z napiwków',
      tips: 'Napiwki',
      allIncome: 'Przychody i napiwki',
      orders: 'Zamówienia',
      ordersGenitive: 'Zamówień',
      tipsGenitive: 'Napiwków',
      clients: 'Klienci',
      summaryClientsGenitive: 'Łącznie klientów',
      newClients: 'Nowi klienci',
      newClientsGenitive: 'Nowych klientów',
      regularClients: 'Stali klienci',
      regularClientsGenitive: 'Stałych klientów',
      allClients: 'Wszyscy klienci',
      timeScale: {
        hour: 'Godzina',
        day: 'Dzień',
        week: 'Tydzień',
      },
      timePeriod: {
        lastDay: 'Ostatni dzień',
        inLastDay: 'W ostatnim dniu',
        lastWeek: 'Ostatni tydzień',
        inLastWeek: 'W ostatnim tygodniu',
        lastTwentyEightDays: 'Ostatnie 28 dni',
        inLastTwentyEightDays: 'W ostatnich 28 dniach',
        lastFourMonth: 'Ostatnie cztery miesiące',
        inLastFourMonth: 'W ostatnich czterech miesiącach',
        lastSixMonth: 'Ostatnie pół roku',
        inLastSixMonth: 'W ciągu ostatniego pół roku',
        lastYear: 'Ostatni rok',
        inLastYear: 'W ostatnim roku',
        custom: 'Własny',
        inPeriod: 'W okresie',
        errors: {
          futureDate: 'Nie można wybrać przyszłej daty.',
          tooEarlyDate: 'Data nie może być wcześniejsza od daty rozpoczynającej okres.',
          tooLateDate: 'Data nie może być późniejsza od daty kończącej okres.',
        },
      },
      from: 'Od',
      to: 'Do',
      date: 'Data',
    },
    inventory: {
      amountMessage:
        'Ilość sztuk produktu dostępnych na magazynie. Stan z dzisiaj, ostatnie odświeżenie o godzinie',
      removeLimitTitle: 'Usuwanie limitu produktu.',
      removeLimitAlert:
        'Usunięcie limitu spowoduje, że użytkownicy będą mieli możliwość kupowania produktu w nieskończoność. Dzięki tej opcji nie ma potrzeby regularnego monitorowania stanu inwentarza.',
      removeLimit: 'Usuń limit',
      createLimitTitle: 'Tworzenie licznika produktu.',
      createLimitAlert:
        'Dzięki utworzeniu licznika produktu, aplikacja będzie monitorować zasobność wybranego produktu. Zrealizowane zamówienia będą uwzględniane, dodatkowo po wyczerpaniu zapasów aplikacja uniemożliwi zakup wyczerpanego zasobu. Wybranie tej opcji wprowadza konieczność aktualizowania stanu magazynu wraz z nowymi dostawami produktów.',
      createLimit: 'Stwórz licznik',
      noLimit: 'Brak limitu',
      errors: {
        negativeValue: 'Ilość produktu nie może być ujemna.',
      },
      manage: 'Zarządzaj inwentarzem',
      noItemsMessageFor: 'Brak produktów dla wyszukiwania',
      noFindMessage: 'Wybrany klub nie posiada produktów.',
    },
    homePage: {
      message: 'Witaj w panelu administracyjnym UDrink.',
    },
    schedule: {
      openHours: 'Godziny otwarcia',
      edit: 'Edytuj godziny otwarcia',
      success: 'Harmonogram został zapisany.',
    },
    local: {
      data: 'Dane lokalu',
      edit: 'Edytuj dane lokalu',
      success: 'Dane lokalu zostały zapisane',
      alert: {
        statusInfo: 'Status klubu zostanie zmieniony po zatwierdzeniu formularza.',
      },
    },
    workers: {
      create: 'Stwórz pracownika',
      edit: 'Edytuj dane pracownika',
    },
    feedback: {
      name: 'Imię',
      email: 'Email',
      whatHappened: 'Co się stało?',
      submit: 'Prześlij raport o awarii',
      footer: 'Raporty o awariach obsługiwane przez',
      sent: 'Twoja opinia została wysłana.',
      title: 'Wygląda na to, że mamy pewne problemy.',
      titleHelper:
        'Nasz zespół został powiadomiony o awarii. Jeśli chcesz pomóc, powiedz nam, co się stało.',
      errors: {
        required: {
          name: 'Imię jest wymagane',
          email: 'Email jest wymagany',
          comments: 'Komentarz do zdarzenia jest wymagany',
        },
        valid: {
          email: 'Email jest nieprawidłowy',
        },
      },
    },
    subcategory: {
      title: 'Nazwa subkategorii',
      polishTitle: 'Polska nazwa subkategorii',
      englishTitle: 'Angielska nazwa subkategorii',
      new: 'Utwórz subkategorię',
      edition: 'Edytuj subkategorię',
      errors: {
        required: {
          name: 'Nazwa jest wymagana',
          language: 'Język jest wymagany',
        },
        delete: {
          subcategoryNotExist: 'Subkategoria, którą próbujesz usunąć, nie istnieje',
        },
      },
    },
    discountCodes: {
      code: 'Kod',
      codeGeneration: 'Generacja kodu',
      autoGenerateCodeInfo: 'Jeśli nie podasz kodu rabatowego zostanie on automatycznie wygenerowany przez system.',
      manualCodeGeneration: 'Generacja własnego kodu rabatowego',
      limitations: 'Ograniczenia',
      minimumOrderPrice: 'Minimalna kwota zamówienia',
      add: 'Dodaj kod rabatowy',
      name: 'Nazwa kodu rabatowego',
      status: 'Status',
      discountType: 'Zniżka',
      pool: 'Pula kodów',
      endDate: 'Dostępny do',
      discountCode: 'Kod rabatowy',
      deletionAlertTitle: 'Kod rabatowy "{{name}}" zostanie usunięty.',
      deletionAlertMessage: 'Czy kontynuować?',
      [PERCENT]: 'Procentowa',
      [CURRENCY]: 'Kwotowa',
      [ACTIVE]: 'Aktywny',
      [INACTIVE]: 'Nieaktywny',
      discountValue: 'Kwota rabatu',
      discountPercent: 'Procent rabatu',
      totalUserAmount: 'Liczba użyć na osobę',
      discountLimitations: 'Ograniczenia zniżki',
      totalAmount: 'Liczba dostępnych kodów',
      totalDiscountPrice: 'Pula pieniężna',
      poolLimitations: 'Ograniczenia puli kodów',
      availabilityLimitations: 'Ograniczenia dostępności kodów',
      availableFrom: 'Dostępny od',
      availableTo: 'Dostępny do',
      createTitle: 'Tworzenie kodu rabatowego',
      editTitle: 'Edycja kodu rabatowego',
      totalUserAmountInfo: 'Jeżeli chcesz, aby użytkownik mógł wykorzystać kod rabatowy X razy możesz wprowadzić ilość w tym polu. Jeżeli pole zostanie puste, użytkownik może wykorzystać kod w nielimitowanej ilosći zamówień.',
      totalAmountInfo: 'Jeżeli chcesz ograniczyć ogólną ilość wykorzystania kodu przez wszystkich użytkowników wprowadź ilość w tym polu"',
      totalDiscountPriceInfo: 'Jeżeli chcesz aby suma zniżek była ograniczona wprowadź wartość w tym polu. Suma zniżek oznacza sumę wszystkich wartości zniżek. Przykładowowo dla zamówienia o wartości 100 złotych wykorzystanie kodu rabatowego 10% dodaje 10 złoty do sumy zniżek',
      errors: {
        number: 'Wymagana wartość liczbowa',
        isRequired: 'Pole jest wymagane',
        tooSmall: 'Wartość jest zbyt mała',
        name: {
          required: 'Nazwa kodu jest wymagana',
        },
        description: {
          max: "Przekroczono maksymalną liczbę znaków, maksimum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        dateFrom: {
          minCurrent: 'Data nie może być w przeszłości',
          maxDateTo: 'Data nie może być później niż data końca dostępności',
        },
        dateTo: {
          minCurrent: 'Data nie może być w przeszłości',
          minDateFrom: 'Data nie może być wcześniej niż data początku dostępności',
        },
      },
      success: {
        create: 'Poprawnie utworzono kod rabatowy',
        update: 'Poprawnie zedytowano kod rabatowy',
        deleted: 'Poprawnie usunięto kod rabatowy',
      },
    },
  },
}
