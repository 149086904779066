import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import {
  AppBar as MaterialAppBar,
  Toolbar,
  Typography,
  LinearProgress,
  MenuItem,
  Button,
} from '@material-ui/core'
import { useLoadingStatus, useClubs } from '@itsilesia/udrink-common-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'

import useCurrentUser from '../hooks/useCurrentUser'
import useAppHeaderTitle from '../hooks/useAppHeaderTitle'
import omitProps from '../utils/omitProps'
import { Select } from './inputs'
import RestrictedContent from './auth/RestrictedContent'
import currentClubModule from '../store/currentClub'
import ROLE_GROUPS from '../constants/roleGroups'
import CLUB_SELECT_PATHNAMES from '../constants/clubSelectPathnames'
import useSignOut from '../hooks/useSignOut'

const ANIMATION_DURATION = 600

const StyledTitle = styled(omitProps('animate')(Typography))`
  margin: 13px 0;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;

  ${({ animate }) => animate && `
    @keyframes animation {
      0%   {
        opacity: 0;
        transform: translateX(-10px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    animation-name: animation;
    animation-duration: ${ANIMATION_DURATION}ms;
  `}
`

const StyledMaterialAppBar = styled(MaterialAppBar)`
  background: white;
  box-shadow: 0px -3px 5px 4px rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(6,6,6,0.2);

  .MuiLinearProgress-colorPrimary {
    background: white;
  }
`
const Username = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  letter-spacing: 0.4px;
  font-weight: 500;
`

const AtWrapper = styled.span`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 16px;
`

const StyledSelect = styled(omitProps('display')(Select))`
  position: relative;
  ${({ display }) => display && 'display: none;'}
  margin-right: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 23px;

  .MuiSelect-root {
    padding: 11px 32px 11px 11px;
    border-radius: 7px;
  }

  &.MuiInput-underline:before {
    content: none;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
`

const LogOutButton = styled(Button)`
  padding: 2px 12px;
`

const AppBar = () => {
  const signOut = useSignOut()
  const { t } = useTranslation()
  const isAnyLoading = useLoadingStatus()
  const username = useCurrentUser('username')
  const headerTitle = useAppHeaderTitle()
  const [headerAnimate, setHeaderAnimate] = useState(false)
  const dispatch = useDispatch()
  const currentClubId = useSelector(state => state.currentClub)
  const location = useLocation()

  const { data } = useClubs()
  const clubs = useMemo(() => data?.content || [], [data])

  useEffect(() => {
    setHeaderAnimate(true)
    setTimeout(() => {
      setHeaderAnimate(false)
    }, ANIMATION_DURATION)
  }, [headerTitle])

  const handleClubChange = (clubId) => {
    dispatch(currentClubModule.actions.change(clubId))
  }

  const isSelectDisplay = !CLUB_SELECT_PATHNAMES.find(
    path => !!matchPath(location.pathname, {
      path,
      exact: true,
    }),
  )

  return (
    <StyledMaterialAppBar position="sticky" color="transparent">
      <Toolbar>
        <HeaderWrapper>
          <RestrictedContent accessRole={ROLE_GROUPS.MANAGER.value}>
            <StyledSelect
              variant="standard"
              display={isSelectDisplay}
              value={currentClubId || ''}
              onChange={event => handleClubChange(event.target.value)}
            >
              {clubs.map(({ name, id }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </StyledSelect>
          </RestrictedContent>
          <StyledTitle
            animate={headerAnimate}
            variant="h1"
          >
            {headerTitle}
          </StyledTitle>
        </HeaderWrapper>
        <Username>
          {username?.includes('@')
            ? (
              <>
                <span>{username.split('@')[0]}</span>
                <AtWrapper>@</AtWrapper>
                <span>{username.split('@')[1]}</span>
              </>
            ) : username}
        </Username>
        <LogOutButton
          variant="outlined"
          onClick={signOut}
        >
          {t('common.signOut')}
        </LogOutButton>
      </Toolbar>
      <LinearProgress
        variant="query"
        {...(!isAnyLoading && { variant: 'determinate', value: 0 })}
      />
    </StyledMaterialAppBar>
  )
}

export default AppBar
