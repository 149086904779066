import { useMemo, useCallback } from 'react'
import { useClub, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { mapClub } from '../utils/mapClub'
import { updateClubAsManager } from '../services/api'

const useManagerClubForm = ({ id, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useClub(id)
  useTrackLoadingStatus('club', isValidating)

  const initialValues = useMemo(() => mapClub(data), [data])

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await mutate(async (status) => {
          const club = await updateClubAsManager(id, values)
          return { ...status, club }
        }, false)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [mutate, id, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useManagerClubForm
