import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClubOrdersStatistic, usePrevious } from '@itsilesia/udrink-common-components'

import IdPropType from '../../../../propTypes/IdPropType'
import OrdersStatisticView from './view/OrdersStatisticView'
import useStatisticChart from '../../../../hooks/useStatisticChart'
import parseTimeScale from '../../../../utils/parseTimeScale'
import parseISODate from '../../../../utils/parseISODate'
import useSnackbar from '../../../../hooks/useSnackbar'
import { ORDERS, TIPS } from '../../../../constants/orders'

const OrdersStatistic = ({ clubId }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const handleError = ({ message }) => addSnackbar(t(message))

  const {
    from,
    to,
    timeScale,
    timePeriod,
    handleTimeScaleChange,
    handleTimePeriodChange,
  } = useStatisticChart({ handleError })

  const { data } = useClubOrdersStatistic(clubId, {
    interval: timeScale.value,
    from,
    to,
  })
  const prevData = usePrevious(data || {})
  const { purchasesByIntervals } = data || { purchasesByIntervals: {} }
  const chartData = useMemo(
    () =>
      Object.entries(purchasesByIntervals || prevData)
        .map(([dateTime, { orders: ordersCount, tips: tipsCount }]) => {
          const [date] = parseISODate(dateTime).split('T')
          return {
            timeScale: parseTimeScale(timeScale.value, dateTime),
            ordersCount,
            tipsCount,
            [ORDERS]: ordersCount,
            [TIPS]: tipsCount,
            date,
          }
        }),
    [purchasesByIntervals, prevData, timeScale],
  )

  return (
    <OrdersStatisticView
      data={chartData}
      tipsCount={data?.totalTips}
      ordersCount={data?.totalOrders}
      timeScale={timeScale}
      timePeriod={timePeriod}
      from={from}
      to={to}
      onTimeScaleChange={handleTimeScaleChange}
      onTimePeriodChange={handleTimePeriodChange}
    />
  )
}

OrdersStatistic.propTypes = {
  clubId: IdPropType.isRequired,
}

export default OrdersStatistic
