import PropTypes from 'prop-types'

const TimeScalePropTypes = PropTypes.shape({
  value: PropTypes.string,
  inHours: PropTypes.number,
  code: PropTypes.string,
  sumCode: PropTypes.string,
})

export default TimeScalePropTypes
