import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import StyledTextField from '../../../ClubForm/StyledTextField'
import StyledPriceTextField from '../../../ClubForm/StyledPriceTextField'
import { ContainerWithInfoTooltip } from '../ContainerWithInfoTooltip'

const PoolLimitationsSubsection = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainerWithInfoTooltip info={t('discountCodes.totalAmountInfo')}>
        <Field
          formatError={t}
          label={`${t('discountCodes.totalAmount')} ${t('common.optional')}`}
          name="totalAmount"
          type="number"
          component={StyledTextField}
        />
      </ContainerWithInfoTooltip>
      <ContainerWithInfoTooltip info={t('discountCodes.totalDiscountPriceInfo')}>
        <Field
          formatError={t}
          label={`${t('discountCodes.totalDiscountPrice')} ${t('common.optional')}`}
          name="totalDiscountPrice"
          component={StyledPriceTextField}
        />
      </ContainerWithInfoTooltip>
    </>
  )
}

export default PoolLimitationsSubsection
