import styled from 'styled-components/macro'

import { PriceTextField } from '../../inputs'

const StyledPriceTextField = styled(PriceTextField)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`

export default StyledPriceTextField
