import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import snackbarModule from '../store/snackbar'

const useSnackbar = () => {
  const dispatch = useDispatch()

  const addSnackbar = useCallback(
    message => dispatch(snackbarModule.actions.addMessage(message)),
    /* eslint-disable-next-line */
    [],
  )

  return { addSnackbar }
}

export default useSnackbar
