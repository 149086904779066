import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { useDiscountCodes } from '@itsilesia/udrink-common-components'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import DiscountCodesList from '../../components/discountCodes/DiscountCodesList/DiscountCodesList'
import RouteHeader from '../../components/layout/RouteHeader'
import useDataList from '../../hooks/useDataList'

const ClubDiscountCodesScreen = () => {
  const { clubId } = useContext(ClubContext)

  const useResource = ({ page, size }) =>
    useDiscountCodes({
      clubId,
      page,
      size,
      sort: 'name',
    })
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    ...props
  } = useDataList('discountCodes', useResource)

  const { t } = useTranslation()

  return (
    <RouteContainer>
      <RouteHeader>{t('drawer.navigation.discountCodes')}</RouteHeader>
      <DiscountCodesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default ClubDiscountCodesScreen
