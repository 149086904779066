import PropTypes from 'prop-types'
import useHasRole from '../../hooks/useHasRole'

const RestrictedContent = ({ accessRole, children, fallback, ...rest }) => {
  const isAllowed = useHasRole(accessRole)

  return isAllowed || accessRole === null
    ? typeof children === 'function'
      ? children(rest)
      : children
    : fallback
}

RestrictedContent.defaultProps = {
  children: null,
  fallback: null,
  accessRole: null,
}

RestrictedContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  fallback: PropTypes.node,
  accessRole: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
}

export default RestrictedContent
