import {
  ClubName,
  UserName,
  OrderPreparationPlaceName,
  OrderDeliveryPlaceName,
} from '../components/navigation/NavigationBreadcrumbs/names'

const pathnames = Object.freeze([
  {
    path: '/',
    render: 'drawer.navigation.mainSite',
    noMatchPattern: [],
  },
  {
    path: '/clubs',
    render: 'drawer.navigation.clubs',
    noMatchPattern: [],
  },
  {
    path: '/clubs/create',
    render: 'common.create',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:id',
    render: ClubName,
    noMatchPattern: ['/clubs/create'],
  },
  {
    path: '/clubs/:id/edit',
    render: 'common.edit',
    noMatchPattern: [],
  },
  {
    path: '/users',
    render: 'drawer.navigation.users',
    noMatchPattern: [],
  },
  {
    path: '/users/create',
    render: 'common.create',
    noMatchPattern: [],
  },
  {
    path: '/users/:id',
    render: UserName,
    noMatchPattern: ['/users/create'],
  },
  {
    path: '/users/:id/edit',
    render: 'common.edit',
    noMatchPattern: [],
  },
  {
    path: '/tax-rates',
    render: 'drawer.navigation.taxRates',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-collect-places',
    render: 'drawer.navigation.orderCollect',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-preparation-places',
    render: 'drawer.navigation.orderPreparation',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-preparation-places/create',
    render: 'common.create',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-preparation-places/:id',
    render: OrderPreparationPlaceName,
    noMatchPattern: ['/clubs/:clubId/order-preparation-places/create'],
  },
  {
    path: '/clubs/:clubId/order-preparation-places/:id/edit',
    render: 'common.edit',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-delivery-places',
    render: 'drawer.navigation.orderDelivery',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-delivery-places/create',
    render: 'common.create',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/order-delivery-places/:id',
    render: OrderDeliveryPlaceName,
    noMatchPattern: ['/clubs/:clubId/order-delivery-places/create'],
  },
  {
    path: '/clubs/:clubId/order-delivery-places/:id/edit',
    render: 'common.edit',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/products',
    render: 'drawer.navigation.products',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/menu',
    render: 'drawer.navigation.menu',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/modifiers',
    render: 'drawer.navigation.modifiers',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/inventory',
    render: 'drawer.navigation.inventory',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/discount-codes',
    render: 'drawer.navigation.discountCodes',
    noMatchPattern: [],
  },
  {
    path: '/workers',
    render: 'drawer.navigation.workers',
    noMatchPattern: [],
  },
  {
    path: '/menu',
    render: 'drawer.navigation.menu',
    noMatchPattern: [],
  },
  {
    path: '/inventory',
    render: 'drawer.navigation.inventory',
    noMatchPattern: [],
  },
  {
    path: '/order-collect-places',
    render: 'drawer.navigation.orderCollect',
    noMatchPattern: [],
  },
  {
    path: '/order-preparation-places',
    render: 'drawer.navigation.orderPreparation',
    noMatchPattern: [],
  },
  {
    path: '/order-delivery-places',
    render: 'drawer.navigation.orderDelivery',
    noMatchPattern: [],
  },
])

export default pathnames
