import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Tooltip, Switch } from '@material-ui/core'
import styled from 'styled-components/macro'

import AlertDialog from '../../../AlertDialog'

const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  padding-left: 20px;
`

const Blur = styled.div`
  ${({ isUpdating }) => isUpdating && `
    opacity: 0.5;
    filter: grayscale(60%) blur(1.5px);
  `}
`

const LoaderRoot = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ButtonsWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const InventoryFormActions = ({
  row,
  onResourceCreate: handleResourceCreate,
  onResourceDelete: handleResourceDelete,
}) => {
  const { t } = useTranslation()
  const [isSwitchChecked, setIsSwitchChecked] = useState(row.amount === null)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertConfig, setAlertConfig] = useState({})
  const [isUpdating, setIsUpdating] = useState(false)

  const handleAlertOpen = (callback, { message, title, confirmText, confirmColor } = {}) => {
    setAlertConfig({
      callback: callback || (() => {}),
      message: message || '',
      title: title || '',
      confirmText: confirmText || '',
      confirmColor: confirmColor || 'secondary',
    })
    setIsAlertOpen(true)
  }

  const handleAlertConfirm = async () => {
    await alertConfig.callback()
    setIsAlertOpen(false)
  }

  const handleAmountCreate = () => {
    handleAlertOpen(
      async () => {
        setIsUpdating(true)
        try {
          await handleResourceCreate(row.id, 0)
          setIsSwitchChecked(false)
        } catch {
          console.error('The switch has not been deactivated') // eslint-disable-line no-console
        }
        setIsUpdating(false)
      },
      {
        message: t('inventory.createLimitAlert'),
        title: t('inventory.createLimitTitle'),
        confirmText: t('inventory.createLimit'),
      },
    )
  }

  const handleAmountDelete = () => {
    handleAlertOpen(
      async () => {
        setIsUpdating(true)
        try {
          await handleResourceDelete(row.productAmountId)
          setIsSwitchChecked(true)
        } catch {
          console.error('The switch has not been activated') // eslint-disable-line no-console
        }
        setIsUpdating(false)
      },
      {
        message: t('inventory.removeLimitAlert'),
        title: t('inventory.removeLimitTitle'),
        confirmText: t('inventory.removeLimit'),
      },
    )
  }

  const handleSwitchToggle = (checked) => {
    if (checked) {
      handleAmountCreate()
    } else {
      handleAmountDelete()
    }
  }

  return (
    <ActionsContainer>
      <ButtonsWrapper>
        <Blur isUpdating={isUpdating}>
          <Tooltip
            title={isSwitchChecked ? t('inventory.createLimit') : t('inventory.removeLimit')}
            aria-label={isSwitchChecked ? t('inventory.createLimit') : t('inventory.removeLimit')}
          >
            <Switch
              checked={!isSwitchChecked}
              onClick={event => handleSwitchToggle(event.target.checked)}
              color="primary"
            />
          </Tooltip>
        </Blur>
        {isUpdating && (
          <LoaderRoot>
            <CircularProgress />
          </LoaderRoot>
        )}
      </ButtonsWrapper>
      <AlertDialog
        onConfirm={handleAlertConfirm}
        onCancel={() => setIsAlertOpen(false)}
        message={alertConfig.message}
        title={alertConfig.title}
        confirmText={alertConfig.confirmText}
        confirmColor={alertConfig.confirmColor}
        isOpen={isAlertOpen}
      />
    </ActionsContainer>
  )
}

InventoryFormActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    productAmountId: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
  onResourceCreate: PropTypes.func,
  onResourceDelete: PropTypes.func,
}

InventoryFormActions.defaultProps = {
  onResourceCreate: () => {},
  onResourceDelete: () => {},
}

export default memo(InventoryFormActions)
