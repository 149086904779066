import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import FieldContainer from './FieldContainer'
import FieldHeader from './FieldHeader'
import FieldValue from './FieldValue'

const DataField = ({ label, value, ...props }) => {
  const { t } = useTranslation()

  if (!Array.isArray(value)) {
    // eslint-disable-next-line no-param-reassign
    value = value ? [value] : []
  }

  return (
    <FieldContainer {...props}>
      <FieldHeader>{label}</FieldHeader>
      <FieldValue>
        {value.length > 0 ? value.join(', ') : t('common.missing')}
      </FieldValue>
    </FieldContainer>
  )
}

DataField.defaultProps = {
  value: [],
}

DataField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string.isRequired)]),
}

export default DataField
