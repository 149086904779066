import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import noop from 'lodash/noop'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons/'

import DataTable from '../../data/DataTable'
import columns from './ClubsList.columns'
import RestrictedContent from '../../auth/RestrictedContent'
import { ADMIN_ROLE } from '../../../constants/roleGroups'

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ClubsList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <TableOptions>
        <RestrictedContent accessRole={ADMIN_ROLE}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/clubs/create"
            startIcon={<AddIcon />}
          >
            {t('common.create')}
          </Button>
        </RestrictedContent>
      </TableOptions>
      <DataTable
        data={data}
        columns={columns}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
    </>
  )
}

ClubsList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
}

ClubsList.propTypes = {
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default ClubsList
