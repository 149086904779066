import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import AlertParagraph from './AlertParagraph'

const Label = styled.label`
  font-size: 12px;
`

const Paragraph = styled.p`
  margin: 0;
  font-weight: ${({ isSolo }) => (isSolo ? '500' : '600')};
`

const Error = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`

const PropEntity = ({ value, label, error, alert }) => (
  <div>
    <Label
      id={`label-${label}`}
      htmlFor={`span=${label}`}
    >
      {label}
    </Label>
    <Paragraph
      id={`span=${label}`}
      isSolo={!label}
    >
      {value}
    </Paragraph>
    {alert && <AlertParagraph value={alert} />}
    <Error>{error}</Error>
  </div>
)

PropEntity.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  alert: PropTypes.string,
}

PropEntity.defaultProps = {
  value: '',
  label: '',
  error: '',
  alert: '',
}
export default PropEntity
