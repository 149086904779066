import ActionsCell from './OrderDeliveryPlacesListActions'

const columns = [
  {
    key: 'name',
    prop: 'name',
    label: { translationKey: 'orderDelivery.title' },
  },
  {
    key: 'actions',
    label: { translationKey: 'drawer.actions.title' },
    component: ActionsCell,
    alignText: 'right',
  },
]

export default columns
