import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'
import { mapToAPIUpdateTaxRate } from '../../../utils/mapTaxRates'

const updateVatRate = async ({ id, ...restData }) => {
  const { data: newDateRate } = await fetch(
    API.taxRates.update(id, mapToAPIUpdateTaxRate(restData)),
  )

  return newDateRate
}

export { updateVatRate }
