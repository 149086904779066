import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Field } from 'formik'

import { TextField, TextArea } from '../../inputs'
import { UPDATE, CREATE } from '../../../constants/formModes'
import FormFooter from '../FormFooter'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import StyledFormikForm from '../StyledFormikForm'
import FormModePropType from '../../../propTypes/FormModePropType'

const StyledTextField = styled(TextField)`
  width: 100%;
`

const OrderDeliveryPlaceForm = ({
  mode,
  isSubmitting,
  onCancel,
}) => {
  const { t } = useTranslation()

  const confirmMessageKey = useMemo(
    () => (mode === UPDATE ? 'common.update' : 'common.save'),
    [mode],
  )

  return (
    <StyledFormikForm>
      <FormStyleWrapper>
        <Field
          formatError={t}
          label={t('orderDelivery.name')}
          name="name"
          component={StyledTextField}
        />
        <Field
          formatError={t}
          label={t('common.description')}
          name="description"
          component={TextArea}
        />
      </FormStyleWrapper>
      <FormFooter
        onCancel={onCancel}
        disabled={isSubmitting}
        confirmMessageKey={confirmMessageKey}
      />
    </StyledFormikForm>
  )
}

OrderDeliveryPlaceForm.defaultProps = {
  mode: CREATE,
}

OrderDeliveryPlaceForm.propTypes = {
  mode: FormModePropType,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default OrderDeliveryPlaceForm
