import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { WarningRounded } from '@material-ui/icons'

const WarningRoundedIcon = styled(WarningRounded)`
  width: 18px;
  margin-right: 5px;
`

const Alert = styled.div`
  display: flex;
  color: orange;
  font-size: 11px;
  line-height: 25px;
  font-weight: 400;
  transform: translateY(-2px);
`

const AlertParagraph = ({ value }) => (
  <Alert>
    <WarningRoundedIcon />
    <span>{value}</span>
  </Alert>
)

AlertParagraph.propTypes = {
  value: PropTypes.string,
}

AlertParagraph.defaultProps = {
  value: '',
}

export default AlertParagraph
