import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { API } from '@itsilesia/udrink-common-components'
import useSWR from 'swr'

import currentClubModule from './store/currentClub'
import RestrictedRoute from './components/auth/RestrictedRoute'
import SignInScreen from './screens/SignInScreen'
import ClubScreen from './screens/ClubScreen'
import ClubsScreen from './screens/ClubsScreen'
import UsersScreen from './screens/UsersScreen'
import UserCreateScreen from './screens/UsersScreen/UserCreateScreen'
import UserEditScreen from './screens/UsersScreen/UserEditScreen'
import TaxRatesScreen from './screens/TaxRatesScreen'
import OrderPreparationPlaceScreen from './screens/OrderPreparationPlaceScreen'
import OrderPreparationPlacesScreen from './screens/OrderPreparationPlacesScreen'
import OrderCollectPlaceScreen from './screens/OrderCollectPlaceScreen'
import OrderCollectPlacesScreen from './screens/OrderCollectPlacesScreen'
import OrderDeliveryPlaceScreen from './screens/OrderDeliveryPlaceScreen'
import OrderDeliveryPlacesScreen from './screens/OrderDeliveryPlacesScreen'
import NewPasswordScreen from './screens/NewPasswordScreen'
import ProductsScreen from './screens/ProductsScreen'
import MenuScreen from './screens/MenuScreen'
import ClubDetailsScreen from './screens/ClubDetailsScreen'
import UserDetailsScreen from './screens/UserDetailsScreen'
import InventoryScreen from './screens/InventoryScreen'
import HomePageScreen from './screens/HomePageScreen'
import ClubWorkersScreen from './screens/manager/ClubWorkersScreen'
import ClubMenuScreen from './screens/manager/ClubMenuScreen'
import ClubOrderPreparationPlacesScreen from './screens/manager/ClubOrderPreparationPlacesScreen'
import ClubOrderCollectPlacesScreen from './screens/manager/ClubOrderCollectPlacesScreen'
import ClubOrderDeliveryPlacesScreen from './screens/manager/ClubOrderDeliveryPlacesScreen'
import ClubDataScreen from './screens/manager/ClubDataScreen'
import ClubInventoryScreen from './screens/manager/ClubInventoryScreen'
import ClubStatisticsScreen from './screens/manager/ClubStatisticsScreen'
import ClubHoursScreen from './screens/manager/ClubHoursScreen'
import ClubHoursEditScreen from './screens/manager/ClubHoursEditScreen'
import ClubDataEditScreen from './screens/manager/ClubDataEditScreen'
import ClubModifiersScreen from './screens/manager/ClubModifiersScreen'
import ClubDiscountCodesScreen from './screens/manager/ClubDiscountCodesScreen'
import ModifiersScreen from './screens/ModifiersScreen'
import DiscountCodeScreen from './screens/DiscountCodeScreen'
import DiscountCodesScreen from './screens/DiscountCodesScreen'

const Routes = () => {
  const isLoggedIn = useSelector(state => !!state.auth.user)
  const dispatch = useDispatch()
  const redirectPath = '/statistics'

  const { data } = useSWR(() => (isLoggedIn ? API.clubs.list({
    page: 0,
    size: 2,
    sort: 'name',
  }).key : null))

  useEffect(() => {
    if (isLoggedIn) {
      if (data?.content[0]) {
        dispatch(currentClubModule.actions.change(data.content[0].id))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Switch>
      <Route exact path="/login">
        {isLoggedIn ? <Redirect to={redirectPath} /> : <SignInScreen />}
      </Route>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/">
        <HomePageScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs">
        <ClubsScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN']} exact path="/clubs/create">
        <ClubScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:id">
        <ClubDetailsScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:id/edit">
        <ClubScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-preparation-places">
        <OrderPreparationPlacesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-preparation-places/create">
        <OrderPreparationPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-preparation-places/:id/edit">
        <OrderPreparationPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-collect-places">
        <OrderCollectPlacesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-collect-places/create">
        <OrderCollectPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-collect-places/:id/edit">
        <OrderCollectPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-delivery-places">
        <OrderDeliveryPlacesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-delivery-places/create">
        <OrderDeliveryPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/order-delivery-places/:id/edit">
        <OrderDeliveryPlaceScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/products">
        <ProductsScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/menu">
        <MenuScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/modifiers">
        <ModifiersScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/inventory">
        <InventoryScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/discount-codes">
        <DiscountCodesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/users">
        <UsersScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/users/create">
        <UserCreateScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/users/:id">
        <UserDetailsScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/users/:id/edit">
        <UserEditScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN']} exact path="/tax-rates">
        <TaxRatesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/discount-codes">
        <DiscountCodeScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/discount-codes/:id/edit">
        <DiscountCodeScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['ADMIN', 'MANAGER']} exact path="/clubs/:clubId/discount-codes/create">
        <DiscountCodeScreen />
      </RestrictedRoute>

      <RestrictedRoute accessRole={['MANAGER']} exact path="/data">
        <ClubDataScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/data/edit">
        <ClubDataEditScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/open-hours">
        <ClubHoursScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/open-hours/edit">
        <ClubHoursEditScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/statistics">
        <ClubStatisticsScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/workers">
        <ClubWorkersScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/menu">
        <ClubMenuScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/modifiers">
        <ClubModifiersScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/inventory">
        <ClubInventoryScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/discount-codes">
        <ClubDiscountCodesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/order-preparation-places">
        <ClubOrderPreparationPlacesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/order-collect-places">
        <ClubOrderCollectPlacesScreen />
      </RestrictedRoute>
      <RestrictedRoute accessRole={['MANAGER']} exact path="/order-delivery-places">
        <ClubOrderDeliveryPlacesScreen />
      </RestrictedRoute>
      <Route path="/reset-password/:token" component={NewPasswordScreen} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  )
}

export default Routes
