import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import formatIncome from '../../../../../utils/formatIncome'
import ChartTooltip from '../../chart/ChartTooltip'

const IncomeTooltip = ({ timeScaleName, active, payload }) => {
  const { t } = useTranslation()
  const { ordersIncome, tipsIncome, date, timeScale } = get(payload, '[0].payload', {})

  const tooltipRows = [
    {
      label: t('statistics.income'),
      value: formatIncome(ordersIncome) || '',
    },
    {
      label: t('statistics.tips'),
      value: formatIncome(tipsIncome) || '',
    },
    {
      label: timeScaleName,
      value: timeScale || '',
    },
  ]

  return active ? <ChartTooltip header={date} rows={tooltipRows} /> : null
}

IncomeTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      ordersIncome: PropTypes.number,
      tipsIncome: PropTypes.number,
      date: PropTypes.string,
      timeScale: PropTypes.string,
    }),
  ),
  active: PropTypes.bool,
  timeScaleName: PropTypes.string,
}

IncomeTooltip.defaultProps = {
  active: false,
  payload: [],
  timeScaleName: '',
}

export default IncomeTooltip
