import { useDiscountCodes } from '@itsilesia/udrink-common-components'
import { useParams } from 'react-router-dom'

import RouteContainer from '../components/layout/RouteContainer'
import DiscountCodesList from '../components/discountCodes/DiscountCodesList/DiscountCodesList'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs/NavigationBreadcrumbs'
import useDataList from '../hooks/useDataList'

const DiscountCodesScreen = () => {
  const { clubId } = useParams()

  const useResource = ({ page, size }) =>
    useDiscountCodes({
      clubId,
      page,
      size,
      sort: 'name',
    })
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    ...props
  } = useDataList('discountCodes', useResource)

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <DiscountCodesList
        {...props}
        clubId={clubId}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default DiscountCodesScreen
