import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { DAYS } from '../constants/days'
import { PAYMENT_ACQUIRER_FISERV } from '../constants/paymentAcquirers'

const defaultTime = '00:00'

const getOpeningHour = (data, day) =>
  get(data, `weeklySchedule.schedule.${day}.openingHour`, defaultTime).slice(0, 5)
const getClosingHour = (data, day) =>
  get(data, `weeklySchedule.schedule.${day}.closingHour`, defaultTime).slice(0, 5)

const mapCoordinates = (coordinates) => {
  const longitude = get(coordinates, 'longitude', null)
  const latitude = get(coordinates, 'longitude', null)

  return longitude && latitude ? `${longitude}, ${latitude}` : ''
}

const getFiservPaymentData = data => ({
  sharedSecret: get(data, 'sharedSecret', ''),
  storeId: get(data, 'storeId', ''),
  apiKey: get(data, 'apiKey', ''),
  secretKey: get(data, 'secretKey', ''),
  isTestMode: get(data, 'test', true),
})

const getOpeningHours = data => mapValues(
  keyBy(DAYS, 'value'),
  ({ value: day }) => get(data, `weeklySchedule.schedule.${day}`)
    && {
      openTime: getOpeningHour(data, day),
      closeTime: getClosingHour(data, day),
    },
)

const mapClub = (data, dataFiserv) => ({
  name: get(data, 'club.name', ''),
  nip: get(data, 'club.nip', ''),
  coordinates: mapCoordinates(get(data, 'club.location.coordinates', null)),
  address: get(data, 'club.location.address', ''),
  zipCode: get(data, 'club.location.postalCode', ''),
  city: get(data, 'club.location.city', ''),
  country: get(data, 'club.location.countryCode', 'PL'),
  timeZoneId: get(data, 'club.location.timeZoneId', 'Europe/Warsaw'),
  ownerId: get(data, 'club.ownerId', ''),
  isActive: get(data, 'club.active', false),
  isUsingPrinter: get(data, 'club.usingPrinter', true),
  weeklyScheduleId: get(data, 'weeklySchedule.id', null),
  openingHours: getOpeningHours(data),
  fiservAccessData: PAYMENT_ACQUIRER_FISERV === get(dataFiserv, 'acquirer', '')
    ? getFiservPaymentData(dataFiserv) : getFiservPaymentData({}),
})

const mapToAPIClub = (data, action) => {
  const {
    coordinates,
    address,
    city,
    country,
    zipCode,
    name,
    nip,
    isActive,
    isUsingPrinter,
    timeZoneId,
  } = data
  const [latitude, longitude] = coordinates
    ? coordinates.split(',').map(coordinate => parseFloat(coordinate))
    : [0, 0]

  return {
    [`location${action}Dto`]: {
      address,
      city,
      [`coordinates${action}Dto`]: {
        latitude,
        longitude,
      },
      countryCode: country,
      zoneId: timeZoneId,
      postalCode: zipCode,
    },
    name,
    nip,
    active: isActive,
    usingPrinter: isUsingPrinter,
  }
}

const removeSeconds = time => time && time.substr(0, 5)
const mapToAPICreateClub = data => mapToAPIClub(data, 'Create')
const mapToAPIUpdateClub = data => mapToAPIClub(data, 'Update')

const mapToAPIWeeklySchedules = ({ openingHours, clubId }) => ({
  clubId,
  hours: DAYS.map(({ value }) => (openingHours[value]
    ? {
      openingHour: removeSeconds(openingHours[value].openTime),
      closingHour: removeSeconds(openingHours[value].closeTime),
    }
    : null)),
})

const mapToAPIAccessDataFiserv = (data, payeeId) => ({
  payeeId,
  sharedSecret: get(data, 'fiservAccessData.sharedSecret'),
  storeId: get(data, 'fiservAccessData.storeId'),
  apiKey: get(data, 'fiservAccessData.apiKey'),
  secretKey: get(data, 'fiservAccessData.secretKey'),
  test: get(data, 'fiservAccessData.isTestMode'),
})

export {
  mapToAPICreateClub,
  mapToAPIUpdateClub,
  mapToAPIWeeklySchedules,
  mapToAPIAccessDataFiserv,
  mapClub,
}
