import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import CompletedSrc from '../assets/images/completed.svg'
import { BLUE } from '../constants/colors'

const Conteriner = styled.div`
  width: 90%;
  max-width: 700px;
  padding: 30px;
  border-radius: 6px;
  background-color: white;
`

const Border = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px;
  border: 1px solid ${BLUE};
  border-radius: 6px;
`

const Message = styled.span`
  margin-left: 10px;
  font-size: 28px;
`

const SendImage = styled.img`
  width: 100px;
  height: 108px;
`

const SentryFeedbackSentMessage = () => {
  const { t } = useTranslation()

  return (
    <Conteriner>
      <Border>
        <SendImage alt="" src={CompletedSrc} />
        <Message>{t('feedback.sent')}</Message>
      </Border>
    </Conteriner>
  )
}

export default SentryFeedbackSentMessage
