import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@material-ui/core'
import QRCode from 'qrcode.react'

const QrCodeDialog = ({
  uuid,
  open,
  onClose: handleClose,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogContent>
      {uuid && (
        <QRCode
          value={uuid}
          size={300}
        />
      )}
    </DialogContent>
  </Dialog>
)

QrCodeDialog.propTypes = {
  uuid: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

QrCodeDialog.defaultProps = {
  uuid: null,
  onClose: () => {},
}

export default QrCodeDialog
