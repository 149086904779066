const DAYS = Object.freeze([
  { value: 'MONDAY' },
  { value: 'TUESDAY' },
  { value: 'WEDNESDAY' },
  { value: 'THURSDAY' },
  { value: 'FRIDAY' },
  { value: 'SATURDAY' },
  { value: 'SUNDAY' },
])

export { DAYS }
