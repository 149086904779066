import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@material-ui/core'
import styled from 'styled-components/macro'

import { BLUE } from '../../constants/colors'

const StyledLink = styled(RouterLink)`
  color: ${BLUE};
`

const LinkRouter = props => <Link {...props} component={StyledLink} />

export default LinkRouter
