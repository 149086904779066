import { createContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const OrderPreparationPlaceContext = createContext(null)

const OrderPreparationPlaceContextProvider = ({ children }) => {
  const [orderPreparationPlaceUuid, setOrderPreparationPlaceUuid] = useState(null)
  const [orderPreparationPlaceId, setOrderPreparationPlaceId] = useState(null)
  const [
    isDeleteOrderPreparationPlaceDialogOpen,
    setIsDeleteOrderPreparationPlaceDialogOpen,
  ] = useState(false)

  const values = useMemo(() => ({
    orderPreparationPlaceUuid,
    orderPreparationPlaceId,
    isDeleteOrderPreparationPlaceDialogOpen,
    setOrderPreparationPlaceUuid,
    setOrderPreparationPlaceId,
    setIsDeleteOrderPreparationPlaceDialogOpen,
  }), [
    orderPreparationPlaceUuid,
    orderPreparationPlaceId,
    isDeleteOrderPreparationPlaceDialogOpen,
    setOrderPreparationPlaceUuid,
    setOrderPreparationPlaceId,
    setIsDeleteOrderPreparationPlaceDialogOpen,
  ])

  return (
    <OrderPreparationPlaceContext.Provider
      value={values}
    >
      {children}
    </OrderPreparationPlaceContext.Provider>
  )
}

OrderPreparationPlaceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OrderPreparationPlaceContextProvider
