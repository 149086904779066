import * as yup from 'yup'
import times from 'lodash/times'

const taxRatesValidationSchema = yup.object().shape({
  ...times(7, () =>
    yup.object().shape({
      rate: yup
        .string()
        .trim()
        .required('taxRates.errors.required')
        .matches(/^[ ]*([-+]?\d*([.]?\d*))$/, 'taxRates.errors.noMatch')
        .test('maxTest', 'taxRates.errors.max', value => parseFloat(value) <= 100)
        .test('minTest', 'taxRates.errors.min', value => parseFloat(value) >= 0),
    })),
})

export default taxRatesValidationSchema
