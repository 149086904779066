import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@material-ui/core'
import {
  Edit as EditIcon,
  CropFree as CropFreeIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'

import { OrderCollectPlaceContext } from '../../../providers/OrderCollectPlaceContextProvider'
import IdPropType from '../../../propTypes/IdPropType'

const OrderCollectPlacesListActions = ({ row }) => {
  const { t } = useTranslation()
  const {
    setOrderCollectPlaceUuid,
    setOrderCollectPlaceId,
    setIsDeleteOrderCollectPlaceDialogOpen,
  } = useContext(OrderCollectPlaceContext)

  return (
    <>
      <Tooltip
        title={t('tableTooltips.edit')}
        aria-label={t('tableTooltips.edit')}
      >
        <IconButton
          component={Link}
          to={`/clubs/${row.clubId}/order-collect-places/${row.id}/edit`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.qrCode')}
        aria-label={t('tableTooltips.qrCode')}
      >
        <IconButton onClick={() => setOrderCollectPlaceUuid(row.uuid)}>
          <CropFreeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.delete')}
        aria-label={t('tableTooltips.delete')}
      >
        <IconButton onClick={() => {
          setOrderCollectPlaceId(row.id)
          setIsDeleteOrderCollectPlaceDialogOpen(true)
        }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

OrderCollectPlacesListActions.propTypes = {
  row: PropTypes.shape({
    id: IdPropType.isRequired,
    clubId: IdPropType.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
}

export default OrderCollectPlacesListActions
