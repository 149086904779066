import PropTypes from 'prop-types'
import { Route, Redirect, useLocation } from 'react-router-dom'

import useHasRole from '../../hooks/useHasRole'
import useIsAuthenticated from '../../hooks/useIsAuthenticated'

const RestrictedRoute = ({ accessRole, children, ...rest }) => {
  const location = useLocation()

  const isAllowed = useHasRole(accessRole)
  const isLoggedIn = useIsAuthenticated()
  const isMissingRole = isLoggedIn && !isAllowed

  const redirectPath = isMissingRole ? {
    pathname: '/',
  } : {
    pathname: '/login',
    state: {
      redirectPath: location.pathname,
    },
  }

  return (
    <Route
      {...rest}
      render={() =>
        (isAllowed || accessRole === null ? (
          children
        ) : (
          <Redirect
            to={redirectPath}
          />
        ))}
    />
  )
}

RestrictedRoute.defaultProps = {
  children: null,
  accessRole: null,
}

RestrictedRoute.propTypes = {
  children: PropTypes.node,
  accessRole: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
}

export default RestrictedRoute
