import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { getIn } from 'formik'

import { WHITE } from '../../constants/colors'

const StyledTextArea = styled.textarea(({ theme, isError }) => `
  width: 100%;
  height: 120px;
  padding: 14px;
  border-radius: 4px;
  background-color: ${WHITE}9c;
  font-family: ${theme.typography.fontFamily};
  color: ${isError ? theme.palette.error.main : theme.palette.text.secondary};
  font-size: 16px;
  resize: none;
  ${isError && `border-color: ${theme.palette.error.main};`}
`)

const TextHelper = styled.p(({ theme, isError }) => `
  margin: 0;
  transform: translate(15px,-3px);
  font-size: 0.75rem;
  font-weight: 400;
  color: ${isError ? theme.palette.error.main : theme.palette.text.secondary};
`)

const StyleLabel = styled.label(({ theme, isError }) => `
  font-size: 0.75rem;
  font-weight: 400;
  color: ${isError ? theme.palette.error.main : theme.palette.text.secondary};
`)

const Container = styled.div`
  position: relative;
`

const TextArea = ({
  field,
  helperText,
  formatError,
  label,
  form: { touched, errors },
  className,
}) => {
  const { name } = field
  const fieldError = getIn(errors, name)

  let errorText
  if (typeof fieldError === 'object') {
    const messageKey = fieldError.key
    const messageParams = fieldError.params
    errorText = formatError ? formatError(messageKey, messageParams) : fieldError
  } else {
    errorText = formatError ? formatError(fieldError) : fieldError
  }
  const showError = getIn(touched, name) && !!fieldError

  return (
    <Container className={className}>
      <StyleLabel isError={showError}>
        {label}
        <StyledTextArea
          isError={showError}
          {...field}
        />
      </StyleLabel>
      <TextHelper isError={showError}>{errorText || helperText}</TextHelper>
    </Container>
  )
}

TextArea.defaultProps = {
  field: {},
  helperText: '',
  label: '',
  className: '',
  formatError: value => value,
}

TextArea.propTypes = {
  field: PropTypes.object,
  helperText: PropTypes.string,
  formatError: PropTypes.func,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
}

export default TextArea
