import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts'

import {
  DEEPER_BLUE,
  DEEPER_GREEN,
  DEEPER_MARITIME,
} from '../../../../../constants/colors'
import { ORDERS, TIPS, ALL } from '../../../../../constants/income'
import TIME_SCALE from '../../../../../constants/timeScale'
import TIME_PERIOD from '../../../../../constants/timePeriod'
import IncomeTooltip from './IncomeTooltip'
import ChartSettings from '../../chart/ChartSettings'
import formatIncome from '../../../../../utils/formatIncome'
import { StatisticHeader, StatisticContainer } from '../../statistic'
import TimePeriodPropTypes from '../../../../../propTypes/TimePeriodPropTypes'
import TimeScalePropTypes from '../../../../../propTypes/TimeScalePropTypes'
import IncomeLegend from './IncomeLegend'
import { yAxisConfig } from '../../chart/config'
import IncomeSVGSrc from '../../../../../assets/images/income.svg'
import { peanutsToPLN } from '../../../../../utils/peanutsToPLN'

const IncomeStatisticView = ({
  data,
  incomeSum,
  tipsIncome,
  orderIncome,
  timeScale,
  timePeriod,
  from,
  to,
  onTimeScaleChange: handleTimeScaleChange,
  onTimePeriodChange: handleTimePeriodChange,
}) => {
  const { t } = useTranslation()
  const [isTipsIncomeChecked, setIsTipsIncomeChecked] = useState(true)
  const [isOrdersIncomeChecked, setIsOrderIncomeChecked] = useState(true)
  const [isIncomeSumChecked, setIsIncomeSumChecked] = useState(false)

  const lines = useMemo(() => {
    const toDisplay = []
    const activeDot = { r: 6 }
    const strokeWidth = 2

    if (isTipsIncomeChecked) {
      const tipsIncomeConfig = {
        key: '2',
        dataKey: TIPS,
        stroke: DEEPER_BLUE,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(tipsIncomeConfig)
    }

    if (isOrdersIncomeChecked) {
      const ordersIncomeConfig = {
        key: '1',
        dataKey: ORDERS,
        stroke: DEEPER_GREEN,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(ordersIncomeConfig)
    }

    if (isIncomeSumChecked) {
      const incomeSumConfig = {
        key: '3',
        dataKey: ALL,
        stroke: DEEPER_MARITIME,
        strokeWidth,
        activeDot,
      }
      toDisplay.push(incomeSumConfig)
    }

    return toDisplay
  }, [isTipsIncomeChecked, isOrdersIncomeChecked, isIncomeSumChecked])

  return (
    <StatisticContainer>
      <StatisticHeader
        iconSrc={IncomeSVGSrc}
        timePeriod={timePeriod}
        from={from}
        to={to}
        summaryConfig={[{
          key: '1',
          value: formatIncome(peanutsToPLN(orderIncome)),
          textHelper: t('statistics.ordersIncomes'),
        }, {
          key: '2',
          value: formatIncome(peanutsToPLN(tipsIncome)),
          textHelper: t('statistics.tipsIncomes'),
        }, {
          key: '3',
          value: formatIncome(peanutsToPLN(incomeSum)),
          textHelper: t('common.inTotal'),
        }]}
      >
        {t('statistics.ordersIncomes')}
      </StatisticHeader>
      <ChartSettings
        activeTimeScale={timeScale.value}
        activeTimePeriod={timePeriod.value}
        onTimeScaleChange={handleTimeScaleChange}
        onTimePeriodChange={handleTimePeriodChange}
        from={from}
        to={to}
      />
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data}>
          <YAxis
            {...yAxisConfig}
            width={100}
            tickFormatter={tick => formatIncome(tick, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          />
          <XAxis dataKey="timeScale" />
          <Tooltip
            header={t('statistics.income')}
            content={<IncomeTooltip timeScaleName={t(timeScale.code)} />}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {lines.map(({
            key,
            dataKey,
            stroke,
            strokeWidth,
            dot,
            activeDot,
          }) => (
            <Line
              key={key}
              dataKey={dataKey}
              stroke={stroke}
              strokeWidth={strokeWidth}
              activeDot={activeDot}
              dot={dot}
            />
          ))}
          <Legend
            layout="horizontal"
            align="center"
            content={
              <IncomeLegend
                isTipsIncomeChecked={isTipsIncomeChecked}
                isOrdersIncomeChecked={isOrdersIncomeChecked}
                isIncomeSumChecked={isIncomeSumChecked}
                onTipsIncomeClick={() => setIsTipsIncomeChecked(checked => !checked)}
                onOrdersIncomeClick={() => setIsOrderIncomeChecked(checked => !checked)}
                onIncomeSumClick={() => setIsIncomeSumChecked(checked => !checked)}
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </StatisticContainer>
  )
}

IncomeStatisticView.propTypes = {
  data: PropTypes.array,
  timeScale: TimeScalePropTypes,
  timePeriod: TimePeriodPropTypes,
  onTimeScaleChange: PropTypes.func,
  onTimePeriodChange: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  tipsIncome: PropTypes.number,
  orderIncome: PropTypes.number,
  incomeSum: PropTypes.number,
}

IncomeStatisticView.defaultProps = {
  data: [],
  timeScale: TIME_SCALE.HOUR,
  timePeriod: TIME_PERIOD.LAST_DAY,
  onTimeScaleChange: () => {},
  onTimePeriodChange: () => {},
  from: null,
  to: null,
  tipsIncome: 0,
  orderIncome: 0,
  incomeSum: 0,
}

export default IncomeStatisticView
