import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import useSnackbar from '../../../hooks/useSnackbar'
import { TextField } from '../../inputs'
import IdPropType from '../../../propTypes/IdPropType'

const StyledTextField = styled(TextField)`
  width: 100%;
  min-width: 44px;
  margin-right: 15px;

  .MuiOutlinedInput-input {
    padding: 6px 4px 7px 12px;
    text-align: right;
  }
`

const NoLimitText = styled.span`
  white-space: nowrap
`

const AmountComponent = ({ row, onResourceUpdate: handleResourceUpdate }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const [value, setValue] = useState(row.amount || 0)

  const handleBlur = async ({ target: { value: inputValue } }) => {
    if (inputValue === '') {
      setValue(0)
    } else if (inputValue < 0) {
      setValue(0)
      addSnackbar(t('inventory.errors.negativeValue'))
    } else {
      const amountDifference = inputValue - row.amount
      await handleResourceUpdate(row.productAmountId, amountDifference)
    }
  }

  useEffect(() => {
    if (row.amount !== value && row.amount !== null) {
      setValue(row.amount)
    }
  }, [row.amount]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {row.amount === null
        ? <NoLimitText>{t('inventory.noLimit')}</NoLimitText>
        : (
          <StyledTextField
            value={value}
            type="number"
            onChange={event => setValue(event.target.value)}
            onBlur={handleBlur}
          />
        )}
    </div>
  )
}

AmountComponent.propTypes = {
  row: PropTypes.shape({
    amount: PropTypes.number,
    productAmountId: IdPropType,
  }).isRequired,
  onResourceUpdate: PropTypes.func,
}

AmountComponent.defaultProps = {
  onResourceUpdate: () => {},
}

export default AmountComponent
