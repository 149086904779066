import TIME_SCALE from '../../constants/timeScale'

const trimDownPeriod = (customStart, timeScale) => {
  const dateCopy = new Date(customStart.toString())

  switch (timeScale) {
    case TIME_SCALE.HOUR:
      dateCopy.setMinutes(0)
      dateCopy.setSeconds(0)
      break
    case TIME_SCALE.DAY:
    case TIME_SCALE.WEEK:
      dateCopy.setHours(0)
      dateCopy.setMinutes(0)
      dateCopy.setSeconds(0)
      break
    default: break
  }

  return dateCopy
}

export default trimDownPeriod
