import { useTranslation } from 'react-i18next'
import { Card, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { BLACK } from '../../../constants/colors'
import CATEGORIES from '../../../constants/clubNavigationCategories'
import LinkRouter from '../../navigation/LinkRouter'

const StyledCard = styled(Card)`
  grid-area: navigation;
`

const StyledCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
`

const StyledLinkRouter = styled(LinkRouter)`
  color: ${BLACK};
`

const ClubNavigation = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <StyledCard>
      <StyledCardContent>
        {CATEGORIES.map(({ route, label }) =>
          <StyledLinkRouter
            key={route}
            to={`/clubs/${id}/${route}`}
          >
            <Typography>{t(label)}</Typography>
          </StyledLinkRouter>)}
      </StyledCardContent>
    </StyledCard>
  )
}

export default ClubNavigation
