import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyles = createGlobalStyle(({ theme }) => `
  body {
    background: ${theme.palette.background.default};
    font-family: 'Montserrat', sans-serif;
  }
  button {
    font-family: 'Montserrat', sans-serif;
  }
  input {
    font-family: 'Montserrat', sans-serif;
  }

  ::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 1s all ease;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`)

export default GlobalStyles
