import PropTypes from 'prop-types'
import IdPropType from './IdPropType'

const UserPropType = PropTypes.shape({
  id: IdPropType.isRequired,
  enabled: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: IdPropType.isRequired,
      name: PropTypes.string.isRequired,
      location: PropTypes.shape({
        city: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
})

export default UserPropType
