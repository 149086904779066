import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components/macro'

const StyledButton = styled.button`
  padding: 13px;
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 800;
  color: white;
  letter-spacing: 0.2px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid black;
  outline: none;
  background-image: linear-gradient(-90deg, #fdb388, #f86675);
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`
const LoaderRoot = styled.div`
  display: inline-block;
  margin-right: 6px;
  position: relative;
  transform: translateY(3px);
`
const StyledCircularProgress = styled(CircularProgress)`
  color: #fff;
`

const LogInButton = ({ children, ...props }) => {
  const { isLoading } = props
  return (
    <StyledButton {...props} type="submit">
      {isLoading ? (
        <LoaderRoot>
          <StyledCircularProgress size={15} thickness={4.9} />
        </LoaderRoot>
      ) : null}
      {children}
    </StyledButton>
  )
}

LogInButton.defaultProps = {
  children: null,
  isLoading: false,
}

LogInButton.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}

export default LogInButton
