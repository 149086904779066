import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import ChartTooltip from '../../chart/ChartTooltip'

const OrdersTooltip = ({ timeScaleName, active, payload }) => {
  const { t } = useTranslation()
  const { ordersCount, tipsCount, date, timeScale } = get(payload, '[0].payload', {})
  const tooltipRows = [
    {
      label: t('statistics.orders'),
      value: ordersCount ?? '',
    },
    {
      label: t('statistics.tips'),
      value: tipsCount ?? '',
    },
    {
      label: timeScaleName,
      value: timeScale || '',
    },
  ]

  return active ? <ChartTooltip header={date} rows={tooltipRows} /> : null
}

OrdersTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      ordersCount: PropTypes.number,
      tipsCount: PropTypes.number,
      date: PropTypes.string,
      timeScale: PropTypes.string,
    }),
  ),
  active: PropTypes.bool,
  timeScaleName: PropTypes.string,
}

OrdersTooltip.defaultProps = {
  payload: [],
  active: false,
  timeScaleName: '',
}

export default OrdersTooltip
