import PropTypes from 'prop-types'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Route } from 'react-router-dom'
import { combineProviders } from 'react-combine-providers'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'
import { QueryParamProvider } from 'use-query-params'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { I18nProvider, LoadingProvider } from '@itsilesia/udrink-common-components'
import { SWRConfig } from 'swr'

import { darkTheme as theme } from '../themes'
import store, { persistor } from '../store'
import OrderPreparationPlaceContextProvider from './OrderPreparationPlaceContextProvider'
import OrderCollectPlaceContextProvider from './OrderCollectPlaceContextProvider'
import OrderDeliveryPlaceContextProvider from './OrderDeliveryPlaceContextProvider'
import pl from '../i18n/pl'
import en from '../i18n/en'
import fetch from '../services/fetch'
import ClubContextProvider from '../components/ClubContext'

const AppProviders = ({ children }) => {
  const providers = combineProviders()

  providers.push(StoreProvider, { store })
  providers.push(PersistGate, { persistor, loading: null })
  providers.push(I18nProvider, {
    resources: {
      pl,
      en,
    },
    customize: () => {},
  })
  providers.push(BrowserRouter)
  providers.push(MuiThemeProvider, { theme })
  providers.push(StyledThemeProvider, { theme })
  providers.push(SWRConfig, {
    value: {
      provider: () => new Map(),
      fetcher: async key => fetch.get(key).then(response => response.data),
    },
  })
  providers.push(QueryParamProvider, { ReactRouterRoute: Route })
  providers.push(LoadingProvider)
  providers.push(OrderPreparationPlaceContextProvider)
  providers.push(OrderCollectPlaceContextProvider)
  providers.push(OrderDeliveryPlaceContextProvider)
  providers.push(ClubContextProvider)

  const MasterProvider = providers.master()

  return <MasterProvider>{children}</MasterProvider>
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProviders
