import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import OperatingBoardSvg from '../assets/images/operating_board.svg'
import RouteContainer from '../components/layout/RouteContainer'

const Image = styled.img`
  width: 100%;
  min-width: 700px;
  padding: 0 100px;
`

const TextHelper = styled.span`
  font-size: 22px;
  text-align: center;
  transform: translateY(-140%);
  margin-top: 100px;
`

const StyledRouteContainer = styled(RouteContainer)`
  padding-top: 10px;
`

const HomePageScreen = () => {
  const { t } = useTranslation()

  return (
    <StyledRouteContainer justifyContent="start">
      <Image src={OperatingBoardSvg} alt="" />
      <TextHelper>{t('homePage.message')}</TextHelper>
    </StyledRouteContainer>
  )
}

export default HomePageScreen
