import getNextMenuState from './getNextMenuState'
import getNextMenuStructureState from './getNextMenuStructureState'

import {
  CHANGE_ORDER,
  UPDATE_PRODUCT_EVENT,
  CREATE_PRODUCT_EVENT,
  DELETE_PRODUCT_EVENT,
  CHANGE_SUBCATEGORY_ORDER,
  CREATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  ADD_MODIFIER,
  REMOVE_MODIFIER,
} from '../constants/menuEditEvents'
import mapToProductDto from './mapToProductDto'

const reduceItemsEvents = (eventsPipe) => {
  const events = []

  eventsPipe.forEach((event) => {
    const { payload } = event

    if (getNextMenuState.actions.createProduct.match(event)) {
      const { id: tempId, subcategoryUuid, ...data } = payload
      events.push({
        eventType: CREATE_PRODUCT_EVENT,
        subcategoryUuid,
        productCreateDto: {
          available: true,
          ...mapToProductDto(data),
        },
      })
    }

    if (getNextMenuState.actions.updateProduct.match(event)) {
      const { id, ...rest } = payload
      events.push({
        eventType: UPDATE_PRODUCT_EVENT,
        productUuid: id,
        productUpdateDto: mapToProductDto(rest),
      })
    }

    if (getNextMenuState.actions.deleteProduct.match(event)) {
      const id = payload
      events.push({
        eventType: DELETE_PRODUCT_EVENT,
        productUuid: id,
      })
    }

    if (getNextMenuState.actions.reorderProducts.match(event)) {
      const { uuid, newOrderNumber } = payload
      events.push({
        eventType: CHANGE_ORDER,
        productUuid: uuid,
        newOrderNumber,
      })
    }

    if (getNextMenuStructureState.actions.createSubcategory.match(event)) {
      const { name, namePl, uuid, categoryId } = payload
      events.push({
        eventType: CREATE_SUBCATEGORY,
        subcategoryCreateDto: {
          name,
          namePl,
          uuid,
          categoryId,
        },
      })
    }

    if (getNextMenuStructureState.actions.updateSubcategory.match(event)) {
      const { name, namePl, subcategoryUuid } = payload
      events.push({
        eventType: UPDATE_SUBCATEGORY,
        subcategoryUuid,
        subcategoryUpdateDto: {
          name,
          namePl,
        },
      })
    }

    if (getNextMenuStructureState.actions.deleteSubcategory.match(event)) {
      const subcategoryUuid = payload
      events.push({
        eventType: DELETE_SUBCATEGORY,
        subcategoryUuid,
      })
    }

    if (getNextMenuStructureState.actions.reorderSubcategory.match(event)) {
      const { subcategoryUuid, newOrderNumber } = payload
      events.push({
        eventType: CHANGE_SUBCATEGORY_ORDER,
        subcategoryUuid,
        newOrderNumber,
      })
    }

    if (getNextMenuState.actions.addModifier.match(event)) {
      const { productUuid, modifierUuid } = payload

      events.push({
        eventType: ADD_MODIFIER,
        productUuid,
        modifierUuid,
      })
    }

    if (getNextMenuState.actions.removeModifier.match(event)) {
      const { productUuid, modifierUuid } = payload

      events.push({
        eventType: REMOVE_MODIFIER,
        productUuid,
        modifierUuid,
      })
    }
  })

  return events.map((event) => {
    const { productCreateDto, productUpdateDto } = event

    if (productUpdateDto?.description === '') {
      productUpdateDto.description = null
    }
    if (productCreateDto?.description === '') {
      productCreateDto.description = null
    }

    return event
  })
}

export default reduceItemsEvents
