import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Container } from '@material-ui/core'
import omitProps from '../../utils/omitProps'

const RouteContainer = styled(omitProps('fillHeight', 'justifyContent')(Container))`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ theme }) => theme.spacing(3)}px ${({ theme }) => theme.spacing(6)}px;
  height: ${({ fillHeight }) => fillHeight && '100%'};
`
RouteContainer.defaultProps = {
  maxWidth: 'lg',
  fillHeight: true,
  justifyContent: 'center',
}

RouteContainer.propTypes = {
  maxWidth: PropTypes.string,
  fillHeight: PropTypes.bool,
  justifyContent: PropTypes.oneOf([
    'start',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'flex-start',
    'flex-end',
    'inherit',
  ]),
}

export default RouteContainer
