const yAxisConfig = {
  width: 50,
}

const CHAR_CONFIG = Object.freeze({
  YAxis: yAxisConfig,
})

export { yAxisConfig }

export default CHAR_CONFIG
