import omit from 'lodash/omit'

// eslint-disable-next-line react/display-name
const omitProps = (...propsToExtract) => Component => (props) => {
  const [firstPropToExtract] = propsToExtract
  if (Array.isArray(firstPropToExtract)) {
    // eslint-disable-next-line no-param-reassign
    propsToExtract = firstPropToExtract
  }
  const rest = omit(props, propsToExtract)
  return <Component {...rest} />
}

export default omitProps
