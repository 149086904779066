import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { Formik } from 'formik'

import { CREATE, UPDATE } from '../constants/formModes'
import orderDeliveryPlaceValidationSchema from '../validations/orderDeliveryPlaceSchema'
import useOrderDeliveryPlaceForm from '../hooks/useOrderDeliveryPlaceForm'
import useSnackbar from '../hooks/useSnackbar'
import OrderDeliveryPlaceForm from '../components/forms/OrderDeliveryPlaceForm'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import { ADMIN_ROLE, MANAGER_ROLE } from '../constants/roleGroups'
import RestrictedContent from '../components/auth/RestrictedContent'
import RouteHeader from '../components/layout/RouteHeader'
import useHasRole from '../hooks/useHasRole'

const OrderDeliveryPlaceScreen = () => {
  const { t } = useTranslation()
  const { clubId, id } = useParams()
  const history = useHistory()
  const { addSnackbar } = useSnackbar()
  const isAdmin = useHasRole(ADMIN_ROLE)

  const { initialValues, handleSubmit } = useOrderDeliveryPlaceForm({
    id,
    clubId,
    onSuccess: () => {
      // TODO go to details screen
      addSnackbar(t(`orderDelivery.success.${id ? 'update' : 'create'}`))
      history.replace(isAdmin ? `/clubs/${clubId}/order-delivery-places` : '/order-delivery-places')
    },
    onError: (err) => {
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    },
  })

  const MODE = id ? UPDATE : CREATE

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={orderDeliveryPlaceValidationSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <RouteContainer>
          <RestrictedContent accessRole={ADMIN_ROLE}>
            <NavigationBreadcrumbs />
          </RestrictedContent>
          <RestrictedContent accessRole={MANAGER_ROLE}>
            <RouteHeader>
              {t(`orderDelivery.${MODE === CREATE ? 'createTitle' : 'editTitle'}`)}
            </RouteHeader>
          </RestrictedContent>
          <OrderDeliveryPlaceForm
            {...props}
            mode={MODE}
            onCancel={history.goBack}
          />
        </RouteContainer>
      )}
    </Formik>
  )
}

export default OrderDeliveryPlaceScreen
