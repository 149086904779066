import { useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import styled from 'styled-components/macro'
import { useClubs, useTrackLoadingStatus, useUser } from '@itsilesia/udrink-common-components'

import { updateUser } from '../../../services/api'
import { UserFormClubs, UserFormSimple } from '../../../components/forms/UserForm'
import { ADMIN_ROLE, MANAGER_ROLE, BARTENDER_ROLE } from '../../../constants/roleGroups'
import { UPDATE } from '../../../constants/formModes'
import useHasRole from '../../../hooks/useHasRole'
import useSnackbar from '../../../hooks/useSnackbar'
import RouteContainer from '../../../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../../../components/navigation/NavigationBreadcrumbs'
import RestrictedContent from '../../../components/auth/RestrictedContent'
import RouteHeader from '../../../components/layout/RouteHeader'

import mapUserInitialValues from './mapUserInitialValues'

// this component keeps the breadcrumbs at the top when user data is loading
const Placeholder = styled.div`
  flex: 1;
`

const UserEditScreen = () => {
  const { addSnackbar } = useSnackbar()
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  const isAdmin = useHasRole(ADMIN_ROLE)

  const { data: clubsData, isValidating: isClubValidating } = useClubs()
  const { content: clubsContent } = clubsData || {}
  const clubs = useMemo(() => clubsContent || [], [clubsContent])

  const { data: user, mutate, isValidating: isUserValidating } = useUser(id)

  useTrackLoadingStatus('clubs', isClubValidating)
  useTrackLoadingStatus('user', isUserValidating)

  const initialValues = useMemo(() => mapUserInitialValues(user), [user])
  const role = get(user, 'roleGroup.name')

  const handleSubmit = async (values) => {
    try {
      await mutate(async () => updateUser(id, values, user.clubs), false)
      history.replace(isAdmin ? '/users' : '/workers')
      addSnackbar(t('user.screen.edit.success'))
    } catch (err) {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    }
  }

  /* eslint-disable react/display-name */
  const userForm = {
    [ADMIN_ROLE]: () => <UserFormSimple
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mode={UPDATE}
    />,
    [MANAGER_ROLE]: () => <UserFormClubs
      initialValues={initialValues}
      clubs={clubs}
      onSubmit={handleSubmit}
      mode={UPDATE}
    />,
    [BARTENDER_ROLE]: () => <UserFormClubs
      initialValues={initialValues}
      clubs={clubs}
      onSubmit={handleSubmit}
      mode={UPDATE}
    />,
  }
  /* eslint-enable react/display-name */

  return (
    <RouteContainer maxWidth="md">
      <RestrictedContent accessRole={ADMIN_ROLE}>
        <NavigationBreadcrumbs />
      </RestrictedContent>
      <RestrictedContent accessRole={MANAGER_ROLE}>
        <RouteHeader>{t('workers.edit')}</RouteHeader>
      </RestrictedContent>
      {role ? userForm[role]() : <Placeholder />}
    </RouteContainer>
  )
}

export default UserEditScreen
