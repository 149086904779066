import ROLE_GROUPS from '../../../constants/roleGroups'

const mapUserInitialValues = (user) => {
  if (!user) {
    return null
  }

  const {
    username: email,
    roleGroup: { name: role },
    clubs: userClubs,
    profile: userProfile,
  } = user

  const {
    name,
    surname,
  } = userProfile

  const userFlattenedData = {
    name: name || '',
    surname: surname || '',
    email,
    password: '',
  }

  if (role === ROLE_GROUPS.BARTENDER.value || role === ROLE_GROUPS.MANAGER.value) {
    return {
      ...userFlattenedData,
      club: userClubs.map(({ id }) => id),
    }
  }

  return userFlattenedData
}

export default mapUserInitialValues
