import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useOrderCollectPlace } from '@itsilesia/udrink-common-components'
import styled from 'styled-components/macro'
import { Field } from 'formik'
import {
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Divider,
  Switch,
  Tooltip,
} from '@material-ui/core'

import { UPDATE, CREATE } from '../../../constants/formModes'
import OrderCollectPlacesDialog from './OrderCollectPlacesDialog'
import {
  TextField,
  TextArea,
  Select,
} from '../../inputs'
import WORK_ZONES from '../../../constants/workZones'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import FormFooter from '../FormFooter'
import StyledFormikForm from '../StyledFormikForm'
import FormModePropType from '../../../propTypes/FormModePropType'
import PropEntity from '../PropEntity'
import AlertDialog from '../../AlertDialog'

const FormFields = styled(FormStyleWrapper)`
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField)(({ theme }) => `
  margin: ${theme.spacing(1)}px 0;
`)

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`

const OrderPreparationPlaceForm = ({
  mode,
  values,
  handleChange,
  setFieldValue,
  isSubmitting,
  onCancel,
  clubId,
  status,
  setStatus,
}) => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertCallback, setAlertCallback] = useState(() => () => {})

  const confirmMessageKey = useMemo(
    () => (mode === UPDATE ? 'common.update' : 'common.save'),
    [mode],
  )

  const { isDeviceConnected } = values

  const { data: orderCollectPlace } = useOrderCollectPlace(values.collectOrderPlaceId)
  const OrderCollectPlaceName = useMemo(
    () => orderCollectPlace?.name || '',
    [orderCollectPlace],
  )

  useEffect(() => {
    if (!OrderCollectPlaceName) {
      setStatus({ orderCollectPlaceNameError: t('orderPreparation.errors.orderCollectPlace.required') })
    } else {
      setStatus({ orderCollectPlaceNameError: undefined })
    }
    /* eslint-disable-next-line */
  }, [OrderCollectPlaceName])

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])

  const handleDeviceConnectionChange = (event) => {
    const isChecked = event.target.checked
    if (isChecked) {
      setFieldValue('isDeviceConnected', isChecked)
    } else {
      setIsAlertOpen(true)
      setAlertCallback(() => () => setFieldValue('isDeviceConnected', isChecked))
    }
  }

  const handleAlertConfirm = async () => {
    alertCallback()
    setIsAlertOpen(false)
  }

  return (
    <StyledFormikForm>
      <FormFields>
        <Field
          formatError={t}
          label={t('orderPreparation.name')}
          name="name"
          component={StyledTextField}
        />
        <FormControl>
          <InputLabel variant="outlined" htmlFor="workZone">{t('orderPreparation.zone')}</InputLabel>
          <Field
            label={t('orderPreparation.zone')}
            name="workZone"
            inputProps={{ id: 'workZone' }}
            onChange={handleChange}
            component={Select}
          >
            {WORK_ZONES.map(({ value, code }) => (
              <MenuItem key={value} value={value}>
                {t(code)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Row>
          <PropEntity
            label={t('orderPreparation.oneOrderCollect')}
            error={status.orderCollectPlaceNameError}
            value={OrderCollectPlaceName}
          />
          <Field
            name="collectOrderPlaceId"
            clubId={clubId}
            open={dialogOpen}
            handleClose={handleDialogClose}
            component={OrderCollectPlacesDialog}
          />
          <Button
            variant="outlined"
            onClick={() => setDialogOpen(true)}
          >
            {t('common.select')}
          </Button>
        </Row>
        {mode === UPDATE && (
          <>
            <Divider />
            <Row>
              <PropEntity
                value={isDeviceConnected ? t('orderPlace.deviceIsConnected') : t('orderPlace.deviceIsNotConnected')}
                alert={!isDeviceConnected && values.deviceConnectionId ? t('orderPlace.alert.info') : ''}
              />
              <Tooltip
                title={isDeviceConnected ? t('orderPlace.disconnectDevice') : t('orderPlace.connectDevice')}
                aria-label={isDeviceConnected ? t('orderPlace.disconnectDevice') : t('orderPlace.connectDevice')}
              >
                <Switch
                  onChange={handleDeviceConnectionChange}
                  checked={isDeviceConnected}
                  disabled={!values.deviceConnectionId}
                  color="primary"
                />
              </Tooltip>
              <AlertDialog
                onConfirm={handleAlertConfirm}
                onCancel={() => setIsAlertOpen(false)}
                message={t('orderPreparation.alert.message')}
                title={t('orderPlace.alert.title')}
                confirmText={t('orderPlace.alert.removeConnection')}
                confirmColor="secondary"
                isOpen={isAlertOpen}
              />
            </Row>
          </>
        )}
        <Field
          formatError={t}
          label={t('common.description')}
          name="description"
          component={TextArea}
        />
      </FormFields>
      <FormFooter
        onCancel={onCancel}
        disabled={isSubmitting}
        confirmMessageKey={confirmMessageKey}
      />
    </StyledFormikForm>
  )
}

OrderPreparationPlaceForm.defaultProps = {
  mode: CREATE,
  onCancel: () => {},
  status: {},
}

OrderPreparationPlaceForm.propTypes = {
  status: PropTypes.object,
  setStatus: PropTypes.func.isRequired,
  mode: FormModePropType,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  clubId: PropTypes.string.isRequired,
}

export default OrderPreparationPlaceForm
