import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'

const mapUserValues = ({
  name,
  surname,
  email,
  password,
  role,
  enabled,
  adult,
}) => ({
  ...(name && { name }),
  ...(surname && { surname }),
  ...(email && { username: email }),
  ...(password && { password }),
  ...(role && { roleGroup: role }),
  ...(enabled && { enabled }),
  ...(adult && { adult }),
})

const updateUser = async (id, {
  name,
  surname,
  email,
  password,
  role,
  club,
  enabled = true,
  adult = true,
}, currentUserClubs) => {
  await fetch(API.users.update(id, mapUserValues({
    name,
    surname,
    email,
    password,
    role,
    enabled,
    adult,
  })))

  let userClubs = Array.isArray(club) ? club : [club]
  userClubs = userClubs.filter(userClub => typeof userClub === 'number')
  const currentUserClubsIds = currentUserClubs.map(singleClub => singleClub.id)
  const clubsToRemove = currentUserClubsIds.filter(singleClub => !userClubs.includes(singleClub))
  const clubsToAdd = userClubs.filter(singleClub => !currentUserClubsIds.includes(singleClub))

  await Promise.all(
    clubsToRemove.map(clubId => fetch(API.clubUsersRelations.delete(id, clubId))),
  )

  await Promise.all(
    clubsToAdd.map(clubId => fetch(API.clubUsersRelations.create({ userId: id, clubId }))),
  )
}

export default updateUser
