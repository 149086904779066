import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Close } from '@material-ui/icons'

const MuiDialogTitle = styled(DialogTitle)(({ theme }) => `
  &.MuiDialogTitle-root {
    text-align: center;
    padding-bottom: ${theme.spacing(1)}px;
  }
`)

const MuiDialogContent = styled(DialogContent)(({ theme }) => `
  &.MuiDialogContent-root {
    text-align: center;
    margin-bottom: ${theme.spacing(4)}px;
  }
`)

const DiscountCode = styled(Typography)(({ theme }) => `
    text-align: center;
    font-size: 35px;
    font-weight: 1000;
    margin-bottom: ${theme.spacing(2)}px;
`)

const MuiDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    width: 500px;
  }
`

const DialogContainer = styled.div(({ theme }) => `
    padding: 0 ${theme.spacing(8)}px ${theme.spacing(1)}px ${theme.spacing(8)}px;
`)

const CloseIcon = styled(IconButton)`
    align-self: end;
`

const DiscountCodePreviewDialog = ({ handleClose, isOpen, discountCode }) => {
  const { t } = useTranslation()

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseIcon
        aria-label="close"
        onClick={handleClose}
      >
        <Close />
      </CloseIcon>
      <DialogContainer>
        <MuiDialogTitle id="alert-dialog-title">
          {`${t('discountCodes.discountCode')}:`}
        </MuiDialogTitle>
        <MuiDialogContent>
          <DiscountCode>{discountCode}</DiscountCode>
        </MuiDialogContent>
      </DialogContainer>
    </MuiDialog>
  )
}

DiscountCodePreviewDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  discountCode: PropTypes.string.isRequired,
}

export default DiscountCodePreviewDialog
