import { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTaxRates } from '@itsilesia/udrink-common-components'

import SubcategoryItemsContainer from './SubcategoryItemsContainer'
import { FormStyleWrapper } from '../../wrappers'

const FlexFormStyleWrapper = styled(FormStyleWrapper)`
  flex: 1;
`

const CategoryWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'block' : 'none')};
`

const SubcategoriesContainer = ({
  groupedByCategories,
  activeCategory,
  isLoading,
  isMenuDisabled,
  subcategoriesToDeleteImage,
  pushEvent,
  onProductEditDialogOpen: handleProductEditDialogOpen,
  onProductDelete: handleProductDelete,
  onProductEdit: handleProductEdit,
  onProductCreate: handleProductCreate,
  onSubcategoryDelete: handleSubcategoryDelete,
  onSubcategoryEditDialogOpen: handleSubcategoryEditDialogOpen,
  onSubcategoryReorder: handleSubcategoryReorder,
  onProductModifierAdd: handleProductModifierAdd,
  onProductModifierRemove: handleProductModifierRemove,
}) => {
  const { data: taxRatesList } = useTaxRates()

  const getIsSubcategoryImageDeleted = uuid => subcategoriesToDeleteImage.find(subcategory =>
    subcategory.subcategoryUuid === uuid)

  return (
    <FlexFormStyleWrapper>
      {Object.entries(groupedByCategories).map(([key, category]) => (
        <CategoryWrapper hidden={key === activeCategory} key={key}>
          {category.subcategories.map((
            { uuid, name, namePl, imageUrl, products, position },
            index,
            subcategories,
          ) => (
            <SubcategoryItemsContainer
              index={index}
              position={position}
              isFirst={!index}
              isLast={subcategories.length - 1 === index}
              key={name}
              name={name}
              namePl={namePl}
              imageUrl={imageUrl}
              subcategoryUuid={uuid}
              products={products}
              isLoading={isLoading}
              isSubcategoryImageDeleted={getIsSubcategoryImageDeleted(uuid)}
              taxRatesList={taxRatesList}
              isMenuDisabled={isMenuDisabled}
              pushEvent={pushEvent}
              onProductEdit={handleProductEdit}
              onProductEditDialogOpen={handleProductEditDialogOpen}
              onProductDelete={handleProductDelete}
              onProductCreate={handleProductCreate}
              onSubcategoryDelete={handleSubcategoryDelete}
              onSubcategoryEditDialogOpen={handleSubcategoryEditDialogOpen}
              onSubcategoryReorder={handleSubcategoryReorder}
              onProductModifierAdd={handleProductModifierAdd}
              onProductModifierRemove={handleProductModifierRemove}
            />
          ))}
        </CategoryWrapper>
      ))}
    </FlexFormStyleWrapper>
  )
}

SubcategoriesContainer.propTypes = {
  groupedByCategories: PropTypes.object.isRequired,
  activeCategory: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isMenuDisabled: PropTypes.bool,
  subcategoriesToDeleteImage: PropTypes.array.isRequired,
  pushEvent: PropTypes.func.isRequired,
  onProductDelete: PropTypes.func,
  onProductEdit: PropTypes.func,
  onProductEditDialogOpen: PropTypes.func,
  onProductCreate: PropTypes.func,
  onSubcategoryDelete: PropTypes.func,
  onSubcategoryEditDialogOpen: PropTypes.func,
  onSubcategoryReorder: PropTypes.func,
  onProductModifierAdd: PropTypes.func,
  onProductModifierRemove: PropTypes.func,
}

SubcategoriesContainer.defaultProps = {
  isLoading: false,
  isMenuDisabled: false,
  onProductDelete: () => {},
  onProductEdit: () => {},
  onProductEditDialogOpen: () => {},
  onProductCreate: () => {},
  onSubcategoryReorder: () => {},
  onSubcategoryEditDialogOpen: () => {},
  onSubcategoryDelete: () => {},
  onProductModifierAdd: () => {},
  onProductModifierRemove: () => {},
}

export default memo(SubcategoriesContainer)
