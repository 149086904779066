import qs from 'qs'

const sendUserFeedback = async ({
  comments,
  email,
  eventId,
  name,
}) => {
  const url = `https://sentry.io/api/embed/error-page/?dsn=${process.env.REACT_APP_SENTRY_DSN}&eventId=${eventId}`

  const { status } = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qs.stringify({
      comments,
      email,
      name,
    }),
  })

  return status === 200
}

export { sendUserFeedback }
