const CATEGORIES = [
  {
    route: 'menu',
    label: 'drawer.navigation.menu',
  },
  {
    route: 'modifiers',
    label: 'drawer.navigation.modifiers',
  },
  {
    route: 'inventory',
    label: 'drawer.navigation.inventory',
  },
  {
    route: 'discount-codes',
    label: 'drawer.navigation.discountCodes',
  },
  {
    route: 'order-preparation-places',
    label: 'drawer.navigation.orderPreparation',
  },
  {
    route: 'order-collect-places',
    label: 'drawer.navigation.orderCollect',
  },
  {
    route: 'order-delivery-places',
    label: 'drawer.navigation.orderDelivery',
  },
]

export default CATEGORIES
