import { useMemo, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { OrderPreparationPlaceContext } from '../../../providers/OrderPreparationPlaceContextProvider'
import { deleteOrderPreparationPlace } from '../../../services/api/orderPreparationPlaces'
import useSnackbar from '../../../hooks/useSnackbar'
import IdPropType from '../../../propTypes/IdPropType'
import DataTable from '../../data/DataTable'
import QrCodeDialog from '../../QrCodeDialog'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog'

import columns from './OrderPreparationPlacesList.columns'

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const OrderPreparationPlacesList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
  clubId,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const {
    orderPreparationPlaceUuid,
    orderPreparationPlaceId,
    isDeleteOrderPreparationPlaceDialogOpen,
    setOrderPreparationPlaceUuid,
    setIsDeleteOrderPreparationPlaceDialogOpen,
  } = useContext(OrderPreparationPlaceContext)
  const history = useHistory()

  const clubPreparationPlaces = useMemo(
    () => data.map(preparationPlace => ({ ...preparationPlace, clubId })),
    [data, clubId],
  )

  const clubPreparationPlaceData = useMemo(
    () => clubPreparationPlaces.find(place => place.id === orderPreparationPlaceId),
    [clubPreparationPlaces, orderPreparationPlaceId],
  )

  const handleDeleteOrderPreparationPlace = async () => {
    try {
      await deleteOrderPreparationPlace(orderPreparationPlaceId)
      addSnackbar(t('orderPreparation.success.delete'))
      setIsDeleteOrderPreparationPlaceDialogOpen(false)
      history.replace('/order-preparation-places')
    } catch (err) {
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    }
  }

  return (
    <>
      <TableOptions>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/clubs/${clubId}/order-preparation-places/create`}
          startIcon={<AddIcon />}
        >
          {t('common.create')}
        </Button>
      </TableOptions>
      <DataTable
        data={clubPreparationPlaces}
        columns={columns}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
      <QrCodeDialog
        uuid={orderPreparationPlaceUuid}
        open={!!orderPreparationPlaceUuid}
        onClose={() => setOrderPreparationPlaceUuid(null)}
      />
      {clubPreparationPlaceData && (
        <DeleteConfirmationDialog
          isOpen={isDeleteOrderPreparationPlaceDialogOpen}
          titleText={t('orderPreparation.dialog.deleteOrderPreparationPlaceAlertTitle', { name: clubPreparationPlaceData.name })}
          onCancel={() => setIsDeleteOrderPreparationPlaceDialogOpen(false)}
          onConfirm={() => handleDeleteOrderPreparationPlace()}
        />
      )}
    </>
  )
}

OrderPreparationPlacesList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
}

OrderPreparationPlacesList.propTypes = {
  clubId: IdPropType.isRequired,
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default OrderPreparationPlacesList
