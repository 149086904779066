/* eslint-disable import/no-import-module-exports */
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

const render = () => {
  /* eslint-disable-next-line global-require */
  const App = require('./App').default

  ReactDOM.render(<App />, document.getElementById('root'))
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
