const PAGE_TITLES = Object.freeze([
  {
    path: '/',
    code: 'drawer.navigation.mainSite',
    noMatchPattern: [],
  },
  {
    path: [
      '/clubs',
      '/clubs/:clubId',
      '/clubs/:clubId/edit',
      '/clubs/create',
    ],
    code: 'drawer.navigation.clubs',
    noMatchPattern: [],
  },
  {
    path: [
      '/users',
      '/users/*',
    ],
    code: 'drawer.navigation.users',
    noMatchPattern: [],
  },
  {
    path: '/tax-rates',
    code: 'drawer.navigation.taxRates',
    noMatchPattern: [],
  },
  {
    path: [
      '/order-collect-places',
      '/clubs/:clubId/order-collect-places',
      '/clubs/:clubId/order-collect-places/*',
    ],
    code: 'drawer.navigation.orderCollect',
    noMatchPattern: [],
  },
  {
    path: [
      '/order-preparation-places',
      '/clubs/:clubId/order-preparation-places',
      '/clubs/:clubId/order-preparation-places/*',
    ],
    code: 'drawer.navigation.orderPreparation',
    noMatchPattern: [],
  },
  {
    path: [
      '/order-delivery-places',
      '/clubs/:clubId/order-delivery-places',
      '/clubs/:clubId/order-delivery-places/*',
    ],
    code: 'drawer.navigation.orderDelivery',
    noMatchPattern: [],
  },
  {
    path: '/clubs/:clubId/products',
    code: 'drawer.navigation.products',
    noMatchPattern: [],
  },
  {
    path: [
      '/menu',
      '/clubs/:clubId/menu',
    ],
    code: 'drawer.navigation.menu',
    noMatchPattern: [],
  },
  {
    path: [
      '/inventory',
      '/clubs/:clubId/inventory',
    ],
    code: 'drawer.navigation.inventory',
    noMatchPattern: [],
  },
  {
    path: [
      '/modifiers',
      '/clubs/:clubId/modifiers',
    ],
    code: 'drawer.navigation.modifiers',
    noMatchPattern: [],
  },
  {
    path: [
      '/discount-codes',
      '/clubs/:clubId/discount-codes',
      '/clubs/:clubId/discount-codes/*',
    ],
    code: 'drawer.navigation.discountCodes',
    noMatchPattern: [],
  },
  {
    path: '/statistics',
    code: 'drawer.navigation.statistics',
    noMatchPattern: [],
  },
  {
    path: '/workers',
    code: 'drawer.navigation.workers',
    noMatchPattern: [],
  },
  {
    path: [
      '/data',
      '/data/*',
    ],
    code: 'drawer.navigation.data',
    noMatchPattern: [],
  },
  {
    path: [
      '/open-hours',
      '/open-hours/*',
    ],
    code: 'drawer.navigation.hours',
    noMatchPattern: [],
  },
  {
    path: '/reset-password/:token',
    code: 'common.titles.passwordRecovery',
    noMatchPattern: [],
  },
])

export default PAGE_TITLES
