import * as yup from 'yup'

const subcategorySchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('subcategory.errors.required.name'),
  namePl: yup
    .string()
    .trim()
    .required('subcategory.errors.required.name'),
})

export {
  subcategorySchema,
}
