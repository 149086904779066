import { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { Collapse, FormControl, InputLabel, Select, Tooltip, Switch } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useUsers } from '@itsilesia/udrink-common-components'

import useCollapse from '../../../../../hooks/useCollapse'
import StyledTextField from '../../StyledTextField'
import SectionBody from './section/SectionBody'
import SectionHeader from './section/SectionHeader'
import SectionContent from './section/SectionContent'
import AlertDialog from '../../../../AlertDialog'
import { UPDATE, CREATE } from '../../../../../constants/formModes'
import FormModePropType from '../../../../../propTypes/FormModePropType'
import { MANAGER_ROLE } from '../../../../../constants/roleGroups'
import PropEntity from '../../../PropEntity'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const GeneralSection = ({ values, setFieldValue, mode }) => {
  const { t } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse(false)
  const [isStatusAlertOpen, setIsStatusAlertOpen] = useState(false)
  const statusValue = t(`common.${values.isActive ? 'active' : 'inactive'}`)

  const [isPrinterModeInfoVisible, setIsPrinterModeInfoVisible] = useState(false)
  const [isPrinterModeAlertOpen, setIsPrinterModeAlertOpen] = useState(false)
  const [printerModeAlertCallback, setPrinterModeAlertCallback] = useState(() => () => {})

  const { data: usersData } = useUsers({ roleGroup: MANAGER_ROLE })
  const managers = useMemo(
    () => usersData?.content || [],
    [usersData],
  )

  useEffect(() => {
    if (!values.ownerId) {
      setFieldValue('ownerId', managers[0]?.id)
    }
  }, [managers]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOwnerChange = ({ target: { value } }) => {
    setFieldValue('ownerId', value)
  }

  const handleClubStatusToggle = () => {
    setFieldValue('isActive', !values.isActive)
    setIsStatusAlertOpen(false)
  }

  const handlePrinterModeChange = ({ target: { checked } }) => {
    setIsPrinterModeAlertOpen(true)
    setPrinterModeAlertCallback(() => () => setFieldValue('isUsingPrinter', checked))
  }

  const handlePrinterModeAlertConfirm = async () => {
    printerModeAlertCallback()
    setIsPrinterModeInfoVisible(status => !status)
    setIsPrinterModeAlertOpen(false)
  }

  const isCreateMode = mode === CREATE
  const isUpdateMode = mode === UPDATE
  const statusButtonText = t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}`)
  const statusButtonColor = values.isActive ? 'secondary' : 'primary'

  const printerModeTranslationRoot = 'clubs.form.printingMode'
  const printerModeTooltipText = t(`${printerModeTranslationRoot}.set${!values.isUsingPrinter ? 'Active' : 'Inactive'}`)
  const printerModeButtonColor = values.isUsingPrinter ? 'secondary' : 'primary'

  return (
    <SectionBody>
      <SectionHeader
        title={t('clubs.form.general')}
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      />
      <Collapse in={!collapse} timeout="auto">
        <SectionContent>
          <Field
            formatError={t}
            label={t('clubs.form.name')}
            name="name"
            component={StyledTextField}
          />
          <Row>
            <StyledFormControl>
              <InputLabel variant="outlined" htmlFor="club-owner-select">
                {t('clubs.form.owner')}
              </InputLabel>
              <Select
                native
                label={t('clubs.form.owner')}
                variant="outlined"
                name="ownerId"
                inputProps={{ id: 'club-owner-select' }}
                value={values.ownerId}
                onChange={handleOwnerChange}
              >
                {managers.map(({ id, username }) => (
                  <option key={id} value={id}>
                    {username}
                  </option>
                ))}
              </Select>
            </StyledFormControl>
          </Row>
          <Field
            formatError={t}
            label={t('clubs.form.nip')}
            name="nip"
            component={StyledTextField}
          />
          <Row>
            <PropEntity
              label={t('common.status')}
              value={statusValue}
              alert={isCreateMode ? t('clubs.form.statusTextHelper') : ''}
            />
            {isUpdateMode && (
              <>
                <Tooltip
                  title={statusButtonText}
                  aria-label={statusButtonText}
                >
                  <Switch
                    onChange={() => setIsStatusAlertOpen(true)}
                    checked={values.isActive}
                    color="primary"
                  />
                </Tooltip>
                <AlertDialog
                  onConfirm={handleClubStatusToggle}
                  onCancel={() => setIsStatusAlertOpen(false)}
                  message={t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}AlertMessage`)}
                  title={t(`clubs.form.${values.isActive ? 'deactivate' : 'activate'}AlertTitle`)}
                  confirmText={statusButtonText}
                  confirmColor={statusButtonColor}
                  isOpen={isStatusAlertOpen}
                />
              </>
            )}
          </Row>
          <Row>
            <PropEntity
              label={t(`${printerModeTranslationRoot}.title`)}
              value={t(`${printerModeTranslationRoot}.${values.isUsingPrinter ? 'active' : 'inactive'}`)}
              alert={isPrinterModeInfoVisible ? t(`${printerModeTranslationRoot}.alertInfo`) : ''}
            />
            <Tooltip
              title={printerModeTooltipText}
              aria-label={printerModeTooltipText}
            >
              <Switch
                onChange={handlePrinterModeChange}
                checked={values.isUsingPrinter}
                color="primary"
              />
            </Tooltip>
            <AlertDialog
              onConfirm={handlePrinterModeAlertConfirm}
              onCancel={() => setIsPrinterModeAlertOpen(false)}
              message={t(`${printerModeTranslationRoot}.${values.isUsingPrinter ? 'inactive' : 'active'}AlertMessage`)}
              title={t(`${printerModeTranslationRoot}.${values.isUsingPrinter ? 'inactive' : 'active'}AlertTitle`)}
              confirmText={printerModeTooltipText}
              confirmColor={printerModeButtonColor}
              isOpen={isPrinterModeAlertOpen}
            />
          </Row>
        </SectionContent>
      </Collapse>
    </SectionBody>
  )
}

GeneralSection.propTypes = {
  values: PropTypes.shape({
    isActive: PropTypes.bool,
    isUsingPrinter: PropTypes.bool,
    ownerId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  setFieldValue: PropTypes.func,
  mode: FormModePropType,
}

GeneralSection.defaultProps = {
  values: {
    isActive: '',
    isUsingPrinter: true,
  },
  setFieldValue: () => {},
  mode: CREATE,
}

export default GeneralSection
