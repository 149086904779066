import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useClubProductsAmounts } from '@itsilesia/udrink-common-components'

import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import Inventory from '../components/inventory'
import useInventory from '../hooks/useInventory'

const InventoryScreen = () => {
  const { clubId } = useParams()
  const [searchValue, setSearchValue] = useState('')

  const useResource = ({ page, size }) =>
    useClubProductsAmounts(clubId, {
      page,
      size,
      sort: 'name',
      'name-filter': searchValue,
    })

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleResourceUpdate,
    handleResourceCreate,
    handleResourceDelete,
    ...props
  } = useInventory(
    `${clubId}-inventory`,
    useResource,
  )

  useEffect(() => {
    handleChangePage(0)
  }, [searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <Inventory
        {...props}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        onResourceUpdate={handleResourceUpdate}
        onResourceCreate={handleResourceCreate}
        onResourceDelete={handleResourceDelete}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default InventoryScreen
