import { PERCENT } from '../constants/discountTypes'
import { PLNToPeanuts } from './PLNToPeanuts'
import { peanutsToPLN } from './peanutsToPLN'

const mapDiscountCodeValues = (values) => {
  const {
    dateFrom,
    dateTo,
    value,
    type,
    totalDiscountPrice,
    minimumOrderPrice,
  } = values

  return {
    ...values,
    dateFrom: dateFrom.slice(0, 10),
    dateTo: dateTo.slice(0, 10),
    value: type === PERCENT ? value * 100 : value,
    ...(totalDiscountPrice && { totalDiscountPrice: peanutsToPLN(totalDiscountPrice) }),
    ...(minimumOrderPrice && { minimumOrderPrice: peanutsToPLN(minimumOrderPrice) }),
  }
}

const mapDiscountCodeToPayload = (values, isEdit, clubId) => {
  const {
    description,
    dateFrom,
    dateTo,
    totalUserAmount,
    totalAmount,
    minimumOrderPrice,
    totalDiscountPrice,
    name,
    status,
    type,
    value,
    discountCode,
  } = values
  const createPayload = {
    discountCode,
    clubId,
    dateFrom: new Date(dateFrom).toISOString(),
    dateTo: new Date(dateTo).toISOString(),
    description,
    totalAmount: totalAmount === '' ? null : totalAmount,
    totalUserAmount: totalUserAmount === '' ? null : totalUserAmount,
    totalDiscountPrice: totalDiscountPrice ? PLNToPeanuts(totalDiscountPrice) : null,
    minimumOrderPrice: minimumOrderPrice ? PLNToPeanuts(minimumOrderPrice) : null,
    name,
    status,
    type,
    value,
  }
  return createPayload
}

export { mapDiscountCodeValues, mapDiscountCodeToPayload }
