import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons/'
import styled from 'styled-components/macro'

import AddNewSubcategoryDialog from './SubcategoryDialog'
import useCollapse from '../../../hooks/useCollapse'
import stopPropagateEventFactory from '../../../utils/stopPropagateEventFactory'

const Container = styled.div`
  display: inline-block;
`

const CreateSubcategoryButton = ({ onSubcategoryCreate: handleSubcategoryCreate, disabled }) => {
  const { t } = useTranslation()
  const [isOpen, toggleIsOpen] = useCollapse(false)
  const initialValues = {
    name: '',
    namePl: '',
    imageUrl: '',
    subcategoryUuid: '',
  }

  return (
    <Container onClick={stopPropagateEventFactory()}>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleIsOpen}
        startIcon={<AddIcon />}
        disabled={disabled}
      >
        {t('subcategory.new')}
      </Button>
      <AddNewSubcategoryDialog
        initialValues={initialValues}
        title={t('subcategory.new')}
        open={isOpen}
        onClose={toggleIsOpen}
        onSubmit={handleSubcategoryCreate}
      />
    </Container>
  )
}

CreateSubcategoryButton.propTypes = {
  onSubcategoryCreate: PropTypes.func,
  disabled: PropTypes.bool,
}

CreateSubcategoryButton.defaultProps = {
  onSubcategoryCreate: () => {},
  disabled: false,
}

export default memo(CreateSubcategoryButton)
