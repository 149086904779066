import { useState, useCallback } from 'react'

const useCollapse = (init = true) => {
  const [collapse, setCollapse] = useState(!!init)

  const toggleCollapse = useCallback((value) => {
    if (typeof value === 'boolean') {
      setCollapse(value)
    } else {
      setCollapse(currentValue => !currentValue)
    }
  }, [])

  return [collapse, toggleCollapse]
}

export default useCollapse
