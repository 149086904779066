import { useClubs } from '@itsilesia/udrink-common-components'

import ClubsList from '../components/clubs/ClubsList/ClubsList'
import useDataList from '../hooks/useDataList'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'

const ClubsScreen = () => {
  const useResource = ({ page, size }) =>
    useClubs({
      page,
      size,
      sort: 'name',
    })
  const { handleChangePage, handleChangeRowsPerPage, ...props } = useDataList('clubs', useResource)

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <ClubsList
        {...props}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default ClubsScreen
