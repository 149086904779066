import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import styled from 'styled-components/macro'

import StyledTextField from '../../../ClubForm/StyledTextField'

const DatesContainer = styled.div(({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)}px;
`)

const AvailabilityLimitationsSubsection = () => {
  const { t } = useTranslation()

  return (
    <DatesContainer container>
      <Field
        formatError={t}
        label={t('discountCodes.availableFrom')}
        name="dateFrom"
        required
        component={StyledTextField}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
      <Field
        formatError={t}
        label={t('discountCodes.availableTo')}
        name="dateTo"
        required
        component={StyledTextField}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
    </DatesContainer>
  )
}

export default AvailabilityLimitationsSubsection
