import TIME_SCALE from '../constants/timeScale'
import getWeekOfYear from './getWeekOfYear'

const parseTimeScale = (timeScaleValue, dateTime) => {
  const date = new Date(dateTime)
  switch (timeScaleValue) {
    case TIME_SCALE.HOUR.value:
      return `${date.getHours()}:00`
    case TIME_SCALE.DAY.value:
      return `${date.getDate()}.${`0${date.getMonth() + 1}`.slice(-2)}`
    case TIME_SCALE.WEEK.value:
      return `${getWeekOfYear(date)}`
    default:
      return ''
  }
}

export default parseTimeScale
