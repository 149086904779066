const mapOrderCollectPlace = (
  clubId,
  {
    name,
    description,
    deviceWorkplaceConnection,
    ...rest
  },
) => ({
  clubId,
  name: name || '',
  description: description || '',
  isDeviceConnected: !!deviceWorkplaceConnection?.id || false,
  deviceConnectionId: deviceWorkplaceConnection?.id || '',
  ...rest,
})

export { mapOrderCollectPlace }
