import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import LinkRouter from '../../navigation/LinkRouter'
import RestrictedContent from '../../auth/RestrictedContent'
import { ADMIN_ROLE, MANAGER_ROLE } from '../../../constants/roleGroups'
import currentClubModule from '../../../store/currentClub'

const ClubsListNameLink = ({ row, children }) => {
  const dispatch = useDispatch()

  const handleManagerClick = () => {
    dispatch(currentClubModule.actions.change(row.id))
  }

  return (
    <>
      <RestrictedContent accessRole={ADMIN_ROLE}>
        <LinkRouter to={`/clubs/${row.id}`}>
          {typeof children === 'function' ? children(row) : children}
        </LinkRouter>
      </RestrictedContent>
      <RestrictedContent accessRole={MANAGER_ROLE}>
        <LinkRouter to={`/data?club-id=${row.id}`} onClick={handleManagerClick}>
          {typeof children === 'function' ? children(row) : children}
        </LinkRouter>
      </RestrictedContent>
    </>
  )
}

ClubsListNameLink.defaultProps = {
  children: item => item.name,
}

ClubsListNameLink.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

export default ClubsListNameLink
