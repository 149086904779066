import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const snackbarModule = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addMessage(state, action) {
      state.push({
        message: action.payload,
        key: new Date().getTime(),
      })
    },
    shiftMessage(state) {
      state.shift()
    },
  },
})

snackbarModule.selectors = {
  getLength: state => state.length,
  getFirst: state => state?.[0],
}

export default snackbarModule
