import { useParams } from 'react-router-dom'
import { useUser } from '@itsilesia/udrink-common-components'

import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import UserDetails from '../components/data/UserDetails'
import FormStyleWrapper from '../components/wrappers/FormStyleWrapper'

const UserDetailsScreen = () => {
  const params = useParams()
  const { data: user } = useUser(params.id)

  return (
    <RouteContainer fillHeight={false}>
      <NavigationBreadcrumbs />
      <FormStyleWrapper>
        {user && <UserDetails user={user} />}
      </FormStyleWrapper>
    </RouteContainer>
  )
}

export default UserDetailsScreen
