import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons/'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import noop from 'lodash/noop'

import parseISODate from '../../../utils/parseISODate'
import IdPropType from '../../../propTypes/IdPropType'
import DataTable from '../../data/DataTable'

import DISCOUNT_CODES_COLUMNS from './DiscountCodesList.columns'

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DiscountCodesList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  rowsPerPageOptions,
  total,
  pageSize,
  page,
  clubId,
}) => {
  const { t } = useTranslation()

  const tableData = useMemo(() =>
    data.map(row => ({
      ...row,
      status: t(`discountCodes.${row.status}`),
      type: t(`discountCodes.${row.type}`),
      dateTo: parseISODate(row.dateTo),
      clubId,
    })), [clubId, data, t])

  return (
    <>
      <TableOptions>
        {/* TODO: add search handling */}
        {/* <Searchbar /> */}
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/clubs/${clubId}/discount-codes/create`}
          startIcon={<AddIcon />}
        >
          {t('discountCodes.add')}
        </Button>
      </TableOptions>
      <DataTable
        data={tableData}
        columns={DISCOUNT_CODES_COLUMNS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
    </>
  )
}

DiscountCodesList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
}

DiscountCodesList.propTypes = {
  clubId: IdPropType.isRequired,
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default DiscountCodesList
