import { useEffect, createContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useSelector, useDispatch } from 'react-redux'
import { usePrevious } from '@itsilesia/udrink-common-components'
import currentClubModule from '../store/currentClub'

export const ClubContext = createContext(null)

const ClubContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [clubId, setClubId] = useQueryParam('club-id', NumberParam)
  const currentClubId = useSelector(state => state.currentClub)
  const prevCurrentClubId = usePrevious(currentClubId)
  const contextValue = useMemo(() => ({ clubId }), [clubId])

  useEffect(() => {
    if (!clubId) {
      if (currentClubId) {
        setClubId(currentClubId, 'replaceIn')
      }
    } else if (prevCurrentClubId !== currentClubId && clubId !== currentClubId) {
      setClubId(currentClubId, 'pushIn')
    } else if (currentClubId !== clubId) {
      dispatch(currentClubModule.actions.change(clubId))
    }
  }, [clubId, currentClubId, setClubId, prevCurrentClubId, dispatch])

  return (
    <ClubContext.Provider value={contextValue}>
      {children}
    </ClubContext.Provider>
  )
}

ClubContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ClubContextProvider
