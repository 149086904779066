import Axios from 'axios'
import { authModule } from '@itsilesia/udrink-common-components'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import store from '../store'

// Temporary workaround, should be fixed in #635
const createAuthAwareFetch = (
  axios,
  { getAccessToken, getRefreshToken, setAccessToken, getNewToken },
) => {
  axios.interceptors.request.use((request) => {
    const accessToken = getAccessToken()

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`
    }

    return request
  })

  createAuthRefreshInterceptor(axios, async (failedRequest) => {
    const refreshToken = getRefreshToken()
    const token = await getNewToken(refreshToken)
    setAccessToken(token)

    failedRequest.response.config.headers.Authorization = `Bearer ${token}`
  }, { skipWhileRefreshing: false })

  return axios
}

const mapStoreToFetchActions = ({ getState, dispatch }) => ({
  getAccessToken: () => getState().auth.accessToken,
  getRefreshToken: () => getState().auth.refreshToken,
  setAccessToken: token => dispatch(authModule.actions.setAccessToken(token)),
  getNewToken: async (refreshToken) => {
    const response = await Axios.request({
      baseURL: process.env.REACT_APP_API_URL,
      url: '/auth/token',
      method: 'post',
      headers: {
        Authentication: `Bearer ${refreshToken}`,
      },
    })
    return response.data.token
  },
})

const fetch = createAuthAwareFetch(
  Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
  mapStoreToFetchActions(store),
)

export default fetch
