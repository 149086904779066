import { useLocation, matchPath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import APP_HEADERS from '../constants/appHeaders'

const useAppHeaderTitle = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const appHeaderCode = APP_HEADERS.reduce((headerCode, { path, code }) => {
    const match = matchPath(location.pathname, {
      path,
    })

    return headerCode || (match?.isExact ? code : '')
  }, '')

  return t(appHeaderCode)
}

export default useAppHeaderTitle
