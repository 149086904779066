import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'
import IdPropType from '../../../propTypes/IdPropType'

import { subcategorySchema } from '../../../validations/subcategorySchema'
import {
  TextField,
  FormButton,
} from '../../inputs'
import UploadImage from '../../data/UploadImage/UploadImage'
import useSnackbar from '../../../hooks/useSnackbar'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`

const StyledTextField = styled(TextField)(({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`)

const StyledUploadImage = styled(UploadImage)`
  align-self: flex-start;
  text-align: right;
  flex: 1;
  margin-left: 18px;
`

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.6;
`

const SubcategoryDialog = ({
  open,
  onClose: handleClose,
  onSubmit: handleSubmit,
  initialValues,
  title,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const [subcategoryImage, setSubcategoryImage] = useState(() => ({
    files: [],
    source: '',
    blob: '',
  }))

  const handleFormSubmit = (values, { setSubmitting }) => {
    handleSubmit({
      ...values,
      imageFiles: subcategoryImage.files,
      imageUrl: subcategoryImage.source,
      imageBlob: subcategoryImage.blob,
    })
    setSubmitting(false)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={subcategorySchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <DialogTitle>
              {title}
            </DialogTitle>
            <StyledDialogContent dividers>
              <DataColumn>
                <Field
                  formatError={t}
                  label={t('subcategory.polishTitle')}
                  name="namePl"
                  component={StyledTextField}
                />
                <Field
                  formatError={t}
                  label={t('subcategory.englishTitle')}
                  name="name"
                  component={StyledTextField}
                />
              </DataColumn>
              <StyledUploadImage
                image={subcategoryImage}
                onImageChange={setSubcategoryImage}
                initialValues={initialValues}
                onImageLoadSuccess={() => addSnackbar(t('image.success.load'))}
              />
            </StyledDialogContent>
            <DialogActions>
              <FormButton onClick={handleClose}>{t('common.back')}</FormButton>
              <FormButton type="submit" color="primary">
                {t('common.save')}
              </FormButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

SubcategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  initialValues: PropTypes.exact({
    name: PropTypes.string.isRequired,
    namePl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    subcategoryUuid: IdPropType.isRequired,
  }),
}

SubcategoryDialog.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  title: '',
  initialValues: {
    name: '',
    namePl: '',
    imageUrl: '',
    subcategoryUuid: '',
  },
}

export default SubcategoryDialog
