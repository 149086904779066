import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import SectionHeader from './section/SectionHeader'
import StyledTextField from '../../ClubForm/StyledTextField'
import { ContainerWithInfoTooltip } from './ContainerWithInfoTooltip'

const GenerateCodeSection = () => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader
        title={t('discountCodes.codeGeneration')}
      />
      <ContainerWithInfoTooltip info={t('discountCodes.autoGenerateCodeInfo')}>
        <Field
          formatError={t}
          label={t('discountCodes.code')}
          name="discountCode"
          component={StyledTextField}
        />
      </ContainerWithInfoTooltip>
    </>
  )
}

export default GenerateCodeSection
