import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { authModule } from '@itsilesia/udrink-common-components'
import { useSWRConfig } from 'swr'

const useSignOut = () => {
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()

  return useCallback(() => {
    // clear swr cache
    mutate(
      () => true,
      undefined,
      { revalidate: false },
    )
    dispatch(authModule.actions.signOut())
  }, [dispatch, mutate])
}

export default useSignOut
