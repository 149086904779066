import camelCase from 'lodash/camelCase'

const parseCodeToI18nMessage = (message, root = 'errorCode') => {
  const parts = message.split('.').map(part => camelCase(part))

  const prefixParts = []
  const params = []

  parts.forEach((part) => {
    if (!part.includes(':')) {
      prefixParts.push(part)
    } else {
      params.push(part.split(':'))
    }
  })

  const prefix = [root, ...prefixParts].join('.')
  return [prefix, Object.fromEntries(params)]
}

export default parseCodeToI18nMessage
