import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons/'
import styled from 'styled-components/macro'

import ROLE_GROUPS from '../../../constants/roleGroups'
import RestrictedContent from '../../auth/RestrictedContent'

const TableOptions = styled.div`
  display: grid;
  grid-template-columns: auto 200px 1fr;
  grid-template-areas: 'roleGroups clubs create';
  grid-gap: 25px;
`

const StyledButton = styled(Button)`
  grid-area: create;
  justify-self: end;
`

const UserListHeader = ({
  activeRoleGroup,
  onActiveRoleChange: handleActiveRoleChange,
}) => {
  const { t } = useTranslation()

  return (
    <TableOptions>
      <ButtonGroup variant="contained">
        {Object.values(ROLE_GROUPS).map(({ value, code, accessRole }) => (
          <RestrictedContent
            key={value}
            accessRole={accessRole}
          >
            {props => (
              <Button
                {...props}
                color={activeRoleGroup === value ? 'primary' : 'default'}
                onClick={() => handleActiveRoleChange(value)}
              >
                {t(code)}
              </Button>
            )}
          </RestrictedContent>
        ))}
      </ButtonGroup>
      <StyledButton
        variant="contained"
        color="primary"
        component={Link}
        to="/users/create"
        startIcon={<AddIcon />}
      >
        {t('common.create')}
      </StyledButton>
    </TableOptions>
  )
}

UserListHeader.propTypes = {
  activeRoleGroup: PropTypes.string.isRequired,
  onActiveRoleChange: PropTypes.func,
}

UserListHeader.defaultProps = {
  onActiveRoleChange: () => {},
}

export default UserListHeader
