import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  Switch,
  Collapse,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { useFormik } from 'formik'
import styled from 'styled-components/macro'
import { useTaxRates } from '@itsilesia/udrink-common-components'
import { v4 as generateUuid } from 'uuid'

import { productWithPriceInPlnSchema } from '../../../validations/productSchema'
import WORK_ZONES from '../../../constants/workZones'
import IdPropType from '../../../propTypes/IdPropType'
import useCollapse from '../../../hooks/useCollapse'
import BILLING_TYPES from '../../../constants/billingTypes'
import useSnackbar from '../../../hooks/useSnackbar'
import {
  FormButton,
  Select,
  PriceTextField,
  TextField,
} from '../../inputs'
import UploadImage from '../../data/UploadImage/UploadImage'
import getNextMenuState from '../../../utils/getNextMenuState'
import PropEntity from '../PropEntity'

import ProductModifiersSection from './ProductModifiersSection'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const ErrorMessage = styled(Typography)(({ theme }) => `
  margin-left: ${theme.spacing(1)}px;
  color: ${theme.palette.error.main};
`)

const StyledTextField = styled(TextField)(({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`)

const StyledPriceTextField = styled(PriceTextField)(({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`)

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.6;
`

const Row = styled.div`
  display: flex;
  align-items: stretch;
`

const ProductAvailabilityRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0 13px 0;
`

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EndTimeColumn = styled.div`
  flex: 1;
`

const BeginTimeColumn = styled(EndTimeColumn)`
  flex: 1;
  margin-right: 18px;
`

const TaxRateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Rate = styled.span`
  font-size: 12px;
`

const StyledUploadImage = styled(UploadImage)`
  align-self: flex-start;
  text-align: right;
`
const SectionTitle = styled(Typography)`
  font-weight: 600;
`

const ProductDialog = ({
  open,
  onClose: handleClose,
  onSubmit: handleSubmit,
  initialValues,
  title,
  pushEvent,
  clubId,
  onAdd: handleAdd,
  onRemove: handleRemove,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const { data: taxRatesList } = useTaxRates()
  const [
    isAvailabilityPeriodCollapsed,
    toggleIsAvailabilityPeriodCollapsed,
  ] = useCollapse(!initialValues.availabilityHoursBegin)

  useEffect(() => {
    toggleIsAvailabilityPeriodCollapsed(!initialValues.availabilityHoursBegin)
  }, [initialValues.availabilityHoursBegin, toggleIsAvailabilityPeriodCollapsed])

  const [isBasicInfoSectionExpanded, setIsBasicInfoSectionExpanded] = useState(false)
  const taxRates = useMemo(() => taxRatesList || [], [taxRatesList])
  const [productImage, setProductImage] = useState(() => ({
    files: [],
    source: '',
    blob: '',
  }))

  const modifiersValues = useMemo(() =>
    Object.fromEntries(initialValues.modifiers?.map(({ uuid }) =>
      [uuid, true])), [initialValues.modifiers])

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      modifiers: modifiersValues,
      availabilityHoursBegin: initialValues.availabilityHoursBegin || '00:00',
      availabilityHoursEnd: initialValues.availabilityHoursEnd || '00:00',
    },
    validationSchema: productWithPriceInPlnSchema,
    enableReinitialize: true,
    validateOnChange: false,
    // eslint-disable-next-line no-use-before-define
    onSubmit: handleFormSubmit,
  })

  // Using function prevents from error no-use-before-define
  function handleFormSubmit({ availabilityHoursBegin,
    availabilityHoursEnd,
    modifiers: modifiersArray,
    uuid: uuid_,
    ...values
  }, { setSubmitting }) {
    setSubmitting(true)
    const productUuid = generateUuid()
    handleSubmit({
      ...values,
      productUuid: uuid_ ?? productUuid,
      availabilityHoursBegin: isAvailabilityPeriodCollapsed ? null : availabilityHoursBegin,
      availabilityHoursEnd: isAvailabilityPeriodCollapsed ? null : availabilityHoursEnd,
      imageFiles: productImage.files,
      imageUrl: productImage.source,
      imageBlob: productImage.blob,
      // Convert to peanuts.
      price: (typeof values.price === 'string')
        ? parseInt(parseFloat(values.price.replace(',', '.')) * 100, 10)
        : values.price * 100,
    })

    const { modifiers, uuid } = initialValues
    Object.entries(modifiersArray).forEach(
      (modifierTuple) => {
        const [modifierUuid, checkboxValue] = modifierTuple
        const modifierFound = modifiers.find(modifier => modifier.uuid === modifierUuid)

        if (checkboxValue && !modifierFound) {
          handleAdd(uuid ?? productUuid, modifierUuid)
        } else if (!checkboxValue && modifierFound) {
          handleRemove(uuid ?? productUuid, modifierUuid)
        }
      },
    )
    formik.resetForm()
    setSubmitting(false)
    handleClose()
  }

  const handleFormClose = () => {
    formik.handleReset()
    handleClose()
  }

  const handleProductModifierAdd = useCallback((productUuid, modifierUuid) => {
    pushEvent(
      getNextMenuState.actions.addModifier({
        productUuid,
        modifierUuid,
      }),
    )
  }, [pushEvent])

  const handleProductModifierRemove = useCallback((productUuid, modifierUuid) => {
    pushEvent(
      getNextMenuState.actions.removeModifier({
        productUuid,
        modifierUuid,
      }),
    )
  }, [pushEvent])

  const handleWorkZoneChange = ({ target: { value } }) => {
    formik.setFieldValue('workZone', value)
  }

  const handleVatTypeChange = ({ target: { value } }) => {
    formik.setFieldValue('vatType', value)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <StyledDialogContent dividers>
          <Accordion onChange={(e, expanded) => setIsBasicInfoSectionExpanded(expanded)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <SectionTitle>{t('product.productDialog.basicInfo')}</SectionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <DataColumn>
                <StyledTextField
                  error={formik.errors.name}
                  helperText={t(formik.errors.name)}
                  label={t('product.title')}
                  name="name"
                  value={formik.values.name}
                  onChange={e => formik.setFieldValue('name', e.target.value)}
                />
                <FormControl>
                  <InputLabel variant="outlined" htmlFor="productEditworkZone">{t('orderPreparation.zone')}</InputLabel>
                  <Select
                    inputProps={{ id: 'productEditworkZone' }}
                    label={t('orderPreparation.zone')}
                    name="workZone"
                    value={formik.values.workZone}
                    onChange={handleWorkZoneChange}
                  >
                    {WORK_ZONES.map(({ value, code }) => (
                      <MenuItem key={value} value={value}>
                        {t(code)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <StyledPriceTextField
                  error={formik.errors.price}
                  helperText={t(formik.errors.price)}
                  label={t('product.price')}
                  name="price"
                  value={formik.values.price}
                  onChange={e => formik.setFieldValue('price', e.target.value)}
                />
                <FormControl>
                  <InputLabel variant="outlined" htmlFor="productEditTaxRate">{t('taxRates.title')}</InputLabel>
                  <Select
                    inputProps={{ id: 'productEditTaxRate' }}
                    label={t('taxRates.title')}
                    name="vatType"
                    value={formik.values.vatType}
                    onChange={handleVatTypeChange}
                  >
                    {taxRates.map(({ id, vatType, rate, billingType }) => (
                      <MenuItem key={id} value={vatType}>
                        <TaxRateContainer>
                          <span>{vatType}</span>
                          <Rate>
                            {typeof rate === 'number'
                              ? `${rate}%`
                              : t(BILLING_TYPES[billingType].i18n)}
                          </Rate>
                        </TaxRateContainer>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <StyledTextField
                  error={formik.errors.volume}
                  helperText={t(formik.errors.volume)}
                  label={t('product.portion')}
                  name="volume"
                  value={formik.values.volume}
                  onChange={e => formik.setFieldValue('volume', e.target.value)}
                />
                <ProductAvailabilityRow>
                  <PropEntity
                    label={t('product.availabilityLabel')}
                    value={isAvailabilityPeriodCollapsed ? t('product.wholeDay') : t('product.customPeriod')}
                  />
                  <Tooltip
                    title={!isAvailabilityPeriodCollapsed ? t('product.setWholeDay') : t('product.setCustomPeriod')}
                    aria-label={!isAvailabilityPeriodCollapsed ? t('product.setWholeDay') : t('product.setCustomPeriod')}
                  >
                    <Switch
                      onChange={toggleIsAvailabilityPeriodCollapsed}
                      checked={!isAvailabilityPeriodCollapsed}
                      color="primary"
                    />
                  </Tooltip>
                </ProductAvailabilityRow>
                <Collapse in={!isAvailabilityPeriodCollapsed}>
                  <Row>
                    <BeginTimeColumn>
                      <StyledTextField
                        error={formik.errors.availabilityHoursBegin}
                        helperText={t(formik.errors.availabilityHoursBegin)}
                        label={t('product.availabilityHours.beginTime')}
                        name="availabilityHoursBegin"
                        type="time"
                        value={formik.values.availabilityHoursBegin}
                        onChange={e => formik.setFieldValue('availabilityHoursBegin', e.target.value)}
                      />
                    </BeginTimeColumn>
                    <EndTimeColumn>
                      <StyledTextField
                        error={formik.errors.availabilityHoursEnd}
                        helperText={t(formik.errors.availabilityHoursEnd)}
                        label={t('product.availabilityHours.endTime')}
                        name="availabilityHoursEnd"
                        type="time"
                        value={formik.values.availabilityHoursEnd}
                        onChange={e => formik.setFieldValue('availabilityHoursEnd', e.target.value)}
                      />
                    </EndTimeColumn>
                  </Row>
                </Collapse>
                <StyledTextField
                  multiline
                  minRows={3}
                  maxRows={3}
                  value={formik.values.description}
                  onChange={e => formik.setFieldValue('description', e.target.value)}
                  label={t('common.description')}
                  name="description"
                  form={{ touched: formik.touched }}
                  error={formik.errors.description}
                  helperText={t(formik.errors.description)}
                />
              </DataColumn>
            </AccordionDetails>
          </Accordion>
          <Collapse in={!isBasicInfoSectionExpanded && Object.keys(formik.errors).length !== 0}>
            <ErrorMessage color="palette.error.main">{t('product.productDialog.errors')}</ErrorMessage>
          </Collapse>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <SectionTitle>{t('product.productDialog.photo')}</SectionTitle>
            </AccordionSummary>
            <PhotoWrapper>
              <AccordionDetails>
                <StyledUploadImage
                  image={productImage}
                  onImageChange={setProductImage}
                  initialValues={initialValues}
                  onImageLoadSuccess={() => addSnackbar(t('image.success.load'))}
                />
              </AccordionDetails>
            </PhotoWrapper>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <SectionTitle>{t('product.productDialog.modifiers')}</SectionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <ProductModifiersSection
                {...formik}
                setFieldValue={formik.setFieldValue}
                selectedmodifiers={formik.values.modifiers}
                clubId={clubId}
                onAdd={handleProductModifierAdd}
                onRemove={handleProductModifierRemove}
              />
            </AccordionDetails>
          </Accordion>
        </StyledDialogContent>
        <DialogActions>
          <FormButton onClick={handleFormClose}>{t('common.back')}</FormButton>
          <FormButton type="submit" color="primary">
            {t('common.save')}
          </FormButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  pushEvent: PropTypes.func.isRequired,
  initialValues: PropTypes.exact({
    id: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    workZone: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    vatType: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    subcategoryUuid: IdPropType.isRequired,
    availabilityHoursBegin: PropTypes.string,
    availabilityHoursEnd: PropTypes.string,
    onlyPreview: PropTypes.bool.isRequired,
    modifiers: PropTypes.array,
    uuid: PropTypes.string,
  }),
  clubId: IdPropType.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

ProductDialog.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  title: '',
  initialValues: {
    id: '',
    name: '',
    workZone: '',
    vatType: '',
    price: '',
    volume: '',
    description: '',
    imageUrl: '',
    subcategoryUuid: '',
    availabilityHoursBegin: null,
    availabilityHoursEnd: null,
    onlyPreview: false,
    modifiers: [],
    uuid: '',
  },
  onAdd: () => {},
  onRemove: () => {},
}

export default ProductDialog
