import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import FormModePropType from '../../../../propTypes/FormModePropType'
import { UPDATE, CREATE } from '../../../../constants/formModes'
import FormFooter from '../../FormFooter'
import {
  LocalizationSection,
  GeneralSection,
  HoursSection,
  PaymentKeysSection,
} from './formSections'
import FormikForm from '../../StyledFormikForm'

const StyledFormikForm = styled(FormikForm)`
  padding-bottom: 25px;
`

const ClubForm = ({
  mode,
  values,
  handleChange,
  setFieldValue,
  isSubmitting,
  onCancel }) => {
  const confirmMessageKey = useMemo(
    () => (mode === UPDATE ? 'common.update' : 'common.save'),
    [mode],
  )

  return (
    <StyledFormikForm>
      <GeneralSection
        values={values}
        setFieldValue={setFieldValue}
        mode={mode}
      />
      <LocalizationSection
        countryValue={values.country}
        timeZoneValue={values.timeZoneId}
        onChange={handleChange}
      />
      <HoursSection values={values} setFieldValue={setFieldValue} />
      <PaymentKeysSection
        mode={mode}
        isClubActive={values.isActive}
        isTestMode={values.fiservAccessData.isTestMode}
        setFieldValue={setFieldValue}
      />
      <FormFooter
        onCancel={onCancel}
        disabled={isSubmitting}
        confirmMessageKey={confirmMessageKey}
      />
    </StyledFormikForm>
  )
}

ClubForm.defaultProps = {
  mode: CREATE,
}

ClubForm.propTypes = {
  mode: FormModePropType,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ClubForm
