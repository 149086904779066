import workZones from '../constants/workZones'

const mapToAPICreateOrderPreparationPlaces = (
  clubId,
  { workZone, ...rest },
) => ({
  clubId,
  workZoneKey: workZone,
  ...rest,
})

const mapToAPIUpdateOrderPreparationPlaces = ({ workZone, ...rest }) => ({
  workZoneKey: workZone,
  ...rest,
})

const mapOrderPreparationPlaces = ({
  name,
  description,
  workZone,
  collectOrderPlace,
  deviceWorkplaceConnection,
}) => ({
  name: name || '',
  description: description || '',
  workZone: workZone || workZones[0].value,
  collectOrderPlaceId: collectOrderPlace?.id || '',
  isDeviceConnected: !!deviceWorkplaceConnection?.id || false,
  deviceConnectionId: deviceWorkplaceConnection?.id || '',
})

export {
  mapToAPICreateOrderPreparationPlaces,
  mapToAPIUpdateOrderPreparationPlaces,
  mapOrderPreparationPlaces,
}
