import { useMemo, useContext } from 'react'
import { useUsers, useClubUsers, authModule } from '@itsilesia/udrink-common-components'
import { useQueryParam, StringParam } from 'use-query-params'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash/groupBy'

import { ClubContext } from '../../components/ClubContext'
import useDataList from '../../hooks/useDataList'
import ROLE_GROUPS from '../../constants/roleGroups'
import UsersList from '../../components/data/UsersList'
import RouteContainer from '../../components/layout/RouteContainer'
import IdPropType from '../../propTypes/IdPropType'
import RouteHeader from '../../components/layout/RouteHeader'

const ClubWorkersScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)

  const userRole = useSelector(state => authModule.selectors.getUserRole(state.auth))
  const [activeRoleGroup, setActiveRoleGroup] = useQueryParam('user-group', StringParam)
  const roleGroup = (activeRoleGroup
    || ROLE_GROUPS[userRole].value
    || ROLE_GROUPS.MANAGER.value
  ).toUpperCase()

  const useResource = ROLE_GROUPS[userRole] === ROLE_GROUPS.ADMIN
    ? ({ page, size }) =>
      useUsers({ // eslint-disable-line react-hooks/rules-of-hooks
        page,
        size,
        roleGroup,
      })
    : ({ page, size }) =>
      useClubUsers(clubId, { // eslint-disable-line react-hooks/rules-of-hooks
        page,
        size,
      })

  const { handleChangePage, handleChangeRowsPerPage, ...dataTableProps } = useDataList(
    'users',
    useResource,
  )

  const { data: usersData, ...tableProps } = dataTableProps
  const data = useMemo(() => groupBy(usersData, 'roleGroup.name')[roleGroup] || [], [usersData, roleGroup])

  return (
    <RouteContainer>
      <RouteHeader>{t('drawer.navigation.workers')}</RouteHeader>
      <UsersList
        activeRoleGroup={roleGroup}
        setActiveRoleGroup={value => setActiveRoleGroup(value.toLowerCase(), 'pushIn')}
        data={data}
        {...tableProps}
        onChangePage={IdPropType}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default ClubWorkersScreen
