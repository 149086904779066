import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'

import ClubActionsMenu from './ClubsListActionsMenu'

const ClubsListActions = ({ row }) => {
  const { t } = useTranslation()

  return (
    <>
      <Tooltip
        title={t('tableTooltips.edit')}
        aria-label={t('tableTooltips.edit')}
      >
        <IconButton component={Link} to={`/clubs/${row.id}/edit`}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <ClubActionsMenu clubId={row.id} clubName={row.name} />
    </>
  )
}

ClubsListActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default ClubsListActions
