import { useContext, useState, useEffect } from 'react'
import { useClubProductsAmounts } from '@itsilesia/udrink-common-components'
import { useTranslation } from 'react-i18next'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import Inventory from '../../components/inventory'
import useInventory from '../../hooks/useInventory'
import RouteHeader from '../../components/layout/RouteHeader'

const ClubInventoryScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)
  const [searchValue, setSearchValue] = useState('')

  const useResource = ({ page, size }) =>
    useClubProductsAmounts(clubId, {
      page,
      size,
      sort: 'name',
      'name-filter': searchValue,
    })

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleResourceUpdate,
    handleResourceCreate,
    handleResourceDelete,
    ...props
  } = useInventory(
    `${clubId}-inventory-manager`,
    useResource,
  )

  useEffect(() => {
    handleChangePage(0)
  }, [searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RouteContainer>
      <RouteHeader>{t('inventory.manage')}</RouteHeader>
      <Inventory
        {...props}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        onResourceUpdate={handleResourceUpdate}
        onResourceCreate={handleResourceCreate}
        onResourceDelete={handleResourceDelete}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default ClubInventoryScreen
