import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import DataTable from '../../data/DataTable'

import useInventoryColumns from './useInventoryColumns'

const InventoryForm = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  onResourceUpdate: handleResourceUpdate,
  onResourceCreate: handleResourceCreate,
  onResourceDelete: handleResourceDelete,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
  emptyArrayMessage,
}) => {
  const columns = useInventoryColumns(
    handleResourceUpdate,
    handleResourceCreate,
    handleResourceDelete,
  )

  return (
    <DataTable
      data={data}
      columns={columns}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      total={total}
      rowsPerPageOptions={rowsPerPageOptions}
      pageSize={pageSize}
      page={page}
      emptyArrayMessage={emptyArrayMessage}
    />
  )
}

InventoryForm.propTypes = {
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onResourceUpdate: PropTypes.func,
  onResourceCreate: PropTypes.func,
  onResourceDelete: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  emptyArrayMessage: PropTypes.string,
}

InventoryForm.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  onResourceUpdate: () => {},
  onResourceCreate: () => {},
  onResourceDelete: () => {},
  total: 0,
  emptyArrayMessage: '',
}

export default InventoryForm
