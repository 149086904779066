import { useMemo, useCallback } from 'react'
import {
  useOrderPreparationPlace,
  useTrackLoadingStatus,
  useClubOrderCollectPlaces,
} from '@itsilesia/udrink-common-components'

import { createOrderPreparationPlace, updateOrderPreparationPlace } from '../services/api'
import { mapOrderPreparationPlaces } from '../utils/mapOrderPreparationPlaces'

const useOrderPreparationPlaceForm = ({ id, clubId, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useOrderPreparationPlace(id)

  useTrackLoadingStatus(`order-preparation-place-${id}`, isValidating)

  const { data: orderCollectPlaces } = useClubOrderCollectPlaces(clubId)
  const clubOrderCollectPlaces = useMemo(
    () => (orderCollectPlaces && orderCollectPlaces.content) || [],
    [orderCollectPlaces],
  )

  const initialValues = useMemo(
    () =>
      mapOrderPreparationPlaces({ collectOrderPlace: clubOrderCollectPlaces[0], ...data } || {}),
    [data, clubOrderCollectPlaces],
  )

  const handleRequest = useMemo(
    () =>
      (id
        ? values => mutate(() => updateOrderPreparationPlace(id, values), false)
        : values => createOrderPreparationPlace(clubId, values)),
    [id, mutate, clubId],
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleRequest(values)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [handleRequest, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useOrderPreparationPlaceForm
