const CLIENTS = Object.freeze({
  OLD: {
    value: 'OLD',
    code: 'statistics.regularClients',
  },
  NEW: {
    value: 'NEW',
    code: 'statistics.newClients',
  },
  ALL: {
    value: 'ALL',
    code: 'statistics.allClients',
  },
})

export default CLIENTS
