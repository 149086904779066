import { useCallback } from 'react'
import { useDiscountCode, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { createDiscountCode, updateDiscountCode } from '../services/api'
import { mapDiscountCodeToPayload } from '../utils/mapDiscountCode'

const useDiscountCodeForm = ({ id, clubId, onSuccess, onError }) => {
  const { data: initialValues, isValidating: isLoading } = useDiscountCode(id)
  useTrackLoadingStatus('discountCode', isLoading)
  const isEdit = !!id

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        if (isEdit) {
          await updateDiscountCode(id, mapDiscountCodeToPayload(values, isEdit, clubId))
        } else {
          await createDiscountCode(mapDiscountCodeToPayload(values, isEdit, clubId))
        }
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [onSuccess, onError, isEdit, clubId, id],
  )

  return {
    initialValues,
    handleSubmit,
    isLoading,
  }
}

export default useDiscountCodeForm
