import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useClubs, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { createUser } from '../../../services/api'
import { UserFormClubs, UserFormSimple } from '../../../components/forms/UserForm'
import { ADMIN_ROLE, MANAGER_ROLE, BARTENDER_ROLE } from '../../../constants/roleGroups'
import { CREATE } from '../../../constants/formModes'
import useHasRole from '../../../hooks/useHasRole'
import useSnackbar from '../../../hooks/useSnackbar'
import RouteContainer from '../../../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../../../components/navigation/NavigationBreadcrumbs'
import RestrictedContent from '../../../components/auth/RestrictedContent'
import RouteHeader from '../../../components/layout/RouteHeader'

import UserRoleSelect from './UserRoleSelect'

const UserCreateScreen = () => {
  const { addSnackbar } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()

  const [role, setRole] = useState(null)

  const { data, isValidating } = useClubs()
  const { content: clubsContent } = data || {}
  const clubs = useMemo(() => clubsContent || [], [clubsContent])
  const isAdmin = useHasRole(ADMIN_ROLE)

  useTrackLoadingStatus('clubs', isValidating)

  const handleSubmit = async (values) => {
    try {
      await createUser({
        ...values,
        role,
      })
      history.replace(isAdmin ? '/users' : '/workers')
      addSnackbar(t('user.screen.create.success'))
    } catch (err) {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    }
  }

  /* eslint-disable react/display-name */
  const userForm = {
    [ADMIN_ROLE]: () => <UserFormSimple
      isPasswordRequired
      onSubmit={handleSubmit}
      mode={CREATE}
    />,
    [MANAGER_ROLE]: () => <UserFormClubs
      clubs={clubs}
      isPasswordRequired
      onSubmit={handleSubmit}
      mode={CREATE}
    />,
    [BARTENDER_ROLE]: () => <UserFormClubs
      clubs={clubs}
      isPasswordRequired
      onSubmit={handleSubmit}
      mode={CREATE}
    />,
  }
  /* eslint-enable react/display-name */

  return (
    <RouteContainer maxWidth="md">
      <RestrictedContent accessRole={ADMIN_ROLE}>
        <NavigationBreadcrumbs />
      </RestrictedContent>
      <RestrictedContent accessRole={MANAGER_ROLE}>
        <RouteHeader>{t('workers.create')}</RouteHeader>
      </RestrictedContent>
      {role ? (
        <>
          {userForm[role]()}
        </>
      ) : (
        <UserRoleSelect onRoleClick={setRole} />
      )}
    </RouteContainer>
  )
}

export default UserCreateScreen
