import { useMemo, useCallback } from 'react'
import { useClubWeeklySchedule, useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import { mapClub } from '../utils/mapClub'
import { updateWeeklySchedules } from '../services/api'

const useHoursForm = ({ clubId, onSuccess, onError }) => {
  const { data, mutate, isValidating } = useClubWeeklySchedule(clubId)
  useTrackLoadingStatus('club-wekly-schedule', isValidating)

  const initialValues = useMemo(() => mapClub({ weeklySchedule: data }), [data])

  const handleRequest = useCallback(
    values => mutate(() => updateWeeklySchedules(values)),
    [mutate],
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleRequest(values)
        setSubmitting(false)
        onSuccess()
      } catch (err) {
        setSubmitting(false)
        onError(err)
      }
    },
    [handleRequest, onSuccess, onError],
  )

  return {
    initialValues,
    handleSubmit,
  }
}

export default useHoursForm
