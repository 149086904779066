import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import styled from 'styled-components/macro'
import { InputAdornment, MenuItem } from '@material-ui/core'

import { TextField, Select } from '../../inputs'
import BILLING_TYPES from '../../../constants/billingTypes'

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  padding: 4px 0 0 15%;

  .MuiInputBase-input {
    text-align: right;
    padding-right: 2px;
  }
`
const StyledTextField = props => (
  <Wrapper>
    <TextField
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <mat-icon matSuffix>%</mat-icon>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  </Wrapper>
)

const Column = styled.div`
  flex: 1;

  ${({ hide }) => hide && `
    visibility: hidden;
  `}
`

const NameColumn = styled(Column)`
  flex: .5;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  font-weight: 600;
  font-size: 16px;
`

const PercentColumn = styled(Column)`
  flex: .8;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

const TaxRatesRow = ({ billingType, name, index }) => {
  const { t } = useTranslation()
  const hideRateStyle = useMemo(() => billingType !== BILLING_TYPES.TAXED.type, [billingType])

  return (
    <Container>
      <NameColumn>{`${t('taxRates.rate')} ${name}`}</NameColumn>
      <Column>
        <Field component={StyledSelect} name={`${index}.billingType`}>
          {Object.values(BILLING_TYPES).map(({ type, i18n }) => (
            <MenuItem key={type} value={type}>
              {t(i18n)}
            </MenuItem>
          ))}
        </Field>
      </Column>
      <PercentColumn hide={hideRateStyle}>
        <Field
          name={`${index}.rate`}
          component={StyledTextField}
          formatError={t}
          label={`${t('taxRates.rate')} ${name}`}
        />
      </PercentColumn>
    </Container>
  )
}

TaxRatesRow.propTypes = {
  billingType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default TaxRatesRow
