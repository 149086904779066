import { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { Collapse, Tooltip, Switch } from '@material-ui/core'
import styled from 'styled-components/macro'

import useCollapse from '../../../../../hooks/useCollapse'
import StyledTextField from '../../StyledTextField'
import SectionBody from './section/SectionBody'
import SectionHeader from './section/SectionHeader'
import SectionContent from './section/SectionContent'
import { FormButton } from '../../../../inputs'
import RestrictedContent from '../../../../auth/RestrictedContent'
import ROLE_GROUPS from '../../../../../constants/roleGroups'
import { CREATE } from '../../../../../constants/formModes'
import AlertParagraph from '../../../AlertParagraph'
import PropEntity from '../../../PropEntity'
import AlertDialog from '../../../../AlertDialog'

const StyledFormButton = styled(FormButton)`
  margin-top: 5px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
`

const PaymentKeysSection = ({ mode, isClubActive, isTestMode, setFieldValue }) => {
  const { t } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse(mode !== CREATE)
  const [isSectionDisabled, setIsSectionDisabled] = useState(mode !== CREATE)

  const [isStatusInfoVisible, setIsStatusInfoVisible] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertCallback, setAlertCallback] = useState(() => () => {})

  useEffect(() => {
    if (isClubActive) {
      setIsSectionDisabled(true)
    }
  }, [isClubActive])

  const handleWorkingModeChange = ({ target: { checked } }) => {
    setIsAlertOpen(true)
    setAlertCallback(() => () => setFieldValue('fiservAccessData.isTestMode', checked))
  }

  const handleAlertConfirm = async () => {
    alertCallback()
    setIsStatusInfoVisible(status => !status)
    setIsAlertOpen(false)
  }

  const workingModeTranslationRoot = 'clubs.form.workingMode'
  const statusTooltipText = t(`${workingModeTranslationRoot}.set${isTestMode ? 'Off' : 'On'}`)
  const statusButtonColor = isTestMode ? 'secondary' : 'primary'

  return (
    <RestrictedContent accessRole={ROLE_GROUPS.ADMIN.value}>
      <SectionBody>
        <SectionHeader
          title={t('clubs.form.paymentKeys')}
          collapse={collapse}
          toggleCollapse={toggleCollapse}
          alert={isClubActive && t('clubs.form.paymentAlert')}
        />
        <Collapse in={!collapse} timeout="auto">
          <SectionContent>
            <h2>
              <span>{t('clubs.form.fiservAccessData.header')}</span>
              {isClubActive && <AlertParagraph value={t('clubs.form.fiservAccessData.alert')} />}
            </h2>
            <Field
              disabled={isSectionDisabled}
              formatError={t}
              label={t('clubs.form.fiservAccessData.sharedSecret')}
              name="fiservAccessData.sharedSecret"
              component={StyledTextField}
            />
            <Field
              disabled={isSectionDisabled}
              formatError={t}
              label={t('clubs.form.fiservAccessData.apiKey')}
              name="fiservAccessData.apiKey"
              component={StyledTextField}
            />
            <Field
              disabled={isSectionDisabled}
              formatError={t}
              label={t('clubs.form.fiservAccessData.secretKey')}
              name="fiservAccessData.secretKey"
              component={StyledTextField}
            />
            <Field
              disabled={isSectionDisabled}
              formatError={t}
              label={t('clubs.form.fiservAccessData.storeId')}
              name="fiservAccessData.storeId"
              component={StyledTextField}
            />
            {!isClubActive && (
              <StyledFormButton
                onClick={() => setIsSectionDisabled(isDisabled => !isDisabled)}
                color={isSectionDisabled ? 'default' : 'primary'}
              >
                {t(`clubs.form.${isSectionDisabled ? 'unlockEdit' : 'lockEdit'}`)}
              </StyledFormButton>
            )}
            <Row>
              <PropEntity
                label={t(`${workingModeTranslationRoot}.title`)}
                value={t(`${workingModeTranslationRoot}.${isTestMode ? 'on' : 'off'}`)}
                alert={isStatusInfoVisible ? t(`${workingModeTranslationRoot}.alertInfo`) : ''}
              />
              <Tooltip
                title={statusTooltipText}
                aria-label={statusTooltipText}
              >
                <Switch
                  onChange={handleWorkingModeChange}
                  checked={isTestMode}
                  color="primary"
                />
              </Tooltip>
              <AlertDialog
                onConfirm={handleAlertConfirm}
                onCancel={() => setIsAlertOpen(false)}
                message={t(
                  `${workingModeTranslationRoot}.${isTestMode ? 'off' : 'on'}AlertMessage`,
                )}
                title={t(`${workingModeTranslationRoot}.${isTestMode ? 'off' : 'on'}AlertTitle`)}
                confirmText={statusTooltipText}
                confirmColor={statusButtonColor}
                isOpen={isAlertOpen}
              />
            </Row>
          </SectionContent>
        </Collapse>
      </SectionBody>
    </RestrictedContent>
  )
}

PaymentKeysSection.propTypes = {
  mode: PropTypes.string,
  isClubActive: PropTypes.bool,
  isTestMode: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
}

PaymentKeysSection.defaultProps = {
  mode: CREATE,
  isClubActive: false,
  isTestMode: true,
}

export default memo(PaymentKeysSection)
