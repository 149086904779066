import { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

import AddNewProductDialog from '../ProductDialog'
import useCollapse from '../../../../hooks/useCollapse'
import IdPropType from '../../../../propTypes/IdPropType'
import WORK_ZONES from '../../../../constants/workZones'
import stopPropagateEventFactory from '../../../../utils/stopPropagateEventFactory'
import { ClubContext } from '../../../ClubContext'

const Container = styled.div`
  display: inline-block;
`

const CreateProductButton = ({
  subcategoryUuid,
  onProductCreate: handleProductCreate,
  disabled,
  pushEvent,
  onProductModifierAdd: handleProductModifierAdd,
  onProductModifierRemove: handleProductModifierRemove,
}) => {
  const { t } = useTranslation()
  const [isOpen, toggleIsOpen] = useCollapse(false)
  const { clubId: pathClubId } = useParams()
  const { clubId } = useContext(ClubContext)
  const initialValues = {
    id: '',
    name: '',
    workZone: WORK_ZONES[0].value,
    vatType: 'A',
    price: '',
    volume: '',
    description: '',
    imageUrl: '',
    subcategoryUuid,
    availabilityHoursBegin: null,
    availabilityHoursEnd: null,
    onlyPreview: false,
    modifiers: [],
  }

  return (
    <Container onClick={stopPropagateEventFactory()}>
      <Button onClick={toggleIsOpen} disabled={disabled}>{t('product.add')}</Button>
      <AddNewProductDialog
        initialValues={initialValues}
        title={t('product.new')}
        open={isOpen}
        onClose={toggleIsOpen}
        onSubmit={handleProductCreate}
        pushEvent={pushEvent}
        clubId={pathClubId ?? clubId}
        onAdd={handleProductModifierAdd}
        onRemove={handleProductModifierRemove}
      />
    </Container>
  )
}

CreateProductButton.propTypes = {
  subcategoryUuid: IdPropType.isRequired,
  disabled: PropTypes.bool,
  onProductCreate: PropTypes.func,
  pushEvent: PropTypes.func.isRequired,
  onProductModifierAdd: PropTypes.func,
  onProductModifierRemove: PropTypes.func,
}

CreateProductButton.defaultProps = {
  disabled: false,
  onProductCreate: () => {},
  onProductModifierAdd: () => {},
  onProductModifierRemove: () => {},
}

export default memo(CreateProductButton)
