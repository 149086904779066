import { createSlice } from '@reduxjs/toolkit'
import { useReducer } from 'react'

const createInitialState = ({ ...rest }) => ({
  page: 0,
  size: 0,
  ...rest,
})

const pagination = createSlice({
  name: 'pagination',
  reducers: {
    setPage(state, action) {
      state.page = action.payload
    },
    setSize(state, action) {
      state.page = 0
      state.size = action.payload
    },
  },
})

const useMemoryPagination = (defaultSize = 25) => {
  const [state, dispatch] = useReducer(
    pagination.reducer,
    { size: defaultSize },
    createInitialState,
  )

  return {
    ...state,
    setPageSize: size => dispatch(pagination.actions.setSize(size)),
    setPage: page => dispatch(pagination.actions.setPage(page)),
  }
}

export default useMemoryPagination
