import { createSlice } from '@reduxjs/toolkit'
import { maxBy } from 'lodash'

const getNextModifiersState = createSlice({
  name: 'GetNextModifiersState',
  initialState: null,
  reducers: {
    createModifier(modifiers, { payload: modifier }) {
      modifiers.push({
        ...modifier,
        options: [],
      })
    },
    updateModifier(modifiers, {
      payload: {
        uuid,
        ...modifierUpdates
      },
    }) {
      const modifierIndex = modifiers.findIndex(modifier => modifier.uuid === uuid)
      if (modifierIndex > -1) {
        modifiers[modifierIndex] = {
          ...modifiers[modifierIndex],
          ...modifierUpdates,
        }
      }
    },
    deleteModifier(modifiers, { payload: uuid }) {
      const indexToDelete = modifiers.findIndex(modifier => modifier.uuid === uuid)
      if (indexToDelete > -1) {
        modifiers.splice(indexToDelete, 1)
        modifiers.forEach((modifier, index) => {
          modifier.menuModifierOrder = index + 1
        })
      }
    },
    createOption(modifiers, { payload: { modifierUuid, ...option } }) {
      const modifierIndex = modifiers.findIndex(modifier => modifier.uuid === modifierUuid)
      if (modifierIndex > -1) {
        const maxPosition = maxBy(modifiers[modifierIndex].options, 'position')?.position || 0
        modifiers[modifierIndex].options.push({
          ...option,
          position: maxPosition,
        })
      }
    },
    reorderOption(modifiers, { payload: { modifierUuid, uuid, newOrderNumber } }) {
      const modifierIndex = modifiers.findIndex(modifier => modifier.uuid === modifierUuid)
      if (modifierIndex > -1) {
        const movedIndex = modifiers[modifierIndex].options.findIndex(
          option => option.uuid === uuid,
        )
        if (movedIndex > -1) {
          const [removed] = modifiers[modifierIndex].options.splice(movedIndex, 1)
          modifiers[modifierIndex].options.splice(newOrderNumber - 1, 0, removed)
          modifiers[modifierIndex].options.forEach((option, index) => {
            option.position = index + 1
          })
        }
      }
    },
    updateOption(modifiers, {
      payload: {
        modifierUuid,
        uuid,
        ...optionUpdates
      },
    }) {
      const modifierIndex = modifiers.findIndex(modifier => modifier.uuid === modifierUuid)
      if (modifierIndex > -1) {
        const optionIndex = modifiers[modifierIndex].options.findIndex(
          option => option.uuid === uuid,
        )
        if (optionIndex > -1) {
          modifiers[modifierIndex].options[optionIndex] = {
            ...modifiers[modifierIndex].options[optionIndex],
            ...optionUpdates,
          }
        }
      }
    },
    deleteOption(modifiers, { payload: { modifierUuid, uuid } }) {
      const modifierIndex = modifiers.findIndex(modifier => modifier.uuid === modifierUuid)
      if (modifierIndex > -1) {
        const indexToDelete = modifiers[modifierIndex].options.findIndex(
          option => option.uuid === uuid,
        )
        modifiers[modifierIndex].options.splice(indexToDelete, 1)

        modifiers[modifierIndex].options.forEach((option, index) => {
          option.position = index + 1
        })
      }
    },
  },
})

export default getNextModifiersState
