const BLUE = '#0062ff'
const DEEPER_BLUE = '#0059e8'
const LIGHT_BLUE = '#e6efff'
const DARK_BLUE = '#20283b'
const DARKER_BLUE = '#232732'
const RED = '#ea4644'
const DEEPER_RED = '#d13a38'
const GREEN = '#10c73e'
const DEEPER_GREEN = '#13ad3a'
const MARITIME = '#08959F'
const DEEPER_MARITIME = '#0f6f75'
const LIGHT_PURPLE = '#8b95b2'
const WHITE = '#ffffff'
const GRAY = '#f7f7f7'
const BLACK = '#000000'

export {
  BLUE,
  DEEPER_BLUE,
  LIGHT_BLUE,
  DARK_BLUE,
  DARKER_BLUE,
  RED,
  DEEPER_RED,
  GREEN,
  DEEPER_GREEN,
  MARITIME,
  DEEPER_MARITIME,
  LIGHT_PURPLE,
  WHITE,
  GRAY,
  BLACK,
}
