import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import FieldContainer from './FieldContainer'
import FieldHeader from './FieldHeader'
import FieldValue from './FieldValue'
import { RED, GREEN } from '../../../constants/colors'

const Circle = styled.div`
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 7px 1px 0;
  border-radius: 3.5px;
  background-color: ${({ status }) => (status ? GREEN : RED)};
`

const StatusField = ({ status, label, ...props }) => {
  const { t } = useTranslation()

  return (
    <FieldContainer {...props}>
      <FieldHeader>{label}</FieldHeader>
      <FieldValue>
        <Circle status={status} />
        {status ? t('common.active') : t('common.inactive')}
      </FieldValue>
    </FieldContainer>
  )
}

StatusField.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
}

export default StatusField
