import { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import styled from 'styled-components/macro'

import { BLUE, DARKER_BLUE, LIGHT_PURPLE } from '../../../../constants/colors'

const StyledListItem = styled(ListItem)(({ theme }) => `
  margin: 6px;
  border-radius: 5px;

  &.MuiListItem-root.Mui-selected {
    background-color: ${DARKER_BLUE};
  }

  &.Mui-selected .MuiListItemIcon-root {
    color: ${BLUE};
  }

  .MuiListItemIcon-root {
    min-width: 47px;
  }

  .MuiListItemText-root {
    margin-right: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.palette.text.navigationDraver};
  }

  .MuiTypography-body1 {
    font-size: 14px;
  }
`)

const StyledListIcon = styled(ListItemIcon)`
  color: ${LIGHT_PURPLE};

  .MuiSvgIcon-root {
    width: 22px;
    height: 22px;
  }
`

const ListItemRow = ({ icon, primary, to, onClick, selected }) => {
  const renderLink = useMemo(
    /* eslint-disable-next-line */
    () => to ? forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />) : undefined,
    [to],
  )

  return (
    <li>
      <StyledListItem
        button
        selected={selected}
        component={renderLink}
        onClick={onClick}
      >
        {icon && <StyledListIcon>{icon}</StyledListIcon>}
        <ListItemText primary={primary} />
      </StyledListItem>
    </li>
  )
}

ListItemRow.propTypes = {
  selected: PropTypes.bool,
  icon: PropTypes.node,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

ListItemRow.defaultProps = {
  selected: false,
  icon: null,
  to: null,
  onClick: () => {},
}

export default ListItemRow
