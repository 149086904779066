import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Typography } from '@material-ui/core'

const HeaderTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

const SectionHeader = ({ title }) => (
  <HeaderTitle>{title}</HeaderTitle>
)

SectionHeader.propTypes = {
  title: PropTypes.string,
}

SectionHeader.defaultProps = {
  title: '',
}

export default SectionHeader
