import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import styled from 'styled-components/macro'

import { modifierOptionSchema } from '../../../validations/modifierOptionSchema'
import {
  TextField,
  FormButton,
} from '../../inputs'
import IdPropType from '../../../propTypes/IdPropType'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1.6;
`

const StyledTextField = styled(TextField)(({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`)

const StyledPriceTextField = props => (
  <StyledTextField
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <mat-icon matSuffix>PLN</mat-icon>
        </InputAdornment>
      ),
    }}
    {...props}
  />
)

const ModifierOptionDialog = ({
  open,
  onClose: handleClose,
  onSubmit: handleSubmit,
  dialogValues,
  title,
}) => {
  const { t } = useTranslation()

  const handleFormClose = () => {
    handleClose()
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    handleSubmit({
      ...values,
      price: (typeof values.price === 'string')
        ? parseInt(parseFloat(values.price.replace(',', '.')) * 100, 10)
        : values.price * 100,
    })
    setSubmitting(false)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        initialValues={dialogValues}
        validationSchema={modifierOptionSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle>
              {title}
            </DialogTitle>
            <StyledDialogContent dividers>
              <Field
                formatError={t}
                label={t('modifiers.options.name')}
                name="name"
                component={StyledTextField}
              />
              <Field
                formatError={t}
                label={t('modifiers.options.price')}
                name="price"
                type="number"
                component={StyledPriceTextField}
              />
            </StyledDialogContent>
            <DialogActions>
              <FormButton onClick={handleFormClose}>{t('common.back')}</FormButton>
              <FormButton
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                {t('common.save')}
              </FormButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

ModifierOptionDialog.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
}

ModifierOptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  dialogValues: PropTypes.exact({
    modifierUuid: IdPropType.isRequired,
    name: PropTypes.string,
    price: PropTypes.string,
  }),
  title: PropTypes.string,
}

ModifierOptionDialog.defaultProps = {
  dialogValues: {
    name: '',
    price: '',
  },
  title: '',
}

export default ModifierOptionDialog
