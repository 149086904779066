import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useClub } from '@itsilesia/udrink-common-components'
import { Button } from '@material-ui/core'
import styled from 'styled-components/macro'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import ClubDetails from '../../components/clubs/ClubDetails/ClubDetails'
import RouteHeader from '../../components/layout/RouteHeader'
import ButtonsSection from '../../components/layout/ButtonsSection'

const StyledButtonsSection = styled(ButtonsSection)`
  margin-top: 20px;
`

const ClubDataScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)
  const { data: club } = useClub(clubId)

  return (
    <RouteContainer fillHeight={false}>
      <RouteHeader>{t('local.data')}</RouteHeader>
      {club && (
        <>
          <ClubDetails club={club} />
          <StyledButtonsSection>
            <Button variant="contained" color="primary" to="data/edit" component={Link}>
              {t('common.goToEdit')}
            </Button>
          </StyledButtonsSection>
        </>
      )}
    </RouteContainer>
  )
}

export default ClubDataScreen
