import {
  AccountCircle,
  Equalizer,
  RestaurantMenu,
  LocationOn,
  Schedule,
  Timeline,
  ConfirmationNumberOutlined,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Divider } from '@material-ui/core'

import ROLE_GROUPS from './roleGroups'
import {
  CollectIcon,
  PreparationIcon,
  DataIcon,
  InventoryIcon,
} from '../assets/icons'

const ADMIN_TABS = Object.freeze([
  {
    to: '/clubs',
    name: 'clubs',
    translationKey: 'drawer.navigation.clubs',
    icon: <LocationOn />,
  },
  {
    to: '/users',
    name: 'users',
    translationKey: 'drawer.navigation.users',
    icon: <AccountCircle />,
  },
  {
    to: '/tax-rates',
    name: 'tax-rates',
    translationKey: 'drawer.navigation.taxRates',
    icon: <Equalizer />,
    restrictRole: ROLE_GROUPS.ADMIN.value,
  },
])

const MANAGER_TABS = Object.freeze([
  {
    to: '/statistics',
    name: 'statistics',
    translationKey: 'drawer.navigation.statistics',
    icon: <Timeline />,
  },
  {
    to: '/workers',
    partial: ['workers', 'users'],
    name: 'users',
    translationKey: 'drawer.navigation.workers',
    icon: <AccountCircle />,
  },
  {
    to: '/menu',
    partial: 'menu',
    name: '',
    translationKey: 'drawer.navigation.menu',
    icon: <RestaurantMenu />,
  },
  {
    to: '/modifiers',
    partial: 'modifiers',
    name: '',
    translationKey: 'drawer.navigation.modifiers',
    icon: <AddCircleIcon />,
  },
  {
    to: '/inventory',
    name: '',
    translationKey: 'drawer.navigation.inventory',
    icon: <InventoryIcon />,
  },
  {
    to: '/data',
    name: 'data',
    translationKey: 'drawer.navigation.data',
    icon: <DataIcon />,
  },
  {
    to: '/open-hours',
    partial: 'open-hours',
    name: 'openHours',
    translationKey: 'drawer.navigation.hours',
    icon: <Schedule />,
  },
  {
    to: '/discount-codes',
    partial: 'discount-codes',
    name: 'discountCodes',
    translationKey: 'drawer.navigation.discountCodes',
    icon: <ConfirmationNumberOutlined />,
  },
  {
    key: 'divider-2',
    component: Divider,
  },
  {
    to: '/order-collect-places',
    partial: 'order-collect-places',
    name: '',
    translationKey: 'drawer.navigation.orderCollect',
    icon: <CollectIcon />,
  },
  {
    to: '/order-preparation-places',
    partial: 'order-preparation-places',
    name: '',
    translationKey: 'drawer.navigation.orderPreparation',
    icon: <PreparationIcon />,
  },
  {
    to: '/order-delivery-places',
    partial: 'order-delivery-places',
    name: '',
    translationKey: 'drawer.navigation.orderDelivery',
    icon: <LocationOn />,
  },
])

export { ADMIN_TABS, MANAGER_TABS }
