import { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { authModule, SignInFormController } from '@itsilesia/udrink-common-components'

import SignInForm from '../components/forms/SignInForm'
import { signIn } from '../services/api'
import { ADMIN_ROLE, MANAGER_ROLE } from '../constants/roleGroups'

const Center = styled.div(({ theme }) => `
  background-color: ${theme.palette.background.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`)

const SignInScreen = () => {
  const [isInvalidData, setIsInvalidData] = useState(false)
  const [isInvalidRole, setIsInvalidRole] = useState(false)
  const dispatch = useDispatch()

  const handleSuccess = useCallback(
    ({ refreshToken, token, user }) => {
      setIsInvalidData(false)
      const { roleGroup: { name: userRole } } = user

      if (userRole === ADMIN_ROLE || userRole === MANAGER_ROLE) {
        setIsInvalidRole(false)
        dispatch(
          authModule.actions.setAuth({
            accessToken: token,
            refreshToken,
            user,
          }),
        )
      } else {
        setIsInvalidRole(true)
      }
    },
    [dispatch],
  )

  const handleError = () => {
    setIsInvalidData(true)
    setIsInvalidRole(false)
  }

  return (
    <Center>
      <SignInFormController
        initialValues={{
          username: '',
          password: '',
        }}
        signIn={signIn}
        onSuccess={handleSuccess}
        onError={handleError}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {props => (
          <SignInForm
            {...props}
            isInvalidData={isInvalidData}
            isInvalidRole={isInvalidRole}
          />
        )}
      </SignInFormController>
    </Center>
  )
}
export default SignInScreen
