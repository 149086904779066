import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const UserEditValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('user.errors.email.valid')
    .required('user.errors.email.required'),
  password: yup
    .string()
    .min(5, messageObject('user.errors.password.min')),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'user.errors.confirmPassword.notMatch')
    .when('password', (password, schema) => {
      if (password) {
        return schema.required('user.errors.confirmPassword.required')
      }
      return schema
    }),
})

const ClubUserEditValidationSchema = UserEditValidationSchema.shape({
  club: yup.array().of(yup.number()).required('user.errors.clubs.required'),
})

export {
  UserEditValidationSchema,
  ClubUserEditValidationSchema,
}
