import { useEffect, useRef } from 'react'

const useTotalCount = (total) => {
  const ref = useRef(0)
  useEffect(() => {
    if (total !== undefined) {
      ref.current = total
    }
  }, [total])
  return total ?? ref.current
}

export default useTotalCount
