import PropTypes from 'prop-types'
import IdPropType from './IdPropType'

const ClubPropType = PropTypes.shape({
  club: PropTypes.shape({
    id: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
})

export default ClubPropType
