import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { API } from '@itsilesia/udrink-common-components'
import useSWR from 'swr'
import styled from 'styled-components/macro'

import IdPropType from '../../../propTypes/IdPropType'

const StyledFormLabel = styled.p`
  font-weight: 600;
  margin: 0;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 10px;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductModifiersSection = ({
  clubId,
  setFieldValue,
  selectedmodifiers,
}) => {
  const { t } = useTranslation()
  const { data: modifiersList } = useSWR(() => API.modifiers.list(clubId).key)
  const isModifiersEmpty = !modifiersList?.length

  const handleCheckboxChange = ({ target }) => {
    setFieldValue('modifiers', { ...selectedmodifiers, [target.name]: target.checked })
  }

  return (
    <SectionWrapper>
      {isModifiersEmpty ? (
        <Typography>
          {t('modifiers.emptyHelper')}
        </Typography>
      ) : (
        <FormControl component="fieldset">
          <StyledFormLabel component="legend">{t('modifiers.helper')}</StyledFormLabel>
          <FormGroup>
            {modifiersList?.map(({ name, uuid, options }) => (
              <StyledFormControlLabel
                key={uuid}
                label={`${name} (${options.map(o => o.name)})`.replaceAll(',', ', ')}
                control={
                  <Checkbox
                    checked={selectedmodifiers[uuid]}
                    onChange={handleCheckboxChange}
                    name={uuid}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
    </SectionWrapper>
  )
}

ProductModifiersSection.propTypes = {
  clubId: IdPropType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedmodifiers: PropTypes.array,
}

ProductModifiersSection.defaultProps = {
  selectedmodifiers: [],
}

export default ProductModifiersSection
