import styled from 'styled-components/macro'

import omitProps from '../../../../utils/omitProps'
import { Select } from '../../../inputs'
import { LIGHT_BLUE } from '../../../../constants/colors'

const StyledSelect = styled(omitProps('hasChange')(Select))`
  background-color: ${({ hasChange }) => (hasChange ? LIGHT_BLUE : '')};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default StyledSelect
