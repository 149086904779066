import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import isEqual from 'lodash/isEqual'
import { useTaxRates } from '@itsilesia/udrink-common-components'

import TaxRatesRow from './TaxRatesRow'
import { updateVatRate } from '../../../services/api/vatRates'
import taxRatesValidationSchema from '../../../validations/taxRatesSchema'
import mapTaxRates from '../../../utils/mapTaxRates'
import useSnackbar from '../../../hooks/useSnackbar'
import FormFooter from '../FormFooter'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import StyledFormikForm from '../StyledFormikForm'

const TaxRateForm = () => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const { data, mutate } = useTaxRates()
  const taxRates = useMemo(() => mapTaxRates(data || []), [data])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await mutate(
        () =>
          Promise.all(
            values.map(
              taxRate => (!isEqual(taxRates[taxRate.id - 1], taxRate)
                ? updateVatRate(taxRate)
                : taxRate),
            ),
          ),
        false,
      )
      addSnackbar(t('taxRates.success'))
    } catch (err) {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    !!taxRates.length && (
      <Formik
        initialValues={taxRates}
        onSubmit={handleSubmit}
        validationSchema={taxRatesValidationSchema}
      >
        {({ values }) => (
          <StyledFormikForm>
            <FormStyleWrapper>
              {values.map(({ id, vatType, billingType }, index) => (
                <TaxRatesRow
                  key={id}
                  index={index}
                  billingType={billingType}
                  name={vatType}
                />
              ))}
            </FormStyleWrapper>
            <FormFooter confirmMessageKey={t('common.save')} />
          </StyledFormikForm>
        )}
      </Formik>
    )
  )
}

export default TaxRateForm
