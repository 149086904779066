import { useMemo, useCallback, useReducer } from 'react'
import { API, usePrevious } from '@itsilesia/udrink-common-components'
import { createSlice } from '@reduxjs/toolkit'
import useSWR from 'swr'

import getNextModifiersState from '../utils/getNextModifiersState'

const createInitialPipeState = (rest = {}) => ({
  pipe: [],
  ...rest,
})

const EventsPipe = createSlice({
  name: 'ModifierEventsPipe',
  initialState: createInitialPipeState(),
  reducers: {
    push(state, action) {
      state.pipe.push(action.payload)
    },
    clear(state) {
      state.pipe = []
    },
  },
})

const isMissingModifiersError = error => (
  error?.response?.status === 404
)

const useModifiersForm = ({ clubId }) => {
  const [pipeState, pipeDispatch] = useReducer(EventsPipe.reducer, {}, createInitialPipeState)
  const prevPipe = usePrevious(pipeState.pipe)

  const pushEvent = useCallback(event => pipeDispatch(EventsPipe.actions.push(event)), [])
  const clearEvents = useCallback(() => pipeDispatch(EventsPipe.actions.clear()), [])

  const {
    data: currentModifiers,
    isValidating,
    error: modifiersError,
    mutate,
  } = useSWR(() => API.modifiers.list(clubId).key)

  const prevModifiers = usePrevious(currentModifiers)
  const activeModifiers = useMemo(() => {
    if (!currentModifiers) {
      return null
    }

    const data = pipeState.pipe === prevPipe && pipeState.pipe.length !== 0
      ? prevModifiers
      : currentModifiers

    const modifiers = pipeState.pipe.reduce(
      getNextModifiersState.reducer,
      data,
    )

    return modifiersError
      ? []
      : modifiers
  }, [currentModifiers, pipeState.pipe, prevPipe, prevModifiers, modifiersError])

  const loading = !modifiersError && isValidating

  const revalidate = async () => { await mutate() }

  return {
    revalidate,
    pipeState,
    modifiers: activeModifiers,
    pushEvent,
    isValidating: loading,
    clearEvents,
    error: isMissingModifiersError(modifiersError) ? null : modifiersError,
  }
}

export default useModifiersForm
