import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '@itsilesia/udrink-common-components'
import styled from 'styled-components/macro'

import UsersListUsernameLink from '../../data/UsersList/UsersListUsernameLink'
import ClubPropType from '../../../propTypes/ClubPropType'
import { FieldContainer, FieldHeader, FieldValue } from '../../data/fields'
import UserDialog from '../../data/UserDetails/UserDialog'

import { BLUE } from '../../../constants/colors'

const StyledFieldValue = styled(FieldValue)`
  cursor: pointer;
  color: ${BLUE};

  &:hover {
    text-decoration: underline;
  }
`

const ClubOwnerDetails = ({ club: { club } }) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { data: owner } = useUser(club.ownerId)

  const content = owner ? (
    <UsersListUsernameLink row={owner} />
  ) : (
    <FieldValue>{t('common.missing')}</FieldValue>
  )

  return (
    <FieldContainer>
      <FieldHeader>{t('clubs.form.owner')}</FieldHeader>
      <StyledFieldValue onClick={() => setIsDialogOpen(true)}>
        {content}
      </StyledFieldValue>
      <UserDialog
        isOpen={isDialogOpen}
        user={owner}
        onClose={() => setIsDialogOpen(false)}
      />
    </FieldContainer>
  )
}

ClubOwnerDetails.propTypes = {
  club: ClubPropType.isRequired,
}

export default ClubOwnerDetails
