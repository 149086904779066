import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'

const HeaderContainer = styled.div`
  display: flex;
  padding: 10px;
  padding-left: 0;
  margin: 10px 0 0;
  cursor: pointer;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  margin-right: 10px;
  font-size: 16px;
`

const AlertHeader = styled(Header)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.error.main};
`

const IconContainer = styled.div`
  transform: translateY(-8%);
`

const SectionHeader = ({ title, collapse, toggleCollapse, alert }) => (
  <HeaderContainer
    title={title}
    onClick={toggleCollapse}
  >
    <InnerContainer>
      <Header>{title}</Header>
      <AlertHeader>{alert}</AlertHeader>
    </InnerContainer>
    <IconContainer>
      {collapse
        ? <ArrowDropDown />
        : <ArrowDropUp />}
    </IconContainer>
  </HeaderContainer>
)

SectionHeader.propTypes = {
  title: PropTypes.string,
  collapse: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  alert: PropTypes.string,
}

SectionHeader.defaultProps = {
  title: '',
  collapse: false,
  toggleCollapse: () => {},
  alert: '',
}

export default SectionHeader
