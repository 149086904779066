import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import InventoryForm from '../forms/InventoryForm'

import InventorySearch from './InventorySearch'

const Inventory = ({
  data,
  searchValue,
  onSearchValueChange: handleSearchValueChange,
  onResourceUpdate: handleResourceUpdate,
  onResourceCreate: handleResourceCreate,
  onResourceDelete: handleResourceDelete,
  ...tableData
}) => {
  const { t } = useTranslation()

  const parsedData = useMemo(
    () => data
      .map(({ id, ...rest }) => ({
        id: rest.product.id,
        productAmountId: id,
        ...rest,
      })),
    [data],
  )

  return (
    <>
      <InventorySearch
        searchValue={searchValue}
        onSearch={handleSearchValueChange}
      />
      <InventoryForm
        data={parsedData}
        onResourceUpdate={handleResourceUpdate}
        onResourceCreate={handleResourceCreate}
        onResourceDelete={handleResourceDelete}
        emptyArrayMessage={searchValue ? `${t('inventory.noItemsMessageFor')} "${searchValue}"` : t('inventory.noFindMessage')}
        {...tableData}
      />
    </>
  )
}

Inventory.propTypes = {
  data: PropTypes.array,
  searchValue: PropTypes.string,
  onSearchValueChange: PropTypes.func,
  onResourceUpdate: PropTypes.func,
  onResourceCreate: PropTypes.func,
  onResourceDelete: PropTypes.func,
}

Inventory.defaultProps = {
  data: [],
  searchValue: '',
  onSearchValueChange: () => {},
  onResourceUpdate: () => {},
  onResourceCreate: () => {},
  onResourceDelete: () => {},
}
export default Inventory
