import { useState, useCallback, useEffect } from 'react'

const useClockInterval = ({
  from = 0,
  to = 10,
  interval: intervalTime = 1000,
  condition = false,
}) => {
  const [clock, setClock] = useState(() => from)
  const [interval, setIntervalInstance] = useState(null)

  const createIntervalInstance = useCallback(
    () =>
      setInterval(() => {
        if (clock + 1 > to) {
          setClock(from)
        } else {
          setClock(o => o + 1)
        }
      }, intervalTime),
    /* eslint-disable-next-line */
    [from, to, intervalTime],
  )

  const toggleClock = useCallback(() => {
    if (interval) {
      clearInterval(interval)
      setIntervalInstance(null)
    } else {
      setIntervalInstance(createIntervalInstance)
    }
  }, [interval, createIntervalInstance])

  useEffect(() => {
    if (condition && !interval) {
      setIntervalInstance(createIntervalInstance)
    }
    if (!condition && interval) {
      clearInterval(interval)
      setIntervalInstance(null)
    }
  }, [condition, interval, createIntervalInstance])

  return [clock, toggleClock]
}

export default useClockInterval
