import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  min-height: 100vh;
  display: flex;
`

export { ContentContainer, Container }
