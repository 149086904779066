import { getIn } from 'formik'

export const hasFieldProps = ({ form, field }) =>
  typeof form === 'object' && typeof field === 'object'

export const fieldToTextFieldProps = (props) => {
  if (!hasFieldProps(props)) {
    return props
  }

  const { field, form, variant = 'standard', disabled, formatError, ...rest } = props

  const { name } = field
  const { touched, errors, isSubmitting } = form

  const fieldError = getIn(errors, name)

  let errorText
  if (typeof fieldError === 'object') {
    const messageKey = fieldError.key
    const messageParams = fieldError.params
    errorText = formatError ? formatError(messageKey, messageParams) : fieldError
  } else {
    errorText = formatError ? formatError(fieldError) : fieldError
  }
  const showError = getIn(touched, name) && !!fieldError

  return {
    ...rest,
    ...field,
    variant,
    error: showError,
    helperText: showError ? errorText : rest.helperText,
    disabled: disabled !== undefined ? disabled : isSubmitting,
  }
}
