import * as yup from 'yup'

import {
  fiservAccessDataEditSchema,
  fiservAccessDataCreateSchema,
} from './fiservAccessDataSchema'
import { isValidNIP } from '../utils/isValidNIP'

const areDigitsOnly = value => (/^\d+$/.test(value))

const clubEditValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('clubs.errors.required.name'),
  nip: yup
    .string()
    .required('clubs.errors.required.nip')
    .test('Digits only', 'clubs.errors.noMatch.nipNumbers', areDigitsOnly)
    .test('Valid NIP', 'clubs.errors.noMatch.nip', isValidNIP),
  coordinates: yup
    .string()
    .matches(
      /^[ ]*[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)[,][ ]*[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
      'clubs.errors.noMatch.coordinates',
    ),
  address: yup
    .string()
    .required('clubs.errors.required.address'),
  zipCode: yup
    .string()
    .matches(/\d{2}-\d{3}$/, 'clubs.errors.noMatch.zipCode')
    .required('clubs.errors.required.zipCode'),
  city: yup
    .string()
    .required('clubs.errors.required.city'),
  country: yup
    .string()
    .required('clubs.errors.required.country'),
  fiservAccessData: fiservAccessDataEditSchema,
})

const clubCreateValidationSchema = clubEditValidationSchema.shape({
  fiservAccessData: fiservAccessDataCreateSchema,
})

const clubForManagerEditValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('clubs.errors.required.name'),
  isActive: yup
    .bool(),
})

export {
  clubCreateValidationSchema,
  clubEditValidationSchema,
  clubForManagerEditValidationSchema,
}
