import PropTypes from 'prop-types'
import { Select as FormikMuiSelect } from 'formik-material-ui'
import { Select as MuiSelect } from '@material-ui/core'

const Select = ({ field, form, onChange, ...rest }) => {
  // the intention of this function is to execute your own function when the field value changes
  // along with the default handler
  const handleChange = (event) => {
    onChange(event.target.value)
    field.onChange(event)
  }

  const myField = field
    ? {
      ...field,
      onChange: handleChange,
    }
    : null

  return field && form ? (
    <FormikMuiSelect field={myField} form={form} {...rest} />
  ) : (
    <MuiSelect onChange={onChange} {...rest} />
  )
}

Select.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  variant: PropTypes.string,
}

Select.defaultProps = {
  onChange: () => {},
  variant: 'outlined',
  field: null,
  form: null,
}

export default Select
