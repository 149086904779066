import { useMemo, useState, useEffect } from 'react'
import { useUsers, useClubUsers, authModule, useClubs } from '@itsilesia/udrink-common-components'
import { useQueryParam, StringParam } from 'use-query-params'
import { useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'

import useDataList from '../../hooks/useDataList'
import ROLE_GROUPS, { ADMIN_ROLE, MANAGER_ROLE } from '../../constants/roleGroups'
import UsersList from '../../components/data/UsersList'
import RouteContainer from '../../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../../components/navigation/NavigationBreadcrumbs'
import RestrictedContent from '../../components/auth/RestrictedContent'

const UsersScreen = () => {
  const userRole = useSelector(state => authModule.selectors.getUserRole(state.auth))
  const [activeRoleGroup, setActiveRoleGroup] = useQueryParam('user-group', StringParam)
  const roleGroup = (activeRoleGroup
    || ROLE_GROUPS[userRole].value
    || MANAGER_ROLE
  ).toUpperCase()
  const { data: clubsData } = useClubs()

  const clubs = useMemo(() => clubsData?.content || [], [clubsData?.content])
  const [selectedClubId, setSelectedClubId] = useState('')
  useEffect(() => {
    if (clubs[0]) {
      setSelectedClubId(clubs[0].id)
    }
  }, [clubs])

  const useResource = userRole === ADMIN_ROLE
    ? ({ page, size }) =>
      useUsers({ // eslint-disable-line react-hooks/rules-of-hooks
        page,
        size,
        roleGroup,
      })
    : ({ page, size }) =>
      useClubUsers(selectedClubId, { // eslint-disable-line react-hooks/rules-of-hooks
        page,
        size,
      })

  const { handleChangePage, handleChangeRowsPerPage, ...dataTableProps } = useDataList(
    'users',
    useResource,
  )

  const { data: usersData, ...tableProps } = dataTableProps
  const data = useMemo(() => groupBy(usersData, 'roleGroup.name')[roleGroup] || [], [usersData, roleGroup])

  return (
    <RouteContainer>
      <RestrictedContent accessRole={ADMIN_ROLE}>
        <NavigationBreadcrumbs />
      </RestrictedContent>
      <UsersList
        activeRoleGroup={roleGroup}
        setActiveRoleGroup={value => setActiveRoleGroup(value.toLowerCase(), 'pushIn')}
        data={data}
        clubs={clubs}
        selectedClubId={selectedClubId}
        onClubChange={setSelectedClubId}
        {...tableProps}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </RouteContainer>
  )
}

export default UsersScreen
