import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import PeriodSummary from './PeriodSummary'
import TimePeriodPropTypes from '../../../../propTypes/TimePeriodPropTypes'

const StyledStatisticHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
`

const Image = styled.img`
  width: 65px;
  height: 65px;
  margin-right: 15px;
  transform: translateY(10px);
`

const StatisticHeader = ({
  iconSrc,
  children,
  timePeriod,
  summaryConfig,
  from,
  to,
}) => (
  <StyledStatisticHeader>
    <div>
      <Image src={iconSrc} alt="" />
      <span>{children}</span>
    </div>
    <PeriodSummary
      timePeriod={timePeriod}
      config={summaryConfig}
      from={from}
      to={to}
    />
  </StyledStatisticHeader>
)

StatisticHeader.propTypes = {
  iconSrc: PropTypes.string,
  children: PropTypes.string,
  timePeriod: TimePeriodPropTypes,
  summaryConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      textHelper: PropTypes.string,
    }),
  ),
  from: PropTypes.string,
  to: PropTypes.string,
}

StatisticHeader.defaultProps = {
  iconSrc: '',
  children: '',
  timePeriod: {},
  summaryConfig: [],
  from: '',
  to: '',
}

export default StatisticHeader
