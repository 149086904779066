import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Formik, Field } from 'formik'
import {
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import IdPropType from '../../../propTypes/IdPropType'
import FormModePropType from '../../../propTypes/FormModePropType'
import { TextField, Select } from '../../inputs'
import FormFooter from '../FormFooter'
import FormStyleWrapper from '../../wrappers/FormStyleWrapper'
import StyledFormikForm from '../StyledFormikForm'
import { CREATE } from '../../../constants/formModes'
import { ClubUserCreateValidationSchema } from '../../../validations/userCreateSchema'
import { ClubUserEditValidationSchema } from '../../../validations/userEditSchema'

const FormFields = styled(FormStyleWrapper)`
  display: flex;
  flex-direction: column;

  > * {
    margin: ${({ theme }) => theme.spacing(1)}px 0;
  }
`

const UserFormClubs = ({ initialValues, clubs, onSubmit, mode }) => {
  const currentClub = useSelector(state => state.currentClub)
  const formValues = useMemo(
    () => {
      const club = [
        mode === CREATE,
        currentClub,
        !initialValues.club.includes(currentClub),
      ].every(Boolean)
        ? [currentClub, ...initialValues.club]
        : initialValues.club

      return {
        ...initialValues,
        club,
        confirmPassword: '',
      }
    },
    [mode, initialValues, currentClub],
  )

  const userSchema = mode === CREATE ? ClubUserCreateValidationSchema : ClubUserEditValidationSchema

  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      validationSchema={userSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <StyledFormikForm>
          <FormFields>
            <Field
              name="email"
              label={t('user.form.email')}
              type="email"
              formatError={t}
              component={TextField}
            />
            <Field
              name="name"
              label={t('user.form.firstName')}
              formatError={t}
              component={TextField}
            />
            <Field
              name="surname"
              label={t('user.form.surname')}
              formatError={t}
              component={TextField}
            />
            <Field
              name="password"
              label={t('user.form.password')}
              type="password"
              formatError={t}
              component={TextField}
            />
            <Field
              name="confirmPassword"
              label={t('user.form.confirmPassword')}
              type="password"
              formatError={t}
              component={TextField}
            />
            <FormControl
              variant="outlined"
              error={!!errors.club}
            >
              <InputLabel>{t('user.form.clubs')}</InputLabel>
              <Field
                label={t('user.form.clubs')}
                name="club"
                component={Select}
                multiple
                MenuProps={{
                  getContentAnchorEl: () => null,
                  style: {
                    maxHeight: 300,
                  },
                }}
              >
                {clubs.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Field>
              {errors.club && <FormHelperText>{t(errors.club)}</FormHelperText>}
            </FormControl>
          </FormFields>
          <FormFooter
            onCancel={history.goBack}
            disabled={isSubmitting}
            confirmMessageKey={t('common.save')}
          />
        </StyledFormikForm>
      )}
    </Formik>
  )
}

UserFormClubs.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    club: PropTypes.arrayOf(IdPropType.isRequired).isRequired,
  }),
  clubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: IdPropType.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  onSubmit: PropTypes.func,
  mode: FormModePropType.isRequired,
}

UserFormClubs.defaultProps = {
  initialValues: {
    email: '',
    name: '',
    surname: '',
    password: '',
    club: [],
  },
  clubs: [],
  onSubmit: () => {},
}

export default UserFormClubs
