import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import styled from 'styled-components/macro'
import { Search } from '@material-ui/icons'
import { InputAdornment } from '@material-ui/core'

import TextField from '../inputs/TextField'
import useSnackbar from '../../hooks/useSnackbar'
import { BLUE } from '../../constants/colors'

const Container = styled.div`
  display: flex;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
`

const SearchIcon = styled(Search)`
  color: ${BLUE};
`

const InventorySearch = ({ onSearch: handleSearch }) => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const getInput = () => {
    const [input] = containerRef.current.getElementsByClassName('MuiInputBase-input')
    return input
  }

  const handleChange = () => {
    try {
      const { value } = getInput()
      handleSearch(value)
    } catch (err) {
      getInput().value = ''
      addSnackbar(t('common.errors.generic'))
    }
  }

  return (
    <Container ref={containerRef}>
      <StyledTextField
        onChange={debounce(handleChange, 1000)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

InventorySearch.propTypes = {
  onSearch: PropTypes.func,
}

InventorySearch.defaultProps = {
  onSearch: () => {},
}

export default InventorySearch
