import { useMemo } from 'react'

import InventoryFormActions from './InventoryFormActions'
import AmountLabel from './AmountLabel'
import AmountComponent from './AmountComponent'

const useInventoryColumns = (
  handleResourceUpdate,
  handleResourceCreate,
  handleResourceDelete,
) => useMemo(() => [{
  key: 'name',
  prop: 'product.name',
  width: '30%',
  label: { translationKey: 'product.title' },
},
{
  key: 'description',
  prop: 'product.description',
  label: { translationKey: 'product.description' },
},
{
  key: 'volume',
  prop: 'product.volume',
  width: '15%',
  label: { translationKey: 'product.portion' },
  alignText: 'right',
}, {
  key: 'amount',
  prop: 'amount',
  width: '15%',
  label: AmountLabel,
  component: AmountComponent,
  componentProps: {
    onResourceUpdate: handleResourceUpdate,
  },
  alignText: 'right',
}, {
  key: 'actions',
  prop: 'actions',
  width: '20%',
  label: '',
  component: InventoryFormActions,
  componentProps: {
    onResourceCreate: handleResourceCreate,
    onResourceDelete: handleResourceDelete,
  },
  alignText: 'right',
}], [handleResourceUpdate, handleResourceCreate, handleResourceDelete])

export default useInventoryColumns
