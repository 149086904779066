import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ButtonGroup, Button } from '@material-ui/core'
import { ArrowLeft, ArrowRight } from '@material-ui/icons'
import styled, { css } from 'styled-components/macro'

import CATEGORIES from '../../../constants/categories'
import omitProps from '../../../utils/omitProps'
import InfoHazeIcon from '../../InfoHazeIcon'
import CreateSubcategoryButton from './CreateSubcategoryButton'

const TableOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

const reorderButtonCss = css`
  position: absolute;
  min-width: 14px;
  opacity: 0;
  ${({ hide }) => hide && 'visibility: hidden;'}
`

const ReorderLeftButton = styled(omitProps('hide')(ArrowLeft))`
  ${reorderButtonCss}
  left: 6px;
`

const ReorderRightButton = styled(omitProps('hide')(ArrowRight))`
  ${reorderButtonCss}
  right: 6px;
`

const StyledButton = styled(Button)`
  position: relative;

  /* Stylelint processor have problems to interpretation of selectors. */
  /* More info here: https://github.com/styled-components/stylelint-processor-styled-components/issues/81 */
  &:hover ${/* sc-selector */ReorderLeftButton},
  &:hover ${/* sc-selector */ReorderRightButton} {
    opacity: 1;
  }
`
const CategoriesContainer = ({
  activeCategory,
  activeMenu,
  disabled,
  onNewActiveCategoryClick: handleNewActiveCategoryClick,
  onCategoryReorder: handleCategoryReorder,
  onSubcategoryCreate: handleSubcategoryCreate,
}) => {
  const { t } = useTranslation()
  const categories = Object.values(activeMenu)

  const handleReorder = (event, id, newIndex) => {
    event.stopPropagation()
    handleCategoryReorder(id, newIndex)
  }

  return (
    <TableOptions>
      <ButtonGroup variant="contained">
        {categories.map(({ name, id }, index, allCategories) => (
          <StyledButton
            key={name}
            color={activeCategory === name ? 'primary' : 'default'}
            onClick={() => handleNewActiveCategoryClick(name)}
          >
            <ReorderLeftButton
              hide={!index}
              onClick={event => handleReorder(event, id, index - 1)}
            />
            <span>{t(CATEGORIES[name].code)}</span>
            {CATEGORIES[name].info && <InfoHazeIcon message={t(CATEGORIES[name].info)} />}
            <ReorderRightButton
              hide={allCategories.length - 1 === index}
              onClick={event => handleReorder(event, id, index + 1)}
            />
          </StyledButton>
        ))}
      </ButtonGroup>
      <CreateSubcategoryButton
        onSubcategoryCreate={handleSubcategoryCreate}
        disabled={disabled}
      />
    </TableOptions>
  )
}

CategoriesContainer.propTypes = {
  activeMenu: PropTypes.object.isRequired,
  activeCategory: PropTypes.string,
  disabled: PropTypes.bool,
  onNewActiveCategoryClick: PropTypes.func,
  onSubcategoryCreate: PropTypes.func,
  onCategoryReorder: PropTypes.func,
}

CategoriesContainer.defaultProps = {
  activeCategory: CATEGORIES.ALCOHOLIC.value,
  disabled: false,
  onNewActiveCategoryClick: () => {},
  onCategoryReorder: () => {},
  onSubcategoryCreate: () => {},
}

export default CategoriesContainer
