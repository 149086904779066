import styled from 'styled-components/macro'

import { WHITE } from '../../constants/colors'

const FormStyleWrapper = styled.div`
  max-height: 70vh;
  padding: ${({ theme }) => theme.spacing(5)}px;
  background-color: ${WHITE};
  box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.1);
  border-radius: 5px;
  overflow-y: auto;

  &:hover {
    ${({ theme }) => theme.scrollbar}
  }
`

export default FormStyleWrapper
