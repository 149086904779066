import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const UserCreateValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('user.errors.email.valid')
    .required('user.errors.email.required'),
  password: yup
    .string()
    .min(5, messageObject('user.errors.password.min'))
    .required('user.errors.password.required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'user.errors.confirmPassword.notMatch')
    .required('user.errors.confirmPassword.required'),
})

const ClubUserCreateValidationSchema = UserCreateValidationSchema.shape({
  club: yup.array().of(yup.number()).required('user.errors.clubs.required'),
})

export {
  UserCreateValidationSchema,
  ClubUserCreateValidationSchema,
}
