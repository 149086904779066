const TIME_SCALE = Object.freeze({
  HOUR: {
    value: 'HOUR',
    code: 'statistics.timeScale.hour',
  },
  DAY: {
    value: 'DAY',
    code: 'statistics.timeScale.day',
  },
  WEEK: {
    value: 'WEEK',
    code: 'statistics.timeScale.week',
  },
})

export default TIME_SCALE
