import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { CropOriginal } from '@material-ui/icons'

import { WHITE, BLUE } from '../../../../constants/colors'

const LoadingMessage = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  font-size: 8px;
  line-height: 60px;
  text-align: center;
  color: ${BLUE};

  &:before, &:after {
    position: absolute;
    height: 100%;
    bottom: -14px;
    left: 50%;
  }

  &:before {
    content: '...';
    transform: translate(-50%, 0);
    opacity: 0;
    transition:
      transform 300ms ease,
      opacity 500ms ease-in-out;
    font-size: 20px;
  }

  &:hover:before {
    transform: translate(-50%, -5px);
    opacity: 1;
  }

  @keyframes move {
    0% {
      transform: translate(calc(-50% - 7px), -17px);
    }
    100% {
      transform: translate(calc(-50% + 7px), -17px);
    }
  }

  &:hover:after {
    content: '';
    height: 16px;
    width: 4px;
    transform: translate(calc(-50% - 7px), -17px);
    background: ${WHITE};
    animation: move 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-delay: .3s;
  }
`

const StyledImage = styled.img(({ theme, isLoading }) => `
  ${isLoading ? 'display: none' : ''};
  width: 56px;
  height: 56px;
  border: 1px  solid ${theme.palette.text.primary};
  border-radius: 4px;
  font-size: 9px;
  object-fit: cover;
`)

const StyledCropOriginal = styled(CropOriginal)`
  width: 56px;
  height: 56px;
  transform: scale(1.26);
`

const ProductImage = ({ src }) => {
  const { t } = useTranslation()
  const [isImageLoading, setIsImageLoading] = useState(!!src)
  const [isImageLoadSuccess, setIsImageLoadSuccess] = useState(false)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setIsImageLoading(!!src)
      setIsImageLoadSuccess(false)
    }
  }, [src])

  const handleStopLoading = () => {
    setIsImageLoading(false)
    setIsImageLoadSuccess(true)
  }

  const handleImageError = () => setIsImageLoading(false)

  return (
    <>
      {isImageLoading && <LoadingMessage>{t('common.loading')}</LoadingMessage>}
      {!isImageLoadSuccess && !isImageLoading
        ? <StyledCropOriginal />
        : (
          <StyledImage
            isLoading={isImageLoading}
            alt={t('image.title')}
            src={src}
            onError={handleImageError}
            onLoad={handleStopLoading}
            onAbort={handleStopLoading}
          />
        )}
    </>
  )
}

ProductImage.propTypes = {
  src: PropTypes.string,
}

ProductImage.defaultProps = {
  src: '',
}

export default ProductImage
