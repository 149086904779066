import { useTranslation } from 'react-i18next'

import useDataList from './useDataList'
import { updateProductAmount, deleteProductAmount, createProductAmount } from '../services/api'
import useSnackbar from './useSnackbar'

const useInventory = (key, useResource) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()

  const { handleChangePage, handleChangeRowsPerPage, mutate, ...props } = useDataList(
    key,
    useResource,
  )

  const handleResourceUpdate = async (productAmountId, newAmount) => {
    await mutate(async () => {
      try {
        await updateProductAmount(productAmountId, newAmount)
      } catch (error) {
        addSnackbar(t('common.errors.generic'))
        console.error(error) // eslint-disable-line no-console
        throw new Error()
      }
    })
  }

  const handleResourceCreate = async (rowId, fieldValue) => {
    await mutate(async () => {
      try {
        await createProductAmount(rowId, fieldValue)
      } catch (error) {
        addSnackbar(t('common.errors.generic'))
        console.error(error) // eslint-disable-line no-console
        throw new Error()
      }
    })
  }

  const handleResourceDelete = async (productAmountId) => {
    await mutate(async () => {
      try {
        await deleteProductAmount(productAmountId)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
        addSnackbar(t('common.errors.generic'))
        throw new Error()
      }
    })
  }

  return {
    handleChangePage,
    handleChangeRowsPerPage,
    handleResourceUpdate,
    handleResourceCreate,
    handleResourceDelete,
    ...props,
  }
}

export default useInventory
