import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Container = styled.header`
  font-size: 12px;
`

const FieldHeader = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
)

FieldHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FieldHeader
