import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, Checkbox } from '@material-ui/core'
import get from 'lodash/get'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'

const DataTableRow = ({
  checked,
  row,
  columns,
  index,
  onToggle,
  enableSelect,
  isSingleItemSelect,
}) => {
  const renderDataCell = useCallback((column) => {
    const props = { row, column, index }
    const children = column.render ? column.render(props) : get(row, column.prop, '')

    const Component = column.component

    return (
      <TableCell
        key={column.key}
        scope="row"
        align={column.alignText}
        {...column.cellProps}
      >
        {Component ? <Component {...column.componentProps} {...props} /> : children}
      </TableCell>
    )
  }, [index, row])

  return (
    <TableRow>
      {enableSelect && (
        <TableCell onClick={e => onToggle(e, row.id)} padding="checkbox">
          <Checkbox
            checked={checked}
            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
            checkedIcon={isSingleItemSelect ? <RadioButtonChecked /> : undefined}
            icon={isSingleItemSelect ? <RadioButtonUnchecked /> : undefined}
          />
        </TableCell>
      )}
      {columns
        .filter(column => !(enableSelect && column.key === 'actions'))
        .map(column => renderDataCell(column))}
    </TableRow>
  )
}

DataTableRow.defaultProps = {
  onToggle: () => {},
  enableSelect: false,
  isSingleItemSelect: false,
}

DataTableRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onToggle: PropTypes.func,
  enableSelect: PropTypes.bool,
  isSingleItemSelect: PropTypes.bool,
}

export default memo(DataTableRow)
