import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@material-ui/core'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'

import IdPropType from '../../../propTypes/IdPropType'
import { OrderDeliveryPlaceContext } from '../../../providers/OrderDeliveryPlaceContextProvider'

const OrderDeliveryPlacesListActions = ({ row }) => {
  const { t } = useTranslation()
  const {
    setOrderDeliveryPlaceId,
    setIsDeleteOrderDeliveryPlaceDialogOpen,
  } = useContext(OrderDeliveryPlaceContext)

  return (
    <>
      <Tooltip
        title={t('tableTooltips.edit')}
        aria-label={t('tableTooltips.edit')}
      >
        <IconButton
          component={Link}
          to={`/clubs/${row.clubId}/order-delivery-places/${row.id}/edit`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={t('tableTooltips.delete')}
        aria-label={t('tableTooltips.delete')}
      >
        <IconButton onClick={() => {
          setOrderDeliveryPlaceId(row.id)
          setIsDeleteOrderDeliveryPlaceDialogOpen(true)
        }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

OrderDeliveryPlacesListActions.propTypes = {
  row: PropTypes.shape({
    id: IdPropType.isRequired,
    clubId: IdPropType.isRequired,
  }).isRequired,
}

export default OrderDeliveryPlacesListActions
