import { useParams } from 'react-router-dom'

import Menu from '../components/menu/Menu'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import RouteContainer from '../components/layout/RouteContainer'

const MenuScreen = () => {
  const { clubId } = useParams()

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      <Menu clubId={clubId} />
    </RouteContainer>
  )
}

export default MenuScreen
