import { useTranslation } from 'react-i18next'
import { Close } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { BLACK, WHITE } from '../constants/colors'

const MuiDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    text-align: center;
  }
`

const MuiDialogContent = styled(DialogContent)(({ theme }) => `
  &.MuiDialogContent-root {
    text-align: center;
    color: ${BLACK};
    margin-bottom: ${theme.spacing(2)}px;
  }
`)

const MuiDialogActions = styled(DialogActions)(({ theme }) => `
  &.MuiDialogActions-root {
    justify-content: center;
    gap: ${theme.spacing(6)}px;
  }
`)

const CancelButton = styled(Button)(({ color }) => `
  &.MuiButton-outlined {
    color: ${color};
    border: 1px solid ${color};
    width: 100px;
  }
`)

const ConfirmButton = styled(Button)(({ color }) => `
  &.MuiButton-contained {
    color: ${WHITE};
    background-color: ${color};
    border: 1px solid ${color};
    width: 100px;
  }
`)

const MuiDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
`

const DialogContainer = styled.div(({ theme }) => `
    padding: ${theme.spacing(3)}px ${theme.spacing(8)}px ${theme.spacing(6)}px ${theme.spacing(8)}px;
`)

const CloseIcon = styled(IconButton)`
    align-self: end;
`

const DeleteConfirmationDialog = ({
  isOpen,
  titleText,
  onConfirm: handleConfirm,
  onCancel: handleCancel,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseIcon
        aria-label="close"
        onClick={handleCancel}
      >
        <Close />
      </CloseIcon>
      <DialogContainer>
        <MuiDialogTitle id="alert-dialog-title">{titleText}</MuiDialogTitle>
        <MuiDialogContent>
          <Typography>{t('user.screen.delete.deleteAlertMessage')}</Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <CancelButton
            onClick={handleCancel}
            variant="outlined"
            color={theme.palette.error.main}
          >
            {t('common.cancel')}
          </CancelButton>
          <ConfirmButton
            onClick={handleConfirm}
            color={theme.palette.error.main}
            variant="contained"
          >
            {t('common.delete')}
          </ConfirmButton>
        </MuiDialogActions>
      </DialogContainer>
    </MuiDialog>
  )
}

DeleteConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default DeleteConfirmationDialog
