import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FormButton } from './inputs'

const AlertDialog = ({
  onConfirm: handleConfirm,
  onCancel: handleCancel,
  message,
  title,
  isOpen,
  confirmText,
  confirmColor,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} autoFocus>
          {t('common.cancel')}
        </Button>
        <FormButton onClick={handleConfirm} color={confirmColor}>
          {confirmText}
        </FormButton>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmColor: PropTypes.string,
}

AlertDialog.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  message: '',
  title: '',
  isOpen: false,
  confirmText: '',
  confirmColor: 'primary',
}

export default AlertDialog
