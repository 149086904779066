const WORK_ZONES = Object.freeze([
  {
    value: 'BAR',
    code: 'orderPreparation.zones.bar',
  },
  {
    value: 'KITCHEN',
    code: 'orderPreparation.zones.kitchen',
  },
])

export default WORK_ZONES
