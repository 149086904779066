import { createContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const OrderCollectPlaceContext = createContext(null)

const OrderCollectPlaceContextProvider = ({ children }) => {
  const [orderCollectPlaceUuid, setOrderCollectPlaceUuid] = useState(null)
  const [orderCollectPlaceId, setOrderCollectPlaceId] = useState(null)
  const [
    isDeleteOrderCollectPlaceDialogOpen,
    setIsDeleteOrderCollectPlaceDialogOpen,
  ] = useState(false)

  const values = useMemo(() => ({
    orderCollectPlaceUuid,
    orderCollectPlaceId,
    isDeleteOrderCollectPlaceDialogOpen,
    setOrderCollectPlaceUuid,
    setOrderCollectPlaceId,
    setIsDeleteOrderCollectPlaceDialogOpen,
  }), [
    orderCollectPlaceUuid,
    orderCollectPlaceId,
    isDeleteOrderCollectPlaceDialogOpen,
    setOrderCollectPlaceUuid,
    setOrderCollectPlaceId,
    setIsDeleteOrderCollectPlaceDialogOpen,
  ])

  return (
    <OrderCollectPlaceContext.Provider
      value={values}
    >
      {children}
    </OrderCollectPlaceContext.Provider>
  )
}

OrderCollectPlaceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OrderCollectPlaceContextProvider
