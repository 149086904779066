import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import styled from 'styled-components/macro'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import { AddAlarm as AddAlarmIcon, TimerOff as TimerOffIcon } from '@material-ui/icons'

import { TextField } from '../../inputs'
import IdPropType from '../../../propTypes/IdPropType'
import useCollapse from '../../../hooks/useCollapse'

const Row = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  color: ${theme.palette.text.secondary};
`,
)

const Column = styled.div`
  flex: 1;
`

const DayColumn = styled.div`
  width: 50px;
`

const ColumnGap = styled.div`
  width: 18px;
`

const HourLabel = styled.p`
  text-align: left;
  margin-bottom: -8px;
`

const StyledTextField = styled(TextField)(
  ({ theme }) => `
  width: 100%;
  margin: ${theme.spacing(1)}px 0;
`,
)

const DisableColumn = styled.div`
  flex: 0.1;
`

const MainColumn = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 72px;
  line-height: 72px;
`

function HoursRow({ dayName, showHeader, setFieldValue, values, clubId, ...other }) {
  const { t } = useTranslation()
  const [isClubClosed, toggleIsClubClosed] = useCollapse(values && !values.openingHours[dayName])

  const handleClubClosedChange = () => {
    const clearValues = !isClubClosed
      ? undefined
      : {
        openTime: '00:00',
        closeTime: '00:00',
      }

    const openingHours = { ...values.openingHours }
    openingHours[dayName] = clearValues
    setFieldValue('openingHours', openingHours)
    toggleIsClubClosed()
  }

  useEffect(() => {
    toggleIsClubClosed(!values?.openingHours[dayName])
  }, [values, dayName, toggleIsClubClosed])

  return (
    <Typography component="div" {...other}>
      {showHeader && (
        <Row>
          <DayColumn />
          <Column>
            <HourLabel>{t('clubs.form.beginHour')}</HourLabel>
          </Column>
          <ColumnGap />
          <Column>
            <HourLabel>{t('clubs.form.closeHour')}</HourLabel>
          </Column>
        </Row>
      )}
      <Row>
        <DayColumn>{t(`clubs.form.daysOfWeek.${dayName.slice(0, 3).toLowerCase()}`)}</DayColumn>
        {isClubClosed ? (
          <MainColumn>
            <span>{t('clubs.form.isClosedThisDay')}</span>
          </MainColumn>
        ) : (
          <MainColumn>
            <Column>
              <Field
                type="time"
                formatError={t}
                name={`openingHours.${dayName}.openTime`}
                component={StyledTextField}
                required
              />
            </Column>
            <ColumnGap />
            <Column>
              <Field
                type="time"
                formatError={t}
                name={`openingHours.${dayName}.closeTime`}
                component={StyledTextField}
                required
              />
            </Column>
          </MainColumn>
        )}
        <DisableColumn>
          <Tooltip
            title={t(`clubs.form.${isClubClosed ? 'setOpeningHours' : 'closeOnThisDay'}`)}
            aria-label={t(`clubs.form.${isClubClosed ? 'setOpeningHours' : 'closeOnThisDay'}`)}
          >
            <IconButton onClick={handleClubClosedChange} aria-label="disable-column">
              {isClubClosed ? <AddAlarmIcon /> : <TimerOffIcon />}
            </IconButton>
          </Tooltip>
        </DisableColumn>
      </Row>
    </Typography>
  )
}

HoursRow.defaultProps = {
  children: null,
  showHeader: false,
  setFieldValue: () => { },
  clubId: null,
}

HoursRow.propTypes = {
  children: PropTypes.node,
  showHeader: PropTypes.bool,
  setFieldValue: PropTypes.func,
  dayName: PropTypes.string.isRequired,
  values: PropTypes.shape({
    openingHours: PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    }),
  }).isRequired,
  clubId: IdPropType,
}

export default HoursRow
