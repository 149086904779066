import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'

import StyledSelect from './StyledSelect'

const VatRateSelect = ({ flex, taxRates, ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledSelect style={{ flex }} {...props}>
      {taxRates.map(({ id: taxRateId, vatType }) => (
        <MenuItem key={taxRateId} value={vatType}>
          {`${t('taxRates.rate')} ${vatType}`}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

VatRateSelect.propTypes = {
  flex: PropTypes.string,
  taxRates: PropTypes.array,
}

VatRateSelect.defaultProps = {
  flex: '1',
  taxRates: [],
}

export default VatRateSelect
