import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Checkbox } from '@material-ui/core'

import CLIENTS from '../../../../../constants/clients'
import getPayloadColor from '../../chart/utils/getPayloadColor'

const List = styled.ul`
  display: block;
  white-space: nowrap;
  margin: 0;
  margin-top: 15px;
  padding-left: 31px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: 20px;
  opacity: 0.7;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 200ms ease;

  ${({ active }) => active
    && 'opacity: 1;'}
`

const ClientsLegend = ({
  payload,
  onOldClientsClick,
  onNewClientsClick,
  onAllClientsClick,
  isOldClientsChecked,
  isNewClientsChecked,
  isAllClientsChecked,
}) => {
  const { t } = useTranslation()
  // Styles are destructed because array payload items order depends on chart lines render order.
  // So this approach prevents dynamicly reordering legend list items.
  const oldClientsStyle = { color: getPayloadColor(payload, CLIENTS.OLD.value) }
  const newClientsStyle = { color: getPayloadColor(payload, CLIENTS.NEW.value) }
  const allClientsStyle = { color: getPayloadColor(payload, CLIENTS.ALL.value) }

  return (
    <List>
      <ListItem
        active={isOldClientsChecked}
        style={oldClientsStyle}
        onClick={onOldClientsClick}
      >
        <Checkbox checked={isOldClientsChecked} style={oldClientsStyle} />
        <span>{t(CLIENTS.OLD.code)}</span>
      </ListItem>
      <ListItem
        active={isNewClientsChecked}
        style={newClientsStyle}
        onClick={onNewClientsClick}
      >
        <Checkbox checked={isNewClientsChecked} style={newClientsStyle} />
        <span>{t(CLIENTS.NEW.code)}</span>
      </ListItem>
      <ListItem
        active={isAllClientsChecked}
        style={allClientsStyle}
        onClick={onAllClientsClick}
      >
        <Checkbox checked={isAllClientsChecked} style={allClientsStyle} />
        <span>{t(CLIENTS.ALL.code)}</span>
      </ListItem>
    </List>
  )
}

ClientsLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  onOldClientsClick: PropTypes.func,
  onNewClientsClick: PropTypes.func,
  onAllClientsClick: PropTypes.func,
  isOldClientsChecked: PropTypes.bool,
  isNewClientsChecked: PropTypes.bool,
  isAllClientsChecked: PropTypes.bool,
}

ClientsLegend.defaultProps = {
  payload: [],
  onOldClientsClick: () => {},
  onNewClientsClick: () => {},
  onAllClientsClick: () => {},
  isOldClientsChecked: false,
  isNewClientsChecked: false,
  isAllClientsChecked: false,
}
export default ClientsLegend
