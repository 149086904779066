import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { DARK_BLUE } from '../constants/colors'
import SendSentryReportForm from '../components/forms/SendSentryReportForm'
import SentryFeedbackSentMessage from './SentryFeedbackSentMessage'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${DARK_BLUE};
`

const SendReportScreen = ({
  onConfirm: handleConfirm,
  isFeedbackSent,
}) => (
  <Container>
    {isFeedbackSent
      ? <SentryFeedbackSentMessage />
      : <SendSentryReportForm onConfirm={handleConfirm} />}
  </Container>
)

SendReportScreen.propTypes = {
  onConfirm: PropTypes.func,
  isFeedbackSent: PropTypes.bool,
}

SendReportScreen.defaultProps = {
  onConfirm: () => {},
  isFeedbackSent: false,
}

export default SendReportScreen
