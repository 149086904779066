import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { InfoOutlined } from '@material-ui/icons'
import { Box, Tooltip } from '@material-ui/core'

import { BLUE } from '../../../../constants/colors'

const Container = styled(Box)(({ theme }) => `
  display: flex;
  align-items: center;
  gap: 10px;

  .MuiSvgIcon-root {
    color: ${BLUE};
    margin-right: ${theme.spacing(2)}px;
  }
`)

export const ContainerWithInfoTooltip = ({ children, info }) => (
  <Container>
    {children}
    <Tooltip arrow title={info}>
      <InfoOutlined />
    </Tooltip>
  </Container>
)

ContainerWithInfoTooltip.propTypes = {
  children: PropTypes.func.isRequired,
  info: PropTypes.string.isRequired,
}
