import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const productWithoutPriceSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('product.errors.name.required'),
  workZone: yup
    .string()
    .required('product.errors.workZone.required'),
  vatType: yup
    .string()
    .required('product.errors.vatType.required'),
  volume: yup
    .string()
    .max(255, messageObject('product.errors.portion.max')),
  availabilityHours: yup
    .object().shape({
      beginTime: yup
        .string()
        .nullable(),
      endTime: yup
        .string()
        .nullable(),
    }),
  description: yup
    .string()
    .max(1000, messageObject('product.errors.description.max')),
})

const productWithPriceInPlnSchema = productWithoutPriceSchema.shape({
  price: yup
    .string()
    .required('product.errors.price.required')
    .test('isNumberTest', 'product.errors.price.isNumber', value => !!parseFloat(value))
    .test('isPositiveNumberTest', 'product.errors.price.isPositiveNumber', value => parseFloat(value) >= 0)
    .test('minimumTest', 'product.errors.price.minimum', value => parseFloat(value) >= 0.01),
})

const productWithPriceInPeanutsSchema = productWithoutPriceSchema.shape({
  price: yup
    .string()
    .required('product.errors.price.required')
    .test('isNumberTest', 'product.errors.price.isNumber', value => !!parseFloat(value))
    .test('isPositiveNumberTest', 'product.errors.price.isPositiveNumber', value => parseFloat(value) >= 0)
    .test('minimumTest', 'product.errors.price.minimum', value => parseFloat(value) >= 1),
})

export {
  productWithoutPriceSchema,
  productWithPriceInPlnSchema,
  productWithPriceInPeanutsSchema,
}
