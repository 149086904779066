/* eslint-disable import/no-import-module-exports */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import rootReducer from './rootReducer'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['auth'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = getDefaultMiddleware({
  thunk: false,
  // disabled because redux-persist has to inject not serializable functions in actions
  serializableCheck: false,
})

const store = configureStore({
  reducer: persistedReducer,
  middleware,
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    /* eslint-disable-next-line global-require */
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export const persistor = persistStore(store)
export default store
