import { memo, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

const MenuProductInput = ({
  component: Component,
  componentPlaceholder: ComponentPlaceholder,
  value,
  placeholderValue: placeholderText,
  onEdit: handleEdit,
  updateInstant,
  ...rest
}) => {
  const placeholderValue = useMemo(() => placeholderText || value, [placeholderText, value])
  const [isActive, setIsActive] = useState(false)
  const [buffer, setBuffer] = useState(value)

  const isDifference = useMemo(
    () => buffer != value, // eslint-disable-line eqeqeq
    [buffer, value],
  )
  useEffect(() => setBuffer(value), [value])

  const handleOnBlur = ({ target }) => {
    if (isDifference) {
      handleEdit(target.value)
      setBuffer(target.value)
    }
    setIsActive(false)
  }

  const handleChange = ({ target }) => {
    if (updateInstant && target.value !== value) {
      handleEdit(target.value)
    }
    setBuffer(target.value)
  }

  const handlePlaceholderMouseOver = () => {
    setIsActive(true)
    setBuffer(value)
  }

  return isActive ? (
    <Component
      value={buffer}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onMouseLeave={(event) => {
        if (
          event.target !== document.activeElement
          && event.target.querySelector('input') !== document.activeElement
        ) {
          setIsActive(false)
        }
      }}
      hasChange={isDifference}
      {...rest}
    />
  ) : (
    <ComponentPlaceholder
      onFocus={() => {}}
      onMouseOver={handlePlaceholderMouseOver}
      {...rest}
    >
      {placeholderValue}
    </ComponentPlaceholder>
  )
}

MenuProductInput.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  componentPlaceholder: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholderValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onEdit: PropTypes.func,
  updateInstant: PropTypes.bool,
}

MenuProductInput.defaultProps = {
  value: '',
  placeholderValue: undefined,
  onEdit: () => {},
  updateInstant: false,
}

export default memo(MenuProductInput)
