import PropTypes from 'prop-types'

import { Card, CardContent, Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { BLUE, WHITE } from '../../../constants/colors'
import { DAYS } from '../../../constants/days'

const StyledCardContent = styled(CardContent)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

const DayOfMonth = styled.div`
  padding-bottom: 10px;
  text-align: center;
  font-size: 30px;
`

const DayContainer = styled.div`
  position: relative;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;

  ${({ active, theme }) => active && `
  background-color: ${BLUE};
  color: ${WHITE};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: ${theme.palette.background.paper};
  }

  & > ${DayOfMonth} {
    font-weight: ${active ? '500' : '700'};
  }
  `}
`

const DayHeader = styled.header`
  padding-top: 10px;
  font-size: 22px;
  text-align: center;
`

const Hours = styled.div`
  padding: 0 10px 0 10px;
  font-size: 14px;
  letter-spacing: 2px;
`

const ClosedSpan = styled.span`
  font-size: 10px;
  letter-spacing: 0;
  line-height: 40px;
`

const ClubOpenHours = ({ weeklySchedule }) => {
  const { t } = useTranslation()
  const { schedule } = weeklySchedule
  const dayOfWeekNumber = new Date().getDay() || 7
  const dayOfMonthNumber = new Date().getDate()

  const dayIterator = new Date()
  dayIterator.setDate(dayOfMonthNumber - dayOfWeekNumber)

  return (
    <Card>
      <StyledCardContent>
        {DAYS.map(({ value }, index) => {
          dayIterator.setDate(dayIterator.getDate() + 1)
          const isDayActive = index + 1 === dayOfWeekNumber

          return (
            <DayContainer key={value} active={isDayActive}>
              <DayHeader>{t(`clubs.form.daysOfWeek.${value.slice(0, 3).toLowerCase()}`)}</DayHeader>
              <DayOfMonth>{dayIterator.getDate()}</DayOfMonth>
              <Hours>
                {schedule[value] ? (
                  <>
                    <span>{schedule[value].openingHour.slice(0, 5)}</span>
                    <Divider />
                    <span>{schedule[value].closingHour.slice(0, 5)}</span>
                  </>
                ) : (
                  <ClosedSpan>{t('clubs.form.closed')}</ClosedSpan>
                )}
              </Hours>
            </DayContainer>
          )
        })}
      </StyledCardContent>
    </Card>
  )
}

ClubOpenHours.propTypes = {
  weeklySchedule: PropTypes.shape({
    schedule: PropTypes.shape({
      MONDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      TUESDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      WEDNESDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      THURSDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      FRIDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      SATURDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
      SUNDAY: PropTypes.shape({
        openingHour: PropTypes.string.isRequired,
        closingHour: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}

export default ClubOpenHours
