import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import {
  clubCreateValidationSchema,
  clubEditValidationSchema,
} from '../validations/clubSchema'
import useClubForm from '../hooks/useClubForm'
import ClubForm from '../components/forms/ClubForm/ClubAdminForm'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import useSnackbar from '../hooks/useSnackbar'
import { CREATE, UPDATE } from '../constants/formModes'

const ClubsFormScreen = () => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  const { initialValues, handleSubmit } = useClubForm({
    id,
    onSuccess: () => {
      // TODO go to details screen
      history.replace('/clubs')
      addSnackbar(t('clubs.success'))
    },
    onError: (err) => {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    },
  })

  const mode = id === undefined ? CREATE : UPDATE

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={mode === UPDATE ? clubEditValidationSchema : clubCreateValidationSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <RouteContainer justifyContent="flex-start">
          <NavigationBreadcrumbs />
          <ClubForm
            {...props}
            mode={mode}
            onCancel={history.goBack}
          />
        </RouteContainer>
      )}
    </Formik>
  )
}

export default ClubsFormScreen
