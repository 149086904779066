import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import noop from 'lodash/noop'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import DataTable from '../DataTable'
import columns from './ProductsList.columns'

const TableOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ProductsList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
  clubId,
}) => {
  const { t } = useTranslation()

  const products = useMemo(
    () => data.map(product => ({ ...product, clubId })),
    [data, clubId],
  )

  return (
    <>
      <TableOptions>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/clubs/${clubId}/products/create`}
          startIcon={<AddIcon />}
        >
          {t('common.create')}
        </Button>
      </TableOptions>
      <DataTable
        data={products}
        columns={columns}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
    </>
  )
}

ProductsList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  total: 0,
}

ProductsList.propTypes = {
  clubId: PropTypes.string.isRequired,
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default ProductsList
