import * as yup from 'yup'
import { messageObject } from '@itsilesia/udrink-common-components'

const orderCollectPlacesValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('orderCollect.errors.name.required'),
  description: yup
    .string()
    .max(1000, messageObject('orderCollect.errors.description.max')),
})

export default orderCollectPlacesValidationSchema
