import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import SectionHeader from '../section/SectionHeader'
import SubsectionHeader from './SubsectionHeader'
import PoolLimitationsSubsection from './PoolLimitationsSubsection'
import DiscountLimitationsSubsection from './DiscountLimitationsSubsection'
import AvailabilityLimitationsSubsection from './AvailabilityLimitationsSubsection'

const LimitationsSection = ({ values, setFieldValue }) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader title={t('discountCodes.limitations')} />

      <SubsectionHeader title={t('discountCodes.availabilityLimitations')} />
      <AvailabilityLimitationsSubsection />

      <SubsectionHeader title={t('discountCodes.discountLimitations')} />
      <DiscountLimitationsSubsection type={values.type} setFieldValue={setFieldValue} />

      <SubsectionHeader title={t('discountCodes.poolLimitations')} />
      <PoolLimitationsSubsection />
    </>
  )
}

LimitationsSection.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: {
    type: PropTypes.string,
  },
}

LimitationsSection.defaultProps = {
  values: {
    type: '',
  },
}

export default LimitationsSection
