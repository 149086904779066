import { Card, CardContent } from '@material-ui/core'
import styled from 'styled-components/macro'

import ClubPropType from '../../../propTypes/ClubPropType'
import { IncomeStatistic, OrdersStatistic, ClientsStatistic } from '../Statistics'

const StyledCard = styled(Card)`
  margin-bottom: 22px;
`

const ClubStatistics = ({ club: { club } }) => (
  <>
    <StyledCard>
      <CardContent>
        <IncomeStatistic clubId={club.id} />
      </CardContent>
    </StyledCard>
    <StyledCard>
      <CardContent>
        <ClientsStatistic clubId={club.id} />
      </CardContent>
    </StyledCard>
    <StyledCard>
      <CardContent>
        <OrdersStatistic clubId={club.id} />
      </CardContent>
    </StyledCard>
  </>
)

ClubStatistics.propTypes = {
  club: ClubPropType.isRequired,
}

export default ClubStatistics
