import { useState } from 'react'
import { useUser, useUsers } from '@itsilesia/udrink-common-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { deleteUser } from '../../../services/api/users'
import { ADMIN_ROLE } from '../../../constants/roleGroups'
import useSnackbar from '../../../hooks/useSnackbar'
import useHasRole from '../../../hooks/useHasRole'
import DeleteConfirmationDialog from '../../DeleteConfirmationDialog'
import DataTable from '../DataTable'
import UserDialog from '../UserDetails/UserDialog'

import useUsersColumns from './useUsersColumns'
import UserListHeader from './UserListHeader'
import useDataList from '../../../hooks/useDataList'

const UsersList = ({
  data,
  onChangePage: handleChangePage,
  onChangeRowsPerPage: handleChangeRowsPerPage,
  total,
  rowsPerPageOptions,
  pageSize,
  page,
  activeRoleGroup,
  setActiveRoleGroup,
}) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const history = useHistory()

  const [isMoreInfoDialogOpen, setIsMoreInfoDialogOpen] = useState(false)
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false)
  const [userId, setUserId] = useState(null)
  const { data: user } = useUser(userId)
  const isAdmin = useHasRole(ADMIN_ROLE)
  const useResource = ({ _, size }) =>
    useUsers({
      _,
      size,
      sort: 'name',
    })
  const { revalidate } = useDataList('users', useResource)

  const handleMoreInfoDialogOpen = (id) => {
    setUserId(id)
    setIsMoreInfoDialogOpen(true)
  }

  const handleDeleteUserDialogOpen = (id) => {
    setUserId(id)
    setIsDeleteUserDialogOpen(true)
  }

  const handleDeleteUser = async () => {
    try {
      await deleteUser(userId)
      addSnackbar(t('user.screen.delete.success'))
      setIsDeleteUserDialogOpen(false)
      revalidate()
      history.replace(isAdmin ? '/users' : '/workers')
    } catch (err) {
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    }
  }

  const columns = useUsersColumns(handleMoreInfoDialogOpen, handleDeleteUserDialogOpen)

  return (
    <>
      <UserListHeader
        activeRoleGroup={activeRoleGroup}
        onActiveRoleChange={setActiveRoleGroup}
      />
      <UserDialog
        isOpen={isMoreInfoDialogOpen}
        user={user}
        onClose={() => setIsMoreInfoDialogOpen(false)}
      />
      {user && (
        <DeleteConfirmationDialog
          isOpen={isDeleteUserDialogOpen}
          titleText={t('user.screen.delete.deleteUserAlertTitle', { username: user?.username })}
          onCancel={() => setIsDeleteUserDialogOpen(false)}
          onConfirm={() => handleDeleteUser()}
        />
      )}
      <DataTable
        data={data}
        columns={columns}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={pageSize}
        page={page}
      />
    </>
  )
}

UsersList.propTypes = {
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  activeRoleGroup: PropTypes.string.isRequired,
  setActiveRoleGroup: PropTypes.func,
}

UsersList.defaultProps = {
  data: [],
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  setActiveRoleGroup: noop,
  total: 0,
}

export default UsersList
