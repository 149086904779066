import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'

import { ClubContext } from '../../components/ClubContext'
import RouteContainer from '../../components/layout/RouteContainer'
import RouteHeader from '../../components/layout/RouteHeader'
import useSnackbar from '../../hooks/useSnackbar'
import ClubManagerForm from '../../components/forms/ClubForm/ClubManagerForm'
import useManagerClubForm from '../../hooks/useManagerClubForm'
import { clubForManagerEditValidationSchema } from '../../validations/clubSchema'

const ClubDataEditScreen = () => {
  const { t } = useTranslation()
  const { clubId } = useContext(ClubContext)
  const { addSnackbar } = useSnackbar()
  const history = useHistory()

  const { initialValues, handleSubmit } = useManagerClubForm({
    id: clubId,
    onSuccess: () => {
      history.replace('/data')
      addSnackbar(t('local.success'))
    },
    onError: (err) => {
      // TODO: add more detailed error based on what happened
      addSnackbar(t('common.errors.generic'))
      if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.error(err)
      }
    },
  })

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={clubForManagerEditValidationSchema}
    >
      {props => (
        <RouteContainer fillHeight={false}>
          <RouteHeader>{t('local.edit')}</RouteHeader>
          {initialValues && <ClubManagerForm {...props} />}
        </RouteContainer>
      )}
    </Formik>
  )
}

export default ClubDataEditScreen
