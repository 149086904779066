import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import UserDetails from './UserDetails'
import UserPropType from '../../../propTypes/UserPropType'

const LoaderRoot = styled.div`
  width: 630px;
  height: 300px;
  line-height: 300px;
  text-align: center;
`

const UserDialog = ({ onClose: handleClose, isOpen, user }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="user-details-dialog"
      aria-describedby="user-details-description"
    >
      <DialogContent>
        {user
          ? <UserDetails user={user} />
          : (
            <LoaderRoot>
              <CircularProgress />
            </LoaderRoot>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserDialog.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  user: PropTypes.oneOfType([
    UserPropType,
    PropTypes.bool,
  ]),
}

UserDialog.defaultProps = {
  onClose: () => {},
  isOpen: false,
  user: false,
}

export default UserDialog
