import { useParams } from 'react-router-dom'

import Modifiers from '../components/modifiers/Modifiers'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'

const ModifiersScreen = () => {
  const { clubId } = useParams()

  return (
    <RouteContainer>
      <NavigationBreadcrumbs />
      {clubId && <Modifiers clubId={clubId} />}
    </RouteContainer>
  )
}

export default ModifiersScreen
