import { useState, useCallback, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useClubOrderCollectPlaces } from '@itsilesia/udrink-common-components'

import FormikFieldMapper from '../FormikFieldMapper'
import useDataList from '../../../hooks/useDataList'
import useMemoryPagination from '../../../hooks/useMemoryPagination'
import { FormButton } from '../../inputs'
import DataTable from '../../data/DataTable'
import columns from '../../clubs/OrderCollectPlacesList/OrderCollectPlacesList.columns'

const OrderCollectPlacesDialog = ({
  open,
  handleClose,
  clubId,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  const [selectedIds, setSelectedIds] = useState([])
  const initialIds = useMemo(() => (value ? [value] : []), [value])

  const useResource = ({ page, size }) =>
    useClubOrderCollectPlaces(clubId, {
      page,
      size,
      sort: 'name',
    })

  const {
    pageSize,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    ...props
  } = useDataList(
    `${clubId}-order-collect-places`,
    useResource,
    useMemoryPagination,
  )

  const handleConfirm = useCallback(() => {
    onChange(selectedIds[0])
    handleClose()
  }, [onChange, handleClose, selectedIds])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{t('drawer.navigation.orderCollect')}</DialogTitle>
      <DialogContent dividers>
        <DataTable
          {...props}
          columns={columns}
          clubId={clubId}
          setSelectedIds={setSelectedIds}
          initialSelectedIds={initialIds}
          enableSelect
          pageSize={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          isSingleItemSelect
        />
      </DialogContent>
      <DialogActions>
        <FormButton onClick={handleClose}>
          {t('common.back')}
        </FormButton>
        <FormButton onClick={handleConfirm} color="primary">
          {t('common.select')}
        </FormButton>
      </DialogActions>
    </Dialog>
  )
}

OrderCollectPlacesDialog.defaultProps = {
  onChange: () => {},
  handleClose: () => {},
}

OrderCollectPlacesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  clubId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
}

const MemoizedOrderCollectPlacesDialog = memo(OrderCollectPlacesDialog)

const FieldOrderCollectPlacesDialog = propsToMap => (
  <FormikFieldMapper {...propsToMap}>
    {props => <MemoizedOrderCollectPlacesDialog {...props} />}
  </FormikFieldMapper>
)

export default FieldOrderCollectPlacesDialog
