import styled from 'styled-components/macro'
import { Link as RouterLink } from 'react-router-dom'
import { Drawer } from '@material-ui/core'

import SoEasyLogo from '../../../assets/images/soeasy_horizontal.png'
import RestrictedContent from '../../auth/RestrictedContent'
import NavigationList from './NavigationList'
import ROLE_GROUPS from '../../../constants/roleGroups'
import { ADMIN_TABS, MANAGER_TABS } from '../../../constants/tabs'
import { DARKER_BLUE } from '../../../constants/colors'

const drawerWidth = 254

const StyledDrawer = styled(Drawer)(
  ({ theme }) => `
  width: ${drawerWidth}px;
  flex-shrink: 0;
  justify-content: space-between;
  
  .MuiDrawer-paper {
    width: ${drawerWidth}px;
    background-color: ${theme.palette.background.navigationDraver};
    overflow-x: hidden;
  }

  .MuiDivider-root {
    background: ${DARKER_BLUE};
  }
`,
)

const ToolbarLogo = styled.div(props => props.theme.mixins.toolbar)

const LogoImage = styled.img`
  width: 90%;
  padding: 8px;
  padding-right: 45px;
  margin-bottom: 12px;
`

const NavigationDrawer = () => (
  <StyledDrawer variant="permanent" anchor="left">
    <RouterLink to="/statistics">
      <ToolbarLogo>
        <LogoImage src={SoEasyLogo} alt="SoEasyLogo" />
      </ToolbarLogo>
    </RouterLink>
    <RestrictedContent accessRole={ROLE_GROUPS.ADMIN.value}>
      <NavigationList tabsList={ADMIN_TABS} />
    </RestrictedContent>
    <RestrictedContent accessRole={ROLE_GROUPS.MANAGER.value}>
      <NavigationList tabsList={MANAGER_TABS} />
    </RestrictedContent>
  </StyledDrawer>
)

export default NavigationDrawer
