import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { BLACK } from '../../../constants/colors'

const Container = styled.div`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.1px;
  color: ${BLACK};
`

const FieldValue = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
)

FieldValue.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FieldValue
