import { CssBaseline } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'

import GlobalStyles from './GlobalStyles'
import AppProviders from './providers/AppProviders'
import Content from './Content'
import AdminSnackbar from './components/AdminSnackbar'
import ErrorBoundary from './ErrorBoundary'
import PageHelmet from './components/PageHelmet'

const App = () => (
  <StylesProvider injectFirst>
    <AppProviders>
      <CssBaseline />
      <GlobalStyles />
      <ErrorBoundary>
        <Content />
        <AdminSnackbar />
        <PageHelmet />
      </ErrorBoundary>
    </AppProviders>
  </StylesProvider>
)

export default App
