import { useMemo, useCallback } from 'react'
import { useTrackLoadingStatus } from '@itsilesia/udrink-common-components'

import useTotalCount from './useTotalCount'
import useQueryPagination from './useQueryPagination'

const useDataList = (key, useResource, usePagination = useQueryPagination) => {
  const { page, setPage, size, setPageSize } = usePagination()
  const { data, mutate, isValidating } = useResource({ page, size })

  useTrackLoadingStatus(key, isValidating)

  const content = useMemo(() => data?.content || [], [data])
  const total = useTotalCount(data?.totalElements)

  const handleChangePage = useCallback((_, value) => setPage(value), [setPage])
  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setPageSize(parseInt(event.target.value, 10))
    },
    [setPageSize],
  )

  const revalidate = async () => { await mutate() }

  return {
    data: content,
    mutate,
    handleChangePage,
    handleChangeRowsPerPage,
    revalidate,
    total,
    rowsPerPageOptions: [5, 7, 10, 15, 25, 50, 100],
    pageSize: size,
    page,
  }
}

export default useDataList
