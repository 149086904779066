import ClubsListActions from './ClubsListActions'
import ClubsListNameLink from './ClubsListNameLink'

const columns = [
  {
    key: 'name',
    component: ClubsListNameLink,
    label: { translationKey: 'clubs.form.name' },
  },
  {
    key: 'actions',
    label: { translationKey: 'drawer.actions.title' },
    component: ClubsListActions,
    alignText: 'right',
  },
]

export default columns
