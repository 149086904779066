import { API } from '@itsilesia/udrink-common-components'
import fetch from '../../fetch'
import {
  mapToAPICreateOrderPreparationPlaces,
  mapToAPIUpdateOrderPreparationPlaces,
} from '../../../utils/mapOrderPreparationPlaces'

const updateOrderPreparationPlace = async (id, values) => {
  const { data: orderPreparationPlace } = await fetch(
    API.orderPreparationPlaces.update(id, mapToAPIUpdateOrderPreparationPlaces(values)),
  )

  if (!values.isDeviceConnected && values.deviceConnectionId) {
    await fetch(API.deviceWorkplaces.delete(values.deviceConnectionId))
  }

  return orderPreparationPlace
}

const createOrderPreparationPlace = async (clubId, values) => {
  const { data: orderPreparationPlace } = await fetch(
    API.orderPreparationPlaces.create(mapToAPICreateOrderPreparationPlaces(clubId, values)),
  )

  return orderPreparationPlace
}

const deleteOrderPreparationPlace = async (id) => {
  const { data: orderPreparationPlace } = await fetch(API.orderPreparationPlaces.delete(id))
  return orderPreparationPlace
}

export {
  updateOrderPreparationPlace,
  createOrderPreparationPlace,
  deleteOrderPreparationPlace,
}
