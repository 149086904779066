import mapValues from 'lodash/mapValues'
import { API } from '@itsilesia/udrink-common-components'

import fetch from '../fetch'
import { updateClub, createClub, updateClubAsManager, deleteClub } from './clubs'
import { createUser, updateUser } from './users'
import {
  createOrderPreparationPlace,
  updateOrderPreparationPlace,
} from './orderPreparationPlaces'
import {
  updateOrderCollectPlace,
  createOrderCollectPlace,
} from './orderCollectPlaces'
import {
  createOrderDeliveryPlace,
  updateOrderDeliveryPlace,
} from './orderDeliveryPlaces'
import {
  createMenu,
  updateMenu,
  getActiveMenuId,
} from './menus'
import {
  updateProductAmount,
  createProductAmount,
  deleteProductAmount,
} from './inventory'
import {
  updateWeeklySchedules,
} from './weeklySchedules'
import {
  createDiscountCode,
  updateDiscountCode,
  deleteDiscountCode,
} from './discountCodes'

const bindConfigCreators = (obj, fn) => mapValues(obj, value => (...rest) => fn(value(...rest)))

const {
  signIn,
  signUp,
  signInWithFacebook,
  connectFacebookAccount,
  resetPassword,
  updatePassword,
} = bindConfigCreators(API.auth, fetch)

const { get: getClubs } = bindConfigCreators(API.clubs, fetch)

export {
  signIn,
  signUp,
  signInWithFacebook,
  connectFacebookAccount,
  resetPassword,
  updatePassword,
  getClubs,
  createUser,
  updateUser,
  createClub,
  updateClubAsManager,
  updateClub,
  deleteClub,
  createOrderPreparationPlace,
  updateOrderPreparationPlace,
  createOrderCollectPlace,
  updateOrderCollectPlace,
  createOrderDeliveryPlace,
  updateOrderDeliveryPlace,
  createMenu,
  updateMenu,
  getActiveMenuId,
  updateProductAmount,
  createProductAmount,
  deleteProductAmount,
  updateWeeklySchedules,
  deleteDiscountCode,
  createDiscountCode,
  updateDiscountCode,
}
