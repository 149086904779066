import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Checkbox } from '@material-ui/core'

import getPayloadColor from '../../chart/utils/getPayloadColor'
import { ORDERS, TIPS } from '../../../../../constants/orders'

const List = styled.ul`
  display: block;
  white-space: nowrap;
  margin: 0;
  margin-top: 15px;
  padding-left: 31px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
`

const ListItem = styled.li`
  display: inline-block;
  margin-right: 20px;
  opacity: 0.7;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 200ms ease;

  ${({ active }) => active
    && 'opacity: 1;'}
`

const OrdersLegend = ({
  payload,
  onTipsClick,
  onOrdersClick,
  isTipsChecked,
  isOrdersChecked,
}) => {
  const { t } = useTranslation()
  // Styles are destructed because array payload items order depends on chart lines render order.
  // So this approach prevents dynamicly reordering legend list items.
  const tipsStyle = { color: getPayloadColor(payload, TIPS) }
  const incomeStyle = { color: getPayloadColor(payload, ORDERS) }

  return (
    <List>
      <ListItem
        active={isOrdersChecked}
        style={incomeStyle}
        onClick={onOrdersClick}
      >
        <Checkbox checked={isOrdersChecked} style={incomeStyle} />
        <span>{t('statistics.orders')}</span>
      </ListItem>
      <ListItem
        active={isTipsChecked}
        style={tipsStyle}
        onClick={onTipsClick}
      >
        <Checkbox checked={isTipsChecked} style={tipsStyle} />
        <span>{t('statistics.tips')}</span>
      </ListItem>
    </List>
  )
}

OrdersLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  onTipsClick: PropTypes.func,
  onOrdersClick: PropTypes.func,
  isTipsChecked: PropTypes.bool,
  isOrdersChecked: PropTypes.bool,
}

OrdersLegend.defaultProps = {
  payload: [],
  onTipsClick: () => {},
  onOrdersClick: () => {},
  isTipsChecked: false,
  isOrdersChecked: false,
}
export default OrdersLegend
