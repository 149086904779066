import { useMemo } from 'react'
import PropTypes from 'prop-types'

import NavigationDrawer from '../../navigation/NavigationDrawer'
import useIsAuthenticated from '../../../hooks/useIsAuthenticated'
import * as StyledAppLayout from './AppLayout.styles'
import useIsResettingPassword from '../../../hooks/useIsResettingPassword'

const AppLayout = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const isResettingPassword = useIsResettingPassword()

  const showDrawer = useMemo(
    () => isAuthenticated && !isResettingPassword,
    [isAuthenticated, isResettingPassword],
  )

  return (
    <StyledAppLayout.Container>
      {showDrawer && <NavigationDrawer />}
      <StyledAppLayout.ContentContainer>{children}</StyledAppLayout.ContentContainer>
    </StyledAppLayout.Container>
  )
}

AppLayout.defaultProps = {
  children: null,
}

AppLayout.propTypes = {
  children: PropTypes.node,
}

export default AppLayout
