import styled from 'styled-components/macro'

import { WHITE } from '../../../../../constants/colors'

const FormContent = styled.div`
  padding: ${({ theme }) => theme.spacing(5)}px;
  background-color: ${WHITE};
  box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0, 0.05);
  border-radius: 5px;
`

export default FormContent
