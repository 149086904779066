import TaxRatesForm from '../components/forms/TaxRatesForm'
import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'

const TaxRatesScreen = () => (
  <RouteContainer>
    <NavigationBreadcrumbs />
    <TaxRatesForm />
  </RouteContainer>
)

export default TaxRatesScreen
