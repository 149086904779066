import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { WHITE } from '../../../../../constants/colors'

const TooltipContainer = styled.div`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.palette.text.disabled};
  border-radius: 4px;
  background: ${WHITE};
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
`

const Label = styled.span`
  margin-right: 5px;
`

const ValueText = styled.span`
  font-weight: 600;
`

const ChartTooltip = ({ header, rows }) => (
  <TooltipContainer>
    <Header>{header}</Header>
    {rows.map(({ label, value }) => (
      <p key={label}>
        <Label>
          {`${label}:`}
        </Label>
        <ValueText>
          {value}
        </ValueText>
      </p>
    ))}
  </TooltipContainer>
)

ChartTooltip.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }).isRequired,
  ),
  header: PropTypes.string,
}

ChartTooltip.defaultProps = {
  rows: [],
  header: '',
}

export default ChartTooltip
