import { createSlice } from '@reduxjs/toolkit'
import maxBy from 'lodash/maxBy'

const getNextMenuState = createSlice({
  name: 'getNextMenuState',
  initialState: null,
  reducers: {
    createProduct(products, { payload: product }) {
      const maxMenuProductOrder = maxBy(products, 'menuProductOrder')?.menuProductOrder || 0
      products.push({
        ...product,
        menuProductOrder: maxMenuProductOrder + 1,
      })
    },
    reorderProducts(products, { payload }) {
      const { uuid, newOrderNumber } = payload
      const movedIndex = products.findIndex(product => product.uuid === uuid)
      const [removed] = products.splice(movedIndex, 1)
      products.splice(newOrderNumber - 1, 0, removed)
      products.forEach((product, index) => {
        product.menuProductOrder = index + 1
      })
    },
    updateProduct(products, {
      payload: {
        id: uuid,
        ...productUpdates
      },
    }) {
      const productIndex = products.findIndex(product => product.uuid === uuid)

      products[productIndex] = {
        ...products[productIndex],
        ...productUpdates,
      }
    },
    deleteProduct(products, { payload: uuid }) {
      const indexToDelete = products.findIndex(product => product.uuid === uuid)
      products.splice(indexToDelete, 1)
      products.forEach((product, index) => {
        product.menuProductOrder = index + 1
      })
    },
    addModifier(products, { payload: { productUuid, modifierUuid } }) {
      const productIndex = products.findIndex(product => product.uuid === productUuid)
      products[productIndex].modifiers.push({ uuid: modifierUuid })
    },
    removeModifier(products, { payload: { productUuid, modifierUuid } }) {
      const productIndex = products.findIndex(product => product.uuid === productUuid)
      const indexToDelete = products[productIndex].modifiers.findIndex(
        modifier => modifier.uuid === modifierUuid,
      )
      products[productIndex].modifiers.splice(indexToDelete, 1)
    },
  },
})

export default getNextMenuState
