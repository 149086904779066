import { API } from '@itsilesia/udrink-common-components'

import fetch from '../../fetch'
import getResponseErrorCodes from '../../../utils/getResponseErrorCodes'
import { ACTIVE_MENU_NOT_FOUND_CODE } from '../../../constants/errorCodes'

const getActiveMenuId = async (clubId) => {
  const { data: menu } = await fetch(API.clubMenus.getActive(clubId))
  return menu.id
}

const createSubcategoryImage = (subcategoryUuid, file) =>
  fetch(API.subcategoryImages.create(subcategoryUuid, file))

const deleteSubcategoryImage = imageUuid =>
  fetch(API.subcategoryImages.delete(imageUuid))

const createProductImage = (productUuid, file) =>
  fetch(API.productImages.create(productUuid, file))

const deleteProductImage = imageUuid =>
  fetch(API.productImages.delete(imageUuid))

const updateMenuItems = async (menuId, events) => {
  const { data } = await fetch(API.menuItems.update(menuId, events))
  return data
}

const getActiveClubMenu = async (clubId) => {
  try {
    const { data: activeMenu } = await fetch(API.clubMenus.getActive(clubId))
    const { data: menu } = await fetch(API.menus.get(activeMenu.id))
    return { ...menu, id: activeMenu.id }
  } catch (err) {
    const codes = getResponseErrorCodes(err)
    if (codes.includes(code => code?.match(ACTIVE_MENU_NOT_FOUND_CODE))) {
      return null
    }
    throw err
  }
}

const createMenu = async (values) => {
  const { data: menu } = await fetch(API.menus.create(values))
  return menu
}

const updateMenu = async (menuId, values) => {
  const { data: menu } = await fetch(API.menus.update(menuId, values))
  return menu
}

const updateMenuStructure = async (menuId, categoryOrder) => {
  const { data } = await fetch(API.menus.update(menuId, {
    categoryOrder,
  }))
  return data
}

const createClubMenu = async (clubId) => {
  const { data: menu } = await fetch(
    API.menus.create({
      active: true,
      clubId,
      name: 'Menu',
    }),
  )

  return menu
}

export {
  createClubMenu,
  getActiveClubMenu,
  createMenu,
  updateMenu,
  getActiveMenuId,
  updateMenuItems,
  updateMenuStructure,
  createSubcategoryImage,
  deleteSubcategoryImage,
  createProductImage,
  deleteProductImage,
}
