import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { RemoveRedEyeOutlined, Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { MANAGER_ROLE } from '../../../constants/roleGroups'
import useHasRole from '../../../hooks/useHasRole'
import IdPropType from '../../../propTypes/IdPropType'
import DiscountCodePreviewDialog from '../../DiscountCodePreviewDialog'

const DiscountCodesListActions = ({ row }) => {
  const { t } = useTranslation()
  const isManager = useHasRole(MANAGER_ROLE)

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)

  return (
    <>
      <Tooltip
        title={t('tableTooltips.preview')}
        aria-label={t('tableTooltips.preview')}
      >
        <IconButton onClick={() => setIsPreviewDialogOpen(true)}>
          <RemoveRedEyeOutlined />
        </IconButton>
      </Tooltip>
      {isManager && (
        <Tooltip
          title={t('tableTooltips.edit')}
          aria-label={t('tableTooltips.edit')}
        >
          <IconButton component={Link} to={`/clubs/${row.clubId}/discount-codes/${row.id}/edit`}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}
      <DiscountCodePreviewDialog
        handleClose={() => setIsPreviewDialogOpen(false)}
        isOpen={isPreviewDialogOpen}
        discountCode={row.discountCode}
      />
    </>
  )
}

DiscountCodesListActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clubId: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    discountCode: PropTypes.string.isRequired,
  }).isRequired,
}

export default DiscountCodesListActions
