import { memo, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { DragIndicator } from '@material-ui/icons'
import { Draggable } from 'react-beautiful-dnd'

import WORK_ZONES from '../../../../constants/workZones'
import { TextField } from '../../../inputs'
import omitProps from '../../../../utils/omitProps'
import { peanutsToPLN } from '../../../../utils/peanutsToPLN'
import { WHITE, BLUE, LIGHT_BLUE } from '../../../../constants/colors'
import VatRateSelect from './VatRateSelect'
import WorkZoneSelect from './WorkZoneSelect'
import MenuProductInput from './MenuProductInput'
import ProductActions from './ProductActions'
import ProductImage from './ProductImage'
import IdPropType from '../../../../propTypes/IdPropType'
import triangleImage from '../../../../assets/images/triangle.png'
import { PriceTextField } from '../../../inputs/PriceTextField'

const StyledDragIndicator = styled(DragIndicator)`
  cursor: grab;
`

const StyledPaper = styled.div(({ theme, isDragging }) => `
  display: grid;
  grid-template-columns: auto auto 1.7fr 0.6fr 1fr 1fr auto auto auto auto;
  grid-gap: 5px;
  padding: 2px;
  margin: 0 10px 10px 10px;
  border: 1px solid ${isDragging ? theme.palette.action.disabled : 'transparent'};
  border-radius: 5px;
  background: ${WHITE};
  box-shadow: ${isDragging
    ? '0px 0px 32px -7px rgba(0,0,0,0.3)'
    : '0px 0px 0px 0px rgba(0,0,0,0)'};
  transition:
    border 200ms ease-in-out,
    box-shadow 100ms ease;

  & > * {
    align-self: center;
  }
`)

const StyledNameTextField = styled(omitProps('hasChange')(TextField))`
  border-color: ${BLUE};
  background-color: ${({ hasChange }) => (hasChange ? LIGHT_BLUE : '')};
`

const StyledPlaceholder = styled.div(({ theme }) => `
  position: relative;
  padding: 15.5px 13px;
  border: 1px solid ${theme.palette.text.disabled};
  border-radius: 4px;
  line-height: 23px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid ${theme.palette.text.primary};
  }

  &:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 100%;
    top: 0;
    right: 0;
    background: ${WHITE};
  }
`)

const StyledPriceTextField = styled(omitProps('hasChange')(PriceTextField))`
  min-width: 120px;
  background-color: ${({ hasChange }) => (hasChange ? LIGHT_BLUE : '')};
`

const StyledPriceTextFieldPlaceholder = styled(StyledPlaceholder)`
  position: relative;
  min-width: 120px;

  &:before {
    content: 'zł';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-52%);
    padding-right: 21px;
    background: white;
  }
`

const StyledSelectPlaceholder = styled(StyledPlaceholder)`
  padding-right: 31px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 6px;
    top: 50%;
    right: 13px;
    transform: translateY(-48%);
    background-image: url(${triangleImage});
    background-size: 100% 100%;
  }
`

const MenuProduct = ({
  index,
  onEditDialogOpen: handleToEditDialogOpen,
  onDelete: handleDelete,
  onEdit: handleEdit,
  taxRatesList,
  isLoading,
  isMenuDisabled,
  product: {
    id,
    uuid,
    name,
    price,
    workZone: workZoneName,
    vatType,
    volume,
    description,
    imageUrl,
    subcategoryUuid,
    availabilityHoursBegin,
    availabilityHoursEnd,
    onlyPreview,
    modifiers,
  },
}) => {
  const { t } = useTranslation()
  const priceInPLN = peanutsToPLN(price)
  const taxRates = useMemo(() => taxRatesList || [], [taxRatesList])
  const workZone = useMemo(
    () => WORK_ZONES.find(zone => zone.value === workZoneName),
    [workZoneName],
  )

  const handleNameUpdate = useCallback((value) => {
    handleEdit({
      id,
      name: value,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePriceUpdate = useCallback((value) => {
    handleEdit({
      id,
      price: value * 100,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleVatRateUpdate = useCallback((newVatType) => {
    handleEdit({
      id,
      vatType: newVatType,
    })
  }, [taxRatesList]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleWorkZoneUpdate = useCallback((value) => {
    handleEdit({
      id,
      workZone: value,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnlyPreviewUpdate = useCallback((value) => {
    handleEdit({
      id,
      onlyPreview: value,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditDialogOpen = useCallback(
    () =>
      handleToEditDialogOpen({
        id,
        name: name || '',
        workZone: workZone.value || '',
        vatType: vatType || '',
        price: price / 100 || '',
        volume: volume || '',
        description: description || '',
        imageUrl: imageUrl || '',
        subcategoryUuid: subcategoryUuid || '',
        availabilityHoursBegin,
        availabilityHoursEnd,
        uuid: uuid || '',
        modifiers: modifiers || [],
      }),
    [ // eslint-disable-line react-hooks/exhaustive-deps
      name,
      workZone,
      vatType,
      price,
      volume,
      description,
      imageUrl,
      availabilityHoursBegin,
      availabilityHoursEnd,
      modifiers,
      uuid,
    ],
  )

  const handleProductDelete = useCallback(
    () => handleDelete(id),
    [id], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Draggable draggableId={`${id}`} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging, isDropAnimating }) => (
        <StyledPaper
          ref={innerRef}
          isDragging={isDragging && !isDropAnimating}
          {...draggableProps}
        >
          <div {...dragHandleProps}>
            <StyledDragIndicator />
          </div>
          <ProductImage src={imageUrl} />
          <MenuProductInput
            value={name}
            onEdit={handleNameUpdate}
            component={StyledNameTextField}
            componentPlaceholder={StyledPlaceholder}
            disabled={isMenuDisabled}
          />
          <MenuProductInput
            value={priceInPLN}
            onEdit={handlePriceUpdate}
            component={StyledPriceTextField}
            componentPlaceholder={StyledPriceTextFieldPlaceholder}
            disabled={isMenuDisabled}
          />
          <MenuProductInput
            value={vatType}
            placeholderValue={`${t('taxRates.rate')} ${vatType}`}
            onEdit={handleVatRateUpdate}
            taxRates={taxRates}
            component={VatRateSelect}
            componentPlaceholder={StyledSelectPlaceholder}
            updateInstant
            disabled={isMenuDisabled}
          />
          <MenuProductInput
            value={workZone.value}
            placeholderValue={t(workZone.code)}
            onEdit={handleWorkZoneUpdate}
            component={WorkZoneSelect}
            componentPlaceholder={StyledSelectPlaceholder}
            updateInstant
            disabled={isMenuDisabled}
          />
          <ProductActions
            onlyPreview={onlyPreview}
            isLoading={isLoading}
            onChangeOnlyPreview={handleOnlyPreviewUpdate}
            onEditDialogOpen={handleEditDialogOpen}
            onDelete={handleProductDelete}
            isMenuDisabled={isMenuDisabled}
          />
        </StyledPaper>
      )}
    </Draggable>
  )
}

MenuProduct.propTypes = {
  product: PropTypes.shape({
    id: IdPropType.isRequired,
    uuid: IdPropType.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    workZone: PropTypes.string.isRequired,
    vatType: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    subcategoryUuid: IdPropType.isRequired,
    availabilityHoursBegin: PropTypes.string,
    availabilityHoursEnd: PropTypes.string,
    onlyPreview: PropTypes.bool.isRequired,
    modifiers: PropTypes.array.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onEditDialogOpen: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  taxRatesList: PropTypes.array,
  isLoading: PropTypes.bool,
  isMenuDisabled: PropTypes.bool,
}

MenuProduct.defaultProps = {
  onEditDialogOpen: () => {},
  onDelete: () => {},
  onEdit: () => {},
  taxRatesList: [],
  isLoading: false,
  isMenuDisabled: false,
}

StyledPriceTextField.propTypes = {
  hasChange: PropTypes.bool,
}

StyledPriceTextField.defaultProps = {
  hasChange: false,
}

export default memo(MenuProduct)
