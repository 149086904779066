import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

import StyledTextField from '../../../ClubForm/StyledTextField'
import StyledPriceTextField from '../../../ClubForm/StyledPriceTextField'
import { ContainerWithInfoTooltip } from '../ContainerWithInfoTooltip'

const DiscountLimitationsSubsection = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainerWithInfoTooltip info={t('discountCodes.totalUserAmountInfo')}>
        <Field
          formatError={t}
          label={`${t('discountCodes.totalUserAmount')} ${t('common.optional')}`}
          name="totalUserAmount"
          component={StyledTextField}
        />
      </ContainerWithInfoTooltip>
      <Field
        formatError={t}
        label={`${t('discountCodes.minimumOrderPrice')} ${t('common.optional')}`}
        name="minimumOrderPrice"
        component={StyledPriceTextField}
      />
    </>
  )
}

export default DiscountLimitationsSubsection
