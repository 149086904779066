import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { CardHeader, CardContent, IconButton, Collapse, Tooltip } from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components/macro'

import MenuProduct from './MenuProduct'
import useCollapse from '../../../hooks/useCollapse'
import getNextMenuState from '../../../utils/getNextMenuState'
import CreateProductButton from './MenuProduct/CreateProductButton'
import IdPropType from '../../../propTypes/IdPropType'
import SubcategoryHeader from './SubcategoryHeader'

const StyledCardContent = styled(CardContent)`
  padding: 0;

  &:last-child {
    padding-bottom: 0;
  }
`

const StyledCard = styled.div`
  margin-bottom: 20px;
`

const StyledIconButton = styled(IconButton)`
  margin: 0px;
  padding: 6px;
`

const SubcategoryItemsContainer = ({
  position,
  name,
  namePl,
  imageUrl,
  subcategoryUuid,
  products,
  isLoading,
  pushEvent,
  taxRatesList,
  isFirst,
  isLast,
  isMenuDisabled,
  isSubcategoryImageDeleted,
  onProductEditDialogOpen: handleProductEditDialogOpen,
  onProductDelete: handleProductDelete,
  onProductEdit: handleProductEdit,
  onProductCreate: handleProductCreate,
  onSubcategoryReorder: handleSubcategoryReorder,
  onSubcategoryEditDialogOpen: handleToSubcategoryEditDialogOpen,
  onSubcategoryDelete: handleSubcategoryDelete,
  onProductModifierAdd: handleProductModifierAdd,
  onProductModifierRemove: handleProductModifierRemove,
}) => {
  const { t, i18n: { language } } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse()
  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return
      }

      if (result.destination.index === result.source.index) {
        return
      }

      pushEvent(getNextMenuState.actions.reorderProducts({
        uuid: products[result.source.index].uuid,
        newOrderNumber: products[result.destination.index].menuProductOrder,
      }))
    },
    [products], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSubcategoryEditDialogOpen = useCallback(
    () =>
      handleToSubcategoryEditDialogOpen({
        name: name || '',
        namePl: namePl || '',
        imageUrl: imageUrl || '',
        subcategoryUuid: subcategoryUuid || '',
      }),
    [
      handleToSubcategoryEditDialogOpen,
      name,
      namePl,
      imageUrl,
      subcategoryUuid,
    ],
  )

  const handleSubcategoryRemove = useCallback(
    () => handleSubcategoryDelete(subcategoryUuid),
    [handleSubcategoryDelete, subcategoryUuid],
  )

  const handleSubcategoryEditDialog = (event) => {
    event.stopPropagation()
    handleSubcategoryEditDialogOpen()
  }

  const handleSubcategoryRemoveCallback = (event) => {
    event.stopPropagation()
    handleSubcategoryRemove()
  }

  return (
    <StyledCard>
      <CardHeader
        title={(
          <SubcategoryHeader
            onOrderUp={() => handleSubcategoryReorder(subcategoryUuid, position - 1)}
            onOrderDown={() => handleSubcategoryReorder(subcategoryUuid, position + 1)}
            disabledUp={isFirst}
            disabledDown={isLast}
            imageUrl={isSubcategoryImageDeleted ? '' : imageUrl}
          >
            {(language === 'pl') ? namePl : name}
          </SubcategoryHeader>
        )}
        onClick={toggleCollapse}
        action={(
          <>
            <CreateProductButton
              subcategoryUuid={subcategoryUuid}
              onProductCreate={handleProductCreate}
              disabled={isMenuDisabled}
              pushEvent={pushEvent}
              onProductModifierAdd={handleProductModifierAdd}
              onProductModifierRemove={handleProductModifierRemove}
            />
            <Tooltip title={collapse ? t('common.expand') : t('common.fold')}>
              <span>
                <IconButton disabled={isMenuDisabled}>
                  {collapse ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('tableTooltips.edit')} aria-label={t('tableTooltips.edit')}>
              <span>
                <StyledIconButton onClick={handleSubcategoryEditDialog} disabled={isMenuDisabled}>
                  <EditIcon />
                </StyledIconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('tableTooltips.delete')} aria-label={t('tableTooltips.delete')}>
              <span>
                <StyledIconButton
                  onClick={handleSubcategoryRemoveCallback}
                  disabled={isMenuDisabled}
                >
                  <DeleteIcon />
                </StyledIconButton>
              </span>
            </Tooltip>
          </>
        )}
      />
      <Collapse in={!collapse} timeout="auto" unmountOnExit>
        <StyledCardContent>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={`${name}-list`}>
              {({ innerRef, droppableProps, placeholder }) => (
                <div ref={innerRef} {...droppableProps}>
                  {products.map((product, productIndex) => (
                    <MenuProduct
                      key={product.id}
                      index={productIndex}
                      product={product}
                      isLoading={isLoading}
                      taxRatesList={taxRatesList}
                      onEditDialogOpen={handleProductEditDialogOpen}
                      onDelete={handleProductDelete}
                      onEdit={handleProductEdit}
                      isMenuDisabled={isMenuDisabled}
                    />
                  ))}
                  <span>{placeholder}</span>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </StyledCardContent>
      </Collapse>
    </StyledCard>
  )
}

SubcategoryItemsContainer.propTypes = {
  index: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  namePl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  subcategoryUuid: IdPropType.isRequired,
  products: PropTypes.array,
  collapse: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  pushEvent: PropTypes.func.isRequired,
  taxRatesList: PropTypes.array,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isMenuDisabled: PropTypes.bool,
  isSubcategoryImageDeleted: PropTypes.bool.isRequired,
  onProductCreate: PropTypes.func,
  onProductDelete: PropTypes.func,
  onProductEdit: PropTypes.func,
  onProductEditDialogOpen: PropTypes.func,
  onSubcategoryReorder: PropTypes.func,
  onSubcategoryEdit: PropTypes.func,
  onSubcategoryEditDialogOpen: PropTypes.func,
  onSubcategoryDelete: PropTypes.func,
  onProductModifierAdd: PropTypes.func,
  onProductModifierRemove: PropTypes.func,
}

SubcategoryItemsContainer.defaultProps = {
  imageUrl: '',
  products: [],
  collapse: false,
  isLoading: false,
  toggleCollapse: () => {},
  taxRatesList: [],
  isFirst: false,
  isLast: false,
  isMenuDisabled: false,
  onProductEditDialogOpen: () => {},
  onProductDelete: () => {},
  onProductEdit: () => {},
  onProductCreate: () => {},
  onSubcategoryReorder: () => {},
  onSubcategoryEdit: () => {},
  onSubcategoryEditDialogOpen: () => {},
  onSubcategoryDelete: () => {},
  onProductModifierAdd: () => {},
  onProductModifierRemove: () => {},
}

export default memo(SubcategoryItemsContainer)
