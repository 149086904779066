import { Form, Field } from 'formik'
import styled from 'styled-components/macro'
import { Box, Button, Card, Collapse, Typography } from '@material-ui/core'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { TextField } from '../../inputs'
import { BLUE, DARK_BLUE, DEEPER_GREEN, LIGHT_BLUE, RED } from '../../../constants/colors'
import ThinkingSVG from '../../../assets/images/forgot_password.svg'
import SoEasyLogo from '../../../assets/images/soeasy.png'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 150px);
  margin-left: 55px;

  @media (max-width: 450px) {
    margin: 0;
    padding: 5px 25px;
    width: 100%;
  }
`

const Container = styled(Card)`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 90%;
  max-width: 1100px;
  border-radius: 6px;
  background-color: white;

  @media (max-width: 815px) {
    grid-template-columns: 100%;
  }
`

const ContainerLeftPart = styled.article`
  position: relative;
  flex: 1;
  background-color: ${LIGHT_BLUE};

  @media (max-width: 1104px) {
    overflow: hidden;
  }

  @media (max-width: 815px) {
    display: none;
  }
`

const ThinkingImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 682px;
  padding: 0 20px;
  object-fit: cover;
  transition: transform 1s ease-in-out;
  transform: translateX(0);

  @media (max-width: 1104px) {
    transform: translateX(-17px);
  }
`

const ContainerRightPart = styled.article`
  flex: 1;
`

const ContainerHeader = styled.header`
  width: 100%;
  padding: 30px;
  font-size: 18px;
  text-align: right;
  font-weight: 800;
  letter-spacing: 0;

  @media (max-width: 450px) {
    padding-bottom: 0;
  }
`

const ContainerFooter = styled.footer`
  padding: 30px 0;
  margin-top: 110px;

  @media (max-width: 450px) {
    margin-top: 20px;
  }
`

const FormHeader = styled.h1`
  margin-bottom: 0;
`

const ResetTextHelper = styled.span`
  padding: 5px 5px 5px 0;
  margin-bottom: 24px;
  font-size: 14px;
`

const StyledButton = styled(Button)`
  margin-top: 16px;
  white-space: nowrap;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 6px;
`

const FooterLink = styled.a`
  position: relative;
  padding: 0 11px;
  opacity: 0.85;
  text-decoration: none;
  font-weight: 500;
  color: ${BLUE};

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: ${DARK_BLUE};
  }

  &:last-child::after {
    content: none;
  }
`

const SuccessAlert = styled(Box)(({ theme }) => `
  display: flex;
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  background-color: #f0f4ec;

  .MuiSvgIcon-root {
    color: ${DEEPER_GREEN};
    margin-right: ${theme.spacing(2)}px;
  }
`)

const ErrorAlert = styled(Box)(({ theme }) => `
  display: flex;
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  background-color: #ffecec;

  .MuiSvgIcon-root {
    color: ${RED};
    margin-right: ${theme.spacing(2)}px;
  }
`)

const NewPasswordForm = ({ wasSuccess }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <ContainerLeftPart>
        <ThinkingImage src={ThinkingSVG} alt="" />
      </ContainerLeftPart>
      <ContainerRightPart>
        <ContainerHeader>
          <span>SoEasy</span>
          <img src={SoEasyLogo} alt="logo SoEasy" />
        </ContainerHeader>
        <Form>
          <FormWrapper>
            <FormHeader>{t('newPassword.title')}</FormHeader>
            <ResetTextHelper>{t('newPassword.textHelper')}</ResetTextHelper>
            <Collapse in={wasSuccess}>
              <SuccessAlert>
                <CheckCircleOutline />
                <Typography>{t('newPassword.success')}</Typography>
              </SuccessAlert>
            </Collapse>
            <Collapse in={wasSuccess === false}>
              <ErrorAlert>
                <ErrorOutline />
                <Typography>{t('common.errors.generic')}</Typography>
              </ErrorAlert>
            </Collapse>
            <Collapse in={wasSuccess === null}>
              <Field
                name="password"
                type="password"
                label={t('newPassword.password.label')}
                component={StyledTextField}
                formatError={t}
              />
              <Field
                name="confirmPassword"
                type="password"
                label={t('newPassword.confirmPassword.label')}
                component={StyledTextField}
                formatError={t}
              />
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('newPassword.changePassword')}
              </StyledButton>
            </Collapse>
            <ContainerFooter>
              <FooterLink href="http://udrink.pl">{t('common.homeSite')}</FooterLink>
              <FooterLink href="mailto: support@udrink.pl">{t('common.help')}</FooterLink>
            </ContainerFooter>
          </FormWrapper>
        </Form>
      </ContainerRightPart>
    </Container>
  )
}

NewPasswordForm.defaultProps = {
  wasSuccess: null,
}

NewPasswordForm.propTypes = {
  wasSuccess: PropTypes.bool,
}

export default NewPasswordForm
