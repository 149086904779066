import { ACTIVE, INACTIVE } from '../constants/discountStatuses'
import { PERCENT, CURRENCY } from '../constants/discountTypes'

export default {
  translation: {
    drawer: {
      navigation: {
        mainSite: 'Main Site',
        clubs: 'Clubs',
        title: 'Navigation',
        users: 'Users',
        workers: 'Employees',
        taxRates: 'Tax rates',
        orderCollect: 'Order collect',
        orderPreparation: 'Order preparation places',
        orderDelivery: 'Delivery places',
        products: 'Products',
        menu: 'Menu',
        modifiers: 'Modifiers',
        inventory: 'Inventory',
        data: 'Data',
        statistics: 'Statistics',
        discountCodes: 'Discount codes',
        hours: 'Hours',
        delete: 'Delete club',
      },
      actions: {
        title: 'Action',
        change_location: 'Change location',
        logout: 'Logout',
      },
    },
    errorCode: {
      menu: {
        notInUse: 'The menu should not be in use',
      },
      discountCode: {
        notUnique: 'The code is not unique',
      },
    },
    routes: {},
    common: {
      email: 'Email',
      password: 'Password',
      signIn: 'Sign in',
      connect: 'Connect',
      ok: 'Ok',
      add: 'Add',
      next: 'Next',
      save: 'Save',
      update: 'Update',
      back: 'Back',
      errors: {
        generic: 'An error occured. Try again later.',
        email: {
          required: 'Email is required',
          invalid: 'Please enter a valid email',
        },
        password: {
          required: 'Password is required',
        },
      },
      titles: {
        adminPanel: 'Admin panel login',
      },
      languages: {
        pl: 'Polish',
        en: 'English',
      },
      countries: {
        pl: 'Poland',
      },
      or: 'or',
    },
    signIn: {
      errors: {
        wrongCredentials: 'The username or password provided is incorrect',
      },
    },
    clubs: {
      form: {
        name: '',
        nip: 'NIP',
        coordinates: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        beginHour: '',
        closeHour: '',
        daysOfWeek: {
          mon: 'Mo',
          tue: 'Tu',
          wed: 'We',
          thu: 'Th',
          fri: 'Fr',
          sat: 'Sa',
          sun: 'Su',
        },
      },
      success: 'Club successfully saved.',
      errors: {
        required: {
          name: '',
          address: '',
          zipCode: '',
          city: '',
          country: '',
        },
        noMatch: {
          zipCode: 'Nieprawidłowy format kodu pocztowego',
          coordinates: '',
        },
      },
    },
    user: {
      screen: {
        list: {
          username: 'Username',
          clubs: 'Clubs',
        },
        create: {
          success: 'Successfully created a user.',
        },
        edit: {
          success: 'Successfully edited a user.',
        },
        delete: {
          deleteUserAlertTitle: 'User {{username}} will be removed.',
          deleteAlertMessage: 'Do you confirm?',
          success: 'User successfully removed',
        },
      },
      role: {
        admin: 'Admin',
        manager: 'Manager',
        bartender: 'Bartender',
      },
      form: {
        email: 'E-mail',
        password: 'Password',
        confirmPassword: 'Confirm password',
        role: 'Role',
        clubs: 'Club',
      },
      errors: {
        email: {
          valid: 'Please enter a valid e-mail',
          required: 'E-mail is required',
        },
        password: {
          min: 'Password must have at least {{ min }} characters',
          required: 'Password is required',
        },
        confirmPassword: {
          notMatch: 'TODO not match',
          required: 'TODO required',
        },
        clubs: {
          required: 'TODO min 1 club required',
        },
      },
    },
    newPassword: {
      title: 'Password recovery',
      textHelper: 'Please enter and repeat the new password in the form below.',
      changePassword: 'Change password',
      password: {
        label: 'Password',
        helper: 'Enter a new password',
      },
      confirmPassword: {
        label: 'Confirm password',
        helper: 'Confirm new password',
      },
      success: 'Password successfully updated.',
    },
    signUp: {
      errors: {
        password: {
          min: "Password must have a minimum {{ min }} $t(words:character, { 'count': {{ min }} })",
          hasUppercase: 'Password must contain an uppercase letter.',
          hasLowercase: 'Password must contain a lowercase letter.',
        },
        confirmPassword: {
          notMatch: 'These passwords do not match. try again.',
          required: 'Confirm password',
        },
      },
    },
    taxRates: {
      success: 'Tax rates successfuly updated.',
    },
    orderCollect: {
      title: 'Collect order places',
      createTitle: 'Collect order place generation',
      editTitle: 'Editing a collect order place',
      name: 'Collect order place name',
      description: 'Collect order place description',
      alert: {
        message:
          'Deleting the link between the device and the collect order place will result in the need to pair again.',
      },
      success: {
        update: 'Collect order place has been updated',
        create: 'Collect order place has been added',
        delete: 'Collect order place has been deleted successfully',
      },
      errors: {
        name: {
          required: 'Collect order place name is required',
        },
        description: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
      },
      dialog: {
        deleteOrderCollectPlaceAlertTitle: 'Collect order place {{name}} will be deleted.',
      },
    },
    orderPreparation: {
      title: 'Order preparation place',
      createTitle: 'Order preparation place generation',
      editTitle: 'Editing an order preparation place',
      name: 'Order preparation place name',
      zone: 'Zone',
      oneOrderCollect: 'Collect order place',
      alert: {
        message:
          'Deleting the link between the device and the preparation order place will result in the need to pair again.',
      },
      zones: {
        bar: 'Bar',
        kitchen: 'Kitchen',
      },
      success: {
        update: 'Order preparation place has been updated',
        create: 'Order preparation place has been created',
        delete: 'Order preparation place has been deleted successfully',
      },
      errors: {
        name: {
          required: 'Order preparation place name is required',
        },
        description: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        orderCollectPlace: {
          required: 'Collect order place is required',
        },
      },
      dialog: {
        deleteOrderPreparationPlaceAlertTitle: 'Order preparation place {{name}} will be deleted.',
      },
    },
    orderDelivery: {
      title: 'Delivery place',
      name: 'Delivery place name',
      createTitle: 'Delivery place generation',
      editTitle: 'Editing a delivery place',
      success: {
        update: 'Delivery place has been updated',
        create: 'Delivery place has been created',
        delete: 'Delivery place has been successfully deleted',
      },
      errors: {
        name: {
          required: 'Name is required.',
        },
        description: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
      },
      dialog: {
        deleteOrderDeliveryPlaceAlertTitle: 'Delivery place {{name}} will be deleted.',
      },
    },
    tableTooltips: {
      availableForSale: 'Product in sale',
      onlyPreview: 'Product only for preview in menu',
      edit: 'Edit',
      delete: 'Delete',
      menu: 'Menu',
      qrCode: 'Show QR code',
    },
    product: {
      title: 'Product name',
      edition: 'Product editing',
      add: 'Add product',
      new: 'New product',
      price: 'Product price',
      portion: 'Portion',
      amount: 'Amount',
      maxCount: 'Maximum count',
      availabilityLabel: 'Availability period',
      wholeDay: 'Whole day',
      customPeriod: 'Custom period',
      setWholeDay: 'Set whole day',
      setCustomPeriod: 'Set custom period',
      description: 'Description',
      availabilityHours: {
        beginTime: 'Start of sale',
        endTime: 'End of sale',
      },
      productDialog: {
        basicInfo: 'Basic information',
        photo: 'Photo',
        modifiers: 'Modifiers',
        errors: 'Section contains fields with errors',
      },
      errors: {
        name: {
          required: 'Product name is required',
        },
        price: {
          isNumber: 'Price must be a number',
          isPositiveNumber: 'Price must be a positive number',
          minimum: 'Price cannot be lower than 0.01',
          required: 'Product price is required',
        },
        workZone: {
          required: 'Order preparation place is requred',
        },
        vatType: {
          required: 'The tax rate is required',
        },
        portion: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        description: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        value: {
          invalid: 'Incorrect data has been entered.',
        },
        delete: {
          productNotExist: 'The product you are trying to remove does not exist',
        },
      },
    },
    feedback: {
      name: 'Name',
      email: 'Email',
      whatHappened: 'What happened?',
      submit: 'Submit crash report',
      footer: 'Crash reports powered by',
      sent: 'Your feedback has been sent.',
      title: 'It looks like we\'re having some internal issues.',
      titleHelper: 'Our team has been notified. If you\'d like to help, tell us what happened below.',
      errors: {
        required: {
          name: 'Name is required',
          email: 'Email is required',
          comments: 'Comments are required.',
        },
        valid: {
          email: 'This email is not valid',
        },
      },
    },
    subcategory: {
      title: 'Subcategory name',
      polishTitle: 'Polish subcategory name',
      englishTitle: 'English subcategory name',
      new: 'Create new subcategory',
      edition: 'Edit subcategory',
      errors: {
        required: {
          name: 'Name is required',
          language: 'Email is required',
        },
        delete: {
          subcategoryNotExist: 'Subcategory, which are you trying to delete, doesn\'t exist',
        },
      },
    },
    discountCodes: {
      code: 'Code',
      codeGeneration: 'Code generation',
      autoGenerateCodeInfo: 'If you do not enter discount code it will be automatically generated by the system.',
      manualCodeGeneration: 'Custom discount code generation',
      limitations: 'Limitations',
      minimumOrderPrice: 'Minimum order price',
      discountCode: 'Discount code',
      deletionAlertTitle: 'Discount code "{{name}}" will be deleted.',
      deletionAlertMessage: 'Do you confirm?',
      [PERCENT]: 'Percent',
      [CURRENCY]: 'Value',
      [ACTIVE]: 'Active',
      [INACTIVE]: 'Inactive',
      discountValue: 'Discount value',
      discountPercent: 'Discount percent',
      totalUserAmount: 'Number of uses per person',
      discountLimitations: 'Discount limitations',
      totalAmount: 'Number of available codes',
      totalDiscountPrice: 'Pot',
      poolLimitations: 'Pot limitations',
      availabilityLimitations: 'Codes availability limitations',
      availableFrom: 'Available from',
      availableTo: 'Available to',
      createTitle: 'Discount code generation',
      editTitle: 'Editing a discount code',
      totalUserAmountInfo: 'If you want the user to be able to use the discount code X times you can enter the amount in this field. If the field is left blank, the user can use the code in an unlimited number of orders',
      totalAmountInfo: 'If you want to limit the total amount of use of the code by all users enter the amount in this field',
      totalDiscountPriceInfo: 'If you want the total of discounts to be limited enter the value in this field. Total discounts means the sum of all discount values. For example, for an order worth 100 PLN, using a 10% discount code adds 10 PLN to the total discounts',
      errors: {
        number: 'Numerical value required',
        isRequired: 'Field is required',
        tooSmall: 'Value is too small',
        name: {
          required: 'Discount code name is required',
        },
        description: {
          max: "Maximum number of characters exceeded, maximum {{ max }} $t(words:character, { 'count': {{ max }} })",
        },
        dateFrom: {
          minCurrent: 'Date cannot be in the past',
          maxDateTo: 'Date cannot be later than availability end date',
        },
        dateTo: {
          minCurrent: 'Date cannot be in the past',
          minDateFrom: 'Date cannot be earlier than availability start date',
        },
      },
      success: {
        create: 'Successfully created discount code',
        update: 'Successfully updated discount code',
        deleted: 'Successfully deleted discount code',
      },
    },
  },
}
