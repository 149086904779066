const APP_HEADERS = Object.freeze([
  {
    path: '/',
    code: 'drawer.navigation.mainSite',
  },
  {
    path: '/clubs/:clubId/order-collect-places',
    code: 'drawer.navigation.orderCollect',
  },
  {
    path: '/clubs/:clubId/order-preparation-places',
    code: 'drawer.navigation.orderPreparation',
  },
  {
    path: '/clubs/:clubId/order-delivery-places',
    code: 'drawer.navigation.orderDelivery',
  },
  {
    path: '/clubs/:clubId/products',
    code: 'drawer.navigation.products',
  },
  {
    path: '/clubs/:clubId/menu',
    code: 'drawer.navigation.menu',
  },
  {
    path: '/clubs/:clubId/inventory',
    code: 'drawer.navigation.inventory',
  },
  {
    path: '/clubs/:clubId/modifiers',
    code: 'drawer.navigation.modifiers',
  },
  {
    path: '/clubs/:clubId/discount-codes',
    code: 'drawer.navigation.discountCodes',
  },
])

export default APP_HEADERS
