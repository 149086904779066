import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClubClientsStatistic } from '@itsilesia/udrink-common-components'

import ClientsStatisticView from './view/ClientsStatisticView'
import IdPropType from '../../../../propTypes/IdPropType'
import useStatisticChart from '../../../../hooks/useStatisticChart'
import parseTimeScale from '../../../../utils/parseTimeScale'
import CLIENTS from '../../../../constants/clients'
import parseISODate from '../../../../utils/parseISODate'
import useSnackbar from '../../../../hooks/useSnackbar'

const ClientsStatistic = ({ clubId }) => {
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const handleError = ({ message }) => addSnackbar(t(message))

  const {
    from,
    to,
    timeScale,
    timePeriod,
    handleTimeScaleChange,
    handleTimePeriodChange,
  } = useStatisticChart({ handleError })

  const { data } = useClubClientsStatistic(clubId, {
    interval: timeScale.value,
    from,
    to,
  })
  const { clientsByIntervals } = data || { clientsByIntervals: {} }

  const chartData = useMemo(() => Object.entries(clientsByIntervals).map(
    ([dateTime, { oldClients, newClients }]) => {
      const [date] = parseISODate(dateTime).split('T')

      return {
        timeScale: parseTimeScale(timeScale.value, dateTime),
        [CLIENTS.OLD.value]: oldClients,
        [CLIENTS.NEW.value]: newClients,
        [CLIENTS.ALL.value]: oldClients + newClients,
        date,
      }
    },
  ), [data, timeScale]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ClientsStatisticView
    data={chartData}
    oldClientsCount={data?.totalOldClients}
    newClientsCount={data?.totalNewClients}
    timeScale={timeScale}
    timePeriod={timePeriod}
    from={from}
    to={to}
    onTimeScaleChange={handleTimeScaleChange}
    onTimePeriodChange={handleTimePeriodChange}
  />
}

ClientsStatistic.propTypes = {
  clubId: IdPropType.isRequired,
}

export default ClientsStatistic
