import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { authModule } from '@itsilesia/udrink-common-components'
import { createSelector } from 'reselect'

const isUserRoleInRoles = roles => (userRole) => {
  if (userRole === null) {
    return false
  }
  if (Array.isArray(roles)) {
    return roles.includes(userRole)
  }
  return roles === userRole
}

const createIsUserRoleInRolesSelector = roles =>
  createSelector(state => authModule.selectors.getUserRole(state.auth), isUserRoleInRoles(roles))

const useHasRole = (role) => {
  const getUserRoleInRoles = useMemo(() => createIsUserRoleInRolesSelector(role), [role])
  return useSelector(getUserRoleInRoles)
}

export default useHasRole
