import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Collapse } from '@material-ui/core'

import useCollapse from '../../../../../hooks/useCollapse'
import SectionHeader from './section/SectionHeader'
import SectionBody from './section/SectionBody'
import SectionContent from './section/SectionContent'
import OpeningHoursForm from '../../../OpeningHoursForm'

const HoursSection = ({ values, setFieldValue }) => {
  const { t } = useTranslation()
  const [collapse, toggleCollapse] = useCollapse()

  return (
    <SectionBody>
      <SectionHeader
        title={t('clubs.form.beginHour')}
        collapse={collapse}
        toggleCollapse={toggleCollapse}
      />
      <Collapse in={!collapse} timeout="auto">
        <SectionContent>
          <OpeningHoursForm values={values} setFieldValue={setFieldValue} />
        </SectionContent>
      </Collapse>
    </SectionBody>
  )
}

HoursSection.propTypes = {
  values: PropTypes.shape({
    openingHours: PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    }),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default HoursSection
