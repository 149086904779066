import { useParams } from 'react-router-dom'
import { useClub } from '@itsilesia/udrink-common-components'
import styled from 'styled-components/macro'

import RouteContainer from '../components/layout/RouteContainer'
import NavigationBreadcrumbs from '../components/navigation/NavigationBreadcrumbs'
import ClubDetails from '../components/clubs/ClubDetails/ClubDetails'
import ClubStatistics from '../components/clubs/ClubStatistics'
import ClubNavigation from '../components/clubs/ClubNavigation/ClubNavigation'

const Separator = styled.div`
  height: 20px;
`

const ClubDetailsScreen = () => {
  const params = useParams()
  const { data: club } = useClub(params.id)

  return (
    <RouteContainer fillHeight={false}>
      <NavigationBreadcrumbs />
      {club && (
        <>
          <ClubNavigation />
          <Separator />
          <ClubDetails club={club} />
          <Separator />
          <ClubStatistics club={club} />
        </>
      )}
    </RouteContainer>
  )
}

export default ClubDetailsScreen
